import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");


export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    loading: boolean;
    isJobRoleRequestSideBarOpen: boolean;
    isJobRoleRequestHeaderDropdownOpenVal: boolean;
    txtSavedValue: string;
    isLoading: boolean;
    jobRoleAllRequest: any[];
    projectName:any[];
    projectDepartment:any[];
    projectJobRoleRequest:any[];
    jobRoleInputs:any;
    validationStatusJobRole:any;
    projectRole:any[];
    totalPages: number
    count:number;
    totalJobRoleCount : number;
    vendorInviteCount : number;
    currentpage: number,
    open:boolean
    open2:boolean
    tabIndex:number
    jobRoleprojectName:string
    projectRequest:any[];
    allJobRoleRequestData:any[];
    requestProjectData:any[];
    requestProfileProjects:any[];
    requestedVendorData:any[];
    notification: { type: string, open: boolean, message: string, route?: string };
    JobRoleNotification: { type: string, open: boolean, message: string, route?: string };
    isProjectSelected:boolean
    isDepartmentSelected:boolean
    isRoleTypeSeleted:boolean
    roleReqError:any
    expError:any
    budgetError:any
    noOfPosError:any
    roleDesError:any
    setEditJobRole:boolean
    requestProfileData:any;
    requestProfileTabValue: number;
    bioReadMoreLessRequestPro: boolean;
    ratingAndReviewData:any;
    requestProfileRateValue:number;
    requestProfileCommentValue:string;
    requestRatingValidation:string;
    requestCommentValidation:string;
    inviteVendorModalJobRole:boolean;
    vendorList:any[];
    vendorListSearch:any[];
    inviteVendorProjects:any[];
    inviteVendorRatingAndReview:any;
    inviteVendorManageProfile:any;
    inviteVendorAccountId:number
    vendorInvitedToJobRole:boolean
    vendorInvitedToJobRoleId:number
    manageProfileInviteVendor:any[];
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string | number;
    // Customizable Area End
}

export default class BalanceSheetController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getJobRoleApiCallId: string = "";
    getProjectNameApiCallId : string ="";
    getProjectDepartmentCallId :string = "";
    getProjectDeptRoleApiCallId :string ="";
    getAllProjectJobRoleRequestCallID : string ="";
    getProjectRequestCallId :string ="";
    getAllJobRoleRequestCallID :string = "";
    addJobRoleApiCallId:string ="";
    deleteJobRoleApiCallId:string ="";
    editJobRoleApiCallId:string="";
    acceptVendorRequestCallId:string="";
    declineVendorRequestCallId:string="";
    requestProfileApiCallId:string="";
    ratingAndReviewApiCallId:string="";
    postRatingAndReviewApiCallId:string="";
    projectRequestProfileCallId:string="";
    requestVendorApiCallId:string="";
    inviteVendorApiCallId:string="";
    inviteVendorUserApiCallId:string="";
    inviteVendorManageProfileApiCallId:string="";
    inviteVendorProfileProjectsApiCallId:string="";
    ratingAndReviewInviteVendorApiCallId:string="";
    postRatingAndReviewInviteVendorApiCallId:string="";
    postInviteVendorApiCallId:string=""
    acceptRequestProfileCallId:string="";
    declineRequestProfileCallId:string="";
    removeFromJobApiCallId:string=""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            loading: false,
            isJobRoleRequestSideBarOpen: true,
            isJobRoleRequestHeaderDropdownOpenVal: false,
            txtSavedValue: "A",
            isLoading: false,
            jobRoleAllRequest: [],
            totalPages: 0,
            currentpage: 1,
            count:0,
            totalJobRoleCount:0,
            vendorInviteCount:0,
            projectName:[],
            projectDepartment:[],
            projectRole:[],
            open:false,
            open2:false,
            jobRoleInputs:{
                selectedProject:null,
                selectedProjectDept:null,
                selectedProjectRole:null,
                roleRequirements:[],
                expRequired:"",
                roleBudget:"",
                noOfPos:"",
                roleDescription:"",
                projectId:null,
                projectDeptId:null,
                roleID:null


            },
            roleReqError:{
                require:false
            },
            expError:{
                require:false
            },
            budgetError:{
                require:false
            },
            noOfPosError:{
                require:false
            },
            roleDesError:{
                require:false
            },
            validationStatusJobRole:{
                roleRequirements:{label:"Role Requirements" , valid:false , message :""},
                expRequired:{label:"Exp Required" , valid:false , message :""},
                roleBudget:{label:"Role Budget" , valid:false , message :""},
                noOfPos:{label:"No of Pos" , valid:false , message :""},
                roleDescription:{label:"Role Description" , valid:false , message :""}
            },
            tabIndex:0,
            jobRoleprojectName:'',
            projectJobRoleRequest:[],
            projectRequest:[],
            allJobRoleRequestData:[],
            requestProjectData:[],
            requestProfileProjects:[],
            requestedVendorData:[],
            notification: { type: "", open: false, message: "" },
            JobRoleNotification: { type: "", open: false, message: "" },
            isProjectSelected:true,
            isDepartmentSelected:true,
            isRoleTypeSeleted:true,
            setEditJobRole:false,
            requestProfileData: {
                id: "",
                profile_image: "",
                role: "",
                city: "",
                state: "",
                experience: "",
                gallary_photos: [],
                bank_detail: {
                  data: {
                    attributes: {
                      image: "",
                      bank_name: "",
                    }
                  }
                },
                account_detail: {
                  data: {
                    attributes: {
                      full_name: "",
                      country_code: "",
                      phone_number: "",
                      email: "",
                      aadhar_card_number: "",
                      gst_number: "",
                      pan_card_number: "",
                      tan_number: "",
                      account_number: "",
                      ifsc_code: "",
                      branch_name: "",
                    }
                  }
                },
                profile_bio: {
                  data: {
                    attributes: {
                      about_business: "",
                    }
                  },
                },
                documents: [],
              },
            requestProfileTabValue:0,
            bioReadMoreLessRequestPro:true,
            ratingAndReviewData:{},
            requestProfileRateValue:0,
            requestRatingValidation:'',
            requestCommentValidation:'',
            requestProfileCommentValue:'',
            inviteVendorModalJobRole:false,
            vendorList:[],
            vendorListSearch:[],
            inviteVendorProjects:[],
            inviteVendorRatingAndReview:{},
            inviteVendorAccountId:0,
            vendorInvitedToJobRole:false,
            vendorInvitedToJobRoleId:0,
            inviteVendorManageProfile:{
                // id: "12",
                // "type": "profile",
                attributes: {
                  id: 12,
                  country: "",
                  city: "",
                  postal_code: "",
                  account_id: "",
                  state: "",
                  address: "",
                  role: "",
                  department: "",
                  experience: "",
                  production_house_name: "",
                  profile_image: "",
                  documents: [],
                  bank_detail: {
                    data: null
                  },
                  account_detail: {
                    data: {
                      id: "",
                      type: "",
                      attributes: {
                        country_code: "",
                        email: "",
                        first_name: "",
                        full_phone_number: "",
                        last_name: "",
                        phone_number: "",
                        production_house_name: "",
                        production_house_address: "",
                        full_name: "",
                        gst_number: "",
                        tan_number: "",
                        aadhar_card_number: "",
                        pan_card_number: "",
                        account_number: "",
                        branch_name:"" ,
                        bank_name: "",
                        ifsc_code: "",
                        experience: "",
                        notification: "",
                        country_name: "",
                        platform: "",
                        device_id: "",
                        role_detail: {
                          id: "",
                          name: ""
                        },
                        profile_id: "",
                        profile_image: ""
                      }
                    }
                  },
                  profile_bio: {
                    data: ""
                  },
                  employee_invited: "",
                  vendor_invited_to_job_role: "",
                  gallary_photos: []
                }
              },

            manageProfileInviteVendor:[]
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
            let valueBalanceSheet = message.getData(getName(MessageEnum.AuthTokenDataMessage));


            this.setState({ txtSavedValue: valueBalanceSheet });
            this.showAlert(
                "Change Value",
                "From: " + this.state.txtSavedValue + " To: " + valueBalanceSheet
            );

        }
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if(apiRequestCallId === this.inviteVendorManageProfileApiCallId) {
                this.setState({
                 inviteVendorManageProfile:responseJson.data,
                 inviteVendorAccountId:responseJson.data.attributes.account_id,
                 vendorInvitedToJobRole : responseJson.data.attributes.vendor_invited_to_job_role,
                 vendorInvitedToJobRoleId : responseJson.data.attributes?.vendor_job_request_id
                })
              }

            const apiHandlers = {
                [this.getJobRoleApiCallId]: this.handleJobRoleApiResponse,
                [this.getProjectNameApiCallId]: this.getProjectNameData,
                [this.getProjectDepartmentCallId]: this.getProjectDepartment,
                [this.getProjectDeptRoleApiCallId]: this.getProjectDepartmentRole,
                [this.getAllProjectJobRoleRequestCallID]: this.handleAllProjectJobRoleResponse,
                [this.getAllJobRoleRequestCallID]: this.getAllJobRoleResponse,
                [this.addJobRoleApiCallId]: this.handleAddJobRoleResponse,
                [this.editJobRoleApiCallId]: this.handleEditJobRoleResponse,
                [this.deleteJobRoleApiCallId]: this.handleDeleteJobRoleResponse,
                [this.acceptVendorRequestCallId]:this.handleAcceptUpdateVendorRequest,
                [this.declineVendorRequestCallId]:this.handleDeclineUpdateVendorRequest,
                [this.acceptRequestProfileCallId] :this.handleAcceptRequestProfile,
                [this.declineRequestProfileCallId] : this.handleDeclineRequestProfile,
                [this.requestProfileApiCallId]: this.handleRequestProjectResponse,
                [this.ratingAndReviewApiCallId]: this.handleRatingAndReviewResponse,
                [this.postRatingAndReviewApiCallId] : this.handlePostRatingAndReviewResponse,
                [this.projectRequestProfileCallId] : this.handleProjectTabRequestProfile,
                [this.requestVendorApiCallId] : this.handleRequestedVendorResponse,
                [this.inviteVendorApiCallId] :this.handleInviteVendorResposne,   
                [this.inviteVendorProfileProjectsApiCallId] : this.handleInviteVendorProjectResponse, 
                [this.ratingAndReviewInviteVendorApiCallId] : this.handleInviteVendorRatingReviewResponse,
                [this.postRatingAndReviewInviteVendorApiCallId] : this.handlePostRatingInviteVendorResponse,    
                [this.postInviteVendorApiCallId] : this.handlePostInviteVendorResponse,
                [this.inviteVendorApiCallId] :this.handleInviteVendorResposne,    
                [this.removeFromJobApiCallId] : this.handleRemoveFromJobResponse            
            };
        
            const handler = apiHandlers[apiRequestCallId];
            if (handler) {
                handler.call(this, responseJson);
            }
            
        }

        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        await this.getMyAllJobRole();
        await this.getMyAllProjectName();
        await this.getMyJobRoleAndRequest();
        await this.getMyAllRequestprofileData();
        await this.getRatingAndReviewRequestProfile();
        await this.getProjectRequestProfile();
        await this.getRequestedVendorRequestJobRoleData();
        await this.getInviteVendorJobRoleResponse();
        await this.getManageProfileInviteVendorResponse()
        await this.getInviteVendorProfileProject();
        await this.getRatingAndReviewInviteVendorProfile();
        let isSideBarOpen = await getStorageData("sidebarvalue");
        this.setState({ isJobRoleRequestSideBarOpen: JSON.parse(isSideBarOpen) })
        const projectNames=  await getStorageData("jobRoleproject_Name");
        this.setState({jobRoleprojectName:projectNames})

    }

    handleJobRoleSideBar = () => {
        this.setState((prevState) => ({
            isJobRoleRequestSideBarOpen: !prevState.isJobRoleRequestSideBarOpen
        }), async () => {
            setStorageData("sidebarvalue", this.state.isJobRoleRequestSideBarOpen.toString())
        });
    };

    handleJobRoleHeaderDropdownVal = () => {
        this.setState({
            isJobRoleRequestHeaderDropdownOpenVal: !this.state.isJobRoleRequestHeaderDropdownOpenVal
        })
    };
    handleTabValueRequestProfileChange = (tabVal: number) => {
        this.setState({ requestProfileTabValue: tabVal });
    }

    handleBioReadRequestProfile = () => {
        this.setState({ bioReadMoreLessRequestPro: !this.state.bioReadMoreLessRequestPro });
    }

    requestProfileHandleDownloadPDF = (pdfURL: any, fileName: string) => {
        fetch(pdfURL)
          .then(VendorManageProfileResponse => {
            VendorManageProfileResponse.blob().then(blob => {
              let photoUrl = window.URL.createObjectURL(blob);
              let aTag = document.createElement('a');
              aTag.href = photoUrl;
              aTag.download = fileName;
              aTag.click();
            });
          });
    }

    handleJobRoleApiResponse(responseJson:any) {
        this.setState({
            jobRoleAllRequest: responseJson.data, 
            count: responseJson.meta.projects_count,
            totalJobRoleCount: responseJson.meta.total_job_role_request_count,
            isLoading: false,
        });
    }

    handleAllProjectJobRoleResponse= async(responseJson:any) => {
        this.setState({
            projectJobRoleRequest: responseJson.data,
            isLoading: false,
        });
    }

    handleAddJobRoleResponse(responseJson:any) {
        if (responseJson) {
            this.handleCloseJobRoleModel();
            this.setState({
                isLoading: false,
            });
        }
    }

    handleEditJobRoleResponse(responseJson:any) {
        if (responseJson) {
            this.handleCloseJobRoleModel1();
            this.getMyJobRoleAndRequest();
            this.setState({
                isLoading: false,
            });
        }
    }

    handleDeleteJobRoleResponse(responseJson:any) {
        if (responseJson) {
            this.getMyJobRoleAndRequest();
            this.setState({
                isLoading: false,
            });
        }
    }

    handleAcceptUpdateVendorRequest(responseJson:any) {
        if (responseJson.data) {
            this.getMyJobRoleAndRequest();
            this.setState({
                requestedVendorData:[]
            })
        }
        else{
            if (responseJson.errors && responseJson.errors.length > 0) {
                this.setState({ notification: { open: true, type: "error", message: responseJson.errors[0]} });
            }
        }
        this.setState({
            isLoading: false,
        });
    }

    handleDeclineUpdateVendorRequest(responseJson:any) {
        if (responseJson) {
            this.getRequestedVendorRequestJobRoleData();
            this.setState({
                isLoading: false,
                requestedVendorData:[]
            });
        }
    }

    handleAcceptRequestProfile(responseJson:any) {
        if (responseJson.data) {
            this.props.navigation.navigate("JobRolesAndRequest")
            this.getMyJobRoleAndRequest();

        }
        else{
            if (responseJson.errors && responseJson.errors.length > 0) {
                this.setState({ notification: { open: true, type: "error", message: responseJson.errors[0]} });
            }
        }
        this.setState({
            isLoading: false,
        });
    }

    handleDeclineRequestProfile(responseJson:any) {
        if (responseJson) {
            this.props.navigation.navigate("JobRolesAndRequest")
            this.getMyJobRoleAndRequest();
            this.setState({
                isLoading: false,
            });
        }
    }

    handleRequestProjectResponse(responseJson:any) {
        if(responseJson) {
            this.setState({
                requestProfileData:responseJson.data.attributes
            })
        }
    }
    handleRatingAndReviewResponse(responseJson:any) {
        if(responseJson){
            this.setState({
                ratingAndReviewData:responseJson,
                isLoading:false
            })
        }
    }

    handlePostRatingAndReviewResponse(responseJson:any){
        if(responseJson){
        this.getRatingAndReviewRequestProfile();
        this.setState({
            isLoading: false,
        });
        this.setState({
            requestProfileRateValue:0,
            requestProfileCommentValue:''
        })
        }
    }

    handleProjectTabRequestProfile(responseJson:any){
        if(responseJson){
            this.setState({
                requestProfileProjects:responseJson.data,
                isLoading: false,
            })
        }
    }

    handleRequestedVendorResponse(responseJson:any){
        if(responseJson.data){
            this.setState({
                requestedVendorData:responseJson.data
            })
        }
        this.setState({ isLoading: false });
    }

    handleInviteVendorResposne(responseJson:any){
        if(responseJson){
            this.setState({
                vendorList:responseJson.data,
                vendorListSearch:responseJson.data,
                vendorInviteCount: responseJson.meta.count,
            })
        }
    }

    handleInviteVendorProjectResponse(responseJson:any){
        if(responseJson){
            this.setState({
                inviteVendorProjects:responseJson.data
            })
        }
    }
   
    handleInviteVendorRatingReviewResponse(responseJson:any){
        if(responseJson){
            this.setState({
                inviteVendorRatingAndReview:responseJson
            })
        }
    }

    handleRemoveFromJobResponse(responseJson:any){
        if(responseJson){
            this.getManageProfileInviteVendorResponse()
            this.setState({ isLoading: false });
        }
    }

    navigateToAllJobRolePage=(navigate:string)=>{
        this.props.navigation.navigate(navigate)
    }

    navigateToRequestProfilePage=(navigate:string,profile_id:number,rateable_id:number,requestId:number)=>{
        setStorageData("profileId", profile_id);  
        setStorageData("rateableId",rateable_id)
        setStorageData("requestIdProfile",requestId)
        this.props.navigation.navigate(navigate)
    }

    handleSearchInviteVendor = (event:any) => {
        const updatedList: any[] | null = this.state.vendorListSearch && (this.state.vendorListSearch.filter((item) => {
          return item.attributes.full_name.toLowerCase().includes(event.target.value.toLowerCase())
        }) ?? null);
        this.setState({
          vendorList: updatedList
        })
      };

      handlePostRatingInviteVendorResponse(responseJson:any){
        if(responseJson){
        this.getRatingAndReviewInviteVendorProfile();
        this.setState({
            isLoading: false,
        });
        this.setState({
            requestProfileRateValue:0,
            requestProfileCommentValue:''
        })
        }
    }

    handlePostInviteVendorResponse(responseJson:any){
        if(responseJson){
            this.getManageProfileInviteVendorResponse()
            this.setState({
                isLoading: false,
            }); 
        }
    }



    navigateToRequestPage=(job_role_id:number)=>{
        setStorageData("jobRoleId", job_role_id);  
        this.props.navigation.navigate("Request",)
    }

    getMyAllJobRole = async () => {
        this.getJobRoleApiCallId = await this.allJobRoleRequest(configJSON.GET_Method, configJSON.getallJobRoleRequestEndPoint + `?page=1&per_page=9`);
    };

    getMyAllProjectName = async () => {
        this.getProjectNameApiCallId = await this.allProjectName(configJSON.GET_Method, configJSON.getAllProjectNameEndPoint);
    };

    getMyAllprojectDeptRole =async () =>{
        this.getProjectDeptRoleApiCallId = await this.allProjectRoleType(configJSON.GET_Method, configJSON.getProjectDeptRoleEndPoint)
    }

    getMyAllProjectDepartment =async () =>{
        this.getProjectDepartmentCallId =await this.allProjectDepartment(configJSON.GET_Method, configJSON.getAllProjectDepartmentEndPoint + `?project_id=${this.state.jobRoleInputs.projectId}`)
    }

    getMyAllProjectDepartmentEdit =async (projectId:any) =>{
        this.getProjectDepartmentCallId =await this.allProjectDepartment(configJSON.GET_Method, configJSON.getAllProjectDepartmentEndPoint + `?project_id=${projectId}`)
    }


    getMyJobRoleAndRequest = async () => {
        const projectJobId=  await getStorageData("jobRoleproject_Id");
        this.getAllProjectJobRoleRequestCallID = await this.allProjectJobRoleRequest(configJSON.GET_Method , configJSON.getAllProjectJobRoleRequestEndPoint + `?project_id=${projectJobId}`);
    }
    postMyAddJobRoleFormData =async () =>{
        this.addJobRoleApiCallId = await this.addJobRoleApi(configJSON.httpPostMethod,configJSON.postAddJobRoleEndPoint)
    }

    editMyJobRoleFormData = async () =>{
        let editJobId = await getStorageData("editJobRoleId");
        this.editJobRoleApiCallId = await this.jobRoleRequestEdit("PUT","bx_block_roles_permissions/job_roles/" + `${editJobId}`)
    }

    deleteJobRoleRequest =async (id:any) =>{
        this.deleteJobRoleApiCallId = await this.jobRoleRequestDelete(configJSON.DELETE_Method,configJSON.deleteJobRoleRequest + `/${id}`)
    }

    acceptVendorRequest =async(id:any)=>{
        this.acceptVendorRequestCallId = await this.acceptUpdateVendorRequest(configJSON.PATCH_Method,configJSON.acceptUpdateVendorEndPoint + `/${id}`)
    }

    declineVendorRequest = async(id:any)=>{
        this.declineVendorRequestCallId = await this.declineUpdateVendorRequest(configJSON.PATCH_Method,configJSON.acceptUpdateVendorEndPoint + `/${id}`)
    }

    acceptVendorRequestProfile=async()=>{
        let requestAcceptId = await getStorageData("requestIdProfile");
        this.acceptRequestProfileCallId = await this.acceptRequestProfile(configJSON.PATCH_Method,configJSON.acceptUpdateVendorEndPoint + `/${requestAcceptId}`)
    }

    declineVendorRequestProfile=async()=>{
        let requestDeclineId = await getStorageData("requestIdProfile");
        this.declineRequestProfileCallId = await this.declineRequestProfile(configJSON.PATCH_Method, configJSON.acceptUpdateVendorEndPoint + `/${requestDeclineId}`)
    }

    getMyAllRequestprofileData = async()=>{
        let requestProfileId = await getStorageData("profileId");
        this.requestProfileApiCallId = await this.getRequestProfileData(configJSON.GET_Method ,configJSON.getRequestProfileEndPoint + `/${requestProfileId}`)
    }

    getRatingAndReviewRequestProfile = async()=>{
        let requestProfileIdForRating = await getStorageData("profileId");
        this.ratingAndReviewApiCallId = await this.getRatingAndReviewData(configJSON.GET_Method , configJSON.getRatingAndReviewEndPoint + `?profile_id=${requestProfileIdForRating}`);
    }

    getProjectRequestProfile = async()=>{
        let requestProfileTabDataId = await getStorageData("profileId");
        this.projectRequestProfileCallId = await this.getProjectTabDataRequestProfile(configJSON.GET_Method , configJSON.getProfileDataRequestEndPoint + `/${requestProfileTabDataId}`)
    }

    getRequestedVendorRequestJobRoleData= async()=>{
        let requestVendorId = await getStorageData("jobRoleId");
        this.requestVendorApiCallId = await this.getJobRoleRequestedVendor(configJSON.GET_Method , configJSON.getRequestVendorEndPoint + `/${requestVendorId}`)
    }

    getInviteVendorJobRoleResponse=async()=>{
        this.inviteVendorApiCallId = await this.getInviteVendorJobRole(configJSON.GET_Method,configJSON.getInviteVendorEndPoint + `?page=1&per_page=9`)
    }

    getManageProfileInviteVendorResponse=async()=>{
        let jobRoleAndRequestCardId = await getStorageData("idForJobRoleInvite");
        let inviteVendorUserProfileId = await getStorageData("userInviteVendor");
        if(inviteVendorUserProfileId && jobRoleAndRequestCardId){
        this.inviteVendorManageProfileApiCallId = await this.getInviteVendorManageProfile(configJSON.GET_Method,configJSON.getRequestProfileEndPoint + `/${inviteVendorUserProfileId}?job_role_id=${jobRoleAndRequestCardId}`)
        }
    }

    getInviteVendorProfileProject=async()=>{
        let inviteVendorUserProfileId = await getStorageData("userInviteVendor");
        this.inviteVendorProfileProjectsApiCallId = await this.getInviteVendorManageProfileProjects(configJSON.GET_Method , configJSON.getProfileDataRequestEndPoint + `/${inviteVendorUserProfileId}`)
    }

    getRatingAndReviewInviteVendorProfile = async()=>{
        let inviteVendorUserProfileId = await getStorageData("userInviteVendor");
        this.ratingAndReviewInviteVendorApiCallId = await this.getInviteVendorManageProfileRatings(configJSON.GET_Method , configJSON.getRatingAndReviewEndPoint + `?profile_id=${inviteVendorUserProfileId}`);
    }

    deletefromJobInviteVendor = async()=>{
        this.removeFromJobApiCallId = await this.removeFromJobInviteVendor(configJSON.DELETE_Method,configJSON.deleteFormJobEndPoint + `/${this.state.vendorInvitedToJobRoleId}`)
    }

    editJobRoleRequest = async(id:any) =>{       
        setStorageData("editJobRoleId", id);  
        this.setState({ setEditJobRole: true });
        this.setState({open2:false})
        for(let i=0;i<=this.state.projectJobRoleRequest.length;i++){
             if(this.state.projectJobRoleRequest[i]?.id === id){
                this.getMyAllProjectDepartmentEdit(this.state.projectJobRoleRequest[i].attributes.project_id);
                this.getMyAllprojectDeptRole();
                const project= {

                    label: this.state.projectJobRoleRequest[i].attributes.project_name,
                     value: this.state.projectJobRoleRequest[i].attributes.project_id
                }
                const projectDeptSelection= {

                    label: this.state.projectJobRoleRequest[i].attributes.project_department,
                     value: this.state.projectJobRoleRequest[i].attributes.project_department_id
                }
                const projectRoleSelection= {

                    label: this.state.projectJobRoleRequest[i].attributes.role,
                     value: this.state.projectJobRoleRequest[i].attributes.role_id
                }
                this.setState({jobRoleInputs:{
                selectedProject:project,
                selectedProjectDept:projectDeptSelection,
                selectedProjectRole:projectRoleSelection,
                roleRequirements:this.state.projectJobRoleRequest[i].attributes.job_role_requirment.map((item:any)=>item),
                expRequired:this.state.projectJobRoleRequest[i].attributes.exp_required,
                roleBudget:this.state.projectJobRoleRequest[i].attributes.budget_of_role,
                noOfPos:this.state.projectJobRoleRequest[i].attributes.number_of_positions,
                roleDescription:this.state.projectJobRoleRequest[i].attributes.role_description
    }})
}}}

    handlePageChange = async (event: any, value: number) => {
        this.getJobRoleApiCallId = await this.allJobRoleRequest(configJSON.GET_Method, configJSON.getallJobRoleRequestEndPoint + `?page=${value}&per_page=9`);
        window.scrollTo(0, 0);
        this.setState({ currentpage: value });
    };

    handlePageChangeInviteVendor =async(event:any,value:number)=>{
        this.inviteVendorApiCallId = await this.getInviteVendorJobRole(configJSON.GET_Method,configJSON.getInviteVendorEndPoint + `?page=${value}&per_page=9`);
        window.scrollTo(0, 0);
        this.setState({ currentpage: value });
    }

    handleRateStar=(e:any)=>{
    if(e.target.value===''){
        this.setState({requestRatingValidation:'Please give rating'})
    }
    else{
        this.setState({requestRatingValidation:''});
    }
    this.setState({requestProfileRateValue:e.target.value}) 
    }

    handleCommentRequestProfile=(e:any)=>{
    if(e.target.value===''){
        this.setState({requestCommentValidation:'Please give rating'})
    }
    else{
        this.setState({requestCommentValidation:''});
    }
    this.setState({requestProfileCommentValue:e.target.value})
    } 


    handleOpenAddJobRole=()=>{
        this.setState({
            open:true
        })
    }
    navigateToJobRolesAndRequest=(jobRoleprojectId:any,jobRoleprojectName:any)=>{
        this.props.navigation.navigate("JobRolesAndRequest")
        setStorageData("jobRoleproject_Id", jobRoleprojectId);   
        setStorageData("jobRoleproject_Name",jobRoleprojectName);
      }

    covertToPascalCaseProfileProject = (text = '', trimSpace = false) => {
        if (typeof text !== 'string') {
            return '';
        }
    
        return text.split(' ').map((t) => t[0]?.toUpperCase() + t.slice(1).toLowerCase()).join(trimSpace ? '' : ' ')
    }

    handleCloseJobRoleModel = () => {
        this.setState({
            open: false,
            jobRoleInputs: {
                ...this.state.jobRoleInputs,
                selectedProject: null,
                selectedProjectDept:null,
                selectedProjectRole:null,
                roleRequirements:[],
                expRequired:"",
                roleBudget:"",
                noOfPos:"",
                roleDescription:"",
            },
            projectDepartment: [],
            projectRole: [],
            isProjectSelected: true,
            isDepartmentSelected: true,
            isRoleTypeSeleted: true ,
            roleReqError:{require:false},
            expError:{require:false},
            budgetError:{require:false},
            noOfPosError:{require:false},
            roleDesError:{require:false},
            requestProfileCommentValue:"",
            requestProfileRateValue:0,
            requestRatingValidation:'',
            requestCommentValidation:'',
        });
    }

    handleCloseJobRoleModel1 =()=>{
        this.setState({
            setEditJobRole:false
        })
    }

    handleJobRoleEditAndDelete = (id: any, event?: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
        if (event) {
            event.stopPropagation();
        }
    
        this.setState(prevState => {
            const isSameId = prevState.open2 === id;
            return { open2: isSameId ? null : id };
        });
    
        if (this.state.open2 !== id) {
            document.addEventListener('click', this.handleOutsideClick);
        } else {
            document.removeEventListener('click', this.handleOutsideClick);
        }
    };
    
    handleOutsideClick = (event: MouseEvent) => {
        const dropdown = document.getElementById('dropdownMenu');
        const isClickInsideDropdown = dropdown?.contains(event.target as Node);
        const isClickOnHamburger = event.target instanceof HTMLElement && event.target.closest('[data-test-id="editJobRolehamburger"]');
        
        if (!isClickInsideDropdown && !isClickOnHamburger) {
            this.setState({ open2: false });
            document.removeEventListener('click', this.handleOutsideClick);  
        }
    }
   
    getProjectNameData=(responseJson:any)=>{
        const fetchedProject = responseJson?.data?.map((item: { id: number, attributes: { project_name: string } }) => ({
            id: item.id,
            name: item.attributes.project_name,
        }));
        this.setState({
             projectName:fetchedProject,
             isLoading:false
         });
    }

    getProjectDepartment=(responseJson:any)=>{
        const projectDepartmentResponse = responseJson?.data?.map((item :{ id: number, attributes: { name: string } })=>({
            department:item.attributes.name,
            dept_id:item.id,
        }));
        this.setState({
            projectDepartment:projectDepartmentResponse,
            isLoading:false
        }) 
    }

    getProjectDepartmentRole=(responseJson:any)=>{
        const projectDeptRoleRes = responseJson?.data?.map((item : {id:number , name:string})=>({
            deptRole:item.name,
            role_id:item.id
        }));
        this.setState({
            projectRole:projectDeptRoleRes,
            isLoading:false
        })
    }

    getAllJobRoleResponse=(responseJson:{ errors: { token: string }[], data: any })=>{
        if(responseJson.data){
            this.setState({
                allJobRoleRequestData:responseJson.data
            })
        }
        else if({ notification: { open: true, type: "error", message: responseJson.errors[0].token, route: "Request" } }){
            this.setState({ notification: { open: true, type: "error", message: responseJson.errors[0].token, route: "Request" } });
        }
        this.setState({ isLoading: false });
    }

    handleProjectName = (selectedOption:any) => {
        this.setState((prevState) => ({
            jobRoleInputs: {
                ...prevState.jobRoleInputs,
                selectedProject: selectedOption,
                selectedProjectDept: "",
                projectId: selectedOption.value,
                selectedProjectRole: ""
            },
            isProjectSelected:true
        }), () => {
            this.getMyAllProjectDepartment();
        });
    };
    
    handleProjectdepartment = (selectedOption:any) => {
        this.setState((prevState) => ({
            jobRoleInputs: {
                ...prevState.jobRoleInputs,
                selectedProjectDept: selectedOption,
                projectDeptId: selectedOption.value
            },
            isDepartmentSelected:true
        }), () => {
            this.getMyAllprojectDeptRole();
        });
    };
    

    handleProjectDeptRole =(selectedOption:any) =>{
        this.setState((prevState)=>({
            jobRoleInputs:{
            ...prevState.jobRoleInputs,    
            selectedProjectRole : selectedOption,
            roleID : selectedOption.value
            },
            isRoleTypeSeleted:true
        }),()=>{
            this.getMyAllprojectDeptRole()
        })
    }

    handleSubmitFormAddJobRole=()=>{
        if(!this.state.jobRoleInputs.selectedProject || !this.state.jobRoleInputs.selectedProjectDept ||!this.state.jobRoleInputs.selectedProjectRole || this.state.jobRoleInputs.roleRequirements.length<=0 || !this.state.jobRoleInputs.expRequired || !this.state.jobRoleInputs.roleBudget || !this.state.jobRoleInputs.noOfPos || !this.state.jobRoleInputs.roleDescription){
        this.validateJobRoleRequest();
        return false
    }    
    !this.state.setEditJobRole  ? this.postMyAddJobRoleFormData() : this.editMyJobRoleFormData()
     }

    validateJobRoleRequest=()=>{
        if (!this.state.jobRoleInputs.selectedProject) {
            this.setState({ isProjectSelected: false });
        }
        
        if (!this.state.jobRoleInputs.selectedProjectDept) {
            this.setState({ isDepartmentSelected: false });
        }
        
        if (!this.state.jobRoleInputs.selectedProjectRole) {
            this.setState({ isRoleTypeSeleted: false });
        }
        
        if (this.state.jobRoleInputs.roleRequirements.length===0) {
            this.setState({ roleReqError: { require: true } });
        }
        
        if (!this.state.jobRoleInputs.expRequired) {
            this.setState({ expError: { require: true } });
        }
        
        if (!this.state.jobRoleInputs.roleBudget) {
            this.setState({ budgetError: { require: true } });
        }
        
        if (!this.state.jobRoleInputs.noOfPos) {
            this.setState({ noOfPosError: { require: true } });
        }
        
        if (!this.state.jobRoleInputs.roleDescription) {
            this.setState({ roleDesError: { require: true } });
        }
    }

    

    handleChangeRoleRequirements = (items:any) => {
        const eachvalue=items
        if (!eachvalue) {
            this.setState({
                roleReqError:{
                    require:true
                }
            })
        }
   
        else {
            this.setState((prevState)=>({
                jobRoleInputs:{
                ...prevState.jobRoleInputs,    
                roleRequirements : eachvalue,
                },
                roleReqError:{
                    require:false
        }
            }))
        }
    }

    handleChangeExp=(event:any) =>{
        const expReqData = event?.target.value;
        const regex= /^\d{0,10}-?\d{0,10}$/
        if(expReqData === '' || regex.test(expReqData)){
            this.setState((prevState)=>({
                jobRoleInputs:{
                ...prevState.jobRoleInputs,    
                expRequired : expReqData
                },
            }))
        }
        if (!expReqData) {
            this.setState({
                expError:{
                    require:true
                }
            })
            return false;
        } else {
            this.setState({
                expError:{
                    require:false
                }
            })
        }
    }

    handleChangeBudget=(event:any)=>{
        const budgetData = event?.target.value;
        const regexBudget= /^[\d\s,-]{1,20}$/;
        if(budgetData === '' || regexBudget.test(budgetData)){
        this.setState((prevState)=>({
            jobRoleInputs:{
            ...prevState.jobRoleInputs,    
            roleBudget : budgetData
            },
        }))
    }
        if (!budgetData) {
            this.setState({
                budgetError:{
                    require:true
                }
            })
            return false;
        } else {
            this.setState({
                budgetError:{
                    require:false
                }
            })
        }
    }

    handleChangePos=(event:any)=>{
        const noOfPosData= event.target.value
        const regexPos= /^[0-9\b]+$/
        if(noOfPosData === '' || regexPos.test(noOfPosData)){
        this.setState((prevState)=>({
            jobRoleInputs:{
            ...prevState.jobRoleInputs,    
            noOfPos : noOfPosData
            },
        }))
    }
        if (!noOfPosData) {
            this.setState({
                noOfPosError:{
                    require:true
                }
            })
            return false;
        } else {
            this.setState({
                noOfPosError:{
                    require:false
                }
            })
        }
    }

    handleChangeRoleDes=(event:any)=>{
        const roleDesData= event?.target.value
        this.setState((prevState)=>({
            jobRoleInputs:{
            ...prevState.jobRoleInputs,    
            roleDescription : roleDesData
            },
        }))
        if (!roleDesData) {
            this.setState({
                roleDesError:{
                    require:true
                }
            })
            return false;
        } else {
            this.setState({
                roleDesError:{
                    require:false
                }
            })
        }

    }

    handleRatingAndReviewForm=()=>{
        if(this.state.requestProfileCommentValue===''&& this.state.requestProfileRateValue===0){
          this.setState({
            requestRatingValidation:"Please give rating",
            requestCommentValidation:'Please give review'
        })
        }
        else if(this.state.requestProfileRateValue===0){
          this.setState({requestRatingValidation:"Please give rating"})
        }
        else if(this.state.requestProfileCommentValue===''){
            this.setState({requestCommentValidation:'Please give review'})
            this.setState({requestProfileRateValue:0})
        }
        else{
            this.postRatingAndReviewData();
        }

    }

    handleRatingAndReviewInviteVendorForm=()=>{
        if(this.state.requestProfileCommentValue===''&& this.state.requestProfileRateValue===0){
          this.setState({
            requestRatingValidation:"Please give rating",
            requestCommentValidation:'Please give review'
        })
        }
        else if(this.state.requestProfileRateValue===0){
          this.setState({requestRatingValidation:"Please give rating"})
        }
        else if(this.state.requestProfileCommentValue===''){
            this.setState({requestCommentValidation:'Please give review'})
            this.setState({requestProfileRateValue:0})
        }
        else{
            this.postInviteVendorRatingAndReviewData();
        }

    }

    handleInviteVendorModalJobRole = (jobRoleIdForInvite:number) => {
        setStorageData("idForJobRoleInvite", jobRoleIdForInvite);  
        this.setState({ inviteVendorModalJobRole: !this.state.inviteVendorModalJobRole })
    }

    navigateToManageProfilesJobRole = (inviteVendorUserId:any) => {
        setStorageData("userInviteVendor", inviteVendorUserId);  
        this.props.navigation.navigate("ManageProfileInviteVendor",);
    }
    
      handleCloseJobRoleNotification = () => {
        this.setState({ notification: { type: "", open: false, message: "" } });
      }

    allJobRoleRequest = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestAll = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestAll.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessageJobRoleRequestAll.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestAll.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageJobRoleRequestAll.id, requestMessageJobRoleRequestAll);
        return requestMessageJobRoleRequestAll.messageId;
    };

    allProjectName = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestProjectName = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestProjectName.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessageJobRoleRequestProjectName.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestProjectName.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageJobRoleRequestProjectName.id, requestMessageJobRoleRequestProjectName);
        return requestMessageJobRoleRequestProjectName.messageId;
    };

    allProjectDepartment = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestProjectDept = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestProjectDept.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessageJobRoleRequestProjectDept.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestProjectDept.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageJobRoleRequestProjectDept.id, requestMessageJobRoleRequestProjectDept);
        return requestMessageJobRoleRequestProjectDept.messageId;
    };

    allProjectRoleType = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestRoleType = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestRoleType.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessageJobRoleRequestRoleType.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestRoleType.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageJobRoleRequestRoleType.id, requestMessageJobRoleRequestRoleType);
        return requestMessageJobRoleRequestRoleType.messageId;
    };

    allProjectJobRoleRequest = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestProject = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestProject.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessageJobRoleRequestProject.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestProject.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageJobRoleRequestProject.id, requestMessageJobRoleRequestProject);
        return requestMessageJobRoleRequestProject.messageId;
    };

    allJobRole = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageAllJobRoleRequestRole = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageAllJobRoleRequestRole.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessageAllJobRoleRequestRole.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageAllJobRoleRequestRole.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageAllJobRoleRequestRole.id, requestMessageAllJobRoleRequestRole);
        return requestMessageAllJobRoleRequestRole.messageId;
    };

    addJobRoleApi = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestProject = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestProject.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        const job_roleData = {
            job_role:{
            role_description: this.state.jobRoleInputs.roleDescription,
            exp_required: this.state.jobRoleInputs.expRequired,
            budget_of_role: this.state.jobRoleInputs.roleBudget,
            number_of_positions:this.state.jobRoleInputs.noOfPos,
            project_id:this.state.jobRoleInputs.projectId,
            project_department_id :this.state.jobRoleInputs.projectDeptId,
            role_id:this.state.jobRoleInputs.roleID,
            job_role_requirment: this.state.jobRoleInputs.roleRequirements
            }
            }

        requestMessageJobRoleRequestProject.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(job_roleData)
        );
        requestMessageJobRoleRequestProject.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );
        requestMessageJobRoleRequestProject.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageJobRoleRequestProject.id, requestMessageJobRoleRequestProject);
        return requestMessageJobRoleRequestProject.messageId;
    };

    jobRoleRequestDelete = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestRole = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestRole.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessageJobRoleRequestRole.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestRole.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageJobRoleRequestRole.id, requestMessageJobRoleRequestRole);
        return requestMessageJobRoleRequestRole.messageId;
    };

    jobRoleRequestEdit = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestRole = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestRole.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        const job_roleData_edit = {
            job_role:{
            role_description: this.state.jobRoleInputs.roleDescription,
            exp_required: this.state.jobRoleInputs.expRequired,
            budget_of_role: this.state.jobRoleInputs.roleBudget,
            number_of_positions:this.state.jobRoleInputs.noOfPos,
            project_id:this.state.jobRoleInputs.projectId,
            project_department_id :this.state.jobRoleInputs.projectDeptId,
            role_id:this.state.jobRoleInputs.roleID,
            job_role_requirment: this.state.jobRoleInputs.roleRequirements
            }
            }

            requestMessageJobRoleRequestRole.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(job_roleData_edit)
        );

        

        requestMessageJobRoleRequestRole.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestRole.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageJobRoleRequestRole.id, requestMessageJobRoleRequestRole);
        return requestMessageJobRoleRequestRole.messageId;
    };

    acceptUpdateVendorRequest = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestAccept = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestAccept.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        const vendorJobRequest = {
            vendor_job_request:{
                status: "accept",
            }
        }
        requestMessageJobRoleRequestAccept.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(vendorJobRequest)
        );

        requestMessageJobRoleRequestAccept.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestAccept.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageJobRoleRequestAccept.id, requestMessageJobRoleRequestAccept);
        return requestMessageJobRoleRequestAccept.messageId;
    };

    declineUpdateVendorRequest = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestDecline = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestDecline.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        const vendorJobRequest = {
            vendor_job_request:{
                status: "decline",
            }
        }

        requestMessageJobRoleRequestDecline.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(vendorJobRequest)
        );

        requestMessageJobRoleRequestDecline.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestDecline.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageJobRoleRequestDecline.id, requestMessageJobRoleRequestDecline);
        return requestMessageJobRoleRequestDecline.messageId;
    };

    acceptRequestProfile = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageAcceptRequestProfile = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageAcceptRequestProfile.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        const vendorJobRequest = {
            vendor_job_request:{
                status: "accept",
            }
        }
        requestMessageAcceptRequestProfile.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(vendorJobRequest)
        );

        requestMessageAcceptRequestProfile.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageAcceptRequestProfile.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageAcceptRequestProfile.id, requestMessageAcceptRequestProfile);
        return requestMessageAcceptRequestProfile.messageId;
    };

    declineRequestProfile = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageDeclineRequestProfile = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageDeclineRequestProfile.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        const vendorJobRequest = {
            vendor_job_request:{
                status: "decline",
            }
        }
        requestMessageDeclineRequestProfile.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(vendorJobRequest)
        );

        requestMessageDeclineRequestProfile.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageDeclineRequestProfile.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageDeclineRequestProfile.id, requestMessageDeclineRequestProfile);
        return requestMessageDeclineRequestProfile.messageId;
    };


    getRequestProfileData = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestProfile = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestProfile.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessageJobRoleRequestProfile.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestProfile.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageJobRoleRequestProfile.id, requestMessageJobRoleRequestProfile);
        return requestMessageJobRoleRequestProfile.messageId;
    };

    getRatingAndReviewData = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestProjects = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestProjects.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessageJobRoleRequestProjects.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestProjects.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageJobRoleRequestProjects.id, requestMessageJobRoleRequestProjects);
        return requestMessageJobRoleRequestProjects.messageId;
    };

    getProjectTabDataRequestProfile = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageRequestedJobRoleVendor = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageRequestedJobRoleVendor.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessageRequestedJobRoleVendor.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageRequestedJobRoleVendor.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageRequestedJobRoleVendor.id, requestMessageRequestedJobRoleVendor);
        return requestMessageRequestedJobRoleVendor.messageId;
    };

    postRatingAndReviewData = async () => {
        let requestProfileRatableId = await getStorageData("rateableId");
        this.setState({open:false})
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestRating = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.postRatingAndReviewApiCallId = requestMessageJobRoleRequestRating.messageId;

        requestMessageJobRoleRequestRating.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postRatingAndReviewEndPoint
        );

        const httpBody = {
            review : {
              "comment":this.state.requestProfileCommentValue,
              "given_star":this.state.requestProfileRateValue,
              "reviewable_id":requestProfileRatableId,
              "reviewable_type":"AccountBlock::Account"
            }
        };

        requestMessageJobRoleRequestRating.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
        );

        requestMessageJobRoleRequestRating.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestRating.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPostMethod
        );
        runEngine.sendMessage(requestMessageJobRoleRequestRating.id, requestMessageJobRoleRequestRating);
        return requestMessageJobRoleRequestRating.messageId;
    };

    getJobRoleRequestedVendor = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestVendor = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestVendor.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessageJobRoleRequestVendor.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestVendor.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageJobRoleRequestVendor.id, requestMessageJobRoleRequestVendor);
        return requestMessageJobRoleRequestVendor.messageId;
    };

    getInviteVendorJobRole = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        const requestMessageJobRoleRequestInviteVendor = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestInviteVendor.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessageJobRoleRequestInviteVendor.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestInviteVendor.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageJobRoleRequestInviteVendor.id, requestMessageJobRoleRequestInviteVendor);
        return requestMessageJobRoleRequestInviteVendor.messageId;
    };

    getInviteVendorManageProfile = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        const requestMessageManageProfileInviteVendor = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageManageProfileInviteVendor.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessageManageProfileInviteVendor.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageManageProfileInviteVendor.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageManageProfileInviteVendor.id, requestMessageManageProfileInviteVendor);
        return requestMessageManageProfileInviteVendor.messageId;
    };

    getInviteVendorManageProfileProjects = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        const requestMessageManageProfileInviteVendorProjects = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageManageProfileInviteVendorProjects.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessageManageProfileInviteVendorProjects.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageManageProfileInviteVendorProjects.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageManageProfileInviteVendorProjects.id, requestMessageManageProfileInviteVendorProjects);
        return requestMessageManageProfileInviteVendorProjects.messageId;
    };

    getInviteVendorManageProfileRatings = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        const requestMessageManageProfileInviteVendorRatings = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageManageProfileInviteVendorRatings.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessageManageProfileInviteVendorRatings.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageManageProfileInviteVendorRatings.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageManageProfileInviteVendorRatings.id, requestMessageManageProfileInviteVendorRatings);
        return requestMessageManageProfileInviteVendorRatings.messageId;
    };

    postInviteVendorRatingAndReviewData = async () => {
        this.setState({open:false})
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageInviteVendorRating = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.postRatingAndReviewInviteVendorApiCallId = requestMessageInviteVendorRating.messageId;

        requestMessageInviteVendorRating.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postRatingAndReviewEndPoint
        );

        const httpBody = {
            review : {
              "comment":this.state.requestProfileCommentValue,
              "given_star":this.state.requestProfileRateValue,
              "reviewable_id":this.state.inviteVendorAccountId,
              "reviewable_type":"AccountBlock::Account"
            }
        };

        requestMessageInviteVendorRating.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
        );

        requestMessageInviteVendorRating.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageInviteVendorRating.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPostMethod
        );
        runEngine.sendMessage(requestMessageInviteVendorRating.id, requestMessageInviteVendorRating);
        return requestMessageInviteVendorRating.messageId;
    };

    postInviteforJob = async () => {
        let jobRoleAndRequestCardId = await getStorageData("idForJobRoleInvite");
        const projectJobId=  await getStorageData("jobRoleproject_Id");
        this.setState({open:false})
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageInviteForJob = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.postInviteVendorApiCallId = requestMessageInviteForJob.messageId;

        requestMessageInviteForJob.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postInviteVendorEndPoint
        );

        const httpBody = {
            "vendor_job_request" : {
                "status" :"accept",
                "project_id": projectJobId,
                "job_role_id" :jobRoleAndRequestCardId,
                "account_id":this.state.inviteVendorAccountId,

            }
        };

        requestMessageInviteForJob.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
        );

        requestMessageInviteForJob.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageInviteForJob.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPostMethod
        );
        runEngine.sendMessage(requestMessageInviteForJob.id, requestMessageInviteForJob);
        return requestMessageInviteForJob.messageId;
    };

    removeFromJobInviteVendor = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageManageProfileremoveFromJob = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageManageProfileremoveFromJob.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessageManageProfileremoveFromJob.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageManageProfileremoveFromJob.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageManageProfileremoveFromJob.id, requestMessageManageProfileremoveFromJob);
        return requestMessageManageProfileremoveFromJob.messageId;
    };
    // Customizable Area End
}
