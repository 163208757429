import React from "react";
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { InvoiceProfile, Path, arrowinvoice, jobyrs } from "./assets";

interface MyProps {
    data: any;
    navigateToinvoiceDetail: any;
}

const useStyles = makeStyles({
    borderBox: () => ({
        position: 'absolute', borderBottom: '2px dashed lightgray', top: "17px", left: "7px", width: "95%"
    }),
    cardDivWithOpenSideBar: () => ({
        width: '100%',
        borderRadius: 14,
        border: "1px solid #E1E6EC",
        '& .MuiCardContent-root': {
        },
        "@media(min-width: 1199px)": {
        },
    }),
    cardDivWithClosedSideBAr: () => ({
        //width: 346,
        borderRadius: 14,
        padding: 8,
        border: "1px solid #E1E6EC",
        position: 'relative',
        '& .MuiCardContent-root': {
            padding: 0
        },
        "@media(max-width: 500px)": {
        },
    }),
    parentDiv: () => ({
    }),
    ParentSubheading1: () => ({
        display: "flex",
        gap: "5px",
    }),
    headingDept: () => ({
        display: 'flex',
        justifyContent: 'space-between',
    }),
    positionDiv: () => ({
        display: 'flex',
        gap: '5px',
        borderRadius: '15px',
        backgroundColor: '#f8fafe',
        borderColor: '#979797',
        height: 31,
        width: 101,
        alignItems: 'center'
    }),
    iconposition: () => ({
    }),
    DeptDiv: () => ({
        fontSize: '13px',
        fontFamily: 'urbanist',
        color: '#17181D',
        fontWeight: 500,
        lineHeight: '16px',
    }),
    Dept: () => ({}),
    mainDiv: () => ({
        paddingTop: '4px',
        lineHeight: '19px',
        fontSize: '16px',
        fontWeight: 600,
        fontFamily: 'urbanist',
        color: '#17181D'
    }),
    YrsDiv: () => ({
        display: 'flex',
        gap: '4px',
        paddingTop: '6px',
        '& .MuiBox-root': {
            fontSize: '13px',
            fontWeight: '600px',
            color: '#6d89af',
        }
    }),
    textDiv: () => ({
        '& .MuiBox-root': {
            fontSize: '13px',
            fontWeight: '600px',
        }
    }),
    jobiconBox: () => ({}),
    jobiconyear: () => ({
        height: '16px',
        width: '16px'
    }),
    rupeeicon: () => ({
        paddingTop: '4px',
        height: '16px',
        width: '16px'
    }),
    rupeesDiv: () => ({
        display: 'flex',
        gap: '4px',
        borderBottom: '1px solid #e3eaf4',
        paddingBottom: '11px',
        '& .MuiBox-root': {
            display: 'flex',
            gap: '4px',
            fontSize: '14px',
            color: '#6d89af',
            fontWeight: 400,
            fontFamily: 'Helvetica',
            paddingTop: '5px',
        }
    }),
    jobcardimage: () => ({
        //paddingTop:'10px',
        height: '40px',
        width: '40px',
        borderRadius: '10px'
    }),
    InvoiceandStatus: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: "12px",
        paddingBottom: "8px",
    },
    InovoiceprofileandInformation: {
        display: "flex",
        gap: '10px'
    },
    Invoiceprofile: {},
    Invoiceinformation: {
        paddingTop: "4px",
        paddingBottom: "2px",
    },
    Invoicename: {
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '16px',
        fontWeight: 600,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: "230px"
    },
    invoicedetail: {
        paddingTop: "4px",
        color: ' #17181d',
        fontFamily: 'urbanist',
        fontSize: '12px',
        fontWeight: 500,
    },
    status: {
        ///color: '#3389ff',
        fontFamily: 'urbanist',
        fontSize: '11px',
        fontWeight: 600,
        textAlign: 'right',
        paddingTop: "4px",
        paddingRight: "4px",
    },
    positionandicon: {
        display: 'flex',
        alignItems: 'center',
        gap: '3px',
        paddingLeft: '13px',
    },
    positionicon: {},
    positioninvoice: {
        color: '#6d89af',
        fontFamily: 'urbanist',
        fontSize: '11px',
        fontWeight: 500,
        textAlign: 'center',
    },
    rupeyeandarrow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingInline: "16px",
        paddingBottom: "10px",
    },
    rupeye: {
        color: '#17181d',
        fontFamily: 'Helvetica',
        fontSize: '14px',
        fontWeight: 400,
    },
    arrowicon: {
        width: '26px',
        height: '26px',
        borderRadius: '15px',
        background: '#e1e6ec',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        cursor: "pointer",
    },
    specialpayandleftandrighticon: {
        paddingTop: '7px',
        justifyContent: 'space-between',
        display: 'flex',
        width: "100%"
    },
    specialpayandicon1: {
        zIndex: 11,
        display: 'flex',
        gap: '5px',
        width: '95px',
        height: '24px',
        alignItems: 'center',
        borderRadius: '12px',
        justifyContent: 'center',
        background: '#efffe3',
        color: '#42a200',
        fontFamily: 'Urbanist',
        fontSize: '10px',
        fontWeight: 600,
        margin: "auto",
        marginTop: "-2px",
    },
    specialpayandicon: {
        zIndex: 11,
        display: 'flex',
        gap: '5px',
        width: '95px',
        height: '24px',
        background: 'linear-gradient(225deg, #58a2ff 0%, #1777e2 100%)',
        alignItems: 'center',
        borderRadius: '12px',
        justifyContent: 'center',
        margin: "auto"
    },
    specialpayicon: {},
    specialpay: {
        color: '#ffffff',
        fontFamily: 'urbanist',
        fontSize: '10px',
        fontWeight: 600,
    },
    ShapeDiv: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    ShapeDiv1: {
        border: '1px solid #e3eaf4',
        background: '#ffffff',
        transform: 'scaleX(-1) rotate(180deg)'
    },
    ShapeDiv2: {
    },
    footerDiv: () => ({
        display: "flex"
    }),
    addressDiv: () => ({
        paddingTop: '13px'
    }),
    address1: () => ({
        fontSize: '14px',
        fontFamily: 'urbanist',
        fontWeight: 600,
        color: '#17181D',
    }),
    address2: () => ({
        display: 'flex',
        gap: '4px'
    }),
    ParentSubheading2: () => ({
        fontSize: '11px',
        fontFamily: 'urbanist',
        fontWeight: 500,
        color: '#6d89af',
        paddingTop: '22px'
    }),
    locationDiv: () => ({
        fontSize: '12px',
        fontWeight: 600,
        fontFamily: 'urbanist',
        color: '#6d89af',
    }),
    parentBox: () => ({
        width: '100%'
    }),
    rupeetext: () => ({})
});

export default function InvoiceCard(props: MyProps) {

    const { data, navigateToinvoiceDetail } = props
    const classes = useStyles();
    const formatTypeOfInvoice = (type_of_invoice: any) => {
        const formattedString = type_of_invoice.replace(/_/g, ' ')
            .toLowerCase()
            .replace(/(?:^|\s)\S/g, (char: any) => char.toUpperCase());

        return formattedString;
    };

    function formatAmountWithComma(amount: any) {
        if (amount === null || amount === undefined) {
            return ''; // Handle null or undefined values as needed
        }

        const [wholeNumberPart, decimalPart] = amount.toFixed(2).split('.');
        const formattedWholeNumber = parseInt(wholeNumberPart, 10).toLocaleString();

        return `₹${formattedWholeNumber}.${decimalPart}`;
    }

    return (
        <Box className={classes.parentBox}>
            <Box className={classes.cardDivWithOpenSideBar}>
                <Box className={classes.InvoiceandStatus}>
                    <Box className={classes.InovoiceprofileandInformation}>
                        <Box className={classes.Invoiceprofile}>
                            <img src={data?.attributes?.project?.project_image || InvoiceProfile} alt="" className={classes.jobcardimage} />
                        </Box>
                        <Box className={classes.Invoiceinformation}>
                            <Box title={data?.attributes?.project?.name} className={classes.Invoicename}>{data?.attributes?.project?.name}</Box>
                            <Box className={classes.invoicedetail}>Dept | {data?.attributes?.project_department?.name}</Box>
                        </Box>
                    </Box>
                    {
                        data?.attributes?.status === "rejected" ?
                            (
                                <Box className={classes.status} style={{ color: '#FF5C5C' }}>
                                    Rejected
                                </Box>

                            ) : data?.attributes?.status === "approved" ? (
                                <Box className={classes.status} style={{ color: '#3389FF' }}>
                                    Approved
                                </Box>
                            ) : data?.attributes?.status === "pending" ? (
                                <Box className={classes.status} style={{ color: '#F7B500' }}>
                                    Pending
                                </Box>
                            ) :
                                (
                                    <Box className={classes.status} style={{ color: '#F7B500' }}>
                                        On Hold
                                    </Box>
                                )
                    }
                </Box>
                <Box className={classes.positionandicon}>
                    <Box className={classes.positionicon}>
                        <img src={jobyrs} alt="" className={classes.iconposition} />
                    </Box>
                    <Box className={classes.positioninvoice}>
                        {data?.attributes?.role}
                    </Box>
                </Box>
                <Box style={{ position: "relative" }} >
                    <Box className={classes.specialpayandleftandrighticon} >
                        <Box className={classes.borderBox}></Box>
                        <Box style={{ width: "11.93px", height: "18px", border: "1px solid #e3eaf4", background: "#f8fafe", transform: "scaleX(-1) rotate(180deg)", borderRadius: "0px 9px 9px 0px", borderLeft: "none", marginLeft: "-1px" }}></Box>
                        {data?.attributes?.type_of_invoice === "cash_invoice" ? (
                            <Box className={classes.specialpayandicon1}>
                                {formatTypeOfInvoice(data?.attributes?.type_of_invoice)}
                            </Box>
                        ) : (
                            <Box className={classes.specialpayandicon}>
                                <Box className={classes.specialpayicon}>
                                    <img src={Path} alt="" />
                                </Box>
                                <Box className={classes.specialpay}>
                                    {formatTypeOfInvoice(data?.attributes?.type_of_invoice)}
                                </Box>
                            </Box>
                        )}
                        <Box style={{ width: "11.93px", height: "18px", border: "1px solid #e3eaf4", background: "#f8fafe", transform: "scaleX(-1) rotate(180deg)", borderRadius: "9px 0px 0px 9px", borderRight: "none", marginRight: "-1px" }}></Box>
                    </Box>
                </Box>
                <Box className={classes.rupeyeandarrow}>
                    <Box className={classes.rupeye}>
                        {formatAmountWithComma(data?.attributes?.total_amount)}
                    </Box>
                    <Box onClick={() => navigateToinvoiceDetail(data.id)} className={classes.arrowicon}>
                        <img src={arrowinvoice} alt="" />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
