import React from "react";
import { Card, Box, CardMedia, CardContent, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { JobCardImage, LocationIconjob, dotsIcon, headingicon, Bg_Copy, leaveproject, editstudio,  BookingOpen, BookingClose, RequestBook, MytaskProfile, arrowinvoice, CancelStudio } from "./assets";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreHoriz";
import Menu from "@material-ui/core/Menu";
interface MyProps {
    data: any;
    cancelbooking:any;
    detailpage:any
}



const useStyles = makeStyles({
    cardDivWithOpenSideBar: () => ({
        width: 300,
        //height: 240,
        borderRadius: 14,
        padding: 8,
        border: "1px solid #E1E6EC",
        position: 'relative',
        '& .MuiCardContent-root': {
            padding: 0
        },
        "@media(max-width: 500px)": {

        },
        "@media(min-width: 1440px)": {
            width: '312px',
        },
        backgroundImage: `url(${Bg_Copy})`, // Assuming JobCardImage is the path to your image
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: 200,
    }),
    cardDivWithClosedSideBAr: () => ({
        width: 346,
        borderRadius: 14,
        padding: 8,
        border: "1px solid #E1E6EC",
        position: 'relative',
        '& .MuiCardContent-root': {
            padding: 0
        },
        "@media(max-width: 500px)": {

        },
    }),
    lowertask:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        paddingLeft:'12px',
        paddingRight:'12px',
        paddingTop:'12px'
    },
    parentBoxValue: () => ({
        display: "flex",
        justifyContent: 'space-between',



    }),
    imageandname:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        gap:'5px'

    },
    subtaskrequest: {
        //width: '341px',
        height: '54px',
        border: '1px solid #e3eaf4',
        background: '#f8fafe',
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: '12px',
        paddingRight: '12px'
    },
    daydiv:{
        width: '58px',
        height: '24px',
        borderRadius: '13px',
        background: '#17181d',
        color: '#ffffff',
  fontFamily: 'Urbanist',
  fontSize: '10px',
  fontWeight: 700,
  letterSpacing: '0.16666667px',
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  marginBottom:'4px'
    },
    moneydiv:{
        color: '#42a200',
        fontFamily: "Helvetica-Bold",
        fontSize: '12px',
        fontWeight: 400,
        textAlign: 'center',
        letterSpacing: '0.2px',
    }
,
arrowdiv:{},

    parentBox: () => ({
        height: "177px",
        borderRadius: "12px",
        boxShadow: "0px 7px 18px rgba(24,39,42,0.07)",
        cursor: "pointer",
        paddingBottom: '20px'
        //display:'flex',
        // justifyContent:'space-between'
    }),
    imageContainer: () => ({
        height: "100%",
        width: "100%",
        position: "relative",
    }),
    studioImage: () => ({
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: "12px"
    }),
    studioStatus: () => ({
        position: "absolute",
        top: "10px",
        right: "10px",
        textAlign: "end",
        height: "23px",
    }),
    studioDetailsMainBox: () => ({
        position: "absolute",
        bottom: "5px",
        right: "5px",
        left: "5px",
        background: "#fff",
        height: 95,
        borderRadius: "10px",
        opacity: 0.8290272,
        padding: 12,
        boxSizing: "border-box",
    }),
    studioDetails: () => ({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    }),
    ParentSubheading1: () => ({
        display: "flex",
        gap: "5px",

    })
    ,
    backgroundImage: {
        // Adjust the height according to your requirement
    },
    humbergerparent: {
        display: 'flex',
        paddingLeft: '23px',
        alignItems: 'center',
        gap: '10px',
        paddingBottom: '5px',
        paddingTop:'5px'
    },
    humbergerParentIcon: {

    }, humbergericon: {
        height: '16px',
        width: '16px',
    },
    humbergerLabel: {
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontWeight: 600,

    },
    ProfileInformationandhumberger: {

        display: 'flex',
        justifyContent: "space-between",
        paddingLeft: '12px',
        paddingRight: '12px',
        paddingTop: '12px',
        width: '100%',
        paddingBottom:'12px'
    },
    ProfileimageandName: {
        display: 'flex',
        gap: '5px'
    },
    LeftDiv:{
        //position: 'absolute',
        width: '45px',
        border: '1px dashed rgb(225, 230, 236)',
        //top: '346px',
        // left: '429px',
        height:'0px',
        marginTop:'10px',
       display:'flex'
    },
    RightDiv:{
        width: '45px',
        border: '1px dashed rgb(225, 230, 236)',
        //top: '346px',
        // left: '429px',
        height:'0px',
        marginTop:'10px'
    },
    
    rectangle1:{
        //position: 'absolute',
        //left: '-2px',
        //top: '-7px',
        width: '15px',
        height: '15px',
        borderRadius: '6px',
        background: '#fff2c8',
        display:'flex',
    
        justifyContent:'center',
        alignItems:'center',
        marginTop:'-7px'
    }
    ,
    rectangle2:{
        //position: 'absolute',
        //right: '-2px',
        //top: '-7px',
        width: '15px',
        height: '15px',
        borderRadius: '6px',
        background: '#fff2c8',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        position: 'absolute',
        top:4,
        right: 0
    },
    smlrectangle1:{
        //position: 'absolute',
        //left: '4px',
        top: '4px',
        width: '8px',
        height: '8px',
        borderRadius: '26px',
        background: '#f5c42c',
    },
    
    smlrectangle2:{
        //position: 'absolute',
        //right: '4px',
        //top: '4px',
        width: '8px',
        height: '8px',
        borderRadius: '26px',
        background: '#f5c42c',
    },
    Profile: {

    },
    NameandAddress: {
        display: 'flex',
        flexDirection: 'column',
        gridRowGap: '4px'
    },
    Name: {
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '16px',
        fontWeight: 600,
        letterSpacing: '0.2px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpce: 'nowrap',
        maxWidth: '200px'
    },
    cityandstate: {
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '12px',
        fontWeight: 500,
        letterSpacing: '0.15px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '200px'
    },
    paymoney: {
        color: ' #f7b500',
        fontFamily: 'Urbanist',
        fontSize: '12px',
        fontWeight: 500,
        letterSpacing: '0.2px',

    },
    Profilediv: {
        height: '59px',
        width: '59px',
        borderRadius: '12px',
        boxShadow: '0px 7px 18px rgba(24,39,42,0.07)'
    }
,
opentime:{
    color:' #6d89af',
  fontFamily: 'Urbanist',
  fontSize: '10px',
  fontWeight: 500,
  letterSpacing: '0.2px'
},
enddate:{
    color: "#17181d",
    fontFamily: 'Urbanist',
    fontSize: '12px',
    fontWeight: 600,
    textAlign: 'right',
    letterSpacing:' 0.2px',
    paddingBottom:'4px',
},
enddatetime:{
    color: '#6d89af',
  fontFamily: 'Urbanist',
  fontSize: '10px',
  fontWeight: 500,
  textAlign: 'right',
  letterSpacing: '0.2px'

},
startingdateandtime:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center'

},
daysandmoney:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center'
},
enddataandtime:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center'
},
bottomprofile:{
    height:'36px',
    width:'36px',
    borderRadius:'18px',
},
namelowertask:{
    color: '#17181d',
  fontFamily: 'Urbanist',
  fontSize: '13px',
  fontWeight: 600,
  lineHeight: '19px',
  letterSpacing: '0.2px',
},
positionlower:{
    color:' #17181d',
  fontFamily: 'Urbanist',
  fontSize: '11px',
  fontWeight: 500,
  lineHeight: '16px',
  letterSpacing: '0.2px'
},
arrowBox:{
    width: '26px',
  height: '26px',
  borderRadius: '15px',
  background: '#e1e6ec',
  display:'flex',
  justifyContent:'center',
  alignItems:'center'
}

});

export default function UpcomingStudioList(props: MyProps) {
    const { data,cancelbooking,detailpage } = props

    const classes = useStyles();
    const ITEM_HEIGHT = 48;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openhumberger = Boolean(anchorEl);
    const handleClick = (event: any) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClosehumberger = () => {

        setAnchorEl(null);
    };
    const options = [

        { label: "Cancel Booking", icon: CancelStudio },
    
    ];
    const handleOptionClick = (event: any, label: string) => {
        event.stopPropagation();
        handleClosehumberger(); // Close the dropdown

        // Perform different actions based on the clicked label
        if (label === 'Cancel Booking') {
            cancelbooking(data?.id)
        } 

    };
    const formatDate = (dateString:any) => {
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', options as any);
      };

    return (
        <Box className={classes.parentBox}>
            <Box className={classes.ProfileInformationandhumberger}>
                <Box className={classes.ProfileimageandName}>
                    <Box className={classes.Profile}>
                        <img src={data?.attributes?.studio?.image_url} className={classes.Profilediv} />
                    </Box>
                    <Box className={classes.NameandAddress}>
                        <Box className={classes.paymoney}>
                        {data?.attributes?.time_difference?.time}  Left
                        </Box>
                        <Box title={data?.attributes?.studio?.name} className={classes.Name}>
                         {data?.attributes?.studio?.name}
                        </Box>
                        <Box title={`${data.attributes.studio.address}, ${data.attributes.studio.city} (${data.attributes.studio.state})`} className={classes.cityandstate}>
                        {data.attributes.studio.address}, {data.attributes.studio.city} ({data.attributes.studio.state})
                        </Box>


                    </Box>
                </Box>
                <Box style={{ paddingRight: "12px" }}>

                    <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={openhumberger ? "long-menu" : undefined}
                        aria-expanded={openhumberger ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                        style={{ color: '#6D89AF' }}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="long-menu"
                        MenuListProps={{
                            "aria-labelledby": "long-button",
                        }}
                        anchorEl={anchorEl}
                        open={openhumberger}
                        onClose={handleClosehumberger}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: "20ch",
                                left: '489px'
                            },
                        }}
                    >
                        {options.map((option, index) => (
                            <div key={index} onClick={(e) => handleOptionClick(e, option.label)} >
                                <Box className={classes.humbergerparent}>
                                    <Box className={classes.humbergerParentIcon}>
                                        <img src={option.icon} alt="" className={classes.humbergericon} />
                                    </Box>
                                    <Box className={classes.humbergerLabel}>
                                        {option.label}
                                    </Box>
                                </Box>
                            </div>
                        ))}
                    </Menu>

                </Box>

            </Box>
            {
                    data?.attributes?.booking_type==="hour_wise"?(
                        <Box className={classes.subtaskrequest}>
                        <Box className={classes.startingdateandtime}>
                            <Box style={{
                                color: '#17181d',
                                fontFamily: 'Urbanist',
                                fontSize: '12px',
                                fontWeight: 600,
                                textAlign: 'right',
                                letterSpacing: '0.2px',
                                paddingBottom:'4px'
                            }}>
                               {formatDate(data?.attributes?.date)}
                            </Box>
                            <Box className={classes.opentime} >
                            {data?.attributes?.start_time}
        
                            </Box>
                        </Box>
                        
                            
                        <Box className={classes.daysandmoney}>
                            <Box style={{display:'flex', position: 'relative'}}>
                            <Box className={classes.LeftDiv}>
                                <Box className={classes.rectangle1}>
                                    <Box className={classes.smlrectangle1}>
        
                                    </Box>
                                </Box>
        
                                {/* <Box className={classes.rectangle2}>
                                    <Box className={classes.smlrectangle2}></Box>
                                </Box> */}
                            </Box>
                            <Box className={classes.daydiv}>
                            {data?.attributes?.total_hours} HOURS
        
                            </Box>
                            <Box className={classes.RightDiv}>
                        <Box className={classes.rectangle2}>
                                    <Box className={classes.smlrectangle2}></Box>
                                </Box>
                        </Box>
                            </Box>
                            <Box className={classes.moneydiv}>
                                ₹{data?.attributes?.total_amount}
                            </Box>
        
                        
                      
                        </Box>
                        
                        <Box className={classes.enddataandtime}>
                            <Box className={classes.enddate}>
                            {formatDate(data?.attributes?.date)}
                            </Box>
                            <Box className={classes.enddatetime}>
                            {data?.attributes?.end_time}
                            </Box>
                        </Box>
        
        
                    </Box>

                    ):(
                        <Box className={classes.subtaskrequest}>
                        <Box className={classes.startingdateandtime}>
                            <Box style={{
                                color: '#17181d',
                                fontFamily: 'Urbanist',
                                fontSize: '12px',
                                fontWeight: 600,
                                textAlign: 'right',
                                letterSpacing: '0.2px',
                                paddingBottom:'4px'
                            }}>
                                 {formatDate(data?.attributes?.start_date)}
                            </Box>
                            <Box className={classes.opentime} >
                            {data?.attributes?.open_time}

                            </Box>
                        </Box>
                        
                            
                        <Box className={classes.daysandmoney}>
                            <Box style={{display:'flex', position: 'relative'}}>
                            <Box className={classes.LeftDiv}>
                                <Box className={classes.rectangle1}>
                                    <Box className={classes.smlrectangle1}>
        
                                    </Box>
                                </Box>
        
                                {/* <Box className={classes.rectangle2}>
                                    <Box className={classes.smlrectangle2}></Box>
                                </Box> */}
                            </Box>
                            <Box className={classes.daydiv}>
                            {data?.attributes?.total_days} DAYS
        
                            </Box>
                            <Box className={classes.RightDiv}>
                        <Box className={classes.rectangle2}>
                                    <Box className={classes.smlrectangle2}></Box>
                                </Box>
                        </Box>
                            </Box>
                            <Box className={classes.moneydiv}>
                                ₹{data?.attributes?.total_amount}
                            </Box>
        
                        
                      
                        </Box>
                        
                        <Box className={classes.enddataandtime}>
                            <Box className={classes.enddate}>
                            {formatDate(data?.attributes?.end_date)}
                            </Box>
                            <Box className={classes.enddatetime}>
                            {data?.attributes?.close_time}
                            </Box>
                        </Box>
        
        
                    </Box>
                    )
                    
            }
       <Box  className={classes.lowertask}>
        <Box className={classes.imageandname}>
           <Box>
           <img src={data?.attributes?.vendor?.data?.attributes?.profile_image} className={classes.bottomprofile} />
           </Box>
           <Box>
                <Typography className={classes.namelowertask}>{data?.attributes?.vendor?.data?.attributes?.full_name}</Typography>
                <Typography className={classes.positionlower}>{data?.attributes?.vendor?.data?.attributes?.role}</Typography>
           </Box>
        </Box>
        <Box className={classes.arrowBox} 
         onClick={()=>detailpage(data.attributes.studio_id,data.id)}
        >
        <img src={arrowinvoice} className={classes.arrowdiv} />

        </Box>
       </Box>
        </Box>
    );
}

