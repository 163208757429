import React from "react";
// Customizable Area Start
import ProductionHouseSignUpController, {
  Props
} from "./ProductionHouseSignUpController";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Box, Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, Snackbar, Step, StepIconProps, StepLabel, Stepper, Typography, styled, withStyles } from "@material-ui/core";
import StepConnector from '@material-ui/core/StepConnector';
import { CustomButton, CustomInputlabelEmail, CustomTextField, SignInInputLabel } from "../../email-account-login/src/CustomButton";
// Customizable Area End

// Customizable Area Start
import { cornerImage, logoIcon, mainImage, verifiedCircularIcon, visibilityEyeIcon, visibilityEyeOffIcon } from "./assets";
import Loader from "../../../components/src/Loader.web";
import "./productionHouseSignUp.css";
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector.web";
import VerifyOtpModel from "../../../components/src/VerifyOtpModel";
import CustomNotification from "../../../components/src/CustomNotification";
export const configJSON = require("./config");

const CustomPHSignUPButtonBox = styled(CustomButton)({
  '& .MuiButton-label': {
    fontSize: 16,
    color: '#ffffff',
    fontWeight: 600,
    width: 163,
    height: 48,
  }
});

const GreenPHCheckbox = withStyles({
  root: {
    color: '#6d89af',
    '& .MuiSvgIcon-root': {
      fontSize: 20
    },
    '&$checked': {
      color: '#f5c42c',
    },
  },
  checked: {}
})(Checkbox);

const CustomPHBackButtonBox = styled(CustomButton)({
  background: "#ffffff",
  border: "1px solid #17181d",
  '& .MuiButton-label': {
    fontSize: 16,
    color: '#17181d',
    fontWeight: 600,
    width: 163,
    height: 48,
  }
});

const CustomCSSOfTextField = withStyles({
  root: {
    backgroundColor: 'white',
    borderColor: "#f5c42c",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E3EAF4"
      },
      "&:hover fieldset": {
        borderColor: "#f5c42c"
      },
      "&.Mui-focused fieldset": {
        borderColor: "#f5c42c",
        backgroundColor: 'rgba(245,196,44,0.04)'
      },
      fontWeight: 400,
      color: '#323232',
      borderRadius: "14px",
      "& .MuiOutlinedInput-input": {
        fontWeight: 600,
        color: '#17181D',
        lineHeight: '19px',
        borderColor: '#e3eaf4',
        fontSize: '14px',
        fontFamily: 'Urbanist',
      }
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#f5c42c"
    },
    "& .MuiInputBase-input::placeholder": {
      color: '#6d89af',
      fontWeight: 500,
      fontFamily: 'Urbanist',
    },
    '& .MuiOutlinedInput-inputAdornedStart': {
            paddingLeft: '30px'
        }
  }
})(CustomTextField);

export const ColorlibConnector = styled(StepConnector)(() => ({
  '&.MuiStepConnector-alternativeLabel': {
    top: 16,
    left: 'calc(-50% - 16px)',
    right: 'calc(50% - 16px)',
  },
  '&.MuiStepConnector-active': {
    '& .MuiStepConnector-line': {
      borderColor: '#f5c42c',
    },
  },
  '&.MuiStepConnector-completed': {
    '& .MuiStepConnector-line': {
      borderColor: '#f5c42c',
    },
  },
  '& .MuiStepConnector-line': {
    borderColor: '#e3eaf4',
    borderTopWidth: '3px'
  },
}));

export const ColorlibStepIconRoot = styled('div')(() => ({
  backgroundColor: '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
}));
// Customizable Area End

export default class ProductionHouseSignUp extends ProductionHouseSignUpController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderErrorMessage(type: boolean, message: string) {
    return <>
      {type ? <Typography style={{ color: 'red', fontFamily: 'Urbanist', fontSize: '13px', fontWeight: 600, lineHeight: '18px', letterSpacing: '0.4px' }}>{message}</Typography> : null}
    </>
  }

  ColorlibStepIcon(props: StepIconProps) {
    const { active, completed } = props;

    const icons: { [index: string]: any } = {
      1: "01",
      2: "02",
      3: "03",
    };

    const getclassName = () => {
      if (active) {
        return "activeIcon"
      } else if (completed) {
        return "completedIcon"
      } else {
        return "disbaleIcon"
      }
    }

    return (
      <ColorlibStepIconRoot className={`stepIcon ${getclassName()}`}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  renderStepOneView() {
    return <>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CustomInputlabelEmail id="nameOfPH" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px", paddingTop: '16px' }}>Name of Production House</CustomInputlabelEmail>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CustomCSSOfTextField data-test-id="nameOfPHInput" type={'text'} id="outlined-basic" variant="outlined" placeholder="Enter the name of production house"
          value={this.state.nameOfPH}
          onChange={(e: any) => this.handleChangeNameOfPHInput(e)}
          className="email_input"
          onFocus={() => {
            this.handleInputFocus("nameOfPH");
          }}
          onBlur={() => {
            this.handleInputBlur("nameOfPH");
          }}
        />
        {this.renderErrorMessage(this.state.nameOfPHError.require, "Please enter a Production House name")}
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CustomInputlabelEmail id="emailOfPH" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px", paddingTop: '16px' }}>Email ID</CustomInputlabelEmail>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CustomCSSOfTextField data-test-id="emailOfPHInput" type={'text'} id="outlined-basic" variant="outlined" placeholder="Enter your email address"
          value={this.state.emailOfPH}
          disabled={this.state.isPhoneEmailVerified.email}
          onChange={(e: any) => this.handleChangeEmailOfPHInput(e)}
          className="email_input"
          onFocus={() => {
            this.handleInputFocus("emailOfPH");
          }}
          onBlur={() => {
            this.handleInputBlur("emailOfPH");
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {this.state.isPhoneEmailVerified.email
                  ? <img src={verifiedCircularIcon} />
                  : <Typography style={{ color: '#f5c42c', fontFamily: 'Urbanist', fontSize: '14px', fontWeight: 600, lineHeight: '18px', letterSpacing: '0.4px', cursor: 'pointer' }} data-test-id="verify_email" onClick={this.handleVerifyEmailOfPH}>Verify</Typography>}
              </InputAdornment>
            ),
          }}
        />
        {this.renderErrorMessage(this.state.emailOfPHError.invalid, "Please enter a valid email ID")}
        {this.renderErrorMessage(this.state.emailOfPHError.require, "email ID is required")}
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CustomInputlabelEmail id="phoneOfPH" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px", paddingTop: '16px' }}>Phone Number</CustomInputlabelEmail>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CustomCSSOfTextField data-test-id="phoneOfPHInput" type={'text'} id="outlined-basic" variant="outlined" placeholder="Enter the phone number"
          value={this.state.phoneOfPH}
          disabled={this.state.isPhoneEmailVerified.phone}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {this.state.isPhoneEmailVerified.phone
                  ? <img src={verifiedCircularIcon} />
                  : <Typography style={{ color: '#f5c42c', fontFamily: 'Urbanist', fontSize: '14px', fontWeight: 600, lineHeight: '18px', letterSpacing: '0.4px', cursor: 'pointer' }} onClick={this.handleVerifyPhoneOfPH}>Verify</Typography>}
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start" style={{ cursor: 'hand' }} className="phone_input_adornment_box">
                <CountryCodeSelector
                  allowPropChange={true}
                  navigation={this.props.navigation}
                  style={{ width: "80px" }}
                  disable={false}
                  value={this.state.countryCodeSelected}
                  getCountryName={this.getSelectedCountryName}
                  id={"CountryCodeSelector"}
                />
                <Typography style={{ color: '#17181d', marginLeft: '9px' }}> +{this.state.countryCodeSelected}</Typography>
                <Box style={{ height: '23px', borderLeft: '1px solid #6d89af', marginLeft: '12px' } as React.CSSProperties}></Box>
              </InputAdornment>
            ),
          }}
          onChange={(e: any) => this.handleChangePhoneOfPHInput(e)}
          className="email_input"
          onFocus={() => {
            this.handleInputFocus("phoneOfPH");
          }}
          onBlur={() => {
            this.handleInputBlur("phoneOfPH");
          }}
        />
        {this.renderErrorMessage(this.state.phoneOfPHError.invalid, "Please enter a valid phone number")}
        {this.renderErrorMessage(this.state.phoneOfPHError.require, "Phone number is required")}
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CustomInputlabelEmail id="addressOfPH" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px", paddingTop: '16px' }}>Address of Production House</CustomInputlabelEmail>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CustomCSSOfTextField data-test-id="addressOfPHInput" type={'text'} id="outlined-basic" variant="outlined" placeholder="Enter address of production house"
          value={this.state.addressOfPH}
          onChange={(e: any) => this.handleChangeAddressOfPHInput(e)}
          className="email_input"
          onFocus={() => {
            this.handleInputFocus("addressOfPH");
          }}
          onBlur={() => {
            this.handleInputBlur("addressOfPH");
          }}
        />
        {this.renderErrorMessage(this.state.addressOfPHError.require, "Please enter an address of the production house")}
      </Grid>
    </>
  }

  renderStepTwoView() {
    return <>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CustomInputlabelEmail id="gstOfPH" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px", paddingTop: '16px' }}>GST Number</CustomInputlabelEmail>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CustomCSSOfTextField data-test-id="gstOfPHInput" type={'text'} id="outlined-basic" variant="outlined" placeholder="Enter your GST number"
          value={this.state.gstOfPH}
          onChange={(e: any) => this.handleChangeGstOfPHInput(e)}
          className="email_input"
          onFocus={() => {
            this.handleInputFocus("gstOfPH");
          }}
          onBlur={() => {
            this.handleInputBlur("gstOfPH");
          }}
        />
        {this.renderErrorMessage(this.state.gstOfPHError.invalid, "GST Number should be in the format 22AAAAA0000A1Z5")}
        {this.renderErrorMessage(this.state.gstOfPHError.require, "GST number is required")}
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CustomInputlabelEmail id="tanOfPH" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px", paddingTop: '16px' }}>TAN Number</CustomInputlabelEmail>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CustomCSSOfTextField data-test-id="tanOfPHInput" type={'text'} id="outlined-basic" variant="outlined" placeholder="Enter your TAN number"
          value={this.state.tanOfPH}
          onChange={(e: any) => this.handleChangeTanOfPHInput(e)}
          className="email_input"
          onFocus={() => {
            this.handleInputFocus("tanOfPH");
          }}
          onBlur={() => {
            this.handleInputBlur("tanOfPH");
          }}
        />
        {this.renderErrorMessage(this.state.tanOfPHError.invalid, "TAN Number should be in the format PDES03028F")}
        {this.renderErrorMessage(this.state.tanOfPHError.require, "TAN number is required")}
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CustomInputlabelEmail id="aadharOfPH" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px", paddingTop: '16px' }}>Aadhaar Card Number</CustomInputlabelEmail>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CustomCSSOfTextField data-test-id="aadharOfPHInput" type={'text'} id="outlined-basic" variant="outlined" placeholder="Enter your aadhaar card number"
          value={this.state.aadharOfPH}
          onChange={(e: any) => this.handleChangeAadharOfPHInput(e)}
          className="email_input"
          onFocus={() => {
            this.handleInputFocus("aadharOfPH");
          }}
          onBlur={() => {
            this.handleInputBlur("aadharOfPH");
          }}
        />
        {this.renderErrorMessage(this.state.aadharOfPHError.invalid === "numeric", "Only numeric values allowed")}
        {this.renderErrorMessage(this.state.aadharOfPHError.invalid === "length", "Enter a valid 12-digit Aadhaar number")}
        {this.renderErrorMessage(this.state.aadharOfPHError.require, "Aadhar card number is required")}
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CustomInputlabelEmail id="panOfPH" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px", paddingTop: '16px' }}>PAN Card Number</CustomInputlabelEmail>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CustomCSSOfTextField data-test-id="panOfPHInput" type={'text'} id="outlined-basic" variant="outlined" placeholder="Enter your PAN card number"
          value={this.state.panOfPH}
          onChange={(e: any) => this.handleChangePanOfPHInput(e)}
          className="email_input"
          onFocus={() => {
            this.handleInputFocus("panOfPH");
          }}
          onBlur={() => {
            this.handleInputBlur("panOfPH");
          }}
        />
        {this.renderErrorMessage(this.state.panOfPHError.invalid, "PAN Number should be in the format ABCTY1234D")}
        {this.renderErrorMessage(this.state.panOfPHError.require, "PAN card number is required")}
      </Grid>
    </>
  }

  renderStepThreeView() {
    return <>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CustomInputlabelEmail className="email_lable" id="passwordOfPH" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px", paddingTop: '11px' }}>Set Password</CustomInputlabelEmail>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CustomCSSOfTextField data-test-id="passwordOfPHInput" type={this.state.isPasswordOfPHVisible ? 'text' : "password"} id="outlined-basic" variant="outlined" placeholder="Enter your password"
          value={this.state.passwordOfPH}
          onChange={(e: any) => this.handleChangePasswordOfPHInput(e)}
          className="email_input"
          InputProps={{
            autoComplete: 'new-password',
            endAdornment: (
              <InputAdornment position="end" >
                <img onClick={() => this.handlePHPasswordVisibilityChange("password")} src={this.state.isPasswordOfPHVisible ? visibilityEyeOffIcon : visibilityEyeIcon} />
              </InputAdornment>
            ),
          }}
          onFocus={() => {
            this.handleInputFocus("passwordOfPH");
          }}
          onBlur={() => {
            this.handleInputBlur("passwordOfPH");
          }}
        />
        {this.renderErrorMessage(this.state.passwordOfPHError.invalid, "Password should be of minimum 8 characters and  should include 1 alphabet, 1 uppercase letter,1 numeric and 1 special charater.")}
        {this.renderErrorMessage(this.state.passwordOfPHError.require, "Password is required")}
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CustomInputlabelEmail className="email_lable" id="confirmPasswordOfPH" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px", paddingTop: '11px' }}>Confirm Password</CustomInputlabelEmail>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CustomCSSOfTextField data-test-id="confirmPHPasswordInput" type={this.state.isConfirmPasswordOfPHVisible ? 'text' : "password"} id="outlined-basic" variant="outlined" placeholder="Enter your confirm password"
          value={this.state.confirmPasswordOfPH}
          onChange={(e: any) => this.handleChangeConfirmPasswordOfPHInput(e)}
          className="email_input"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" >
                <img style={{ cursor: 'pointer' }} onClick={() => this.handlePHPasswordVisibilityChange("confirmPassword")} src={this.state.isConfirmPasswordOfPHVisible ? visibilityEyeOffIcon : visibilityEyeIcon} />
              </InputAdornment>
            ),
          }}
          onFocus={() => {
            this.handleInputFocus("confirmPasswordOfPH");
          }}
          onBlur={() => {
            this.handleInputBlur("confirmPasswordOfPH");
          }}
        />
        {this.renderErrorMessage(this.state.confirmPasswordOfPHError.match, "Password and confirm password did not match")}
        {this.renderErrorMessage(this.state.confirmPasswordOfPHError.require, "Confirm password is required")}
      </Grid>

      <Grid>
        <FormControlLabel style={{ marginTop: 12 }}
          control={<GreenPHCheckbox checked={this.state.isTncOfPH} onChange={this.handleChangeTncOfPH} name="tnc" />}
          label={<Typography style={{
            color: '#18272a',
            fontFamily: 'Urbanist',
            fontSize: '13px',
            fontWeight: 600,
            lineHeight: '18px',
            letterSpacing: '0.37142858px'
          }}>Agree to the <span style={{ color: '#f5c42c', textDecoration: 'underline', cursor: "pointer" }} onClick={this.handleTncNavigation}>Terms & Conditions</span> of ai pictures.</Typography>}
        />
      </Grid>
    </>
  }

  render() {
    // Customizable Area Start
    return (<>
      <Loader loading={this.state.isLoading} />
      <Grid container  style={{overflow:'hidden'}}>
        <Grid xs={12} item sm={12} md={6} lg={6} xl={6} style={{ position: 'relative', overflow: 'hidden' }}>
        <img alt="Main" src={mainImage} style={{ width: '50%',
              height: '100%',
              position: 'fixed',
              top: 0,
              left: 0,
              } as React.CSSProperties} />
        </Grid>
        <Grid xs={12} item sm={12} md={6} lg={6} xl={6}  style={{ overflowY: 'auto', height: '100vh', position: 'relative' }}>
          <Box className="main_box">
            <Grid item sm={12} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} xs={12} md={12} lg={12}>
              <Box data-test-id="signPHBack" onClick={() => { this.props.navigation && this.props.navigation.navigate("LandingPage") }}>
                <IconButton style={{ paddingLeft: "0px" }}>
                  <ArrowBackIcon style={{ height: "24px", width: "24px" } as React.CSSProperties} htmlColor="#17181D" />
                </IconButton>
                <span style={{ fontSize: "16px", color: "#17181d", fontFamily: "Urbanist", fontWeight: 600, lineHeight: "18px", textDecoration: "underline", letterSpacing: "0.4923077px" }}>
                  Back to Login
                </span>
              </Box>
              <Box>
                <img src={logoIcon} alt="Main Image" style={{ marginBottom: "28px", width: "70px" }} />
              </Box>
            </Grid>
            <img alt="Corner Image" src={cornerImage} className="corner_img" />
            <Grid xs={12} item sm={12} md={12} lg={12}>
              <label
                className="welcome_back_text"
                style={{ fontFamily: "Urbanist", fontSize: "32px" }}
              >
                Sign up as Production House
              </label>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <SignInInputLabel
                style={{
                  marginTop: "1%",
                  fontSize: "14px",
                  lineHeight: "21px",
                  fontFamily: "Urbanist",
                  color: "#6D89AF",
                  fontWeight: 600,
                  maxWidth: "100%",
                  width: "70%",
                  paddingBottom: "14px"
                }}
              >
                Create your account as a production house to manage projects and studio bookings
              </SignInInputLabel>
            </Grid>
            <Grid item style={{ width: '100%', height: 0, border: '0.2px solid #e3eaf4' } as React.CSSProperties} />
            <Grid container>
              <Box style={{ width: '100%' }}>
                <Stepper style={{ paddingTop: "24px", marginLeft: '-9%', marginRight: '-12%' }} alternativeLabel activeStep={this.state.activeStep} connector={<ColorlibConnector />}>
                  {["Basic Details", "Official Details", "Password"].map((label) => (
                    <Step key={label}>
                      <StepLabel className="stepLabelClass" StepIconComponent={this.ColorlibStepIcon}>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>

              {!this.state.activeStep && this.renderStepOneView()}
              {this.state.activeStep === 1 && this.renderStepTwoView()}
              {this.state.activeStep === 2 && this.renderStepThreeView()}

              <Grid item xs={12} sm={12} md={12} lg={12} style={{ justifyContent: "space-between", display: "flex", marginTop: '30px' }}>
                <Grid item style={{ width: 163, visibility: this.state.activeStep ? "visible" : "hidden", height: 48, backgroundColor: "#FFFFFF" } as React.CSSProperties} className="button_box">
                  <CustomPHBackButtonBox data-test-id="sign_up_button" style={{ height: 48 } as React.CSSProperties} onClick={this.handleStepBack}>
                    Prev
                  </CustomPHBackButtonBox>
                </Grid>
                {this.state.activeStep === 2 ?
                  <Grid item style={{ width: 202, height: 32 } as React.CSSProperties} className="button_box">
                    <CustomPHSignUPButtonBox data-test-id="sign_up_button" style={{ height: 48 } as React.CSSProperties} className="button" onClick={this.handleSignUpForPH}>
                      Sign Up
                    </CustomPHSignUPButtonBox>
                  </Grid>
                  :
                  <Grid item style={{ width: 163, height: 34 } as React.CSSProperties} className="button_box">
                    <CustomPHSignUPButtonBox data-test-id="next_button" style={{ height: 48 } as React.CSSProperties} className="button" onClick={this.handleMoveOnNextPage}>
                      Next
                    </CustomPHSignUPButtonBox>
                  </Grid>}
              </Grid>
            </Grid>
          </Box >
        </Grid >
      </Grid >
      {this.state.isModelOpen ? <VerifyOtpModel type={this.state.verifyPhoneEmail === "email" ? "email" : "phone"} open={this.state.isModelOpen} disabledResend={this.state.isDisabled} setIsDisabled={(val:boolean)=> this.setState({isDisabled:val})} handleClose={this.handleClose} verifyOtpAPICall={this.verifyOtpAPICall} phoneNumber={this.state.verifyPhoneEmail === "email" ? this.state.emailOfPH : `+${this.state.countryCodeSelected}-${this.state.phoneOfPH}`} requestAgainAPICall={this.getOtpApiCall} /> : null}
      <CustomNotification navigation={(route: string) => this.props.navigation.navigate(route)} notification={this.state.notification} handleCloseNotification={this.handleCloseNotification} />
    </>
    );
    // Customizable Area End
  }
}