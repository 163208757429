import { Dialog, DialogTitle, Typography, Box, DialogContent, DialogActions, styled } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { SMSicon } from './assets';
import { CustomButton } from '../../blocks/email-account-login/src/CustomButton';
import { getStorageData } from '../../framework/src/Utilities';

const styles = {
    modalContainer : {
        padding : '20px'
    },
    header: {
        color: ' #17181D',
        // textAlign: 'center',
        fontFamily: 'Urbanist',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',
        letterspacing: '0.2px',
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap' as 'nowrap',
        maxWidth: '200px',
        display: 'inline-block',
        verticalAlign: 'bottom',
        fontWeight: 600,
    },
    description: {
        color: '#6D89AF',
        // textAlign: 'center',
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '18px',
        letterSpacing: '0.215px',
    }
}

const CustomNextStepButtonBox = styled(CustomButton)({
    '& .MuiButton-label': {
        fontSize: 14,
        color: '#ffffff',
        fontWeight: 500,
        width: 163,
        height: 48,
    }
});

interface ISMSProps {
    openSMSDialog: boolean;
    handleSMSModalToggle: () => void;
}

const PublishProjectModal = (props: any) => {
    const [userEmail, setUserEmail] = useState("");
    useEffect(() => {
        async function fetchData() {
          const currentUserEmail = await getStorageData('projectname');
         setUserEmail(currentUserEmail)
         
        }
        fetchData();
      }, []);
    return (
        <Dialog  open={props.openPublishProjectDialog} onClose={props.handlePublishProjectModal} style={{width:400,margin:"auto",...styles.modalContainer}} >
            <DialogTitle>
                <Box style={{ display: "flex", justifyContent: "space-between",  padding : '10px 0px' }}>
                <Typography component='span' >{" "}</Typography >
                    <Typography component='span'>
                        <img src={SMSicon} style={{
                            height: '64px', width: '64px'
                        }} />
                    </Typography >
                    <Typography component='span' data-test-id="addDepartmentCloseBtn" onClick={props.handlePublishProjectModal}>
                        {/* <CloseIcon /> */}
                        </Typography >
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box style={{
                    display : 'flex',
                    flexDirection : 'column',
                    gap : '8px',
                    padding : '4px 20px'
                }} >
                <Typography style={styles.header}>Published Successfully</Typography>
                    <Typography style={styles.description}>
                       <span>Your project <span title={userEmail} style={styles.title}>{userEmail}</span> is published successfully</span> <br />
                       Click on the view project button to manage the project
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions style={{ padding: "0px 19px 24px" }}>
                <CustomNextStepButtonBox data-test-id="department_button" style={{ height: 48 } as React.CSSProperties} className="button" onClick={props.navigateToallJobDetail}>
                View Project
                </CustomNextStepButtonBox>
            </DialogActions>
        </Dialog>
    );
};

export default PublishProjectModal;
