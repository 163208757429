
import React from "react";

//Customizable Area Start
import { Box, Container, Grid, InputAdornment, Typography, withStyles } from "@material-ui/core";
import ForgotPasswordController, { Props } from "./ForgotPasswordController";
export const configJSON = require("./config");
import "./forgotPassword.css";
import { CustomInputlabelEmail, SignInInputLabel, CustomButton, CustomTextField } from "../../email-account-login/src/CustomButton";
import { CssTextField } from "../../email-account-login/src/EmailAccountLoginBlock.web";
import CommonComponent from "./CommonComponent";
import CustomNotification from "../../../components/src/CustomNotification";
import { getStorageData } from "../../../framework/src/Utilities";
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector.web";
//Customizable Area End

const CustomCSSTextFieldpassword = withStyles({
    root: {
      borderColor: "#f5c42c",
      backgroundColor: 'white',
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#E3EAF4",
        },
        "&:hover fieldset": {
          borderColor: "#f5c42c",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#f5c42c",
          backgroundColor: 'rgba(245,196,44,0.04)',
        },
        borderRadius: "14px",
        color: '#323232',
        fontWeight: 400,
        "& .MuiOutlinedInput-input": {
          borderColor: '#e3eaf4',
          fontWeight: 500,
          lineHeight: '19px',
          color: '#17181D',
          fontSize: '16px',
          fontFamily: 'Urbanist',
        }
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#f5c42c"
      },
      "& .MuiInputBase-input::placeholder": {
        color: '#6d89af',
        fontWeight: 500,
        fontFamily: 'Urbanist',
      },
    }
  })(CustomTextField);

export default class ForgotPassword extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
        //Customizable Area Start
        this.state = {
            ...this.state,
            loginTab: 0,
          };
        //Customizable Area End
    }
    
    async componentDidMount() {
        const loginTab = await getStorageData("LoginTab");
        this.setState({ loginTab: parseInt(loginTab) });
      }

    // Customizable Area Start

    render() {
        return (
            <>
              {this.state.loginTab !== 1 ?  
                <Grid container>
                    <CommonComponent navigation={this.props.navigation} />
                    <Grid item xs={1} sm={1} md={4} lg={4} xl={2} className="main_baground" style={{ backgroundColor: '#f6f8fc' }}></Grid>
                    <Grid item xs={10} sm={10} md={4} lg={4} xl={8} style={{ backgroundColor: '#f6f8fc' }}>
                        <Container data-test-id="forgotPasswordId" style={{ backgroundColor: '#f6f8fc' }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: '175px', textAlign: "center" }}>
                                <Typography style={{ lineHeight: "43px", color: "#17181d", fontFamily: "Urbanist", fontSize: "36px", fontWeight: 700, letterSpacing: "1.1076924px" }}>{configJSON.recoverPasswordText}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                <SignInInputLabel className="sing_in_text" style={{ fontSize: "14px", width: "368px", lineHeight: "21px" }}>{configJSON.recoverPasswordHelperText}</SignInInputLabel>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <CustomInputlabelEmail className="email_recover" style={{}}>{configJSON.emailInputLabel}</CustomInputlabelEmail>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <CssTextField type="text" id="outlined-basic" placeholder="Enter your email ID" variant="outlined" className="email_inp" value={this.state.recoveryEmail} onChange={this.handleRecoveryEmailInput} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginBottom: '33%' }}>
                                <CustomButton data-test-id="otp_button" style={{ fontSize: "16px", fontWeight: 600 }} className="otp_button" onClick={this.handleRecoveryEmailSubmit}>{configJSON.getOtp}</CustomButton>
                            </Grid>
                        </Container>
                    </Grid>
                    <Grid item xs={1} sm={1} md={4} lg={4} xl={2} className="main_baground" style={{ backgroundColor: '#f6f8fc' }}></Grid>
                </Grid> :
                <Grid container>
                    <CommonComponent navigation={this.props.navigation} />
                    <Grid item xs={1} sm={1} md={4} lg={4} xl={2} className="main_baground" style={{ backgroundColor: '#f6f8fc' }}></Grid>
                    <Grid item xs={10} sm={10} md={4} lg={4} xl={8} style={{ backgroundColor: '#f6f8fc' }}>
                        <Container data-test-id="forgotPasswordId" style={{ backgroundColor: '#f6f8fc' }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: '175px', textAlign: "center" }}>
                                <Typography style={{ lineHeight: "43px", color: "#17181d", fontFamily: "Urbanist", fontSize: "36px", fontWeight: 700, letterSpacing: "1.1076924px" }}>{configJSON.recoverPasswordText}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                <SignInInputLabel className="sing_in_text" style={{ fontSize: "14px", width: "368px", lineHeight: "21px" }}>{configJSON.recoverPasswordHelperTextForMobile}</SignInInputLabel>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <CustomInputlabelEmail className="email_recover" id="phonenumber" style={{ fontSize: "14px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px", paddingTop: '11px' }}>{configJSON.mobileInputLabel}</CustomInputlabelEmail>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                            <CustomCSSTextFieldpassword data-test-id="phoneNumberInput" type={'text'} id="outlined-basic" variant="outlined" placeholder="Enter your phone number"
                                InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" style={{ cursor: 'hand' }} className="phone_input_adornment_box">
                                    <CountryCodeSelector
                                        navigation={this.props.navigation}
                                        id={"CountryCodeSelector"}
                                        style={{
                                        width: "100%",
                                        }}
                                        disable={false}
                                        allowPropChange={true}
                                        value={this.state.countryCodeSelected}
                                    />
                                    <Typography style={{ marginLeft: '9px', color: '#17181d' }}> +{this.state.countryCodeSelected || "null"}</Typography>
                                    <Box style={{ borderLeft: '1px solid #6d89af', height: '23px', marginLeft: '12px' } as React.CSSProperties}></Box>
                                    </InputAdornment>
                                    ),
                                }}
                                value={this.state.phoneNumber}
                                onChange={(e: any) => this.handlePhoneNumberInput(e)}
                                className="email_input"
                                onFocus={() => {
                                this.handleInputFocus("phonenumber");
                                }}
                                onBlur={() => {
                                this.handleInputBlur("phonenumber");
                                }}
                            />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginBottom: '33%' }}>
                                <CustomButton data-test-id="otp_button" style={{ fontSize: "16px", fontWeight: 600 }} className="otp_button" onClick={this.handleRecoveryMobileSubmit}>{configJSON.getOtp}</CustomButton>
                            </Grid>
                        </Container>
                    </Grid>
                    <Grid item xs={1} sm={1} md={4} lg={4} xl={2} className="main_baground" style={{ backgroundColor: '#f6f8fc' }}></Grid>
                </Grid>
            }
                <CustomNotification navigation={(route: string) => this.props.navigation.navigate(route)} notification={this.state.fpNotification} handleCloseNotification={this.handleFPCloseNotification} />
            </>
        );
    }
    // Customizable Area End
}

// Customizable Area Start
//Customizable Area End