//@ts-nocheck
import React from "react";
// Customizable Area Start
import { Box,Typography, Container,Grid, InputAdornment, IconButton, TextField, Modal, Link } from "@material-ui/core";
import Header from "../../../components/src/Header.web";
import Loader from "../../../components/src/Loader.web";
import SideBar from "../../../components/src/SideBar";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Footer from "../../../components/src/Footer";
import InvoiceRequestDashboard from "../../../components/src/InvoiceRequestDashboard";
import { Shape, Filtericon } from "../../../components/src/assets";
import { arrowicon } from "./assets";
import SearchIcon from "@material-ui/icons/Search";
import InvoiceSortFunctionality from "../../../components/src/InvoiceSortFunctionality";
import Filtermyinvoice from "../../../components/src/Filtermyinvoice";
import Pagination from "@material-ui/lab/Pagination";
import { styled} from "@material-ui/core/styles";

const InvoiceSearchBox = styled("div")({
  minWidth: "286px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": { 
      borderColor: "#E3EAF4" 
    },
    "&:hover fieldset": { 
      borderColor: "rgb(109, 137, 175)" 
    },
    "&.Mui-focused fieldset": {
      borderColor: "#f5c42c",
    },
  }
});
// Customizable Area End

import InvoiceBillingController, {
  Props,
  configJSON,
} from "./InvoiceBillingController.web";

export default class InvoiceBilling extends InvoiceBillingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderGridItems(data) {
    return (
      <Grid container spacing={3}>
        {data.length ? (
          data.map((item, index) => (
            <Grid
              item
              key={`${item}_allinvoice_card`}
              xl={3}
              md={this.state.isinvoiceBillingSideBarOpen?4:3}
              sm={12}
              xs={12}
              data-test-id="invoideDetailPage"
              onClick={() => this.navigateToinvoiceDetailPH(item.id,item.attributes.project_name)}
            >
              <Box sx={webStyle.headerSearchParentBox1}>
                <Box sx={webStyle.InvoiceNoandDate}>
                  <Box sx={webStyle.InvoiceNo}>Invoice No:{item?.id}</Box>
                  <Box sx={webStyle.DateandMonth}>{item?.attributes?.invoice_date}</Box>
                </Box>
                <InvoiceRequestDashboard data={item} />
              </Box>
            </Grid>
          ))
        ) : (
          <Typography
            style={{
              width: '100%',
              textAlign: 'center',
              marginTop: '30px',
              marginBottom: '30px',
              color: '#6d89af',
              fontFamily: 'Urbanist',
              fontSize: 14,
              fontWeight: 600,
            }}
          >
            No record available
          </Typography>
        )}
      </Grid>
    );
  }
  InvoiceMainContent(invoicesideBarStatus: boolean = this.state.isinvoiceBillingSideBarOpen) {
    return (
        <>
            <Box style={webStyle.commonMainWrapper} >
                <Loader loading={false}></Loader>
                <Box style={webStyle.commonMainShrink}>
                  <Header
                  handleSideBar={this.handleInvoiceSideBar}
                  dropDownStatus={this.state.isInvoiceHeaderDropdownOpenVal}
                  navigate={this.props.navigation.navigate}
                  handleDropdown={this.handleInvoiceHeaderDropdownVal}
                  sideBarStatus={invoicesideBarStatus}
                  />
                </Box>
                <Box style={webStyle.mainContentJobIvoice as React.CSSProperties}>
                    <Box style={webStyle.mainContent as React.CSSProperties}>
                        <Box style={webStyle.mainContentHeaderInvoice}>
                            <Box sx={webStyle.activeBlockWrapper}>
                              <Box sx={webStyle.activeBlockAllArrow}>
                                <div  data-test-id="invoice-page" onClick={()=> this.navigateToAllInvoicePage()}> 
                                  <img src={arrowicon} alt="arrow icon" /> 
                                </div>
                              </Box>
                              <Box>
                                <Typography variant="h3" style={webStyle.activeBlockAllInvoice as TypographyProps}>
                                <span title={this.state.projectName} style={webStyle.projectName}>{this.state.projectName}</span> - Invoice Requests
                                </Typography>
                                <Link style={webStyle.breadcrumbTesxtInvoice} data-test-id="allInvoice" onClick={()=>this.handleNavigationPH("AllInvoiceRequests")}>
                                  Home 
                                 </Link> 
                                 <Link style={webStyle.breadcrumbTesxtInvoice} data-test-id="allInvoicePage" onClick={()=>this.handleNavigationPH("AllInvoiceRequests")}>
                                 / Invoices Requests 
                                 </Link> 
                                <span style={{ color: "#6D89AF",fontWeight:600 ,fontSize: "14px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "300px", display: "inline-block", verticalAlign: "bottom"}} title={this.state.projectName}> / {this.state.projectName}</span>
                              </Box>
                            </Box>
                            <Box style={webStyle.iconFilterShapeInvoice}>
                             <div>
                                <Box style={webStyle.headIconsInvoice} onClick={this.handleOpen}>
                                <img src={Shape} alt="Shape" style={webStyle.headIconImgInvoice}/>
                                </Box>
                                <Modal
                                    open={this.state.open}
                                    onClose={this.handleClose}
                                    aria-describedby="simple-modal-description"
                                    aria-labelledby="simple-modal-title"
                                >
                                 <div style={{ // You can style your modal here
                                    top: '50%',
                                    position: 'absolute',                                              
                                    left: '50%',
                                    backgroundColor: 'white',
                                    borderRadius: '20px',
                                    transform: 'translate(-50%, -50%)',  
                                    }}>
                                            
                                    <InvoiceSortFunctionality data={""} ClearDataValues={this.ClearDataValues} allcount={this.state.allcount} handleClose={this.handleClose} handleOptionChange={this.handleOptionChange} selectstatus={this.state.selectstatus} selectstatus1={this.state.selectstatus1} handleOptionChange1={this.handleOptionChange1} approvecount={this.state.countApproved} pendingcount={this.state.countpending} completedcount={this.state.countcomplete} count={this.state.countall} getAppliedinvoiceList={this.handleInvoiceSort} onhold={this.state.counthold} rejected={this.state.countreject} />
                                </div>
                                </Modal>
                            </div>  
                            <div> 
                              <Box style={webStyle.headIconsInvoice}>
                                <img src={Filtericon} onClick={this.handleOpen2} alt="Filter" style={webStyle.headIconImgInvoice}/>
                              </Box>
                              <Modal
                                open={this.state.open2}
                                onClose={this.handleClose} // This handles clicking anywhere outside the modal
                                aria-describedby="simple-modal-description"
                                aria-labelledby="simple-modal-title">
                                  <div style={{ // You can style your modal here
                                    top: '50%',
                                    position: 'absolute',                                              
                                    left: '50%',
                                    backgroundColor: 'white',
                                    borderRadius: '20px',
                                    transform: 'translate(-50%, -50%)',
                                 }}>            
                                    <Filtermyinvoice data={""} handleClose={this.handleClose2} raiseinvoicetype={this.state.raiseinvoicetype} amountdatalist={this.state.amountdatalist} 
                                        departmentlist={this.state.departmentlist} jobroledatalist={this.state.jobroledatalist}
                                        handleOptionChange1={this.handleOptionChangejobroleProductionHouse} jobrolevalue={this.state.jobrolevalue} handleOptionChangeamount={this.handleOptionChangeamountProductionHouse} handleOptionChangedepartment={this.handleOptionChangeDepartmentProductionHouse} handleOptionChangestatus={this.handleOptionChangeStatusProductionHouse} amountvalue={this.state.amountvalue}
                                        statusvalue={this.state.statusvalue} departmentvalue={this.state.departmentvalue} getprojectlistfilter={this.state.getprojectlistfilter} projectvalue={this.state.projectvalue} handleOptionChangeproject={this.handleOptionChangeprojectProductionHouse} handleChangeapply={this.handleChangeapplyProductionHouse} ClearDataValuesfilter={this.ClearDataValuesfilter}/>
                                    </div>
                                </Modal>
                              </div>
                              <InvoiceSearchBox>
                                <TextField
                                  className='fontSizeUrbanist'
                                  InputProps={{
                                      startAdornment: (
                                          <InputAdornment position="start">
                                              <IconButton style={webStyle.iconButton}>
                                                  <SearchIcon style={webStyle.searchIcon} />
                                              </IconButton>
                                          </InputAdornment>
                                      ),
                                      style: {
                                          color: "#6D89AF",
                                          borderRadius: "35px",
                                          backgroundColor: "#FFF",
                                          padding: "0px",
                                          height: "44px",
                                          fontFamily: "Urbanist",
                                      } as React.CSSProperties,
                                  }}
                                  variant="outlined"
                                  fullWidth
                                  placeholder="Search for invoice"
                                  onChange={(event: ChangeEvent<HTMLInputElement>) => this.searchInvoices(event)}
                                  data-test-id="searchInvoice"
                                  data-focusvisible-polyfill="false"
                                />
                              </InvoiceSearchBox>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="tabsContainer">
                                <Tabs
                                    style={{ ...webStyle.mt24Invoice, ...webStyle.bt,textTransform: 'none'}}
                                    value={this.state.tabIndex}
                                    onChange={this.handleChangeTab}
                                    TabIndicatorProps={{
                                        style: {...webStyle.tabs}
                                    }}
                                    textColor="primary"
                                >
                                    {
                                        configJSON.tabLabelsInvoice.map((ele: string, index: number | string) => {
                                            return <Tab 
                                            key={index}
                                             style={{color: this.state.tabIndex == index ? "#F5C42C" : "#6D89AF", ...webStyle.tab }} label={ele}
                                              />
                                        })
                                    }
                                </Tabs>
                                <Box sx={webStyle.headerSearchBox1}>
                                    {this.state.tabIndex === 0 && this.renderGridItems(this.state.projectInvoiceAllRequest)}
                                    {this.state.tabIndex === 1 && this.renderGridItems(this.state.projectInvoiceAllRequest)}
                                </Box>
                            <Box>
                              {this.state.invoiceCount ? (<Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Pagination count={Math.ceil((this.state.invoiceCount) / 9)} page={this.state.currentpage} onChange={this.handlePageChangeInvoices} />
                              </Grid>
                            </Grid>) : null}
                            </Box>
                          </Box>
                </Box>
                <Box style={webStyle.commonMainShrink}>
                  <Footer />
                </Box>
            </Box>

        </>
    )
}
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
        <Container style={webStyle.parentDivInvoiceBilling}>
        {
            this.state.isinvoiceBillingSideBarOpen ?
                <Grid container spacing={0}>
                    <Grid item xs={3} md={3} style={webStyle.websidebarParentInvoice}>
                        <SideBar
                            userData={{ age: 25 }}
                            activeMenuItem={"Invoices Requests"}
                            navigate={this.props.navigation.navigate}
                            handleSideBar={this.handleInvoiceSideBar} />
                    </Grid>
                    <Grid item xs={9} md={9}
                     >{this.InvoiceMainContent()}</Grid>
                </Grid> :
                <Grid item xs={12} md={12}
                >{this.InvoiceMainContent()}</Grid>
        }
    </Container>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
    commonMainWrapper: {
      borderLeft: "1px solid #E3EAF4",
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    },
    commonMainShrink: {
        flexShrink: 0
    },
    mainContentJobIvoice: {
        width: "100%",
        boxSizing: "border-box",
        padding: "24px",
        display:"flex",
        flexDirection:"column",
        position:"relative",
        flex: '1 0 auto',
    },
    parentDivInvoiceBilling: {
        maxWidth: "none",
        padding: "0px",
    },
    websidebarParentInvoice:{
        minHeight: "100vh",
        maxWidth: "23%"
    },
    breadcrumbTesxtInvoice: {
        color: "#F5C42C",
        fontSize: "14px",
        marginTop: "8px",
        fontWeight:600,
        cursor: 'pointer',
        textDecoration: 'underline'
    },
    mainContentHeaderInvoice: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    activeBlockWrapper: {
      display: "flex",
      alignItems: "center",
      gap: 15,
    },
    activeBlockAllInvoice: {
      fontSize: "28px",
      fontWeight: "600",
      letterSpacing: "1px",
      display: "flex",
      flexDirection: "row",
      lineHeight: "33px",
      alignItems: 'center',
      gap: '6px',
      marginBottom: '8px',
    },
    projectName: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: "280px"
    },
    activeBlockAllArrow: {
      backgroundColor:' #fff',
      border: '1px solid #ddd',
      borderRadius: '50%',
      padding: '15px',   
      cursor: 'pointer',
    },
    headerTitleInvoiceBilling: {
        color: " #17181D",
        fontFamily: "Urbanist",
        fontSize: "28px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
        letterSpacing: "0.311px",
    },
    tabs:{
        backgroundColor: "#F5C42C",
        marginTop:"24px",
    },
    mt24Invoice: {
        marginTop: "24px"
    },
    bt:{
        borderBottom: "1px solid #E3EAF4",
    },
    tab: {
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "24px",
        letterSpacing: "0.246px",
        textTransform:"none" as const
    },
    mainContent: {
        padding: "0",
    },
    tabsContent: {
        paddingTop: "16px",
        // paddingLeft:"24px",
        display: "flex",
        gap: "1.5rem",
        flexWrap:"wrap"
    },
    iconFilterShapeInvoice: {
      display: 'flex',
      alignItems: 'center',
      gap: 15
    },
    headIconsInvoice: {
      height: '44px',
      minWidth: '44px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#FFF',
      border:'1px solid #E3EAF4',
      borderRadius: '50%',
      cursor: 'pointer',
    },
    headIconImgInvoice: {
      height: '16px',
      width: '16px',
    },
    searchIcon: {
      color: "#6D89AF",
      fontSize: "22px",
    },
    headerSearchParentBox1: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '5px',
      flexDirection: 'column',
  },
  headerSearchBox1: {
      display: "flex",
      justifyContent: "start",
      align: "end",
      flexDirection: "row",
      flexWrap: "wrap",
      paddingTop: '24px',
  },
  InvoiceNoandDate: {
      display: 'flex',
      justifyContent: 'space-between',
      width: "100%",
      "@media(max-width: 1300px)": {
          width: '100%',
      },
      paddingBottom:'6px'
  },
  InvoiceNo: {
      color: '#6d89af',
      fontFamily: 'Urbanist',
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '19px',
  },
  DateandMonth: {
      color: '#6d89af',
      fontFamily: 'Urbanist',
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '19px',
  },
  };
// Customizable Area End
