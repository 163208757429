import React, { useEffect } from "react";
import { Card, Box, CardMedia, CardContent, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { JobCardImage, LocationIconjob, rupeeIconJobBlack, headingicon, RightmarkIcon } from "./assets";
import { getStorageData } from '../../framework/src/Utilities';

interface MyProps {
    data: any;
    withdrawJob: any;
}

const useStyles = makeStyles({
    cardDivWithOpenSideBar: () => ({
        //width: 312,
        //height: 240,
        borderRadius: 14,
        padding: 8,
        border: "1px solid #E1E6EC",
        '& .MuiCardContent-root': {
            padding: 0
        },
        "@media(max-width: 500px)": {

        },
        "@media(max-width: 1300px)": {
        },
    }),
    cardDivWithClosedSideBAr: () => ({
        //width: 346,
        borderRadius: 14,
        padding: 8,
        border: "1px solid #E1E6EC",
        position: 'relative',
        '& .MuiCardContent-root': {
            padding: 0
        },
        "@media(max-width: 500px)": {
            /// width: 256
        },
    }),
    parentBoxValue: () => ({
        display: "flex",
        justifyContent: 'space-between',
    }),
    parentBox: () => ({
        width: '100%'
    }),
    ParentSubheading1: () => ({
        display: "flex",
        gap: "5px",
    })
    ,
    headingDept: () => ({
        display: 'flex',
        justifyContent: 'space-between',
    })
    ,
    positionDiv: () => ({
        display: 'flex',
        gap: '5px',
        borderRadius: '15px',
        backgroundColor: '#f8fafe',
        borderColor: '#979797',
        height: 31,
        width: 101,
        alignItems: 'center'
    }),
    iconposition: () => ({

    }),
    DeptDiv: () => ({
        fontSize: '13px',
        fontFamily: 'urbanist',
        color: '#17181D',
        fontWeight: 500,
        lineHeight: '16px',
    }),
    Dept: () => ({}),
    mainDiv: () => ({
        paddingTop: '4px',
        lineHeight: '19px',
        fontSize: '14px',
        fontWeight: 600,
        fontFamily: 'urbanist',
        color: '#17181D',
        display: 'flex',
        gap: '4px'
    }),
    YrsDiv: () => ({
        display: 'flex',
        gap: '4px',
        paddingTop: '6px',
        '& .MuiBox-root': {
            fontSize: '13px',
            fontWeight: '600px',

            color: '#6d89af',
        }
    }),
    Yrstext: () => ({
        paddingTop: '6px',
        fontSize: '13px',
        fontWeight: 600,
        color: '#6d89af',
        fontFamily: 'urbanist',
        '& .MuiBox-root': {
            fontSize: '13px',
            fontWeight: '600px',
        }
    }),
    jobiconyear: () => ({
        height: '16px',
        width: '16px'
    }),
    doticon: () => ({
        height: '24px',
        width: '24px'
    }),
    rupeeicon: () => ({
        paddingTop: '2px',
        height: '16px',
        width: '16px'
    }),
    rupeesDiv: () => ({
        display: 'flex',
        gap: '4px',
        borderBottom: '1px solid #e3eaf4',
        paddingBottom: '11px',
        '& .MuiBox-root': {
            display: 'flex',
            gap: '4px',
            fontSize: '14px',
            color: '#6d89af',
            fontWeight: 400,
            fontFamily: 'Helvetica',
            paddingTop: '5px',
        }
    }),
    jobcardimage: () => ({

        height: '40px',
        width: '40px',
        borderRadius: '10px'
    }),
    footerDiv: () => ({
        display: "flex"
    }),
    addressDiv: () => ({
        paddingTop: '13px'
    }),
    address1: () => ({
        fontSize: '14px',
        fontFamily: 'urbanist',
        fontWeight: 600,
        color: '#17181D',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '150px'
    }),
    address2: () => ({
        display: 'flex',
        gap: '4px'

    }),
    ParentSubheading2: () => ({
        fontSize: '11px',
        fontFamily: 'urbanist',
        fontWeight: 500,
        color: '#6d89af',
        paddingTop: '14px'

    }),
    locationDiv: () => ({
        fontSize: '12px',
        fontWeight: 600,
        fontFamily: 'urbanist',
        color: '#6d89af',


    }),
    ParentBottomDiv: () => ({
        display: 'flex',
        justifyContent: 'space-between'
    }),
    Div2: () => ({
        paddingTop: '12.5px'
    }),
    rupeetext: () => ({
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '17px',
        fontFamily: 'Helvetica'
    }),
    button3: {
        cursor: "pointer",
        color: "black",
        backgroundColor: "#ffffff",
        textTransform: "none",
        //position: "absolute",
        bottom: "16px",
        fontWeight: 600,
        fontSize: "12px",
        marginBottom: "5px",
        fontFamily: "urbanist",
        display: 'flex',
        alignItems: 'center'
    },
    rightmarkText: {
        fontSize: '12px',
        color: '#42a200',
        fontWeight: 600,
        fontFamily: 'urbanist',
        lineHeight: '16px',
        letterSpacing: '0.25px',
        paddingLeft: "2px",
    },
    submitButton: {
        cursor: "pointer",
        color: "#17181d",
        backgroundColor: "#e3eaf4",
        textTransform: "none",
        paddingLeft: '18.5px',
        paddingRight: '18.5px',
        border: "none",
        borderRadius: "10px",
        //position: "absolute",
        bottom: "16px",
        height: "38px",
        fontWeight: 600,
        fontSize: "12px",
        marginBottom: "5px",
        fontFamily: "urbanist",
        display: 'flex',
        alignItems: 'center'
    },
    headingMain: () => ({})
});

export default function AppliedJob(props: MyProps) {

    const { data, withdrawJob } = props
    const classes = useStyles();
    const formatDate = (date: any) => {
        if (!date) return ''; // Handle the case where date is not provided

        // Create a new Date object if the provided date is a string
        const formattedDate = new Date(date);

        // Use Intl.DateTimeFormat to format the date
        const options: any = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZoneName: 'short',
        };

        return new Intl.DateTimeFormat('en-US', options).format(formattedDate);
    };
    const [roleid, setRoleid] = React.useState("")
    useEffect(() => {
        const fetchData = async () => {
            const data = await getStorageData("Jobrequestid");
            setRoleid(data);
        };
        fetchData();
    }, []);

    return (
        <Box className={classes.parentBox}>
            <Card className={classes.cardDivWithOpenSideBar}>
                <Box style={{
                    display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid',
                    paddingBottom: '10px',
                    borderColor: '#e3eaf4'
                }}>
                    <Box className={classes.ParentSubheading1}>
                        <Box style={{ paddingTop: '10px', }}>
                            <img src={data?.attributes?.project?.data?.attributes?.image} alt="" className={classes.jobcardimage} />
                        </Box>
                        <Box className={classes.addressDiv}>
                            <Box title={data?.attributes?.project?.data?.attributes?.project_name} className={classes.address1}>
                                {data?.attributes?.project?.data?.attributes?.project_name}
                            </Box>
                           {data?.attributes?.city && data?.attributes?.state &&  
                           <Box className={classes.address2}>
                                <Box>
                                    <img src={LocationIconjob} alt="" className={classes.jobiconyear} />
                                </Box>
                                <Box className={classes.locationDiv}>
                                    {`${data?.attributes?.city}, ${data?.attributes?.state}`}
                                </Box>
                            </Box>}
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.ParentBottomDiv}>
                    <Box style={{ paddingTop: '12.5px' }} >
                        <Box className={classes.DeptDiv}>
                            Dept | {data?.attributes?.project_department_name}
                        </Box>
                        <Box className={classes.headingMain}>
                            <Box className={classes.mainDiv}>
                                <Box>
                                    <img src={headingicon} alt="" className={classes.rupeeicon} />
                                </Box>
                                {data?.attributes?.role}
                            </Box>
                        </Box>
                    </Box>
                    <Box className={classes.Div2} >
                        <Box className={classes.DeptDiv}>
                            Amount of Role
                        </Box>
                        <Box className={classes.mainDiv}>
                            <Box>
                                <img src={rupeeIconJobBlack} alt="" className={classes.rupeeicon} />
                            </Box>
                            <Box className={classes.rupeetext}>₹{data?.attributes?.budget_of_role}</Box>
                        </Box>
                    </Box>
                </Box>

                <Box style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '13px', paddingLeft: '6px' }}>
                    <Box
                        className={classes.button3}  >
                        <Box>
                            <img src={RightmarkIcon} alt="" className={classes.rupeeicon} />
                        </Box>
                        <Box className={classes.rightmarkText}>
                            {`Applied ${data.attributes.days_count} days ago`}
                        </Box>
                    </Box>

                    <button className={classes.submitButton} onClick={(e: any) => withdrawJob(e, data?.attributes?.vendor_job_request_id)} >
                        Withdraw Job Request
                    </button>
                </Box>
            </Card>
        </Box>
    );
}
