import React from "react";
import {
    // Customizable Area Start
    Box,Typography, makeStyles,
    // Customizable Area End
} from "@material-ui/core";
import { mytaskreceipt,Arrowinvoice,workHistoryDark,dummyImage} from "./assets";

interface MyProps {
  data: any;
  labels: any;
  isJobRole?:boolean
}

const useStyles = makeStyles (
 {  
    invoiceWrapper: {
        borderRadius: 12,
        border: '1px solid #e3eaf4',
        padding:'12px 16px 12px 12px',
        backgroundColor: '#fff',
    },

    invoiceContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap:10
    },

    invoiceInfo: {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
    },

    invoiceImage: {
        height:'42px',
        width: '42px',
        borderRadius: 10,
        backgroundColor: '#e3eaf4',
        '& img': {
            height: '100%',
            width: '100%',
            borderRadius: 10,
            objectFit: 'cover',
        }
    },

    invoiceHeads:{
        '& h6':{
            fontSize: '16px',

        },
        '& p': {
            fontSize: '12px',
            color: 'rgb(109, 137, 175)',
            display: 'flex',
            alignItems: 'center',
            gap: 5,
        }
    },
    arrowIcon: {
        cursor: 'pointer',
    },
    projectName: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: "200px"
    }
 });

export default function AllInvoiceProjectDetails(props:MyProps) {
    const classes = useStyles();
    const {data} =props;

  return (
    <>
        <Box className={classes.invoiceWrapper}>
            <Box className={classes.invoiceContent}>
                <Box className={classes.invoiceInfo}>
                    <Box className={classes.invoiceImage}>
                        <img src={data?.attributes?.image ? data?.attributes?.image : dummyImage}/>
                    </Box>
                    <Box className={classes.invoiceHeads}>
                        <Typography title={data?.attributes?.project_name} variant="h6" className={classes.projectName}>{data?.attributes?.project_name}</Typography>
                        <Typography>
                            <img src={props.isJobRole ? workHistoryDark : mytaskreceipt} alt="Receipt" />
                            {props.isJobRole ? data?.attributes?.all_job_role_and_request : data?.attributes?.invoice_count}<span>{props.labels?.invoicesText}</span>
                        </Typography>
                    </Box>    
                </Box>
                <img src={Arrowinvoice} alt="Arrow" className={classes.arrowIcon}/>
            </Box>
        </Box>
    </>
  );
}
