import React, { useRef } from "react";
import { Grid, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import ReactToPrint from "react-to-print";
import {
    styled
} from "@material-ui/core/styles"
import { Arrowinvoice, RightInvoice, RightmarkIcon, Specialpayicon, arrowabove, cashinvoicereceipt, msghold, checkcircal, daterange, downloadinvoice, holdicon, hourglass, jobyrs, pendingicon, receiptinvoice, blockicon, rejectedicon, chatimg, arrowdown, standardpayicon,dummyImage, bankIconImage } from "./assets";
import ReclaimInvoiceModel from "./ReclaiminvoiceModel";

interface MyProps {
    data: any;
    handleOpen: any
    open: any;
    handleClose: any;
    handlehiddenandshow: any;
    showandhidden: any;
    reclaimInvoiceAPICall?: any;
    viewStatusUpdateDetails?: any;
}
const useStyles = makeStyles({

    ParentDiv: () => ({
    }),
    DownloadImage: {
        width: '38px',
        height: '38px',
        borderRadius: '12px',
        background: 'rgba(245,196,44,0.07)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: "10px"
    },
    iconposition: {
        height: "20px",
        width: '20px'
    },
    iconarrow: {
    },
    iconprint: {
        height: '100%',
        width: '100%'
    },
    PrintandDownloadandPay: {
        display: 'flex',
        gap: "24px",
    },
    InvoiceStatusandBox: {},
    Specialicon: {},
    arrowabove: {
    },
    SpecialiconStandard: {
    },
    Specialiconcashinvoice: {},
    profileandnameanddept: {
        marginLeft: '12px',
        paddingTop: '12px',
        paddingBottom: '12px',
        display: 'flex',
        gap: '8px'
    },
    profileDiv: {
        height: '40px',
        width: '40px',
        borderRadius: '12px'
    },
    profileICON: {
        height: '40px',
        width: '40px',
        borderRadius: '12px'
    }
    ,
    nameanddeptDiv: {},
    nameDiv: {
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '16px',
        fontWeight: 600,
    },
    Positioniconandtext: {
        display: 'flex',
        gap: '8px',
        marginLeft: '12px',
        paddingBottom: '17px'
    },
    deptDiv: {
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '12px',
        fontWeight: 500,
        paddingTop: "4px",
    },
    jobyrs: {},
    poistionicon: {},
    poistion: {
        color: '#6d89af',
        fontFamily: 'Urbanist',
        fontSize: '12px',
        fontWeight: 500,
    },
    detailandborderstyle: {
        color: '#6d89af',
        fontFamily: 'Urbanist',
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '19px',
        letterSpacing: '0.2px',
        marginLeft: '12px'
    },
    AccountNoandName: {
        display: 'flex',
        gap: '8px',
        marginLeft: '12px',
        paddingTop: '11px',
        borderBottom: '1px solid #e3eaf4 ',
        paddingBottom: '12px',

        marginRight: '12px'

    },
    ProjectDetailandBox: {},
    AccountIFSCandBRANCH: {
        display: 'flex',
        gap: '8px',
        marginLeft: '12px',
        paddingTop: '11px',
        marginRight: '12px'
    },
    Accountsymbol: {

    },
    Accounttextandnumber: {
        width: '40%'
    },
    AccounttextandName: {},
    Accounttext: {
        color: '#6d89af',
        fontFamily: 'Urbanist',
        fontSize: '12px',
        fontWeight: 500,
        letterSpacing: '0.2px'
    },
    Accountnumber: {
        color: '#18272a',
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontWeight: 600,
        letterSpacing: '0.2px',
        paddingTop: '7px'
    },
    AccountName: {
        color: '#18272a',
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontWeight: 600,
        letterSpacing: '0.2px',
        paddingTop: "7px"
    },
    AccountNameandsymbol: {
        display: "flex",
        gap: '3px',
        alignItems: 'center',
        //justifyContent: 'center'
    },
    BankICON: {
        paddingTop: '5px',
        height: "15px",
        width: "15px"
    },
    pendingiconandtext: {
        display: 'flex',
        gap: '5px',
        alignItems: 'center',

    },
    InvoiceAmountBox: {
        // width: '524px',
        "@media(max-width: 1300px)": {
            width: '100%',
        },

        borderRadius: '12px',
        border: '1px solid #e3eaf4',
        background: '#ffffff'
    },
    InvoiceAmount: {
        color: '#18272a',
        fontFamily: 'Urbanist',
        fontSize: '20px',
        fontWeight: 700,
        letterSpacing: '0.2px',
        paddingLeft: "5px"
    },
    InvoiceText: {
        color: '#18272a',
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontWeight: 600,

        letterSpacing: '0.2px'
    },
    InvoiceTextandAmount: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '16px',
        borderBottom: '1px solid #e3eaf4',
        marginLeft: '17px',
        marginRight: '17px',
        paddingBottom: '16px'
    },
    msginvoice: {
        display: 'flex',
        gap: '5px',
        justifyContent: 'center',
        paddingTop: '13px',
        paddingBottom: "24px"
    },
    msgicon: {},
    msgcontent: {
        color: '#42a200',
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '18px',
        letterSpacing: '0.2px'
    },
    msgimage: {},
    HoldButton: {
        width: '108px',
        height: '48px',
        borderRadius: '14px',
        border: '1px solid #f5c42c',
        background: '#f5c42c',
        color: '#ffffff'
    },
    ApproveButton: {
        width: '108px',
        height: '48px',
        borderRadius: '14px',
        border: '1px solid #17181d',
        background: '#17181d',
        color: '#ffffff'
    },
    RejectButton: {
        width: '108px',
        height: '48px',
        borderRadius: '14px',
        border: '1px solid #ff5c5c',
        background: '#ffffff',
        color: '#ff5c5c'
    },
    buttongroup: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '12px',
        paddingBottom: '16px',
        gap: '10px'
    },
    clearicon: {
        width: '30px',
        height: '30px',
        borderRadius: '15px',
        background: '#f0f4f9',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    InvoiceAmountandBox: {
        paddingTop: '16px'
    },
    cleariconandtext: {
        display: 'flex',
        gap: '5px',
        alignItems: 'center',
    },
    pendingDiv: {
        paddingTop: '18px',
        paddingLeft: "18px",
        display: 'flex',
        justifyContent: 'space-between'
    },
    clearDiv: {
        paddingTop: '18px',
        paddingLeft: "18px",
        display: 'flex',
        justifyContent: 'space-between'
    },
    specialpaymentmsg: {
        color: '#6d89af',
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontWeight: 600,
        letterSpacing: '0.3181818px',
        width: '100%',
        paddingTop: '12px',
        paddingBottom: '24px',
        justifyContent: 'center',
        alignItems: 'center',
        display: "flex",
    },
    submitButton: {
        width: '350px',
        //height: '52px',
        borderRadius: '14px',
        background: '#17181d',
        color: ' #ffffff',
        fontFamily: 'urbanist',
        fontSize: '15px',
        fontWeight: 600,
        letterSpacing: ' 0.25px',
        alignItems: 'center',
        paddingTop: '15px',
        paddingBottom: '15px',
        display: 'flex',
        flexDirection: 'column-reverse',
        //marginLeft: '208px',
    },
    standardpaymentmsgpending: {
        color: '#6d89af',
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontWeight: 600,
        letterSpacing: '0.3181818px',
        width: '307px',
        paddingTop: '13px',
        paddingBottom: '20px',
        textAlign: 'center',
        marginLeft: "70px"
    },
    pendingicon: {
        width: '30px',
        height: '30px',
        borderRadius: '15px',
        background: '#f5c42c',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    },
    pendingiconblock: {
        width: '30px',
        height: '30px',
        borderRadius: '15px',
        background: '#f0f4f9',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    },
    pendingiconhours: {
        width: '30px',
        height: '30px',
        borderRadius: '15px',
        background: '#f0f4f9',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    hourglassicon: {},
    Righticon: {},
    pendingtext: {
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontWeight: 600,
        letterSpacing: '0.2px'
    },
    cleartext: {
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontWeight: 600,
        letterSpacing: '0.2px'
    },
    abovearrow: {
        width: '24px',
        height: '24px',
        background: '#f0f4f9',
        borderRadius: '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: "12px"
    },
    DepartmentHeading: {
        color: '#6d89af',
        fontFamily: 'Urbanist',
        fontSize: '10px',
        fontWeight: 600,
        textTransform: 'uppercase',
        letterSpacing: '0.14285715px',
        paddingTop: '12px',
        paddingLeft: '12px',
        borderBottom: '0.5px dashed #6d89af',
        paddingBottom: '6px'
    },
    Direct: {
        color: '#ffffff',
        fontSize: "14px",
        fontWeight: 700,
    },
    DirectionTeam: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: "14px",
        paddingLeft: '14px',
        paddingRight: '14px'
    },
    Directhold: {
        width: '10px',
        height: '10px',
        borderRadius: '15px',
        background: '#ff5c5c'
    },
    DirectionTeamgroup: {

    },
    statusicon: {},
    DirectionTeamandicon: {
        display: 'flex',

        alignItems: 'center',
        gap: '8px'
    },
    checkcircal: {},
    Directionicon: {
        width: '20px',
        height: '20px',
        borderRadius: '15px',
        background: '#f5c42c',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    statusiconandtext: {
        display: 'flex',
        gap: "3px"
    },
    msgandinconhold: {
        display: 'flex',
        alignItems: 'center',
        //width:'340px',
        height: '40px',
        borderRadius: '8px',
        border: '0.5px solid #f5c42c',
        background: ' #ffffff',
        marginLeft: "40px",
        marginRight: '12px'
    },

    msgandinconclear: {
        borderRadius: '8px',
        border: '1px solid #f5c42c',
        background: 'rgba(245,196,44,0.07)',
        display: 'flex',
        alignItems: 'center',
        //width:'340px',
        height: '40px',
        marginLeft: "50px",
        marginRight: '12px'
    },
    msgandinconreject: {
        display: 'flex',
        alignItems: 'center',
        //width:'340px',
        marginRight: '12px',
        height: '40px',
        borderRadius: '8px',
        border: '0.5px solid #3389ff',
        background: ' #ffffff',
        marginLeft: "40px",
        gap: "5px"
    },
    msgholdicon: {
        hieght: '16px',
        width: '16px',
        paddingLeft: "10px",
        paddingRight: "5px",
    },
    msgrejecticon: {
        hieght: '16px',
        width: '16px',
        display: 'flex',
        alignItems: 'center',
        paddingTop: "3px",
        paddingLeft: '8px'
    },
    msgcontenthold: {
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '10px',
        fontWeight: 500,
        lineHeight: '13px',
        letterSpacing: '0.2px',
    },
    msgcontentreject: {
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '10px',
        fontWeight: 500,
        lineHeight: '13px',
        letterSpacing: '0.2px',
    },
    statustext: {
        color: '#f5c42c',
        fontFamily: 'Urbanist',
        fontSize: '10px',
        fontWeight: 600,
        letterSpacing: '0.14285715px'
    },
    statustexthold: {
        color: '#ff5c5c',
        fontFamily: 'Urbanist',
        fontSize: '10px',
        fontWeight: 600,
        letterSpacing: '0.14285715px'
    },
    statustextpending: {
        color: '#6d89af',
        fontFamily: 'Urbanist',
        fontSize: '10px',
        fontWeight: 600,
        letterSpacing: '0.14285715px',
    },
    Directiontext: {
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '12px',
        fontWeight: 600,
        letterSpacing: '0.17142858px'
    },
    DepartmentLevel: {
        //width: '100%',
        borderRadius: '10px',
        background: ' #f0f4f9',
        marginLeft: '50px',
        marginTop: '11px',
        marginRight: '12px',
        paddingBottom: '14px',
        "@media(max-width: 1300px)": {
            //width: '100%px',
            marginLeft: '42px',
            marginRight: '12px',

        },
    },
    InvoiceStatus: {
        paddingBottom: '8px',
        color: '#6d89af',
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '19px',
        letterSpacing: '0.23333333px',
    },
    InvoiceStatusBox: {
        // width: '524px',
        paddingBottom: '16px',
        borderRadius: '12px',
        border: '1px solid #e3eaf4',
        background: '#ffffff',
        "@media(max-width: 1300px)": {
            width: '100%'
        },
    },
    ArrowIcon: {
        width: '28px',
        height: ' 28px',
        background: '#f0f4f9',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '15px',
        marginRight: '12px'
    },
    Downloadimage: {
        width: '28px',
        height: ' 28px',
        background: '#f0f4f9',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '15px',
        marginRight: '12px'
    },
    DownloadText: {
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '19px',
        "@media(max-width: 1300px)": {
            fontSize: "12px"
        },
    },
    icondownload: {},
    PrintandDownload: {
        display: 'flex',
        gap: "24px",
        marginBottom: '16px'

    },
    InvoiceStatusandInvoiceDetailandYourDetail: {
        paddingTop: "24px",
        display: 'flex',
        width: '100%',
        gap: "24px",
    },
    InvoiceDetailBox: {
        // width: '479px',
        borderRadius: '12px',
        border: '1px solid #e3eaf4',
        background: ' #ffffff',
        "@media(max-width: 1300px)": {
            width: '100%',
        },
    },
    InvoiceName: {
        color: '#18272a',
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontWeight: 600,
        letterSpacing: '0.2px',
        paddingTop: "3px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    InvoiceDate: {
        color: '#18272a',
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontWeight: 600,
        letterSpacing: '0.2px',
        paddingTop: "3px",
    },
    InvoiceNametext: {
        color: '#6d89af',
        fontFamily: 'Urbanist',
        fontSize: '12px',
        fontWeight: 500,
        letterSpacing: '0.2px',
    },
    service: {
        color: '#6d89af',
        fontFamily: 'Urbanist',
        fontSize: '12px',
        fontWeight: 500,
        letterSpacing: '0.2px'
    },
    NoteText: {
        color: '#6d89af',
        fontFamily: 'Urbanist',
        fontSize: '12px',
        fontWeight: 500,
        letterSpacing: '0.2px'
    },
    Notecontent: {
        height: ' 57px',
        color: '#18272a',
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '18px',
        letterSpacing: '0.2px',
        paddingTop: "3px",
        overflowY: 'auto',     
        paddingRight: "12px",
        resize: 'none'
    },
    NoteandContent: {
        marginLeft: '12px',
    },
    YourDetailandBox: {
        paddingBottom: '16px'
    },
    ProjectDetailBox: {
        // width: '479px',
        borderRadius: '12px',
        border: '1px solid #e3eaf4',
        background: '#ffffff',
        "@media(max-width: 1300px)": {
            width: '100%',
        },
    },
    YourDetailBox: {
        //width: '479px',
        height: '231px',
        borderRadius: '12px',
        border: '1px solid #e3eaf4',
        background: '#ffffff',
        width: '100%'
    },
    profileandnameanddirection: {},
    InvoiceDatetext: {
        color: '#6d89af',
        fontFamily: 'Urbanist',
        fontSize: '12px',
        fontWeight: 500,
        letterSpacing: '0.2px'
    },
    InvoiceTextandName: {
        width: '34%',
        marginRight: "50px"
    },
    InvoiceNameandDate: {
        display: 'flex',
        //gap: '66px',
        marginLeft: '12px',
        marginTop: '14px',
        borderBottom: '1px solid #e3eaf4',
        marginBottom: '12px',
        paddingBottom: '12px',
        marginRight: '12px',
    },
    InvoiceDetailandBox: {
        paddingBottom: '16px'
    },
    InvoiceTextandDate: {},
    LeftDiv: {},
    RightDiv: {},
    PrintDiv: {
        display: 'flex',
        alignItems: 'center',
        width: '250px',
        height: '79px',
        borderRadius: '12px',
        border: '1px solid #e3eaf4',
        background: '#ffffff',
        justifyContent: 'space-between',
        "@media(max-width: 1300px)": {
            width: '200px'
        },
    },
    Printtext: {
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '19px',
        "@media(max-width: 1300px)": {
            fontSize: "12px"
        },
    },
    DownloadDiv: {
        display: 'flex',
        alignItems: 'center',
        width: '250px',
        height: '79px',
        borderRadius: '12px',
        border: '1px solid #e3eaf4',
        background: '#ffffff',
        justifyContent: 'space-between',
        "@media(max-width: 1300px)": {
            width: '200px',
        },
    },
    PayDiv: {
        width: '100%',
        height: '79px',
        borderRadius: '14px',
        background: 'linear-gradient(225deg, #58a2ff 0%, #1777e2 100%)',
        display: 'flex',
        alignItems: 'center',
        gap: '14px',
        marginBottom: '16px'
    },
    PayDivStandard: {
        width: '100%',
        height: '79px',
        borderRadius: '14px',
        background: '#f4f1ff',
        display: 'flex',
        alignItems: 'center',
        gap: '14px',
        marginBottom: '16px'
    },
    PayDivcashinvoice: {
        width: '100%',
        height: '79px',
        borderRadius: '14px',
        display: 'flex',
        alignItems: 'center',
        gap: '14px',
        background: '#f4f1ff',
        marginBottom: '16px'

    },
    PayIcon: {
        width: '38px',
        height: '38px',
        borderRadius: '12px',
        background: 'rgba(255,255,255,0.1)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '12px'
    },
    PayIconcashinvoice: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '12px',
        width: '38px',
        height: '38px',
        borderRadius: '12px',
        background: '#ffffff'
    },
    PayIconStandard: {
        width: '38px',
        height: '38px',
        borderRadius: '12px',
        background: '#ffffff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '12px',
    },
    payheadingandContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '3px'
    },
    payheadingandContentcashinvoice: {
        display: 'flex',
        flexDirection: 'column',
        gap: '3px'
    },
    payheadingandContentStandard: {
        display: 'flex',
        flexDirection: 'column',
        gap: '3px'
    },
    payheading: {
        color: '#ffffff',
        fontFamily: 'Urbanist',
        fontSize: '16px',
        fontWeight: 600,
        letterSpacing: '0.22857143px',
    },
    payheadingcashinvoice: {
        color: '#42a200',
        fontFamily: 'Urbanist',
        fontSize: '16px',
        fontWeight: 600,
        letterSpacing: '0.22857143px',
    },
    payheadingStandard: {
        color: '#8765ff',
        fontFamily: 'Urbanist',
        fontSize: '16px',
        fontWeight: 600,
        letterSpacing: '0.22857143px'
    },
    paycontent: {
        width: '252px',
        height: '32px',
        color: '#ffffff',
        fontFamily: 'Urbanist',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '16px',
        letterSpacing: '0.21818182px',
    },
    paycontentcashinvoice: {
        color: ' #17181d',
        fontFamily: 'Urbanist',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '16px',
        letterSpacing: '0.21818182px',
        "@media(max-width: 1300px)": {
            fontSize: '11px'
        },
    },
    paycontentStandard: {
        fontFamily: 'Urbanist',
        fontSize: '13px',
        fontWeight: 500,
        lineHeight: '16px',
        letterSpacing: '0.23636363px',
    },
    DownloadImageandtext: {
        display: 'flex',
        alignItems: 'center',
        gap: '5px'
    },
    PrintImageandtext: {
        display: 'flex',
        alignItems: 'center',
        gap: '5px'
    },
    PrintImage: {
        width: '38px',
        height: '38px',
        borderRadius: '12px',
        background: 'rgba(245,196,44,0.07)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: "10px"
    },
    profileandDepartmentandStatus: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: '12px',
        marginRight: '12px',
        alignItems: 'center',
        borderBottom: '1px solid #e3eaf4',
        paddingBottom: '10px',
        marginLeft: '12px'
    },
    profileanddepartment: {
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
        marginRight: "12px",
    },
    profileimage: {},
    profileimg: {
        height: "40px",
        width: '40px',
        borderRadius: "10px",
    },
    dateview: {},
    postedanddepartment: {},
    department: {
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontWeight: 600,
    },
    posted: {
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        fontSize: '12px',
        fontFamily: 'urbanist',
        paddingTop: '5px',
        color: '#6d89af',
        fontWeight: 600
    },
    allPictureLink: {
        color: 'rgb(51, 137, 255)',
        textDecoration: 'underline',
        textDecorationColor: 'rgb(51, 137, 255)',
        display: '-webkit-box',
        '-webkit-line-clamp': 1,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 180,
        fontFamily: 'urbanist',
        fontSize: '12px'
    },
    StatusDiv: {
        padding: "6px 10px",
        borderRadius: '12px',
        background: '#f5c42c',
        color: '#ffffff',
        fontFamily: 'Urbanist',
        fontSize: '10px',
        fontWeight: 700,
        textAlign: 'center',
        letterSpacing: '0.18181819px',
        alignItems: 'center',
        justifyContent: "center",
        display: 'flex'
    },
    dateandday: {
        paddingTop: '12px',
        marginLeft: '12px',
        marginRight: '12px',
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: '14px'
    },
    dateDiv: {
        display: 'flex',
        gap: '5px',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    dateicon: {},
    datetext: {
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '13px',
        fontWeight: 600,
    },
    DayDiv: {
        color: '#6d89af',
        fontFamily: 'Urbanist',
        fontSize: '13px',
        fontWeight: 600,
    }
});

const CustomGrid = styled(Grid)({
    // position: 'relative',
    // '&::after': {
    //     content: '""',
    //     position: 'absolute',
    //     width: 4,
    //     height: 103,
    //     // height: 142,
    //     background: '#f0f4f9',
    //     top: 30
    // }
});

const CustomGridapprove = styled(Grid)(
    {
        position: 'relative',
        '&::after': {
            content: '""',
            position: 'absolute',
            width: 4,
            height: 177,
            // height: 142,
            background: '#f5c42c',
            top: 30
        }
    });

const CustomGridapproveclear = styled(Grid)(
    {
        position: 'relative',
        '&::before': {
            content: '""',
            position: 'absolute',
            width: 4,
            height: 115,
            // height: 142,
            background: '#f5c42c',
            top: 30
        }
    });

const CustomDetailGrid = styled(Grid)({
    borderRadius: '10px',
    background: ' #f0f4f9',
    marginLeft: '50px',
    marginTop: '11px',
    paddingBottom: '14px',
    "@media(max-width: 1300px)": {
        //width: '386px',
        //marginLeft: '42px',
        //width:'100%'
    },
    marginRight: '11px',
    position: 'relative',
    '&::after': {
        content: '""',
        position: 'absolute',
        width: 4,
        height: 'calc(100% + 30px)',
        // height: 142,
        background: '#E3EAF4',
        top: "-11px",
        left: "-19px"
    }
});

const CustomDetailGridyellow = styled(Grid)({
    borderRadius: '10px',
    background: ' #f0f4f9',
    marginLeft: '50px',
    marginTop: '11px',
    paddingBottom: '14px',
    "@media(max-width: 1300px)": {
        //width: '386px',
        //marginLeft: '42px',
        //width:'100%'
    },
    marginRight: '11px',
    position: 'relative',
    '&::after': {
        content: '""',
        position: 'absolute',
        width: 4,
        height: 'calc(100% + 30px)',
        // height: 142,
        background: '#F5C42C',
        top: "-11px",
        left: "-19px"
    }
});

const CustomGridSmall = styled(Grid)({
    position: 'relative',
    background: 'lightgray',
    '&::after': {
        content: '""',
        position: 'absolute',
        width: 4,
        height: 14,
        background: 'lightgray',
        top: 20,
        opacity: 0.5
    },
});

const CustomGridSmallhold = styled(Grid)({
    position: 'relative',
    background: '#ff5c5c',
    '&::after': {
        content: '""',
        position: 'absolute',
        width: 4,
        height: 70,
        background: '#ff5c5c',
        top: 20
    },
});

const CustomGridSmallapprove = styled(Grid)({
    background: '#f5c42c',
    position: 'relative',
    '&::after': {
        content: '""',
        position: 'absolute',
        width: 4,
        height: 14,
        background: '#f5c42c',
        top: 20
    },
});

const CustomGridSmallreject = styled(Grid)({
    background: 'red',
    position: 'relative',
    '&::after': {
        content: '""',
        position: 'absolute',
        width: 4,
        height: 60,
        background: 'lightgray',
        top: 20
    },
})


const ParentGrid = styled(Grid)({
    '& div:last-child .MuiGrid-root::after': {
        content: 'none'
    }
})

export default function invoiceDetailCard(this: any, props: MyProps) {
    const { data, handleOpen, open, handleClose, handlehiddenandshow, showandhidden, viewStatusUpdateDetails, reclaimInvoiceAPICall } = props;

    const departmentsWithStatus = data?.attributes?.pipeline_departments_with_status;
    const classes = useStyles();
    const componentRef = useRef<any>(null);
    const formatDate = (dateString: any) => {
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', options as any);
    };
    const projectStartDate = formatDate(data?.attributes?.project?.project_start_date);
    const projectEndDate = formatDate(data?.attributes?.project?.project_end_date);

    return (
        <Grid className={classes.ParentDiv}>
            <Grid className={classes.PrintandDownloadandPay}>
            </Grid>
            <Grid container className={classes.InvoiceStatusandInvoiceDetailandYourDetail}>
                <Grid sm={12} md={6} className={classes.LeftDiv} style={{ flex: 1 }}>
                    <Grid className={classes.PrintandDownload} style={{ flex: 1 }}>
                        <Grid className={classes.DownloadDiv} style={{ flex: 1 }}>
                            <Grid className={classes.DownloadImageandtext}>
                                <Grid className={classes.DownloadImage}>
                                    <img src={receiptinvoice} alt="" className={classes.iconposition} />
                                </Grid>
                                <Grid className={classes.DownloadText}>
                                    Downlaod Invoice
                                </Grid>
                            </Grid>
                            <Grid className={classes.Downloadimage}>
                                <img src={downloadinvoice} alt="" className={classes.icondownload} onClick={() => {
                                    fetch(data?.attributes?.invoice_receipt || receiptinvoice).then((response) => response.blob()).then((blob) => {
                                        const blobUrl = URL.createObjectURL(blob);
                                        const link = document.createElement("a");
                                        link.href = blobUrl;
                                        link.download = "image";
                                        link.click();
                                    });
                                }} />
                            </Grid>
                        </Grid>
                        <Grid className={classes.PrintDiv} style={{ flex: 1 }}>
                            <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Grid item className={classes.PrintImage} >
                                    <img src={receiptinvoice} alt="" style={{ height: "18px", width: '18px' }} />
                                </Grid>
                                <Grid item className={classes.PrintImage} style={{ display: "none" }}>
                                    <img src={data?.attributes?.invoice_receipt} alt="" className={classes.iconprint} ref={(el) => (componentRef.current = el)} />
                                </Grid>
                                <Grid item className={classes.Printtext}>
                                    Print Invoice
                                </Grid>
                            </Grid>
                            <Grid item className={classes.ArrowIcon}>
                                <ReactToPrint
                                    trigger={() => (
                                        <img
                                            src={Arrowinvoice}
                                            alt=""
                                            className={classes.iconarrow}
                                        />
                                    )}
                                    content={() => componentRef.current}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={classes.InvoiceStatusandBox}>
                        <Grid className={classes.InvoiceStatus}>
                            Invoice Status
                        </Grid>

                        {
                            data?.attributes?.status === "approved" && departmentsWithStatus?.every((ele: any) => ele.status === "Approved") ? (
                                <Grid className={classes.InvoiceStatusBox} style={{ flex: 1 }}>
                                    <Grid className={classes.pendingDiv}>
                                        <Grid className={classes.pendingiconandtext}>
                                            <CustomGridapprove className={classes.pendingicon} >
                                                <img src={RightInvoice} alt="" className={classes.Righticon} />
                                            </CustomGridapprove>
                                            {departmentsWithStatus?.every((ele: any) => ele.status === "Approved") ?
                                                (<Grid className={classes.pendingtext}>
                                                    Approved
                                                </Grid>) : (<Grid className={classes.pendingtext}>
                                                    Pending
                                                </Grid>)
                                            }
                                        </Grid>
                                        <Grid className={classes.abovearrow} onClick={handlehiddenandshow}>
                                            {showandhidden ? <img src={arrowabove} alt="" className={classes.arrowabove} onClick={handlehiddenandshow} /> :
                                                <img src={arrowdown} alt="" className={classes.arrowabove} onClick={handlehiddenandshow} />
                                            }
                                        </Grid>
                                    </Grid>
                                    {
                                        showandhidden ? (
                                            <CustomDetailGridyellow>
                                                <Grid className={classes.DepartmentHeading}>
                                                    Department Levels
                                                </Grid>
                                                <ParentGrid className={classes.DirectionTeamgroup}>
                                                    {
                                                        departmentsWithStatus?.map((department: any) => {
                                                            console.log("department", department)
                                                            if (department.status === "Pending") {

                                                                return (<Grid className={classes.DirectionTeam}>
                                                                    <Grid className={classes.DirectionTeamandicon}>
                                                                        <CustomGridSmall className={classes.Directionicon} id="directIcon">
                                                                            <Grid className={classes.Direct}>
                                                                                P
                                                                            </Grid>
                                                                        </CustomGridSmall>
                                                                        <Grid className={classes.Directiontext}>
                                                                            {department.department_name}
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid className={classes.statusiconandtext}>
                                                                        <Grid className={classes.statusicon}>
                                                                            <img src={checkcircal} alt="" className={classes.checkcircal} />
                                                                        </Grid>
                                                                        <Grid className={classes.statustextpending}>
                                                                            {department.status}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>)
                                                            }
                                                            else if (department.status === "Approved") {
                                                                return (<Grid className={classes.DirectionTeam}>
                                                                    <Grid className={classes.DirectionTeamandicon}>
                                                                        <CustomGridSmallapprove className={classes.Directionicon} id="directIcon">
                                                                            <Grid className={classes.Direct}>
                                                                                P
                                                                            </Grid>
                                                                        </CustomGridSmallapprove>
                                                                        <Grid className={classes.Directiontext}>
                                                                            {department.department_name}
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid className={classes.statusiconandtext}>
                                                                        <Grid className={classes.statusicon}>
                                                                            <img src={checkcircal} alt="" className={classes.checkcircal} />
                                                                        </Grid>
                                                                        <Grid className={classes.statustext}>
                                                                            {department.status}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>)
                                                            }
                                                            else if (department.status === "Completed") {
                                                                return (<Grid className={classes.DirectionTeam}>
                                                                    <Grid className={classes.DirectionTeamandicon}>
                                                                        <CustomGridSmallapprove className={classes.Directionicon} id="directIcon">
                                                                            <Grid className={classes.Direct}>
                                                                                P
                                                                            </Grid>
                                                                        </CustomGridSmallapprove>
                                                                        <Grid className={classes.Directiontext}>
                                                                            {department.department_name}
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid className={classes.statusiconandtext}>
                                                                        <Grid className={classes.statusicon}>
                                                                            <img src={checkcircal} alt="" className={classes.checkcircal} />
                                                                        </Grid>
                                                                        <Grid className={classes.statustext}>
                                                                            {department.status}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>)
                                                            }
                                                            else if (department.status === "Holded") {
                                                                return (<Grid className={classes.DirectionTeam}>
                                                                    <Grid className={classes.DirectionTeamandicon}>
                                                                        <CustomGridSmall className={classes.Directionicon} id="directIcon">
                                                                            <Grid className={classes.Direct}>
                                                                                P
                                                                            </Grid>
                                                                        </CustomGridSmall>
                                                                        <Grid className={classes.Directiontext}>
                                                                            {department.department_name}
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid className={classes.statusiconandtext}>
                                                                        <Grid className={classes.statusicon}>
                                                                            <img src={holdicon} alt="" className={classes.checkcircal} />
                                                                        </Grid>
                                                                        <Grid className={classes.statustext}>

                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>)
                                                            }
                                                            else if (department.status === "Rejected") {
                                                                return (<Grid className={classes.DirectionTeam}>
                                                                    <Grid className={classes.DirectionTeamandicon}>
                                                                        <CustomGridSmallreject className={classes.Directionicon} id="directIcon">
                                                                            <Grid className={classes.Direct}>
                                                                                P
                                                                            </Grid>
                                                                        </CustomGridSmallreject>
                                                                        <Grid className={classes.Directiontext}>
                                                                            {department.department_name}
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid className={classes.statusiconandtext}>
                                                                        <Grid className={classes.statusicon}>
                                                                            <img src={checkcircal} alt="" className={classes.checkcircal} />
                                                                        </Grid>
                                                                        <Grid className={classes.statustext}>
                                                                            {department.status}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>)
                                                            }
                                                        })
                                                    }
                                                </ParentGrid>
                                            </CustomDetailGridyellow>
                                        ) : (null)
                                    }
                                    <Grid className={classes.pendingDiv}>
                                        <Grid className={classes.pendingiconandtext}>
                                            <CustomGridapproveclear className={classes.pendingicon}>
                                                <img src={RightInvoice} alt="" className={classes.Righticon} />
                                            </CustomGridapproveclear>
                                            <Grid className={classes.pendingtext}>
                                                Approved
                                            </Grid>
                                        </Grid>
                                        <Grid className={classes.abovearrow}>
                                            <img src={arrowabove} alt="" className={classes.arrowabove} />
                                        </Grid>
                                    </Grid>
                                    <Grid className={classes.DepartmentLevel}>
                                        <Grid className={classes.DepartmentHeading}>
                                            Department Levels
                                        </Grid>
                                        <Grid className={classes.DirectionTeamgroup}>
                                            <Grid className={classes.DirectionTeam}>
                                                <Grid className={classes.DirectionTeamandicon}>
                                                    <Grid className={classes.Directionicon}>
                                                        <Grid className={classes.Direct}>
                                                            P
                                                        </Grid>
                                                    </Grid>
                                                    <Grid className={classes.Directiontext}>
                                                        Accounts
                                                    </Grid>
                                                </Grid>
                                                <Grid className={classes.statusiconandtext}>
                                                    <Grid className={classes.statusicon}>
                                                        <img src={checkcircal} alt="" className={classes.checkcircal} />
                                                    </Grid>
                                                    <Grid className={classes.statustext}>
                                                        {data?.attributes?.status}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid className={classes.clearDiv}>
                                        <Grid className={classes.cleariconandtext}>
                                            <Grid className={classes.pendingicon} >
                                                <img src={RightInvoice} alt="" className={classes.Righticon} />
                                            </Grid>
                                            <Grid className={classes.cleartext}>
                                                Cleared
                                            </Grid>
                                        </Grid>
                                        <Grid className={classes.abovearrow}>
                                            <img src={arrowabove} alt="" className={classes.arrowabove} />
                                        </Grid>
                                    </Grid>
                                    <Grid className={classes.msgandinconclear}>
                                        <Grid> <img src={checkcircal} alt="" className={classes.msgholdicon} /></Grid>
                                        <Grid className={classes.msgcontenthold} style={{ color: "#f5c42c" }}>This invoice has been cleared successfully</Grid>
                                    </Grid>
                                </Grid>
                            ) :
                                data?.attributes?.status !== "approved" && departmentsWithStatus?.every((ele: any) => ele.status === "Approved") ? (
                                    <Grid className={classes.InvoiceStatusBox} style={{ flex: 1 }}>
                                        <Grid className={classes.pendingDiv}>
                                            <Grid className={classes.pendingiconandtext}>
                                                <CustomGridapprove className={classes.pendingicon} >
                                                    <img src={RightInvoice} alt="" className={classes.Righticon} />
                                                </CustomGridapprove>
                                                <Grid className={classes.pendingtext}>
                                                    Pending
                                                </Grid>
                                            </Grid>
                                            <Grid className={classes.abovearrow} onClick={handlehiddenandshow}>
                                                {showandhidden ? <img src={arrowabove} alt="" className={classes.arrowabove} onClick={handlehiddenandshow} /> :
                                                    <img src={arrowdown} alt="" className={classes.arrowabove} onClick={handlehiddenandshow} />
                                                }
                                            </Grid>
                                        </Grid>
                                        {
                                            showandhidden ? (
                                                <CustomDetailGrid >
                                                    <Grid className={classes.DepartmentHeading}>
                                                        Department Levels
                                                    </Grid>

                                                    <ParentGrid className={classes.DirectionTeamgroup}>
                                                        {
                                                            departmentsWithStatus?.map((department: any) => {
                                                                console.log("department", department)
                                                                if (department.status === "Pending") {

                                                                    return (<Grid className={classes.DirectionTeam}>
                                                                        <Grid className={classes.DirectionTeamandicon}>
                                                                            <CustomGridSmall className={classes.Directionicon} id="directIcon">
                                                                                <Grid className={classes.Direct}>
                                                                                    P
                                                                                </Grid>
                                                                            </CustomGridSmall>
                                                                            <Grid className={classes.Directiontext}>
                                                                                {department.department_name}
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid className={classes.statusiconandtext}>
                                                                            <Grid className={classes.statusicon}>
                                                                                <img src={checkcircal} alt="" className={classes.checkcircal} />
                                                                            </Grid>
                                                                            <Grid className={classes.statustextpending}>
                                                                                {department.status}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>)
                                                                }
                                                                else if (department.status === "Approved") {
                                                                    return (<Grid className={classes.DirectionTeam}>
                                                                        <Grid className={classes.DirectionTeamandicon}>
                                                                            <CustomGridSmallapprove className={classes.Directionicon} id="directIcon">
                                                                                <Grid className={classes.Direct}>
                                                                                    P
                                                                                </Grid>
                                                                            </CustomGridSmallapprove>
                                                                            <Grid className={classes.Directiontext}>
                                                                                {department.department_name}
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid className={classes.statusiconandtext}>
                                                                            <Grid className={classes.statusicon}>
                                                                                <img src={checkcircal} alt="" className={classes.checkcircal} />
                                                                            </Grid>
                                                                            <Grid className={classes.statustext}>
                                                                                {department.status}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>)
                                                                }
                                                                else if (department.status === "Completed") {
                                                                    return (<Grid className={classes.DirectionTeam}>
                                                                        <Grid className={classes.DirectionTeamandicon}>
                                                                            <CustomGridSmallapprove className={classes.Directionicon} id="directIcon">
                                                                                <Grid className={classes.Direct}>
                                                                                    P
                                                                                </Grid>
                                                                            </CustomGridSmallapprove>
                                                                            <Grid className={classes.Directiontext}>
                                                                                {department.department_name}
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid className={classes.statusiconandtext}>
                                                                            <Grid className={classes.statusicon}>
                                                                                <img src={checkcircal} alt="" className={classes.checkcircal} />
                                                                            </Grid>
                                                                            <Grid className={classes.statustext}>
                                                                                {department.status}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>)
                                                                }
                                                                else if (department.status === "Holded") {
                                                                    return (<Grid className={classes.DirectionTeam}>
                                                                        <Grid className={classes.DirectionTeamandicon}>
                                                                            <CustomGridSmall className={classes.Directionicon} id="directIcon">
                                                                                <Grid className={classes.Direct}>
                                                                                    P
                                                                                </Grid>
                                                                            </CustomGridSmall>
                                                                            <Grid className={classes.Directiontext}>
                                                                                {department.department_name}
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid className={classes.statusiconandtext}>
                                                                            <Grid className={classes.statusicon}>
                                                                                <img src={holdicon} alt="" className={classes.checkcircal} />
                                                                            </Grid>
                                                                            <Grid className={classes.statustext}>

                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>)
                                                                }
                                                                else if (department.status === "Rejected") {
                                                                    return (<Grid className={classes.DirectionTeam}>
                                                                        <Grid className={classes.DirectionTeamandicon}>
                                                                            <CustomGridSmallreject className={classes.Directionicon} id="directIcon">
                                                                                <Grid className={classes.Direct}>
                                                                                    P
                                                                                </Grid>
                                                                            </CustomGridSmallreject>
                                                                            <Grid className={classes.Directiontext}>
                                                                                {department.department_name}
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid className={classes.statusiconandtext}>
                                                                            <Grid className={classes.statusicon}>
                                                                                <img src={checkcircal} alt="" className={classes.checkcircal} />
                                                                            </Grid>
                                                                            <Grid className={classes.statustext}>
                                                                                {department.status}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>)
                                                                }
                                                            })
                                                        }
                                                    </ParentGrid>
                                                </CustomDetailGrid>
                                            ) : (null)
                                        }

                                        <Grid className={classes.pendingDiv}>
                                            <Grid className={classes.pendingiconandtext}>
                                                <Grid className={classes.pendingicon}>
                                                    <img src={RightInvoice} alt="" className={classes.Righticon} />
                                                </Grid>
                                                <Grid className={classes.pendingtext}>
                                                    Approved
                                                </Grid>
                                            </Grid>
                                            <Grid className={classes.abovearrow}>
                                                <img src={arrowabove} alt="" className={classes.arrowabove} />
                                            </Grid>
                                        </Grid>
                                        <Grid className={classes.DepartmentLevel}>
                                            <Grid className={classes.DepartmentHeading}>
                                                Department Levels
                                            </Grid>
                                            <Grid className={classes.DirectionTeamgroup}>
                                                <Grid className={classes.DirectionTeam}>
                                                    <Grid className={classes.DirectionTeamandicon}>
                                                        <Grid className={classes.Directionicon}>
                                                            <Grid className={classes.Direct}>
                                                                P
                                                            </Grid>
                                                        </Grid>
                                                        <Grid className={classes.Directiontext}>
                                                            Accounts
                                                        </Grid>
                                                    </Grid>
                                                    <Grid className={classes.statusiconandtext}>
                                                        <Grid className={classes.statusicon}>
                                                            <img src={checkcircal} alt="" className={classes.checkcircal} />
                                                        </Grid>
                                                        <Grid className={classes.statustext}>
                                                            {data?.attributes?.status}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid className={classes.clearDiv}>
                                            <Grid className={classes.cleariconandtext}>
                                                <Grid className={classes.pendingiconhours} >
                                                    <img src={hourglass} alt="" className={classes.Righticon} />
                                                </Grid>
                                                <Grid className={classes.cleartext}>
                                                    Cleared
                                                </Grid>
                                            </Grid>
                                            <Grid className={classes.abovearrow}>
                                                <img src={arrowabove} alt="" className={classes.arrowabove} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ) :
                                    departmentsWithStatus?.some((ele: any) => ele.status === "Rejected") || departmentsWithStatus?.some((ele: any) => ele.status === "Holded") ? (
                                        <Grid className={classes.InvoiceStatusBox} style={{ flex: 1 }}>
                                            <Grid className={classes.pendingDiv}>
                                                <Grid className={classes.pendingiconandtext}>
                                                    <CustomGrid className={classes.pendingicon}>
                                                        <img src={RightInvoice} alt="" className={classes.Righticon} />
                                                    </CustomGrid>
                                                    <Grid className={classes.pendingtext}>
                                                        Pending
                                                    </Grid>
                                                </Grid>
                                                <Grid className={classes.abovearrow} onClick={handlehiddenandshow}>
                                                    {showandhidden ? <img src={arrowabove} alt="" className={classes.arrowabove} onClick={handlehiddenandshow} /> :
                                                        <img src={arrowdown} alt="" className={classes.arrowabove} onClick={handlehiddenandshow} />
                                                    }
                                                </Grid>
                                            </Grid>
                                            {
                                                showandhidden ? (
                                                    <CustomDetailGrid>
                                                        <Grid className={classes.DepartmentHeading}>
                                                            Department Levels
                                                        </Grid>
                                                        <ParentGrid className={classes.DirectionTeamgroup}>
                                                            {
                                                                departmentsWithStatus?.map((department: any) => {
                                                                    console.log("department", department)
                                                                    if (department.status === "Pending") {

                                                                        return (<Grid className={classes.DirectionTeam}>
                                                                            <Grid className={classes.DirectionTeamandicon}>
                                                                                <CustomGridSmall className={classes.Directionicon} id="directIcon">
                                                                                    <Grid className={classes.Direct}>
                                                                                        P
                                                                                    </Grid>
                                                                                </CustomGridSmall>
                                                                                <Grid className={classes.Directiontext}>
                                                                                    {department.department_name}
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid className={classes.statusiconandtext}>
                                                                                <Grid className={classes.statusicon}>
                                                                                    <img src={pendingicon} alt="" className={classes.checkcircal} />
                                                                                </Grid>
                                                                                <Grid className={classes.statustextpending}>
                                                                                    {department.status}
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>)
                                                                    }
                                                                    else if (department.status === "Approved") {
                                                                        return (<Grid className={classes.DirectionTeam}>
                                                                            <Grid className={classes.DirectionTeamandicon}>
                                                                                <CustomGridSmallapprove className={classes.Directionicon} id="directIcon">
                                                                                    <Grid className={classes.Direct}>
                                                                                        P
                                                                                    </Grid>
                                                                                </CustomGridSmallapprove>
                                                                                <Grid className={classes.Directiontext}>
                                                                                    {department.department_name}
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid className={classes.statusiconandtext}>
                                                                                <Grid className={classes.statusicon}>
                                                                                    <img src={checkcircal} alt="" className={classes.checkcircal} />
                                                                                </Grid>
                                                                                <Grid className={classes.statustext}>
                                                                                    {department.status}
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>)
                                                                    }
                                                                    else if (department.status === "Completed") {
                                                                        return (<Grid className={classes.DirectionTeam}>
                                                                            <Grid className={classes.DirectionTeamandicon}>
                                                                                <CustomGridSmallapprove className={classes.Directionicon} id="directIcon">
                                                                                    <Grid className={classes.Direct}>
                                                                                        P
                                                                                    </Grid>
                                                                                </CustomGridSmallapprove>
                                                                                <Grid className={classes.Directiontext}>
                                                                                    {department.department_name}
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid className={classes.statusiconandtext}>
                                                                                <Grid className={classes.statusicon}>
                                                                                    <img src={checkcircal} alt="" className={classes.checkcircal} />
                                                                                </Grid>
                                                                                <Grid className={classes.statustext}>
                                                                                    {department.status}
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>)
                                                                    }
                                                                    else if (department.status === "Holded") {
                                                                        return (
                                                                            <Grid>
                                                                                <Grid className={classes.DirectionTeam}>
                                                                                    <Grid className={classes.DirectionTeamandicon}>
                                                                                        <CustomGridSmallhold className={classes.Directionicon} id="directIcon">
                                                                                            <Grid className={classes.Directhold}>
                                                                                            </Grid>
                                                                                        </CustomGridSmallhold>
                                                                                        <Grid className={classes.Directiontext}>
                                                                                            {department.department_name}
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    <Grid className={classes.statusiconandtext}>
                                                                                        <Grid className={classes.statusicon}>
                                                                                            <img src={holdicon} alt="" className={classes.checkcircal} />
                                                                                        </Grid>
                                                                                        <Grid className={classes.statustexthold}>
                                                                                            {department.status}
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid className={classes.msgandinconhold}>
                                                                                    <Grid> <img src={msghold} alt="" className={classes.msgholdicon} /></Grid>
                                                                                    <Grid className={classes.msgcontenthold}>{department.comment}</Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        )
                                                                    }
                                                                    else if (department.status === "Rejected") {
                                                                        return (
                                                                            <Grid>
                                                                                <Grid className={classes.DirectionTeam}>
                                                                                    <Grid className={classes.DirectionTeamandicon}>
                                                                                        <CustomGridSmallreject className={classes.Directionicon} id="directIcon">
                                                                                            <Grid className={classes.Direct}>
                                                                                                P
                                                                                            </Grid>
                                                                                        </CustomGridSmallreject>
                                                                                        <Grid className={classes.Directiontext}>
                                                                                            {department.department_name}
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    <Grid className={classes.statusiconandtext}>
                                                                                        <Grid className={classes.statusicon}>
                                                                                            <img src={rejectedicon} alt="" className={classes.checkcircal} />
                                                                                        </Grid>
                                                                                        <Grid className={classes.statustexthold}>
                                                                                            {department.status}
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid className={classes.msgandinconreject}>
                                                                                    <Grid> <img src={chatimg} alt="" className={classes.msgrejecticon} /></Grid>
                                                                                    <Grid className={classes.msgcontentreject}>{department.comment}</Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </ParentGrid>
                                                    </CustomDetailGrid>
                                                ) : (null)
                                            }
                                            <Grid className={classes.pendingDiv}>
                                                <Grid className={classes.pendingiconandtext}>
                                                    <Grid className={classes.pendingiconblock}>
                                                        <img src={blockicon} alt="" className={classes.Righticon} />
                                                    </Grid>
                                                    <Grid className={classes.pendingtext}>
                                                        Approved
                                                    </Grid>
                                                </Grid>
                                                <Grid className={classes.abovearrow}>
                                                    <img src={arrowabove} alt="" className={classes.arrowabove} />
                                                </Grid>
                                            </Grid>
                                            <Grid className={classes.clearDiv}>
                                                <Grid className={classes.cleariconandtext}>
                                                    <Grid className={classes.clearicon}>
                                                        <img src={blockicon} alt="" className={classes.hourglassicon} />
                                                    </Grid>
                                                    <Grid className={classes.cleartext}>
                                                        Cleared
                                                    </Grid>
                                                </Grid>
                                                <Grid className={classes.abovearrow}>
                                                    <img src={arrowabove} alt="" className={classes.arrowabove} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ) :
                                        (
                                            <Grid className={classes.InvoiceStatusBox} style={{ flex: 1 }}>
                                                <Grid className={classes.pendingDiv}>
                                                    <Grid className={classes.pendingiconandtext}>
                                                        <CustomGrid className={classes.pendingicon}>
                                                            <img src={RightInvoice} alt="" className={classes.Righticon} />
                                                        </CustomGrid>
                                                        <Grid className={classes.pendingtext}>
                                                            Pending
                                                        </Grid>
                                                    </Grid>
                                                    <Grid className={classes.abovearrow} onClick={handlehiddenandshow}>
                                                        {showandhidden ? <img src={arrowabove} alt="" className={classes.arrowabove} onClick={handlehiddenandshow} /> :
                                                            <img src={arrowdown} alt="" className={classes.arrowabove} onClick={handlehiddenandshow} />
                                                        }
                                                    </Grid>
                                                </Grid>
                                                {
                                                    showandhidden ? (
                                                        <CustomDetailGrid >
                                                            <Grid className={classes.DepartmentHeading}>
                                                                Department Levels
                                                            </Grid>
                                                            <ParentGrid className={classes.DirectionTeamgroup}>
                                                                {
                                                                    departmentsWithStatus?.length && departmentsWithStatus?.map((department: any) => {
                                                                        console.log("department", department)
                                                                        if (department.status === "Pending") {
                                                                            return (<Grid className={classes.DirectionTeam}>
                                                                                <Grid className={classes.DirectionTeamandicon}>
                                                                                    <CustomGridSmall className={classes.Directionicon} id="directIcon">
                                                                                        <Grid className={classes.Direct}>
                                                                                            P
                                                                                        </Grid>
                                                                                    </CustomGridSmall>
                                                                                    <Grid className={classes.Directiontext}>
                                                                                        {department.department_name}
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid className={classes.statusiconandtext}>
                                                                                    <Grid className={classes.statusicon}>
                                                                                        <img src={pendingicon} alt="" className={classes.checkcircal} />
                                                                                    </Grid>
                                                                                    <Grid className={classes.statustextpending}>
                                                                                        {department.status}
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>)
                                                                        }
                                                                        else if (department.status === "Approved") {
                                                                            return (<Grid className={classes.DirectionTeam}>
                                                                                <Grid className={classes.DirectionTeamandicon}>
                                                                                    <CustomGridSmallapprove className={classes.Directionicon} id="directIcon">
                                                                                        <Grid className={classes.Direct}>
                                                                                            P
                                                                                        </Grid>
                                                                                    </CustomGridSmallapprove>
                                                                                    <Grid className={classes.Directiontext}>
                                                                                        {department.department_name}
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid className={classes.statusiconandtext}>
                                                                                    <Grid className={classes.statusicon}>
                                                                                        <img src={checkcircal} alt="" className={classes.checkcircal} />
                                                                                    </Grid>
                                                                                    <Grid className={classes.statustext}>
                                                                                        {department.status}
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>)
                                                                        }
                                                                        else if (department.status === "Completed") {
                                                                            return (<Grid className={classes.DirectionTeam}>
                                                                                <Grid className={classes.DirectionTeamandicon}>
                                                                                    <CustomGridSmallapprove className={classes.Directionicon} id="directIcon">
                                                                                        <Grid className={classes.Direct}>
                                                                                            P
                                                                                        </Grid>
                                                                                    </CustomGridSmallapprove>
                                                                                    <Grid className={classes.Directiontext}>
                                                                                        {department.department_name}
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid className={classes.statusiconandtext}>
                                                                                    <Grid className={classes.statusicon}>
                                                                                        <img src={checkcircal} alt="" className={classes.checkcircal} />
                                                                                    </Grid>
                                                                                    <Grid className={classes.statustext}>
                                                                                        {department.status}
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>)
                                                                        }
                                                                        else if (department.status === "Holded") {
                                                                            return (
                                                                                <Grid>
                                                                                    <Grid className={classes.DirectionTeam}>
                                                                                        <Grid className={classes.DirectionTeamandicon}>
                                                                                            <CustomGridSmallhold className={classes.Directionicon} id="directIcon">
                                                                                                <Grid className={classes.Directhold}>
                                                                                                </Grid>
                                                                                            </CustomGridSmallhold>
                                                                                            <Grid className={classes.Directiontext}>
                                                                                                {department.department_name}
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        <Grid className={classes.statusiconandtext}>
                                                                                            <Grid className={classes.statusicon}>
                                                                                                <img src={holdicon} alt="" className={classes.checkcircal} />
                                                                                            </Grid>
                                                                                            <Grid className={classes.statustexthold}>
                                                                                                {department.status}
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    <Grid className={classes.msgandinconhold}>
                                                                                        <Grid> <img src={msghold} alt="" className={classes.msgholdicon} /></Grid>
                                                                                        <Grid className={classes.msgcontenthold}>{department.comment}</Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            )
                                                                        }
                                                                        else if (department.status === "Rejected") {
                                                                            return (
                                                                                <Grid>
                                                                                    <Grid className={classes.DirectionTeam}>
                                                                                        <Grid className={classes.DirectionTeamandicon}>
                                                                                            <CustomGridSmallreject className={classes.Directionicon} id="directIcon">
                                                                                                <Grid className={classes.Direct}>
                                                                                                    P
                                                                                                </Grid>
                                                                                            </CustomGridSmallreject>
                                                                                            <Grid className={classes.Directiontext}>
                                                                                                {department.department_name}
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        <Grid className={classes.statusiconandtext}>
                                                                                            <Grid className={classes.statusicon}>
                                                                                                <img src={rejectedicon} alt="" className={classes.checkcircal} />
                                                                                            </Grid>
                                                                                            <Grid className={classes.statustexthold}>
                                                                                                {department.status}
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    <Grid className={classes.msgandinconreject}>
                                                                                        <Grid> <img src={chatimg} alt="" className={classes.msgrejecticon} /></Grid>
                                                                                        <Grid className={classes.msgcontentreject}>{department.comment}</Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </ParentGrid>
                                                        </CustomDetailGrid>
                                                    ) : (null)
                                                }
                                                <Grid className={classes.pendingDiv}>
                                                    <Grid className={classes.pendingiconandtext}>
                                                        <Grid className={classes.pendingiconblock}>
                                                            <img src={hourglass} alt="" className={classes.Righticon} />
                                                        </Grid>
                                                        <Grid className={classes.pendingtext}>
                                                            Approved
                                                        </Grid>
                                                    </Grid>
                                                    <Grid className={classes.abovearrow}>
                                                        <img src={arrowabove} alt="" className={classes.arrowabove} />
                                                    </Grid>
                                                </Grid>
                                                <Grid className={classes.clearDiv}>
                                                    <Grid className={classes.cleariconandtext}>
                                                        <Grid className={classes.clearicon}>
                                                            <img src={hourglass} alt="" className={classes.hourglassicon} />
                                                        </Grid>
                                                        <Grid className={classes.cleartext}>
                                                            Cleared
                                                        </Grid>
                                                    </Grid>
                                                    <Grid className={classes.abovearrow}>
                                                        <img src={arrowabove} alt="" className={classes.arrowabove} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                    </Grid>
                    {
                        data?.attributes?.status === "approved" && departmentsWithStatus?.every((ele: any) => ele.status === "Approved") ? (
                            <Grid className={classes.InvoiceAmountandBox}>
                                <Grid className={classes.InvoiceStatus}>
                                    Invoice Amount
                                </Grid>
                                <Grid className={classes.InvoiceAmountBox}>
                                    <Grid className={classes.InvoiceTextandAmount}>
                                        <Grid className={classes.InvoiceText}>
                                            Total Amount:
                                        </Grid>
                                        <Grid className={classes.InvoiceAmount}>
                                            ₹{data?.attributes?.total_amount}
                                        </Grid>
                                    </Grid>
                                    <Grid className={classes.msginvoice}>
                                        <Grid className={classes.msgicon}>
                                            <img src={RightmarkIcon} alt="" className={classes.msgimage} />
                                        </Grid>
                                        <Grid className={classes.msgcontent}>
                                            This invoice has been cleared successfully
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) :
                            departmentsWithStatus?.some((ele: any) => ele.status === "Rejected") ? (
                                <Grid className={classes.InvoiceAmountandBox}>
                                    <Grid className={classes.InvoiceStatus}>
                                        Invoice Amount
                                    </Grid>
                                    <Grid className={classes.InvoiceAmountBox}>
                                        <Grid className={classes.InvoiceTextandAmount}>
                                            <Grid className={classes.InvoiceText}>
                                                Total Amount:
                                            </Grid>
                                            <Grid className={classes.InvoiceAmount}>
                                                ₹{data?.attributes?.total_amount}
                                            </Grid>
                                        </Grid>
                                        <Grid className={classes.specialpaymentmsg}>
                                            <div>
                                                <Grid
                                                    className={classes.submitButton}
                                                    onClick={handleOpen}

                                                >
                                                    Reclaim Invoice
                                                </Grid>
                                                <Modal
                                                    open={open}
                                                    onClose={handleClose}
                                                    aria-describedby="simple-modal-description"
                                                    aria-labelledby="simple-modal-title"
                                                >
                                                    <div style={{
                                                        top: '50%',
                                                        position: 'absolute',
                                                        left: '50%',
                                                        backgroundColor: 'white',
                                                        borderRadius: '20px',
                                                        transform: 'translate(-50%, -50%)',
                                                        height: '287px',
                                                        width: '375px'

                                                    }}>
                                                        <ReclaimInvoiceModel viewStatusUpdateDetails={viewStatusUpdateDetails} data={""} open={open} handleClose={handleClose} reclaimInvoiceAPICall={reclaimInvoiceAPICall} />
                                                    </div>
                                                </Modal>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid className={classes.InvoiceAmountandBox}>
                                    <Grid className={classes.InvoiceStatus}>
                                        Invoice Amount
                                    </Grid>
                                    <Grid className={classes.InvoiceAmountBox}>
                                        <Grid className={classes.InvoiceTextandAmount}>
                                            <Grid className={classes.InvoiceText}>
                                                Total Amount:
                                            </Grid>
                                            <Grid className={classes.InvoiceAmount}>
                                                ₹{data?.attributes?.total_amount}
                                            </Grid>
                                        </Grid>
                                        <Grid className={classes.standardpaymentmsgpending}>
                                            The invoice is pending right now you can check
                                            the status untill it s been clear
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                    }
                </Grid>
                <Grid sm={12} md={6} className={classes.RightDiv} style={{ flex: 1 }}>
                    {
                        data?.attributes?.type_of_invoice === "special_payment" ? (
                            <Grid className={classes.PayDiv}>
                                <Grid className={classes.PayIcon}>
                                    <img src={Specialpayicon} alt="" className={classes.Specialicon} />
                                </Grid>
                                <Grid className={classes.payheadingandContent}>
                                    <Grid className={classes.payheading}>
                                        Special Pay
                                    </Grid>
                                    <Grid className={classes.paycontent}>
                                        This is special pay invoice. It has to be cleared
                                        within 7 Days after publishing.
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : data?.attributes?.type_of_invoice === "cash_invoice" ? (
                            <Grid className={classes.PayDivcashinvoice} style={{ flex: 1 }}>
                                <Grid className={classes.PayIconcashinvoice}>
                                    <img src={cashinvoicereceipt} alt="" className={classes.Specialiconcashinvoice} />
                                </Grid>
                                <Grid className={classes.payheadingandContentcashinvoice}>
                                    <Grid className={classes.payheadingcashinvoice}>
                                        Cash Pay
                                    </Grid>
                                    <Grid className={classes.paycontentcashinvoice}>
                                        This is a cash pay invoice. It will be cleared
                                        after the approval of pipeline employees.
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid className={classes.PayDivStandard}>
                                <Grid className={classes.PayIconStandard}>
                                    <img src={standardpayicon} alt="" className={classes.SpecialiconStandard} />
                                </Grid>
                                <Grid className={classes.payheadingandContentStandard}>
                                    <Grid className={classes.payheadingStandard}>
                                        Standard Pay
                                    </Grid>
                                    <Grid className={classes.paycontentStandard}>
                                        This is a standard pay invoice. It will be cleared after the approval
                                        of pipeline employees.
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    }
                    <Grid className={classes.InvoiceDetailandBox}>
                        <Grid className={classes.InvoiceStatus}>
                            Invoice Details
                        </Grid>
                        <Grid className={classes.InvoiceDetailBox}>
                            <Grid className={classes.InvoiceNameandDate}>
                                <Grid className={classes.InvoiceTextandName}>
                                    <Grid className={classes.InvoiceNametext}>
                                        Invoice Name
                                    </Grid>
                                    <Grid title={data?.attributes?.invoice_name} className={classes.InvoiceName}>
                                        {data?.attributes?.invoice_name}
                                    </Grid>
                                </Grid>
                                <Grid className={classes.InvoiceTextandDate}>
                                    <Grid className={classes.InvoiceDatetext}>
                                        Invoice Date
                                    </Grid>
                                    <Grid className={classes.InvoiceDate}>
                                        {data?.attributes?.invoice_date}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className={classes.InvoiceNameandDate}>
                                <Grid className={classes.InvoiceTextandName}>
                                    <Grid className={classes.service}>
                                        Service
                                    </Grid>
                                    <Grid className={classes.InvoiceName}>
                                        Food & Stay
                                    </Grid>
                                </Grid>
                                <Grid className={classes.InvoiceTextandDate}>
                                    <Grid className={classes.InvoiceDatetext}>
                                        GST Number
                                    </Grid>
                                    <Grid className={classes.InvoiceDate}>
                                        {data?.attributes?.production_house?.gst_number}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className={classes.NoteandContent}>
                                <Grid className={classes.NoteText}>
                                    Note
                                </Grid>
                                <Grid className={classes.Notecontent}>
                                    {data?.attributes?.add_note}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        data?.attributes?.type_of_invoice === "special_payment" ? (
                            <Grid className={classes.YourDetailandBox}>
                                <Grid className={classes.InvoiceStatus}>
                                    Your Details
                                </Grid>
                                <Grid className={classes.YourDetailBox}>
                                    <Grid className={classes.profileandnameanddept}>
                                        <Grid className={classes.profileDiv}>
                                            <img src={data?.attributes?.account?.image || dummyImage} alt="" className={classes.profileICON} />
                                        </Grid>
                                        <Grid className={classes.nameanddeptDiv}>
                                            <Grid className={classes.nameDiv}>
                                                {data?.attributes?.account?.full_name}
                                            </Grid>
                                            <Grid className={classes.deptDiv}>
                                                Dept | {data?.attributes?.department?.name}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid className={classes.Positioniconandtext}>
                                        <Grid className={classes.poistionicon}>
                                            <img src={jobyrs} alt="" className={classes.jobyrs} />
                                        </Grid>
                                        <Grid className={classes.poistion}>
                                            {data?.attributes?.role}
                                        </Grid>
                                    </Grid>
                                    <Grid className={classes.detailandborderstyle}>
                                        Account Details -----------------------------
                                    </Grid>
                                    <Grid className={classes.AccountNoandName}>
                                        <Grid className={classes.Accounttextandnumber}>
                                            <Grid className={classes.Accounttext}>
                                                Account Number
                                            </Grid>
                                            <Grid className={classes.Accountnumber}>
                                                {data?.attributes?.account?.account_number || "................."}
                                            </Grid>
                                        </Grid>
                                        <Grid className={classes.AccounttextandName}>
                                            <Grid className={classes.Accounttext}>
                                                Bank Name
                                            </Grid>
                                            <Grid className={classes.AccountNameandsymbol}>
                                                <Grid className={classes.Accountsymbol}>
                                                    <img src={data?.attributes?.account?.bank_logo || bankIconImage} alt="" className={classes.BankICON} />
                                                </Grid>
                                                <Grid className={classes.AccountName}>
                                                    {data?.attributes?.account?.bank_name || "..."}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid className={classes.AccountIFSCandBRANCH}>
                                        <Grid className={classes.Accounttextandnumber}>
                                            <Grid className={classes.Accounttext}>
                                                IFSC CODE
                                            </Grid>
                                            <Grid className={classes.Accountnumber}>
                                                {data?.attributes?.account?.ifsc_code || ".................."}
                                            </Grid>
                                        </Grid>
                                        <Grid className={classes.AccounttextandName}>
                                            <Grid className={classes.Accounttext}>
                                                Branch
                                            </Grid>

                                            <Grid className={classes.AccountName}>
                                                {data?.attributes?.account?.branch_name || ".................."}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : data?.attributes?.type_of_invoice === "standard_pay" ? (
                            <Grid className={classes.YourDetailandBox}>
                                <Grid className={classes.InvoiceStatus}>
                                    Your Details
                                </Grid>
                                <Grid className={classes.YourDetailBox}>
                                    <Grid className={classes.profileandnameanddept}>
                                        <Grid className={classes.profileDiv}>
                                            <img src={data?.attributes?.account?.image || dummyImage} alt="" className={classes.profileICON} />
                                        </Grid>
                                        <Grid className={classes.nameanddeptDiv}>
                                            <Grid className={classes.nameDiv}>
                                                {data?.attributes?.account?.full_name}
                                            </Grid>
                                            <Grid className={classes.deptDiv}>
                                                Dept | {data?.attributes?.department?.name}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid className={classes.Positioniconandtext}>
                                        <Grid className={classes.poistionicon}>
                                            <img src={jobyrs} alt="" className={classes.jobyrs} />
                                        </Grid>
                                        <Grid className={classes.poistion}>
                                            {data?.attributes?.role}
                                        </Grid>
                                    </Grid>
                                    <Grid style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                                        <Grid className={classes.detailandborderstyle}>
                                            Account Details
                                        </Grid >
                                        <Grid style={{ border: '1px dashed #e3eaf4', width: '227px', height: '0px' }}></Grid>
                                    </Grid>

                                    <Grid className={classes.AccountNoandName}>
                                        <Grid className={classes.Accounttextandnumber}>
                                            <Grid className={classes.Accounttext}>
                                                Account Number
                                            </Grid>
                                            <Grid className={classes.Accountnumber}>
                                                {data?.attributes?.account?.account_number || "................."}
                                            </Grid>
                                        </Grid>
                                        <Grid className={classes.AccounttextandName}>
                                            <Grid className={classes.Accounttext}>
                                                Bank Name
                                            </Grid>
                                            <Grid className={classes.AccountNameandsymbol}>
                                                <Grid className={classes.Accountsymbol}>
                                                    <img src={data?.attributes?.account?.bank_logo || bankIconImage} alt="" className={classes.BankICON} />
                                                </Grid>
                                                <Grid className={classes.AccountName}>
                                                    {data?.attributes?.account?.bank_name || "..."}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid className={classes.AccountIFSCandBRANCH}>
                                        <Grid className={classes.Accounttextandnumber}>
                                            <Grid className={classes.Accounttext}>
                                                IFSC CODE
                                            </Grid>
                                            <Grid className={classes.Accountnumber}>
                                                {data?.attributes?.account?.ifsc_code || ".................."}
                                            </Grid>
                                        </Grid>
                                        <Grid className={classes.AccounttextandName}>
                                            <Grid className={classes.Accounttext}>
                                                Branch
                                            </Grid>

                                            <Grid className={classes.AccountName}>
                                                {data?.attributes?.account?.branch_name || ".................."}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : (
                            null
                        )
                    }

                    <Grid className={classes.ProjectDetailandBox}>
                        <Grid className={classes.InvoiceStatus}>
                            Project Details
                        </Grid>
                        <Grid className={classes.ProjectDetailBox}>
                            <Grid className={classes.profileandDepartmentandStatus}>
                                <Grid className={classes.profileanddepartment}>
                                    <Grid className={classes.profileimage}>
                                        <img src={data?.attributes?.project?.project_image || "https://www.littlethings.info/wp-content/uploads/2014/04/dummy-image-green-e1398449160839.jpg"} alt="" className={classes.profileimg} />
                                    </Grid>
                                    <Grid className={classes.postedanddepartment}>
                                        <Grid className={classes.department}>
                                            {data?.attributes?.project?.name}
                                        </Grid>
                                        <Grid className={classes.posted}>
                                            Posted By: <Link href="#" className={classes.allPictureLink}>AI Pictures</Link>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid className={classes.StatusDiv}>
                                    {data?.attributes?.project?.project_status && (
                                        <>{data.attributes.project.project_status.toUpperCase()}</>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid className={classes.dateandday}>
                                <Grid className={classes.dateDiv}>
                                    <Grid className={classes.dateicon}>
                                        <img src={daterange} alt="" className={classes.dateview} />
                                    </Grid>
                                    <Grid className={classes.datetext}>
                                        {projectStartDate} - {projectEndDate}
                                    </Grid>
                                </Grid>
                                <Grid className={classes.DayDiv}>
                                    {data?.attributes?.project?.project_total_days} Days
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    );
}
