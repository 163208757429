import React from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  TypographyProps,
  Link, AppBar, Tabs, Tab, Stepper, Step, StepLabel, StepIconProps, InputAdornment, Select, MenuItem, IconButton
} from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SideBar from "../../../components/src/SideBar";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer";
import { createTheme, styled, ThemeProvider, withStyles } from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader.web";
import ProductionHouseProfileSettingsController, {
  Props
} from "./ProductionHouseProfileSettingsController";
import { CustomButton, CustomInputlabelEmail, CustomTextField } from "../../email-account-login/src/CustomButton";
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector.web";
import { ColorlibConnector, ColorlibStepIconRoot } from "../../mobile-account-registration/src/ProductionHouseSignUp.web";
import CustomNotification from "../../../components/src/CustomNotification";

const imagesProductionHouseSetting = require("./assets");

const themeStyleProductionHouse = createTheme({
  palette: {
    primary: {
      main: "#F5C42C",
      contrastText: "#fff"
    }
  },
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          border: "1px solid #F5C42C",
        }
      }
    }
  }
});


const CustomPHBackButtonBoxProductionHouse = styled(CustomButton)({
  background: "#ffffff",
  border: "1px solid #17181d",
  '& .MuiButton-label': {
    fontSize: 14,
    color: '#17181d',
    fontWeight: 600,
    width: 163,
    height: 48,
  }
});

const CustomPHSignUPButtonBoxProductionHouse = styled(CustomButton)({
  '& .MuiButton-label': {
    fontSize: 14,
    color: '#ffffff',
    height: 48,
    width: 163,
    fontWeight: 500,
  }
});

const CustomCSSOfTextFieldProductionHouse = withStyles({
  root: {
    backgroundColor: 'white',
    borderColor: "#f5c42c",
    "& .MuiOutlinedInput-root.Mui-disabled": {
      "& fieldset": {
        border: "none"
      },
      "&:hover fieldset": {
        border: "none"
      },
      "&.Mui-focused fieldset": {
        border: "none",
        backgroundColor: '#f8fafe'
      },
      border: "none",
      backgroundColor: '#f8fafe',
      color: '#18272a',
      borderRadius: "14px",
      "& .MuiOutlinedInput-input": {
        fontWeight: 600,
        color: '#18272a',
        lineHeight: '19px',
        border: "none",
        fontSize: '14px',
        fontFamily: 'Urbanist',
      }
    },
    "& .MuiInputBase-input::placeholder": {
      color: '#6d89af',
      fontWeight: 500,
      fontFamily: 'Urbanist',
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": { borderColor: "#E3EAF4" },
      "&:hover fieldset": { borderColor: "#f5c42c" },
      "&.Mui-focused fieldset": {
        backgroundColor: 'rgba(245,196,44,0.04)',
        borderColor: "#f5c42c",
      },
      borderRadius: "14px",
      fontWeight: 400,
      color: '#323232',
      "& .MuiOutlinedInput-input": {
        color: '#17181D',
        fontWeight: 600,
        fontSize: '14px',
        borderColor: '#e3eaf4',
        lineHeight: '19px',
        fontFamily: 'Urbanist',
      }
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#f5c42c"
    },
  }
})(CustomTextField);

export default class ProductionHouseProfileSettings extends ProductionHouseProfileSettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  vendorProfileRenderErrorMessage(errorType: boolean, errorMessage: string) {
    return <>{errorType ? <Typography style={{ color: 'red', fontFamily: 'Urbanist', fontSize: '13px', fontWeight: 600, lineHeight: '18px', letterSpacing: '0.4px' }}>{errorMessage}</Typography> : null}</>
  }

  VendorProfileColorlibStepIcon(props: StepIconProps) {
    const { active, completed } = props;
    const vendorProfileiIcons: { [index: string]: any } = { 1: "01", 2: "02", 3: "03" };
    const VendorProfileColorlibStepIcon = () => {
      if (active) { return "activeIcon" }
      else if (completed) { return "completedIcon" }
      else { return "disbaleIcon" }
    }

    return (
      <ColorlibStepIconRoot className={`stepIcon ${VendorProfileColorlibStepIcon()}`}>
        {vendorProfileiIcons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  renderVendorProfileStepOneView() {
    return <>
      <Grid container style={{ paddingInline: "12px" }}>
        <Grid item style={{ padding: "12px" }} xs={12} sm={6} md={6} lg={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomInputlabelEmail id="fullnameOfProductionHouseProfile" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px" }}>Name of Production House</CustomInputlabelEmail>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomCSSOfTextFieldProductionHouse
              type={'text'} id="outlined-basic"
              data-test-id="fullnameOfVendorProfileInput"
              value={this.state.vendorProfileData["account[[production_house_name]"]}
              placeholder="Enter the name of production house"
              variant="outlined"
              onFocus={() => {
                this.handleInputFocus("fullnameOfProductionHouseProfile");
              }}
              onBlur={() => {
                this.handleInputBlur("fullnameOfProductionHouseProfile");
              }}
              className="email_input"
              onChange={(e: any) => this.handleChangeProfileDetails(e, "account[[production_house_name]")}
            />
            {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError["account[[production_house_name]"].require, "Name of production house is required")}
          </Grid>
        </Grid>

        <Grid item style={webStyle.productionHouseEmail} xs={12} sm={6} md={6} lg={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomInputlabelEmail id="emailOfVendorProfile" style={webStyle.productionHouseEmailHeading}>Email ID</CustomInputlabelEmail>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomCSSOfTextFieldProductionHouse data-test-id="emailOfVendorProfileInput" type={'text'} id="outlined-basic" variant="outlined"
              value={this.state.vendorProfileData["account[email]"]}
              className="email_input"
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img src={imagesProductionHouseSetting.verifiedCircularIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Grid item style={{ padding: "12px" }} xs={12} sm={6} md={6} lg={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomInputlabelEmail id="phoneOfProductionProfile" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px" }}>Phone Number</CustomInputlabelEmail>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomCSSOfTextFieldProductionHouse data-test-id="phoneOfVendorProfileInput" type={'text'} id="outlined-basic" variant="outlined"
              value={this.state.vendorProfileData["account[phone_number]"]}
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img src={imagesProductionHouseSetting.verifiedCircularIcon} />
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start" style={{ cursor: 'hand', paddingRight: this.state.isProductionHouseProfileSettingsSideBarOpen ? "35px" : "23px" }} className="phone_input_adornment_box vendor_profile_phone">
                    <CountryCodeSelector
                      id={"CountryCodeSelector"}
                      allowPropChange={true}
                      disable={true}
                      style={{ width: "80px" }}
                      navigation={this.props.navigation}
                      value={this.state.vendorProfileData["account[country_code]"]}
                    />
                    <Typography style={webStyle.productionHouseCountryPicker}> +{this.state.vendorProfileData["account[country_code]"]}</Typography>
                    <Box style={webStyle.productionHouseCountryCode as React.CSSProperties}></Box>
                  </InputAdornment>
                ),
              }}
              className="email_input"
            />
          </Grid>
        </Grid>
        <Grid item style={webStyle.productionHouseEmail} xs={12} sm={6} md={6} lg={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomInputlabelEmail id="stateDropdown" style={webStyle.productionHouseState}>State</CustomInputlabelEmail>
          </Grid>
          <Grid className="roleDropdown" item xs={12} sm={12} md={12} lg={12}>
            <Select
              value={this.state.vendorProfileData["profile[state]"]}
              label="Age" displayEmpty
              onFocus={() => {
                this.handleInputFocus("stateDropdown");
              }}
              onBlur={() => {
                this.handleInputBlur("stateDropdown");
              }}
              id="demo-simple-select"
              onChange={(e: any) => this.handleChangeProfileDetails(e, "profile[state]")}
              data-test-id="state-select-dropdown"
            >
              <MenuItem value="">Select your state</MenuItem>
              {this.state.stateData.map((state: any) => {
                return <MenuItem key={"state" + state.iso2} value={state.iso2}>{state.name}</MenuItem>
              })}
            </Select>
            {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError["profile[state]"].require, "State is required")}
          </Grid>
        </Grid>

        <Grid item style={webStyle.productionHouseEmail} xs={12} sm={6} md={6} lg={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomInputlabelEmail id="cityDropdown" style={webStyle.productionHouseCity}>City</CustomInputlabelEmail>
          </Grid>
          <Grid className="roleDropdown" item xs={12} sm={12} md={12} lg={12}>
            <Select
              value={this.state.vendorProfileData["profile[city]"] || ""}
              label="Age"
              onFocus={() => {
                this.handleInputFocus("cityDropdown");
              }}
              onBlur={() => {
                this.handleInputBlur("cityDropdown");
              }}
              id="demo-simple-select" displayEmpty
              onChange={(e: any) => this.handleChangeProfileDetails(e, "profile[city]")}
              data-test-id="city-select-dropdown"
            >
              <MenuItem value="">Select your city</MenuItem>
              {this.state.cityData.length ? this.state.cityData.map((city: any) => {
                return <MenuItem key={"city" + city.name} value={city.name}>{city.name}</MenuItem>
              }) : null}
            </Select>
            {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError["profile[city]"].require, "City is required")}
          </Grid>
        </Grid>

        <Grid item style={webStyle.productionHouseEmail} xs={12} sm={6} md={6} lg={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomInputlabelEmail id="pincodeOfVendorProfile" style={webStyle.productionHousePincode}>PIN Code</CustomInputlabelEmail>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomCSSOfTextFieldProductionHouse
              data-test-id="pincodeOfVendorProfileInput"
              onFocus={() => {
                this.handleInputFocus("pincodeOfVendorProfile");
              }}
              onBlur={() => {
                this.handleInputBlur("pincodeOfVendorProfile");
              }}
              type={'text'}
              value={this.state.vendorProfileData["profile[postal_code]"]}
              id="outlined-basic"
              onChange={(e: any) => this.handleChangeProfileDetails(e, "profile[postal_code]")}
              variant="outlined"
              className="email_input"
              placeholder="Enter PIN code"
            />
            {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError["profile[postal_code]"].require, "PIN code is required")}
            {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError["profile[postal_code]"].invalid === "numeric", "Only numeric values allowed")}
          </Grid>
        </Grid>
        <Grid item style={{ padding: "12px" }} xs={12} sm={6} md={6} lg={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomInputlabelEmail id="productionHouseFullAddress" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px" }}>Full Address of Production House</CustomInputlabelEmail>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomCSSOfTextFieldProductionHouse
              data-test-id="productionHouseFullAddress"
              type={'text'}
              value={this.state.vendorProfileData["account[production_house_address]"]}
              id="outlined-basic"
              onChange={(e: any) => this.handleChangeProfileDetails(e, "account[production_house_address]")}
              onFocus={() => {
                this.handleInputFocus("productionHouseFullAddress");
              }}
              onBlur={() => {
                this.handleInputBlur("productionHouseFullAddress");
              }}
              variant="outlined"
              className="email_input"
              placeholder="Enter address of production house"
            />
            {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError["account[production_house_address]"].require, "Production House full address is required")}
          </Grid>
        </Grid>
      </Grid>
    </>
  }

  renderVendorProfileStepTwoView() {
    return <>
      <Grid container style={{ paddingInline: "12px" }}>
        <Grid item style={webStyle.productionHouseEmail} xs={12} sm={6} md={6} lg={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomInputlabelEmail id="gstOfVendorProfile" style={webStyle.productionHouseGST}>GST Number</CustomInputlabelEmail>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomCSSOfTextFieldProductionHouse
              data-test-id="gstOfVPInput"
              type={'text'}
              className="email_input"
              id="outlined-basic"
              onChange={(e: any) => this.handleChangeProfileDetails(e, "account[gst_number]")}
              variant="outlined"
              onFocus={() => {
                this.handleInputFocus("gstOfVendorProfile");
              }}
              onBlur={() => {
                this.handleInputBlur("gstOfVendorProfile");
              }}
              value={this.state.vendorProfileData["account[gst_number]"]}
              placeholder="Enter your GST number"
            />
            {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError["account[gst_number]"].invalid, "GST Number should be in the format 22AAAAA0000A1Z5")}
            {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError["account[gst_number]"].require, "GST number is required")}
          </Grid>
        </Grid>

        <Grid item style={webStyle.productionHouseEmail} xs={12} sm={6} md={6} lg={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomInputlabelEmail id="tanOfVendorProfile" style={webStyle.productionHouseTan}>TAN Number</CustomInputlabelEmail>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomCSSOfTextFieldProductionHouse
              data-test-id="tanOfVPInput"
              value={this.state.vendorProfileData["account[tan_number]"]}
              type={'text'}
              className="email_input"
              id="outlined-basic"
              onFocus={() => {
                this.handleInputFocus("tanOfVendorProfile");
              }}
              onBlur={() => {
                this.handleInputBlur("tanOfVendorProfile");
              }}
              variant="outlined"
              onChange={(e: any) => this.handleChangeProfileDetails(e, "account[tan_number]")}
              placeholder="Enter your TAN number"
            />
            {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError["account[tan_number]"].invalid, "TAN Number should be in the format PDES03028F")}
            {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError["account[tan_number]"].require, "TAN number is required")}
          </Grid>
        </Grid>

        <Grid item style={webStyle.productionHouseEmail} xs={12} sm={6} md={6} lg={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomInputlabelEmail id="aadharOfVendorProfile" style={webStyle.productionHouseAadhar}>Aadhaar Card Number</CustomInputlabelEmail>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomCSSOfTextFieldProductionHouse data-test-id="aadharOfVPInput" type={'text'} id="outlined-basic" variant="outlined" placeholder="Enter your aadhaar card number"
              onFocus={() => {
                this.handleInputFocus("aadharOfVendorProfile");
              }}
              onBlur={() => {
                this.handleInputBlur("aadharOfVendorProfile");
              }}
              value={this.state.vendorProfileData["account[aadhar_card_number]"]}
              onChange={(e: any) => this.handleChangeProfileDetails(e, "account[aadhar_card_number]")}
              className="email_input"
            />
            {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError["account[aadhar_card_number]"].invalid === "numeric", "Only numeric values allowed")}
            {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError["account[aadhar_card_number]"].invalid === "length", "Enter a valid 12-digit Aadhaar number")}
            {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError["account[aadhar_card_number]"].require, "Aadhaar card number is required")}
          </Grid>
        </Grid>

        <Grid item style={webStyle.productionHouseEmail} xs={12} sm={6} md={6} lg={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomInputlabelEmail id="panOfVendorProfile" style={webStyle.productionHousePanCard}>PAN Card Number</CustomInputlabelEmail>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomCSSOfTextFieldProductionHouse data-test-id="panOfVPInput"
              type={'text'} id="outlined-basic"
              value={this.state.vendorProfileData["account[pan_card_number]"]}
              onChange={(e: any) => this.handleChangeProfileDetails(e, "account[pan_card_number]")}
              onFocus={() => {
                this.handleInputFocus("panOfVendorProfile");
              }}
              onBlur={() => {
                this.handleInputBlur("panOfVendorProfile");
              }}
              className="email_input"
              variant="outlined" placeholder="Enter your PAN card number"
            />
            {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError["account[pan_card_number]"].invalid, "PAN Number should be in the format ABCTY1234D")}
            {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError["account[pan_card_number]"].require, "PAN card number is required")}
          </Grid>
        </Grid>

        <Grid item style={webStyle.productionHouseUploadDocGrid} xs={12} sm={12} md={12} lg={12}>
          <Grid container style={webStyle.productionHouseUploadGridTwo as React.CSSProperties}>
            <Typography style={webStyle.productionHouseProfileAllReviewsTitleProduction as React.CSSProperties}>Upload Certificates & Documents</Typography>
            <Grid key={this.state.vendorProfileData["profile[documents][]"].length} style={{ padding: "2px 7px 7px 7px", display: "flex", overflowX: "auto", width: "calc(100% - 23px)" }}>
              {this.state.vendorProfileData["profile[documents][]"].length ?
                this.state.vendorProfileData["profile[documents][]"].map((document: any, index: number) => {
                  return <Grid item key={`${index + document.id}_documentlist`} style={{ width: "112px" }}>
                    <Box style={{ background: "#ffffff", margin: "4%", padding: "4%" }}>
                      <Box style={{ width: "94px" }} className="vendor_profile_settings_cert_box">
                        <Box className="cert_inner_tool">
                          <Box style={{ backgroundColor: "rgba(245,196,44,0.09)", borderRadius: "8px", padding: "8.5px 11.6px" }}>
                            <img style={{ width: "14.61px", height: "21px" } as React.CSSProperties} src={imagesProductionHouseSetting.certificateIcon} alt="" />
                          </Box>
                          <IconButton data-test-id={`pdf_download_${document.id}`} onClick={() => this.vendorProfileHandleCancelPDF(document)} className="vendor_profile_download_icon" size="medium">
                            <CancelIcon fontSize="small" htmlColor="#6d89af" style={webStyle.productionHouseCancel} />
                          </IconButton>
                        </Box>
                        <Box className="project_name">
                          <Typography noWrap className="file_name">{document.filename || document.name}</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                }) : null
              }
            </Grid>
          </Grid>
          <Grid container style={webStyle.productionHouseAddCard}>
            <AddCircleIcon onClick={this.handleClickAddDocuments} htmlColor="#f5c42c" style={webStyle.productionHouseCircleIcon} />
            <input data-test-id="addDocumentsInput" type="file" ref={this.fileUpload} accept=".jpg, .jpeg, .png, .pdf" onChange={this.handleSelectDocuments} className="filetype" multiple style={{ display: "none" }} />
          </Grid>
        </Grid>
      </Grid>
    </>
  }

  renderVendorProfileResetPasswordTabView() {
    return <>
      <Grid container style={{ paddingInline: "12px" }}>
        <Grid item style={webStyle.productionHouseEmail} xs={12} sm={6} md={6} lg={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomInputlabelEmail id="currentPasswordInput" style={webStyle.productionHouseCurrentPassword}>Current Password</CustomInputlabelEmail>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomCSSOfTextFieldProductionHouse
              onBlur={() => {
                this.handleInputBlur("currentPasswordInput");
              }}
              data-test-id="currentPasswordInput"
              type={'text'} id="outlined-basic" variant="outlined"
              placeholder="Enter your current password"
              onFocus={() => {
                this.handleInputFocus("currentPasswordInput");
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" >
                    <img data-test-id="currentPasswordVisibility" onClick={() => this.handleVendorProfileVisibilityChange("currentPassword")} src={this.state.vendorProfileResetPasswordVisibility.currentPassword ? imagesProductionHouseSetting.visibilityOffIcon : imagesProductionHouseSetting.visibilityIcon} />
                  </InputAdornment>
                ),
              }}
              value={this.state.vendorProfileResetPasswordVisibility.currentPassword ? this.state.vendorProfileResetPasswordData.currentPassword : "*".repeat(this.state.vendorProfileResetPasswordData.currentPassword.length)}
              onChange={(e: any) => this.handleChangeResetPasswordDetails(e, "currentPassword")}
              className="email_input"
            />
            {this.state.vendorProfileResetPasswordData.currentPassword === "" && this.vendorProfileRenderErrorMessage(this.state.vendorProfileResetPasswordDataError.currentPassword.require, "Current password is required")}
            {this.state.vendorProfileResetPasswordData.currentPassword !== "" && this.vendorProfileRenderErrorMessage(this.state.vendorProfileResetPasswordDataError.currentPassword.invalid, "Current password is invalid")}
          </Grid>
        </Grid>

        <Grid item style={webStyle.productionHouseEmail} xs={12} sm={6} md={6} lg={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomInputlabelEmail id="newPasswordInput" style={webStyle.productionHouseNewPassword}>New Password</CustomInputlabelEmail>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomCSSOfTextFieldProductionHouse
              onBlur={() => {
                this.handleInputBlur("newPasswordInput");
              }}
              data-test-id="newPasswordInput"
              type={'text'} id="outlined-basic" variant="outlined"
              placeholder="Enter new password"
              onFocus={() => {
                this.handleInputFocus("newPasswordInput");
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" >
                    <img data-test-id="newPasswordVisibility" onClick={() => this.handleVendorProfileVisibilityChange("newPassword")} src={this.state.vendorProfileResetPasswordVisibility.newPassword ? imagesProductionHouseSetting.visibilityOffIcon : imagesProductionHouseSetting.visibilityIcon} />
                  </InputAdornment>
                ),
              }}
              value={this.state.vendorProfileResetPasswordVisibility.newPassword ? this.state.vendorProfileResetPasswordData.newPassword : "*".repeat(this.state.vendorProfileResetPasswordData.newPassword.length)}
              onChange={(e: any) => this.handleChangeResetPasswordDetails(e, "newPassword")}
              className="email_input"
            />
             {this.vendorProfileRenderErrorMessage(this.state.vendorProfileResetPasswordDataError.newPassword.require && this.state.vendorProfileResetPasswordData.newPassword === "", "New password is required")}
             {this.vendorProfileRenderErrorMessage(this.state.vendorProfileResetPasswordDataError.newPassword.invalid && this.state.vendorProfileResetPasswordData.newPassword !== "", "New password is invalid")}
          </Grid>
        </Grid>

        <Grid item style={webStyle.productionHouseEmail} xs={12} sm={6} md={6} lg={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomInputlabelEmail id="confirmNewPasswordInput" style={webStyle.productionHouseConfirmNewPass}>Confirm New Password</CustomInputlabelEmail>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomCSSOfTextFieldProductionHouse data-test-id="confirmNewPasswordInput"
              onFocus={() => {
                this.handleInputFocus("confirmNewPasswordInput");
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" >
                    <img data-test-id="confirmPasswordVisibility" onClick={() => this.handleVendorProfileVisibilityChange("confirmNewPassword")} src={this.state.vendorProfileResetPasswordVisibility.confirmNewPassword ? imagesProductionHouseSetting.visibilityOffIcon : imagesProductionHouseSetting.visibilityIcon} />
                  </InputAdornment>
                ),
              }}
              type={'text'} id="outlined-basic" variant="outlined" placeholder="Enter your confirm password"
              value={this.state.vendorProfileResetPasswordVisibility.confirmNewPassword ? this.state.vendorProfileResetPasswordData.confirmNewPassword : "*".repeat(this.state.vendorProfileResetPasswordData.confirmNewPassword.length)}
              className="email_input"
              onBlur={() => {
                this.handleInputBlur("confirmNewPasswordInput");
              }}
              onChange={(e: any) => this.handleChangeResetPasswordDetails(e, "confirmNewPassword")}
            />
            {this.vendorProfileRenderErrorMessage(this.state.vendorProfileResetPasswordDataError.confirmNewPassword.require && this.state.vendorProfileResetPasswordData.confirmNewPassword === "", "Confirm new password is required")}
            {this.vendorProfileRenderErrorMessage(this.state.vendorProfileResetPasswordDataError.confirmNewPassword.match && this.state.vendorProfileResetPasswordData.confirmNewPassword !== "" && this.state.vendorProfileResetPasswordData.confirmNewPassword !== this.state.vendorProfileResetPasswordData.newPassword, "Confirm new password should be same as new password")}
          </Grid>
        </Grid>
      </Grid>
    </>
  }

  renderVendorProfileSettingsMainContent = (sideBarStatus: boolean = this.state.isProductionHouseProfileSettingsSideBarOpen) => {
    return <>
      <Loader loading={this.state.isLoading}></Loader>
      <Grid style={webStyle.productionHouseProfileWholePageContentSetupProduction} >
        <Header handleSideBar={this.handleVendorProfileSettingsSideBar}
          dropDownStatus={this.state.isVendorProfileSettingsDropdownOpen}
          navigate={this.props.navigation.navigate}
          sideBarStatus={sideBarStatus}
          handleDropdown={this.handleDropdown}
        />
        <Grid style={webStyle.productionHouseProfileDynamicMainContentProduction as React.CSSProperties}>
          <Box sx={webStyle.productionHouseProfileBlockInfoTitleProduction}>
            <Box>
              <Typography variant="h3" style={webStyle.productionHouseProfileActiveBlockMainTitleProduction as TypographyProps}>Profile Settings</Typography>
              <Typography variant="h6" style={webStyle.productionHouseProfileBlockE2EPathProduction as TypographyProps}>
                <span style={webStyle.productionHouseProfileHighlightedTitleextProduction}>
                  <Link data-test-id="homeLink" color="inherit" onClick={() => this.handleNavigation("Projects")}>
                    Home
                  </Link>
                </span>
                <span style={{ color: "#F5C42C" }}>{" / "}</span>
                <span style={webStyle.productionHouseProfileHighlightedTitleextProduction}>
                  <Link data-test-id="manageProfileLink" color="inherit" onClick={() => this.handleNavigation("ProductionHouseManageProfile")}>
                    Manage Profile
                  </Link>
                </span>
                {" "}/ Profile Settings
              </Typography>
            </Box>
          </Box>
          <Grid style={{ marginTop: "24px" }} item sm={12} xl={12} xs={12} md={12} lg={12}>
            <AppBar
              color="transparent"
              className="appbar app_bar"
              position="static"
            >
              <Tabs
                aria-label="scrollable prevent tabs example"
                value={this.state.vendorProfileTabValue}
                className="tabs_main"
                variant="scrollable"
                onChange={this.handleVendorProfileAppBarValue}
                data-test-id="tabChange"
                TabIndicatorProps={{ style: { height: '2px', background: "orange" } as React.CSSProperties }}
                scrollButtons="on"
              >
                <Tab className={this.state.vendorProfileTabValue == 0 ? "active_btn" : "non_active_btn"} label="Edit & Update Profile" />
                <Tab className={this.state.vendorProfileTabValue == 1 ? "active_btn" : "non_active_btn"} label="Reset Password" />
              </Tabs>
            </AppBar>
          </Grid>
          <Grid style={webStyle.productionHouseTab} item sm={12} xl={12} xs={12} md={12} lg={12}>
            {this.state.vendorProfileTabValue == 0 ?
              <Grid container style={{ display: "flex", flexWrap: "nowrap", flexDirection: "column", minHeight: "607px" }}>
                <Box style={{ width: "100%" }}>
                  <Box style={{ width: '100%', display: "flex", justifyContent: "center" }}>
                    <Stepper style={webStyle.productionHouseStepper} alternativeLabel activeStep={this.state.vendorProfileActiveStep} connector={<ColorlibConnector />}>
                      {
                        ["Basic Details", "Official Details"].map(
                          (label, index) => (<Step key={label}>
                            <StepLabel
                              className="stepLabelClass"
                              data-test-id={`stepLabelId_${index}`}
                              StepIconComponent={this.VendorProfileColorlibStepIcon}>{label}
                            </StepLabel>
                          </Step>))
                      }
                    </Stepper>
                  </Box>
                  {!this.state.vendorProfileActiveStep && this.renderVendorProfileStepOneView()}
                  {this.state.vendorProfileActiveStep === 1 && this.renderVendorProfileStepTwoView()}
                </Box>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ justifyContent: "space-between", display: "flex", flex: "inherit", paddingBottom: "24px", marginTop: 'auto', marginInline: "24px" }}>
                  <Grid item style={{ width: 163, visibility: this.state.vendorProfileActiveStep ? "visible" : "hidden", height: 48, backgroundColor: "#FFFFFF" } as React.CSSProperties} className="button_box">
                    <CustomPHBackButtonBoxProductionHouse data-test-id="sign_up_button" style={{ height: 48 } as React.CSSProperties} onClick={this.handleVendorProfileStepBack}>
                      Back
                    </CustomPHBackButtonBoxProductionHouse>
                  </Grid>
                  {this.state.vendorProfileActiveStep ===  1 ?
                    <Grid item style={{ width: 202, height: 48 } as React.CSSProperties} className="button_box">
                      <CustomPHSignUPButtonBoxProductionHouse data-test-id="sign_up_button" style={{ height: 48, backgroundColor: "#17181d" } as React.CSSProperties} onClick={this.handleSaveDetails}>
                        Save Details
                      </CustomPHSignUPButtonBoxProductionHouse>
                    </Grid>
                    :
                    <Grid item style={{ width: 163, height: 48 } as React.CSSProperties} className="button_box">
                      <CustomPHSignUPButtonBoxProductionHouse data-test-id="next_button" style={{ height: 48, backgroundColor: "#17181d" } as React.CSSProperties} onClick={this.handleVendorProfileMoveOnNextPage}>
                        Next
                      </CustomPHSignUPButtonBoxProductionHouse>
                    </Grid>}
                </Grid>
              </Grid> : null}
            {this.state.vendorProfileTabValue == 1 ?
              <>
                <Grid style={{ height: "602px", display: "flex", flexDirection: "column" }}>
                  {this.renderVendorProfileResetPasswordTabView()}
                  <Grid style={{ marginTop: "auto", width: "100%", alignItems: "end", display: "flex", justifyContent: "end", paddingBottom: "24px", boxSizing: "border-box" }}>
                    <Grid item style={{ width: 180, height: 48, paddingInline: "24px" } as React.CSSProperties} className="button_box">
                      <CustomPHSignUPButtonBoxProductionHouse data-test-id="next_button" style={{ height: 48 } as React.CSSProperties} className="button" onClick={this.handleVendorProfileResetPassword}>
                        Reset Now
                      </CustomPHSignUPButtonBoxProductionHouse>
                    </Grid>
                  </Grid>
                </Grid>
              </> : null}
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </>
  }
  // Customizable Area End

  render() {
    return (
      <ThemeProvider theme={themeStyleProductionHouse}>
        <Container style={webStyle.productionHouseProfileParentContainerProduction}>
          {
            this.state.isProductionHouseProfileSettingsSideBarOpen ? (
              <Grid spacing={0} container>
                <Grid xs={3} item style={webStyle.procutionHouseProfileSidebarParentGridProduction} >
                  <SideBar
                    handleSideBar={this.handleVendorProfileSettingsSideBar}
                    userData={{ name: "Manage Profile" }}
                    activeMenuItem={"Manage Profile"}
                    navigate={this.props.navigation.navigate}
                  />
                </Grid>
                <Grid style={webStyle.productionHouseRender as React.CSSProperties} item xs={9} >
                  {this.renderVendorProfileSettingsMainContent()}
                </Grid>
              </Grid>
            ) : (
              <Grid style={webStyle.productionHouseMainContent as React.CSSProperties} item xs={12}>
                {this.renderVendorProfileSettingsMainContent()}
              </Grid>
            )
          }
        </Container>
        <CustomNotification navigation={(route: string) => this.props.navigation.navigate(route)} notification={this.state.vendorProfileNotification} handleCloseNotification={this.handleVendorProfileCloseNotification} />
      </ThemeProvider>
    );
  }
}

const webStyle = {
  productionHouseProfileAllReviewsTitleProduction: {
    color: "#17181d",
    fontFamily: "Urbanist",
    fontSize: "12px",
    fontWeight: 600,
    padding: "12px 12px 0px 12px",
    width: "100%",
    lineHeight: "15px",
  },
  productionHouseProfileParentContainerProduction: {
    padding: "0px", maxWidth: "none",
  },
  procutionHouseProfileSidebarParentGridProduction: {
    minHeight: "100vh", maxWidth: "22.25%",
  },
  productionHouseProfileWholePageContentSetupProduction: {
    borderLeft: "1px solid #E3EAF4",
    // minHeight: "90vh",
    flex: 1,
  },
  productionHouseProfileDynamicMainContentProduction: {
    padding: "24px",
    boxSizing: "border-box",
    minHeight: "100vh",
    backgroundColor: "#F8FAFE",
    width: "100%",
  },
  productionHouseProfileBlockInfoTitleProduction: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  productionHouseProfileHighlightedTitleextProduction: {
    textDecoration: "underline",
    fontFamily: "Urbanist",
    cursor: "pointer",
    color: "#F5C42C",
    fontWeight: 600,
    fontSize: "14px",
  },
  productionHouseProfileActiveBlockMainTitleProduction: {
    fontWeight: "700",
    lineHeight: "34px",
    fontSize: "28px",
    letterSpacing: "1px",
    color: "#17181D",
  },
  productionHouseProfileBlockE2EPathProduction: {
    lineHeight: "17px",
    paddingTop: "8px",
    color: "#6D89AF",
    fontWeight: 500,
    fontSize: "14px",
    letterSpacing: "1px",
  },
  productionHouseCountryPicker:{
     color: '#18272a', 
     marginLeft: '9px' 
  },
  productionHouseState:{
    fontSize: "13px", 
    fontFamily: "Urbanist", 
    fontWeight: 600, 
    lineHeight: '18px', 
    color: "#6D89AF", 
    paddingBottom: "6px" 
  },
  productionHouseCity:{
     fontSize: "13px", 
     fontFamily: "Urbanist", 
     fontWeight: 600, 
     lineHeight: '18px', 
     color: "#6D89AF", 
     paddingBottom: "6px" 
  },
  productionHouseGST:{
     fontSize: "13px", 
     fontFamily: "Urbanist", 
     fontWeight: 600, 
     lineHeight: '18px', 
     color: "#6D89AF", 
     paddingBottom: "6px", 
     paddingTop: "0px" 
  },
  productionHouseCancel:{
     width: "14px", 
     height: "14px" 
  },
  productionHouseAddCard:{
     width: "132px", 
     height: "125px", 
     borderRadius: "12px", 
     display: "flex", 
     alignItems: "center", 
     justifyContent: "center", 
     backgroundColor: "rgba(245,196,44,0.04)", 
     border: "1px dashed #f5c42c"
  },
  productionHouseCircleIcon:{
     width: "20px", 
     height: "20px", 
     cursor: "pointer" 
  },
  productionHouseCurrentPassword:{
    fontSize: "13px", 
    fontFamily: "Urbanist", 
    fontWeight: 600, 
    lineHeight: '18px', 
    color: "#6D89AF", 
    paddingBottom: "6px" 
  },
  productionHouseTab:{
     marginTop: "24px", 
     width: "100%", 
     minHeight: "607px", 
     borderRadius: "12px", 
     border: "1px solid #e3eaf4", 
     background: "#ffffff" 
  },
  productionHouseStepper:{
     width: '496.5px', 
     paddingTop: "24px", 
     marginLeft: '-9%', 
     marginRight: '-12%' 
  },
  productionHouseRender:{
     display: "flex", 
     flexDirection: "column", 
     flexBasis: "77.75%", 
     maxWidth: "77.75%" 
  },
  productionHouseEmail:{
    padding: "12px" 
  },
  productionHouseEmailHeading:{
     fontSize: "13px", 
     fontFamily: "Urbanist", 
     fontWeight: 600, 
     lineHeight: '18px', 
     color: "#6D89AF", 
     paddingBottom: "6px" 
  },
  productionHouseCountryCode:{
    height: '23px', 
    borderLeft: '1px solid #6d89af', 
    marginLeft: '12px' 
  },
  productionHousePincode:{
    fontSize: "13px", 
    fontFamily: "Urbanist", 
    fontWeight: 600, 
    lineHeight: '18px', 
    color: "#6D89AF", 
    paddingBottom: "6px" 
  },
  productionHouseTan:{
    fontSize: "13px", 
    fontFamily: "Urbanist", 
    fontWeight: 600, 
    lineHeight: '18px', 
    color: "#6D89AF", 
    paddingBottom: "6px", 
    paddingTop: "0px" 
  },
  productionHouseAadhar:{
    fontSize: "13px", 
    fontFamily: "Urbanist", 
    fontWeight: 600, 
    lineHeight: '18px', 
    color: "#6D89AF", 
    paddingBottom: "6px", 
    paddingTop: "0px" 
  },
  productionHousePanCard:{
    fontSize: "13px", 
    fontFamily: "Urbanist", 
    fontWeight: 600, 
    lineHeight: '18px', 
    color: "#6D89AF", 
    paddingBottom: "6px", 
    paddingTop: "0px"
  },
  productionHouseUploadDocGrid:{
     marginTop: "24px", 
     paddingInline: "12px", 
     display: "flex" 
  },
  productionHouseUploadGridTwo:{
    marginRight: "24px", 
    width: "calc(100% - 156px)", 
    height: "125px", borderRadius: "12px", 
    backgroundColor: "#f8fafe", 
    border: "1px solid #e3eaf4", 
    boxSizing: "border-box", 
    color: "#17181d", 
    fontFamily: "Urbanist", 
    fontSize: "12px", 
    fontWeight: 500, 
    lineHeight: "16px" 
  },
  productionHouseNewPassword:{
     fontSize: "13px", 
     fontFamily: "Urbanist", 
     fontWeight: 600, 
     lineHeight: '18px', 
     color: "#6D89AF", 
     paddingBottom: "6px" 
  },
  productionHouseConfirmNewPass:{
    fontSize: "13px", 
    fontFamily: 
    "Urbanist", 
    fontWeight: 600, 
    lineHeight: '18px', 
    color: "#6D89AF", 
    paddingBottom: "6px" 
  },
  productionHouseMainContent:{
     display: "flex", 
     flexDirection: "column" 
  }



};
