import React from 'react';
import {
    // Customizable Area Start
    Box,
    Typography,
    styled,
    Grid    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import Modal from '@material-ui/core/Modal';
import BookStudioSuccessModelController, { Props } from './BookStudioSuccessModelController';
import { CustomButton } from '../../email-account-login/src/CustomButton';
const images = require("./assets");

const StyledModal = styled(Modal)({
    position: 'fixed',
    zIndex: 1300,
    inset: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const style = {
    width: 375,
    borderRadius: '20px',
    backgroundColor: 'white',
    boxShadow: 'none',
    height: 300,
};

const CustomButtonBox = styled(CustomButton)({
    '& .MuiButton-label': {
        fontWeight: 600,
        fontSize: 12,
        letterSpacing: '0.34285715px',
        lineHeight: '14px',
    }
});

// Customizable Area End
export default class BookStudioSuccessModel extends BookStudioSuccessModelController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <StyledModal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={this.props.open}
                onClose={this.props.handleClose}
            >
                <Box sx={style}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box style={{ padding: "24px 16px 21px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Box style={{ width: 64, height: 64, background: "rgba(245,196,44,0.12)", borderRadius: "16px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <img style={{ width: "36px", height: "36px" }} src={images.RequestSendIcon} />
                            </Box>
                            <Typography style={{ marginTop: "14px", color: "#17181d", fontFamily: "Urbanist", fontSize: "20px", fontWeight: 700, lineHeight: "24px", letterSpacing: "0.2px", textAlign: "center" }}>request sent successfully</Typography>
                            <Typography style={{ width: "286px", marginTop: "11px", color: "#6d89af", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 500, lineHeight: "18px", letterSpacing: "0.21538462px", textAlign: "center" }}>Booking request for <span style={{ color: "#f5c42c", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "200px", display: "inline-block", verticalAlign: "bottom" }}>{this.props.studioName}</span> has been send successfully</Typography>
                        </Box>
                    </Grid>
                    <Grid container style={{ padding: "16px" }}>
                        <CustomButtonBox data-test-id="sign_in_button" style={{ borderRadius: "14px", height: 48, background: "#17181d", width: "343px", color: "#ffffff" } as React.CSSProperties} onClick={this.handleViewStatus}>
                            View Status
                        </CustomButtonBox>
                    </Grid>
                </Box>
            </StyledModal>
        )
        // Customizable Area End
    }
}