Object.defineProperty(exports, "__esModule", {
    value: true
  });

//APi Methods
exports.httpGetMethod = "GET";
exports.httpPostMethod =  "POST";

exports.profileValidationSettingsAPiEndPoint = "profile/validations";
exports.passwordRecoveryStartOtpAPiEndPoint = "forgot_password/otp"
exports.passwordRecoveryConfirmOtpAPiEndPoint = "otp_confirmation"
exports.passwordRecoveryChangePasswordAPiEndPoint = "forgot_password/password"
exports.forgotPasswordAPiContentType = "application/json";
exports.pleaseEnterAValidEmail = "Please enter a valid email";
exports.emailIsRequired = "Email is required";
exports.phoneNumberIsNotValid = "Phone number is not valid";
exports.phoneNumberIsRequired = "Phone number is required";
exports.otpCodeIsRequired = "Phone number is required";
exports.pleaseEnterAPassword = "Please enter a password";
exports.passwordMustBeAtLeast2Characters = "Password must be at least 2 characters";
exports.pleaseConfirmYourPassword = "Please confirm your password";
exports.passwordsMustMatch = "Passwords must match";
exports.invalidEmailAddress = "Invalid email address";
exports.invalidPassword = "Invalid password";
exports.goToOtpAfterPhoneValidationErrorTitle = "Error";
exports.goToOtpAfterPhoneValidationErrorBody = "Please select country code";

exports.labelTextIsAccountRecovery = "Account Recovery";
exports.secondLabelText = "Please choose what type of account you signed up with."
exports.thirdLabelText = "To Reset your password, please enter the email associated with your account.";
exports.forthLabelText = "We sent a confirmation code to the following email:";
exports.fifthLabelText = "To Reset your password, please enter the phone number associated with your account."
exports.sixthLabelText = "We sent a confirmation code to the following phone:"

exports.firstInputAutoCompleteType = "email";
exports.firstInputPlaceholder = "Email";
exports.firstInputKeyboardStyle = "email-address";
exports.firstInputErrorColor = "red";

exports.buttonTextIsNext = "Next";
exports.buttonColorForNextButton = "#6200EE";

exports.secondInputAutoCompleteType = "tel";
exports.secondInputKeyboardType= "phone-pad"
exports.secondInputPlaceholder = "Mobile"
exports.secondInputErrorColor = "red";

exports.thirdInputPlaceholder = "Enter OTP";
exports.thirdInputErrorColor = "red";

exports.buttonTitleIsSMSPhoneAccount = "SMS (Phone) Account";
exports.buttonTitleIsEmailAccount = "Email Account";

exports.labelTextIsPleaseEnterYourNewPassword = "Please enter your new password.";
exports.labelTextIsYourPasswordHasBeenSuccessfullyChanged = "Your password has been successfully changed";

exports.handled = "handled";

exports.placeholderIsReTypePassword = "Re-Type Password";

exports.buttonTitleIsOk = "Ok"
exports.buttonColorForOkButton = "#6200EE"; 

exports.placeholderIsPassword = "password";
exports.countryCodeSelectorPlaceholder = "Select Country";
// Customizable Area Start
exports.backButtonText = "Back to Login";
exports.recoverPasswordText = "Recover Password!";
exports.recoverPasswordHelperText = "Enter your email ID and verify OTP to recover your password.";
exports.firstInputMobileCompleteType = "phone";
exports.recoverPasswordHelperTextForMobile = "Enter your phone number and verify OTP to recover your password.";
exports.emailInputLabel = "Email ID";
exports.emailIsRequired = "Email is required";
exports.mobileInputLabel = "Phone Number";
exports.mobileIsRequired = "Phone Number is required";
exports.getOtp = "Send OTP";
exports.verifyText = "Verify OTP";
exports.verifyMessage = "We've sent the 4 digit verification code to ";
exports.emailId = "admin123@yopmail.com";
exports.verifyButtonText = "Verify OTP";

exports.recieveCodeConfirmation = "If you're receive any code?";
exports.recieveCodeConfirmation = "Didn't receive code?";
exports.verifyLink = "Request Again";
exports.newPasswordText = "Reset your password.";
exports.newTextPassword = "New Password";
exports.confirmPasswordText = "Confirm your new password";
exports.confirmButton = "Update Password ";
//TWD
exports.passwordChangeSuccessfullMsg = "Password changed successfully";
exports.hintPass = "Password should be of minimum 8 characters and should include 1 alphabet, 1 uppercase letter,1 numeric and 1 special charater."
exports.hintValid = "*User can enter 8 characters,1 uppercase letter, 1 number, 1 special characters combinations of them in this field";
exports.newText = "Set a new password for you to login into your account.";
//TWD
exports.passwordSuccessMsgHelper = "Your new password has been changed successfully";
//TWD
exports.passwordChangeMsg = "Please login your account with a new password";
exports.popupButton = "Back to Login";
exports.getOtpEndPoint = "account_block/accounts/confirm_otp";
exports.forgotEndPoint = "account_block/accounts/forgot_password";
exports.newPasswordUpdateApiEndPoint = "account_block/accounts/reset_password";
exports.typeAccount = "mobile";
exports.httpPutMethod = "PUT";
exports.phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
exports.upperCaseReg = /[A-Z]/
exports.regPass=/^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/
exports.oneNumReg = /\d/
exports.specialCharReg=/[@$!%*?&]/
// Customizable Area End