import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { phoneValidate, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  backButtonVisible?:boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showOtpScreen: boolean;
  showRecoveryScrenn: boolean;
  screenVisibility: string;
  openPopup: boolean;
  recoveryEmail: string;
  countryCodeSelected: string;
  phoneNumber: string;
  phoneNumberError: { invalid: boolean, require: boolean };
  placeHolderCountryCode: string;
  loginTab: number
  fpNotification: { type: string, open: boolean, message: string };
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  requestEmailOtpCallId: any;
  requestMobileOtpCallId: any;
  otpToken: any;
  isChangePassword: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    //email schema

    this.state = {
      showRecoveryScrenn: true,
      showOtpScreen: false,
      screenVisibility: "Recovery Screen",
      openPopup: false,
      recoveryEmail: "",
      countryCodeSelected: "",
      phoneNumber: "",
      phoneNumberError: { invalid: false, require: false },
      placeHolderCountryCode: "Select Country",
      loginTab: 0,
      fpNotification: { type: "", open: false, message: "" },
    };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  async receive(from: string, message: Message) {
    const countryCode = message.getData(
      getName(MessageEnum.LoginCountryCode)
    );
    if (countryCode) {
      this.setState({
        placeHolderCountryCode: "+" + countryCode,
        countryCodeSelected: countryCode,
      });
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (this.requestEmailOtpCallId === apiRequestCallId) {
        if (responseJson) {
          if (responseJson.errors) {
            this.setState({ fpNotification: { open: true, type: "error", message: responseJson.errors[0].email } });
          } else {
            setStorageData("token", responseJson.meta.token)
            this.props.navigation.navigate("OtpScreen")
          }
        }
      } 
      if (this.requestMobileOtpCallId === apiRequestCallId) {
        if (responseJson) {
          if (responseJson.errors) {
            this.setState({ fpNotification: { open: true, type: "error", message: responseJson.errors[0].phone } });
          } else {
            setStorageData("token", responseJson.meta.token)
            this.props.navigation.navigate("OtpScreen")
          }
        }
      } 
    } else if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      this.getCountryCodeResponseHandled(message);
    }
  }

  getCountryCodeResponseHandled(message: Message) {
    let selectedCode = message.getData(
      getName(MessageEnum.CountyCodeDataMessage)
    );

    if (selectedCode !== undefined) {
      this.setState({
        countryCodeSelected:
          selectedCode.indexOf("+") > 0
            ? selectedCode.split("+")[1]
            : selectedCode
      }, () => {
        if (this.state.countryCodeSelected === "91" && this.state.phoneNumber.length && this.state.phoneNumber.length !== 10) {
          this.setState({ phoneNumberError: { invalid: true, require: false } })
        }
      });
    }
  }


  goToOtpAfterEmailValidation(email: string) {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.requestEmailOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    let attr = {
      email: email
    }

    const data = {
      verify_phone_or_email: configJSON.firstInputAutoCompleteType,
      attributes: attr,
    };

    const httpBody = {
      data: data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToOtpAfterMobileValidation(mobile: string) {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.requestMobileOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    let attr = {
      full_phone_number: mobile
    }

    const data = {
      verify_phone_or_email: configJSON.firstInputMobileCompleteType,
      attributes: attr,
    };

    const httpBody = {
      data: data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleRecoveryEmailInput = (e: any) => {
    this.setState({
      recoveryEmail: e.target.value
    })
  }

  handleFPCloseNotification = () => {
    this.setState({ fpNotification: { type: "", open: false, message: "" } });
  }

  handleRecoveryEmailSubmit = () => {
    if (!this.state.recoveryEmail) {
      this.setState({ fpNotification: { open: true, type: "warning", message: "Please enter your email ID" } });
      return false;
    }
    this.goToOtpAfterEmailValidation(this.state.recoveryEmail)
    setStorageData("recoveremail", this.state.recoveryEmail);
  }

  handleRecoveryMobileSubmit = () => {
    if (!this.state.phoneNumber) {
      this.setState({ fpNotification: { open: true, type: "warning", message: "Please enter your phone number" } });
      return false;
    }
    this.goToOtpAfterMobileValidation(`${this.state.countryCodeSelected}${this.state.phoneNumber}`)
    setStorageData("recovermobile", `${this.state.countryCodeSelected}${this.state.phoneNumber}`);
  }

  handleNewPasswordSubmit = () => {
    this.handlePopUpVisibility(true)
  }

  handlePopUpVisibility = (val: boolean) => {
    this.setState({
      openPopup: val
    });
  }

  handlePhoneNumberInput = (event: any) => {
    const phone = event.currentTarget?.value;
    this.setState({ phoneNumber: phone });
    if (!phone) {
      this.setState({ phoneNumberError: { invalid: false, require: true } });
      return false;
    }
  }

  handleInputFocus = (labelName: string) => {
    const nameLabel = document.getElementById(labelName);
    if (nameLabel) {
      nameLabel.style.color = "#F5C42C";
    }
  };

  handleInputBlur = (labelName: string) => {
    const nameLabel = document.getElementById(labelName);
    if (nameLabel) {
      nameLabel.style.color = "#6D89AF";
    }
  };
  // Customizable Area End
}
