import React from "react";

import {
  // Customizable Area Start
  Container,
  Box,
  Typography,
  Grid,
  TypographyProps,
  Link,
  AppBar,
  Tabs,
  Tab,
  Select,
  MenuItem,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import FormControl from '@material-ui/core/FormControl';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SideBar from "../../../components/src/SideBar";
import Header from "../../../components/src/Header.web";
import "./forecastingandbudgeting.css";
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import ManageBalanceSheetController, { ICategory, ISubCategory, Props } from "./ManageBalanceSheetController";
import BudgetSheetModel from "../../../components/src/BudgetSheetModel";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import BudgetSheetAddCategoryModel from "../../../components/src/BudgetSheetAddCategoryModel";
import CrewCountView from "./CrewCountView.web";
import SummaryCreate from "./SummaryCreate.web";
import Footer from "../../../components/src/Footer";
import Loader from "../../../components/src/Loader.web";
import BudgetSheetUploadCSVModel from "../../../components/src/BudgetSheetUploadCSVModel";
import UploadCSVErrorMessagesModel from "./UploadCSVErrorMessagesModel.web";
import CustomNotification from "../../../components/src/CustomNotification";
const images = require("./assets");

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

export default class ManageBalanceSheet extends ManageBalanceSheetController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderUpdateBudgetSheetContent() {
    return <>
      <Grid container justifyContent="space-between" style={{ alignItems: "center", margin: "15px 0" }}>
        <Grid item style={webStyle.departmentHeading} >
          {this.state.selectedDepartment?.name || ""} <Box style={{ alignItems: "center", marginLeft: 13, display: 'flex', justifyContent: 'center', width: 24, height: 24, backgroundColor: "rgba(245,196,44,0.12)", borderRadius: "16px" } as React.CSSProperties} data-test-id="updateDepartment" onClick={this.handleUpdateDepartment}> <EditOutlinedIcon style={{ color: "#f5c42c", width: "13.5px", height: "13.5px" } as React.CSSProperties} /> </Box>
        </Grid>
        <Grid item style={{ display: 'flex' }} >
          <Box>
            <FormControl className="departmentDropdown">
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={this.state.selectedDepartment ? this.state.selectedDepartment.id : ""}
                data-test-id="selectDepartment"
                onChange={this.handleSelectDepartment}
              >
                {this.state.allProjectDepartments.map((department: { id: number, name: string }, index: number) => {
                  return <MenuItem key={`${index}_department_name`} value={department.id}>{department.name}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Box>
          <Box data-test-id="exportCSV" onClick={this.handleExportCSV} style={webStyle.uploadIconButton}> Export CSV</Box>
          <Box onClick={this.handleUploadCSV} style={webStyle.uploadIconButton}> <img style={{ marginRight: 4, width: 12, height: 12 } as React.CSSProperties} src={`${images.uploadIcon}`} /> Upload CSV</Box>
        </Grid>
      </Grid>
      <Box style={webStyle.departmentSheetBox as React.CSSProperties}>
        <Box style={{ width: '100%', overflowX: 'auto' }}>
          <Box>
            <Grid container justifyContent="space-between" style={{ color: '#17181d', fontFamily: 'Urbanist', fontSize: '12px', fontWeight: 700, alignItems: "center", borderBottom: '1px solid #e3eaf4', paddingRight: 20, }}>
              <Grid item> <span style={webStyle.columnName}> Details </span> <span style={{ paddingLeft: 7 }}>Vendor Name</span></Grid>
              <Grid item>Contact No.</Grid>
              <Grid item>No. of people</Grid>
              <Grid item>Per day/shift charges</Grid>
              <Grid item>Day/shift</Grid>
              <Grid item>Conveyance</Grid>
              <Grid item>WIP Cost</Grid>
              <Grid item>Cash Requirement</Grid>
              <Grid item>Actual Cost</Grid>
            </Grid>
            {this.state.categories?.length ? this.renderCategoriesViewContent()
              : <Box style={{ textAlign: 'center', display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                <Box style={{ textAlign: 'center', width: 324, padding: '31px 0' }}>
                  <img src={`${images.microsoftExcelIcon}`} />
                  <Typography style={webStyle.createDeptTitle as React.CSSProperties} >Create a dept. budget</Typography>
                  <Typography> <span style={webStyle.createDeptSubTitle as React.CSSProperties}> Create a sept. budget sheet by using of adding rows and multiple categories as well </span></Typography>
                </Box>
              </Box>}
          </Box>
        </Box>
        <Box style={webStyle.totalAmountText as React.CSSProperties}>
          <Grid container style={{ marginTop: 16, marginBottom: 12 }} justifyContent="center">
            {/* <Grid item style={{ ...webStyle.addBtn, marginRight: 16, border: '1px solid #17181d', background: '#ffffff' }}> <span style={webStyle.addBtnText as React.CSSProperties}>+ Add Row</span></Grid> */}
            <Grid item onClick={this.handleAddCategory} data-test-id="addCategory" style={{ ...webStyle.addBtn, background: '#17181d' }}> <span style={{ ...webStyle.addBtnText, color: '#ffffff' } as React.CSSProperties}>+ Add Category</span></Grid>
          </Grid>
          <Box style={{ border: '1px solid #e3eaf4', width: '95%', marginLeft: '23.5px', marginRight: '23.5px' }}></Box>
          <Typography style={{ marginTop: 12, marginBottom: 14, color: '#18272a', fontFamily: 'Urbanist', fontSize: 14, fontWeight: 500, lineHeight: '16px' }}>Total Amount: <span style={{ fontFamily: 'Helvetica-Bold', fontSize: 16, fontWeight: 400, }}>₹{this.state.totalAmountByDept}</span></Typography>
        </Box>
      </Box>
    </>
  }

  renderCategoriesViewContent() {
    return <Box style={{ width: '100%', marginTop: 13 }}>
      <Grid style={{ minHeight: '248px', marginBottom: 12 }}>
        {this.state.categories.map((category: ICategory, categoryIndex: number) => {
          return <React.Fragment key={categoryIndex + "categorieview"}>
            <Grid container justifyContent="space-between" style={{ alignItems: "center" }}>
              <Grid item>
                <Typography key={`${category.id}_category_title`} style={webStyle.categoryNameTitle}>{category.attributes.name} <span style={{ color: '#3389ff', textDecoration: 'underline', marginLeft: 15, marginRight: 2, cursor: 'pointer' }} data-test-id={`editCategory_${category.id}`} onClick={() => this.handleEditCategory(category.id, category.attributes.name)} >Edit <EditIcon style={{ marginBottom: '-2px', color: "#3389FF", width: 12, height: 12 } as React.CSSProperties} /></span></Typography>
              </Grid>
              <Grid item style={{ display: 'flex' }} >
                <Grid data-test-id={`saveCategoryRows_${category.id}`} onClick={() => this.handleSaveCategoryDetails(categoryIndex)} item style={{ ...webStyle.addBtn, border: '1px solid #17181d', background: '#ffffff', width: '90px', height: '34px', borderRadius: 7, marginRight: 12 } as React.CSSProperties}> <span style={webStyle.addBtnText as React.CSSProperties}>Save</span></Grid>
                <Grid data-test-id={`addCategoryRows_${category.id}`} onClick={() => this.handleAddRow(category.id)} item style={{ ...webStyle.addBtn, border: '1px solid #17181d', background: '#ffffff', width: '90px', height: '34px', borderRadius: 7, marginRight: 17 } as React.CSSProperties}> <span style={webStyle.addBtnText as React.CSSProperties}>+ Add Row</span></Grid>
              </Grid>
            </Grid>
            <Box key={`${category.id}_category_details`} style={{ marginBottom: 28 }}>
              {category.attributes.sub_categories.data.map((subCategory: ISubCategory, index: number) => {
                return (<React.Fragment key={index + "sub_categories"}>
                  <Box key={`${subCategory.id}_sub_category_details`} className={this.getClassForSubCategoryMainBox(index)} style={{ alignItems: 'center', paddingLeft: '4px', display: 'flex', justifyContent: 'space-between', paddingRight: 17 }}>
                    <Box className={this.getClassForSubCategoryBox(index, category.attributes.sub_categories.data.length)} style={webStyle.categoryLeftBox as React.CSSProperties}>
                      <img data-test-id={`deleteSubCategory_${subCategory.id}`} onClick={() => this.handleDeleteSubCategory(subCategory.id)} style={{ position: 'relative', marginLeft: '-9px' }} src={`${images.minusIcon}`} />
                      <input className="addSubCategoryInputClass" data-test-id={`addSubCategory_${subCategory.id}`} defaultValue={subCategory.attributes.name} disabled={subCategory.attributes.name ? true : false} onBlur={(event) => this.handleSubcategorySave(event, category.id, subCategory.attributes.name)} type="text" size={9} style={webStyle.categoryLeftBoxText as React.CSSProperties} />
                    </Box>
                    <Box key="vendorname" className="categoryInput">
                      <input data-test-id={`vendorname_${subCategory.id}`} onChange={(event) => this.handleChangeBudgetDetails(event, categoryIndex, index, "vendor_name")} defaultValue={subCategory.attributes.budget_details.data?.attributes.vendor_name || ""} key={`${this.state.refreshToken}_vendorname`} type="text" size={9} />
                    </Box>
                    <Box key="contact" className="categoryInput">
                      <input data-test-id={`contact_${subCategory.id}`} onChange={(event) => this.handleChangeBudgetDetails(event, categoryIndex, index, "contact_number")} defaultValue={subCategory.attributes.budget_details.data?.attributes.contact_number || ""} key={`${this.state.refreshToken}_contact`} type="text" size={9} />
                    </Box>
                    <Box key="peoplecount" className="categoryInput">
                      <input data-test-id={`peoplecount_${subCategory.id}`} onChange={(event) => this.handleChangeBudgetDetails(event, categoryIndex, index, "no_of_pepole")} defaultValue={subCategory.attributes.budget_details.data?.attributes.no_of_pepole || ""} key={`${this.state.refreshToken}_peoplecount`} type="text" size={9} />
                    </Box>
                    <Box key="charges" className="categoryInput">
                      <input data-test-id={`charges_${subCategory.id}`} onChange={(event) => this.handleChangeBudgetDetails(event, categoryIndex, index, "per_day_or_shift_charge")} defaultValue={subCategory.attributes.budget_details.data?.attributes.per_day_or_shift_charge || ""} key={`${this.state.refreshToken}_charges`} type="text" size={9} />
                    </Box>
                    <Box key="shift" className="categoryInput">
                      <input data-test-id={`shift_${subCategory.id}`} onChange={(event) => this.handleChangeBudgetDetails(event, categoryIndex, index, "days_or_shift")} defaultValue={subCategory.attributes.budget_details.data?.attributes.days_or_shift || ""} key={`${this.state.refreshToken}_shift`} type="text" size={9} />
                    </Box>
                    <Box key="conveyance" className="categoryInput">
                      <input data-test-id={`conveyance_${subCategory.id}`} onChange={(event) => this.handleChangeBudgetDetails(event, categoryIndex, index, "conveyance")} defaultValue={subCategory.attributes.budget_details.data?.attributes.conveyance || ""} key={`${this.state.refreshToken}_conveyance`} type="text" size={9} />
                    </Box>
                    <Box key="wipcost" className="categoryInput">
                      <input value={subCategory.attributes.budget_details.data?.attributes.wip_cost || ""} disabled key="wipcost" type="text" size={9} />
                    </Box>
                    <Box key="cashrequirement" className="categoryInput">
                      <input data-test-id={`cashrequirement_${subCategory.id}`} onChange={(event) => this.handleChangeBudgetDetails(event, categoryIndex, index, "cash_remont")} defaultValue={subCategory.attributes.budget_details.data?.attributes.cash_remont || ""} key={`${this.state.refreshToken}_cashrequirement`} type="text" size={9} />
                    </Box>
                    <Box key="actualcost" className="categoryInput">
                      <input value={subCategory.attributes.budget_details.data?.attributes.actual_cost || ""} disabled key="actualcost" type="text" size={9} />
                    </Box>
                  </Box>
                  {this.handleIsShowLine(index, category.attributes.sub_categories.data.length) ? <Box style={{ border: '1px solid #e3eaf4', width: '98%', margin: '19 8 13 8' }}></Box> : null}
                </React.Fragment>)
              })}
            </Box>
          </React.Fragment>
        })}
      </Grid>
    </Box>
  }

  renderManageBalanceSheetContent(sideBarStatus: boolean = this.state.isSideBarOpen) {
    return <>
      <Loader loading={this.state.isLoading}></Loader>
      <Grid style={webStyle.contentSetup}>
        < Header
          sideBarStatus={sideBarStatus}
          dropDownStatus={this.state.isDropdownOpen}
          handleSideBar={this.handleSideBar}
          handleDropdown={this.handleDropdown}
          navigate={this.props.navigation.navigate}
        />
        <Grid style={webStyle.mainContent as React.CSSProperties}>
          <Box sx={webStyle.blockInfo}>
            <Box>
              <Typography variant="h3" style={webStyle.currentBlockTitle as TypographyProps}>{this.state.projectName}</Typography>
              <Typography variant="h6" style={webStyle.blockPath as TypographyProps}>
                <span style={webStyle.highlightedText}>
                  <Link  color="inherit" data-test-id="homeLink" onClick={
                    this.state.userAccountType === "production_house_account" ?
                    () => this.handleBalanceSheetNavigation("Projects") :  () => this.handleBalanceSheetNavigation("JobProfile")
                    }>
                    Home /
                  </Link>
                  <Link color="inherit" data-test-id="balanceSheetsLink" onClick={
                     this.state.userAccountType === "production_house_account" ? 
                    () => this.handleBalanceSheetNavigation("BalanceSheet") :  () => this.handleBalanceSheetNavigation("Forecastingandbudgeting")
                    }>
                    {" "}Balance Sheets
                  </Link>
                </span>
                {" "}/ {this.state.projectName}
              </Typography>
            </Box>
            {this.state.tabValue === 0 && <Box data-test-id="addDepartmentBtn" onClick={this.handleAddDepartment} sx={webStyle.addDepartmentText}>
              <AddIcon style={{ color: "#f5c42c", width: 12, height: 12 } as React.CSSProperties} /> Add Department
            </Box>}
          </Box>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <AppBar position="static" className="appbar app_bar" color="transparent">
              <Tabs
                data-test-id="tabChange"
                value={this.state.tabValue}
                onChange={this.handleAppBarValue}
                variant="scrollable"
                scrollButtons="on"
                TabIndicatorProps={{ style: { background: "orange", height: '2px' } as React.CSSProperties }}
                aria-label="scrollable prevent tabs example"
                className="tabs_main"
              >
                <Tab className={this.state.tabValue == 0 ? "active_btn" : "non_active_btn"} label="Budget" />
                <Tab className={this.state.tabValue == 1 ? "active_btn" : "non_active_btn"} label="Crew Count" />
                <Tab className={this.state.tabValue == 2 ? "active_btn" : "non_active_btn"} label="Summary" />
              </Tabs>
            </AppBar>
          </Grid>
          {this.state.tabValue === 0 ?
            this.renderUpdateBudgetSheetContent()
            : null}
          {this.state.tabValue === 1 ?
            <>
              <CrewCountView id={""} navigation={this.props.navigation} />
            </> : null}
          {this.state.tabValue === 2 ?
            <>
              <SummaryCreate id={""} navigation={this.props.navigation} />
            </> : null}
        </Grid >
      </Grid >
      <Footer />
    </>
  }
  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container style={webStyle.parentContainerDiv}>
          {this.state.isSideBarOpen ?
            <Grid spacing={0} container>
              <Grid style={webStyle.sidebarParent} item xs={3}>
                <SideBar
                  navigate={this.props.navigation.navigate}
                  userData={{ age: 25 }}
                  activeMenuItem={"Balance Sheets"}
                  handleSideBar={this.handleSideBar} />
              </Grid>
              <Grid item style={{ display: "flex", flexDirection: "column", flexBasis: "77.75%", maxWidth: "77.75%" }} xs={9}>{this.renderManageBalanceSheetContent()}</Grid>
            </Grid>
            :
            <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>{this.renderManageBalanceSheetContent()}</Grid>
          }
          {this.state.isModelOpen && this.state.modelType === "department" ? <BudgetSheetModel open={this.state.isModelOpen} handleUpdateDepartment={this.handleUpdateDepartmentAPICall} handleClose={this.handleModelClose} editDepartmentData={this.state.editDepartment} handleAddDepartment={this.handleAddDepartmentAPICall} /> : null}
          {this.state.isModelOpen && this.state.modelType === "category" ? <BudgetSheetAddCategoryModel open={this.state.isModelOpen} editCategoryData={null} handleClose={this.handleModelClose} handleAddCategory={this.handleAddCategoryAPICall} /> : null}
          {this.state.isModelOpen && this.state.modelType === "category" && this.state.editCategory ? <BudgetSheetAddCategoryModel open={this.state.isModelOpen} editCategoryData={this.state.editCategory} handleClose={this.handleModelClose} handleUpdateCategory={this.handleUpdateCategoryAPICall} /> : null}
          {this.state.isModelOpen && this.state.modelType === "uploadcsv" ? <BudgetSheetUploadCSVModel open={this.state.isModelOpen} handleClose={this.handleModelClose} handleFileUpload={this.handleFileUpload} /> : null}
          {this.state.isModelOpen && this.state.modelType === "uploadcsvError" ? <UploadCSVErrorMessagesModel id="" navigation="" open={this.state.isModelOpen} handleClose={this.handleModelClose} errors={this.state.uploadCsvErrors} /> : null}
        </Container>
        <CustomNotification navigation={(route: string) => this.props.navigation.navigate(route)} notification={this.state.manageBalanceSheetNotification} handleCloseNotification={this.handleManageBalanceSheetCloseNotification} />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  categoryLeftBoxText: {
    color: '#17181d',
    fontFamily: 'Urbanist',
    fontSize: 12,
    fontWeight: 700,
    width: 107,
    height: 31,
    borderRadius: 7,
    border: '1px solid #e3eaf4',
    background: '#ffffff',
    marginLeft: '-6px',
    lineHeight: '31px',
    padding: '0 12px',
    boxSizing: 'border-box'
  },
  categoryDataBox: {
    width: '109px',
    height: '31px',
    borderRadius: '7px',
    border: '1px solid #f5c42c',
    background: '#ffffff',
  },
  categoryLeftBox: {
    width: 107,
    background: '#e1e6ec',
    height: 31,
    display: 'flex',
    alignItems: 'center',
    padding: '2px 4px',
    position: 'relative',
  },
  categoryNameTitle: {
    height: 14,
    color: '#6d89af',
    fontFamily: 'Urbanist',
    fontSize: 12,
    fontWeight: 600,
    margin: '12px 0 12px 12px',
    display: 'flex',
    alignItems: 'center',
  },
  totalAmountText: {
    height: 109,
    width: '100%',
    borderTop: '1px solid #e3eaf4',
    boxShadow: '0px -4px 9px rgba(23,24,29,0.05)',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  addBtnText: {
    height: 14,
    color: '#17181d',
    fontFamily: 'Urbanist',
    fontSize: 12,
    fontWeight: 600,
    textAlign: 'center',
    letterSpacing: '0.34285715px',
  },
  addBtn: {
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    width: '154px',
    height: '38px',
    borderRadius: '10px',
    cursor: 'pointer',
  },
  createDeptTitle: {
    height: 24,
    color: '#17181d',
    fontFamily: 'Urbanist',
    fontSize: 20,
    fontWeight: 700,
    letterSpacing: '0.22222222px',
    marginTop: 20,
  },
  createDeptSubTitle: {
    height: 34,
    color: '#6d89af',
    fontFamily: 'Urbanist',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '17px',
    letterSpacing: '0.13333334px',
    width: 254,
    display: 'inline-block',
    marginTop: 5,
  },
  columnName: {
    background: '#e1e6ec',
    borderRadius: '7px',
    padding: 11.5,
    margin: '2px 0 0 2px',
    width: 92,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    display: "inline-block",
  },
  departmentSheetBox: {
    backgroundColor: "#ffffff",
    borderRadius: "12px",
    width: '100%',
    border: '1px solid #e3eaf4',
    display: 'flex',
    flexDirection: 'column',
  },
  departmentHeading: {
    color: "#17181d",
    fontFamily: "Urbanist",
    fontSize: 16,
    fontWeight: 700,
    lineHeight: "24px",
    letterSpacing: "0.24615385px",
    display: "flex",
  },
  uploadIconButton: {
    color: "#17181d",
    marginLeft: "10px",
    fontFamily: "Urbanist",
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "21px",
    letterSpacing: "0.21538462px",
    padding: "10.5px 23.7px",
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    border: "1px solid #17181d",
    cursor: 'pointer',
  },
  saveButton: {
    color: "#17181d",
    marginLeft: "10px",
    fontFamily: "Urbanist",
    fontSize: 10,
    fontWeight: 500,
    padding: "7.5px 10.7px",
    backgroundColor: "#FFFFFF",
    borderRadius: "7px",
    border: "1px solid #17181d",
  },
  parentContainerDiv: {
    padding: "0px",
    maxWidth: "none",
  },
  sidebarParent: {
    minHeight: "100vh",
    maxWidth: "22.25%"
  },
  contentSetup: {
    borderLeft: "1px solid #E3EAF4",
    height: '100%',
    flex: 1,
  },
  mainContent: {
    width: "100%",
    minHeight: "100vh",
    backgroundColor: "#F8FAFE",
    boxSizing: "border-box",
    padding: "24px"
  },
  blockInfo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap"
  },
  highlightedText: {
    color: "#F5C42C",
    fontFamily: 'Urbanist',
    cursor: 'pointer',
    fontSize: "14px",
    fontWeight: 600,
    textDecoration: 'underline',
  },
  blockPath: {
    fontSize: "14px",
    letterSpacing: "1px",
    color: "#6D89AF",
    fontWeight: 500,
  },
  addDepartmentText: {
    color: "#f5c42c",
    fontSize: "16px",
    cursor: "pointer",
  },
  currentBlockTitle: {
    fontSize: "28px",
    letterSpacing: "1px",
    fontWeight: 700,
    marginBottom: "8px",
    lineHeight: "34px",
  },
};
// Customizable Area End
