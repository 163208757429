import React from 'react';
import { Box, CardMedia, Grid, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { reviewimg } from "./assets";
import StarOutlinedIcon from '@material-ui/icons/StarOutlined';
import { dummyImage } from '../../blocks/invoicebilling/src/assets';

interface MyProps {
    data: any;
    key: number;

}
const useStyles = makeStyles({
    Review1: {
        //width: '378px',
        border: '2px solid #E1E6EC',
        borderRadius: '14px',
        marginBottom: '10px',
        marginRight: "15px",
        "@media (min-width: 600px) and (max-width: 1440px)": {
            width: "unset"
        },
        '@media(max-width: 600px)': {
            width: '95%',
            margin: '0 auto',
            marginBottom: '10px'
        },
    },

    starView: {
        padding: "0px 8px",
        background: "linear-gradient(225deg, #58A2FF 0%, #1777E2 100%)",
        borderRadius: "12px",
        display: "flex",
        alignItems: "center",
        justifyContent: 'space-evenly',
        height: "22px",
        marginTop: "12px",
        marginRight: '5px'

    },
    starrate: {
        color: "#ffffff",
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "17px",
        paddingLeft: "3px",
    },

    Reviewicon: {
        height: '36px',
        width: '36px',
        marginInline: '10px',
        marginTop: '5px',
        '& .MuiCardMedia-media': {
            borderRadius: "14px"
        }
    },
    reviewbirth: {
        fontSize: '12px',
        paddingTop: '1px',
        lineHeight: '13px',
        color: '#6d89af',
        fontWeight: 500
    },
    reviewname: {
        display: "flex",
        gridColumnGap: "115px",
        '@media(max-width: 600px)': {
            gridColumnGap: "unset",
            justifyContent: 'space-between',
            width: '78%'
        },
    },
    namereview: {
        fontWeight: 600,
        paddingTop: '8px',
        fontSize: '16px',
        color: '#1718D',
        lineHeight: '19px',
        letterSpacing: "0.2px"
    },
    reviewabout: {
        fontSize: '11px',
        fontWeight: 400,
        paddingTop: '8px',
        paddingBottom: '5px',
        marginInline: '10px',
        lineHeight: '16px',
        color: '17181D',
        //width:'300px'

    },
    ReviewInformation:
    {
        display: "flex",
        justifyContent: 'space-between'
    }
    ,
    reviewnameandstar: {
        display: "flex",
        flexDirection: "column",


    }


});

export default function VendorReviewerComponent(props: MyProps) {
    const classes = useStyles();
    const { data, key } = props;

    return (
        <div>
            <Grid className={classes.Review1} >
                <Grid className={classes.ReviewInformation}>
                    <Grid style={{ display: 'flex', }}>
                        <Grid className={classes.Reviewicon}>
                            <CardMedia
                                component="img"
                                alt="Image"
                                // Use data from the corresponding review object
                                image={data?.attributes?.account?.image || dummyImage} style={{ height: "36px", width: '36px' } as React.CSSProperties}
                            />
                        </Grid>
                        <Grid className={classes.reviewname}>
                            <Grid className={classes.reviewnameandstar}>
                                <Grid className={classes.namereview}>
                                    {/* Use review data */}{data?.attributes?.account?.full_name}
                                </Grid>
                                <Grid className={classes.reviewbirth}>
                                    {data?.attributes?.created_at}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={classes.starView}>
                        <StarOutlinedIcon className="start_color" />
                        <span className={classes.starrate}>{data?.attributes?.given_star}</span>
                    </Grid>
                </Grid>
                <Tooltip title={data?.attributes?.comment} arrow>
                    <Grid className={classes.reviewabout} style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                        {data?.attributes?.comment}
                    </Grid>
                </Tooltip>
            </Grid>
        </div>
    );
};


