import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { setStorageData, getStorageData } from "../../../framework/src/Utilities";
import { ProfileDummy } from "../../../components/src/assets";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  passwordLogin: string;
  email: string;
  emailError: boolean;
  emailErrorMessage: string;
  passwordError: boolean;
  passwordErrorMessage: string;
  visibiltyOfPassword: boolean;
  token: string;
  focus: boolean;
  emailLoginNotification: { type: string, open: boolean, message: string, route?: string };
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  notificationcallid: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.AlertMessage),
      getName(MessageEnum.AlertBodyMessage)
    ];

    this.state = {
      passwordLogin: "",
      emailError: false,
      emailErrorMessage: "",
      passwordError: false,
      passwordErrorMessage: "",
      email: "",
      visibiltyOfPassword: false,
      token: "",
      focus: false,
      emailLoginNotification: { type: "", open: false, message: "" },
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleClickShowPassword = () => {
    this.state.passwordLogin &&
      this.setState({
        visibiltyOfPassword: !this.state.visibiltyOfPassword
      });
  };

  setEmail = (e: any) => {
    const email = e?.currentTarget?.value;
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!email) {
      this.setState({ emailError: true, emailErrorMessage: "Email is required" });
    } else if (!regex.test(email)) {
      this.setState({ emailError: true, emailErrorMessage: "Invalid email" });
    } else {
      this.setState({ emailError: false, emailErrorMessage: "" });
    }

    this.setState({ email });
  };

  handleInputFocus = (labelName: string) => {
    const nameLabel = document.getElementById(labelName);
    if (nameLabel) {
      nameLabel.style.color = "#F5C42C";
    }
  };
  handleInputBlur = (labelName: string) => {
    const nameLabel = document.getElementById(labelName);
    if (nameLabel) {
      nameLabel.style.color = "#6D89AF";
    }
  };

  setpasswordOfEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const passwordRegex = configJSON.regPass;

    if (!value) {
      this.setState({
        passwordLogin: value,
        passwordError: true,
        passwordErrorMessage: configJSON.passErrMsg
      });
    } else if (!value.match(passwordRegex)) {
      let errorMessage = "Password must be at least 6 characters and include:";

      if (!value.match(configJSON.upperCaseReg)) {
        errorMessage += " one uppercase letter,";
      }
      if (!value.match(configJSON.oneNumReg)) {
        errorMessage += " one number,";
      }
      if (!value.match(configJSON.specialCharReg)) {
        errorMessage += " one special character.";
      }

      this.setState({
        passwordLogin: value,
        passwordError: true,
        passwordErrorMessage: errorMessage
      });
    } else {
      this.setState({
        passwordLogin: value,
        passwordError: false,
        passwordErrorMessage: ''
      });
    }
  };


  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userName = message.getData(getName(MessageEnum.LoginUserName));

      const password = message.getData(getName(MessageEnum.LoginPassword));

      const countryCode = message.getData(
        getName(MessageEnum.LoginCountryCode)
      );

      //@ts-ignore

    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId != null) {
        if (
          apiRequestCallId === this.validationApiCallId &&
          responseJson !== undefined
        ) {
          var arrayholder = responseJson.data;

          if (arrayholder && arrayholder.length !== 0) {
            let regexData = arrayholder[0];

            if (regexData && regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        }

        if (apiRequestCallId === this.apiEmailLoginCallId) {
          this.handleEmailLoginResponse(responseJson);
        }

      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleEmailLoginResponse = (responseJson: any) => {
    if (responseJson && responseJson.meta) {
      setStorageData("token", responseJson.meta.token);
      setStorageData("full_name", responseJson.data.attributes.full_name);
      setStorageData("last_name", responseJson.data.attributes.last_name);
      setStorageData("production_house_name",responseJson.data.attributes.production_house_name)
      setStorageData("email", responseJson.data.attributes.email);
      setStorageData("userId", responseJson.data.id);
      setStorageData("account_type", responseJson.data.type);
      setStorageData("userid", responseJson.data.attributes.profile.data.id)
      if (responseJson.data.attributes.profile.data.attributes.profile_image === null) {
        setStorageData("image", ProfileDummy);
      }
      else {

        setStorageData("image", responseJson.data.attributes.profile.data.attributes.profile_image);
      }
      if (responseJson.data.type === "admin")
        this.props.navigation.navigate("Dashboard");
      else if (responseJson.data.type === "vendor_account")
        this.props.navigation.navigate("JobProfile");
      else if (responseJson.data.type === "production_house_account")
        this.props.navigation.navigate("Projects");
    } else {
      this.setState({ emailLoginNotification: { open: true, type: "error", message: responseJson.errors[0].failed_login } });
    }
  }

  doEmailLogIn = () => {
    const { email, passwordLogin } = this.state;

    if (!this.state.email) {
      this.setState({ emailError: true, emailErrorMessage: "Email is required" });
    }

    if (!this.state.passwordLogin) {
      this.setState({ passwordError: true, passwordErrorMessage: configJSON.passErrMsg });
      return false;
    }
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email,
      password: passwordLogin,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  Notificationmessage = async () => {


    const authenticationToken = await getStorageData('token', false)
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": authenticationToken
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notificationcallid = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.notificationEndPoint

    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;

  };

  handleEmailLoginCloseNotification = () => {
    this.setState({ emailLoginNotification: { type: "", open: false, message: "" } });
  }
  // Customizable Area End
}
