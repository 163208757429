import React, { ChangeEvent } from "react";
import {
    // Customizable Area Start
    Container,
    Box,
    Typography,
    Grid,
    TypographyProps,
    MenuItem,
    FormControl,
    ListItemText,
    Checkbox,
    CircularProgress
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import Select from "@material-ui/core/Select";
import Modal from '@material-ui/core/Modal';
import Pagination from '@material-ui/lab/Pagination';
import SideBar from "../../../components/src/SideBar";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer";
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import { Filtericon, Shape } from "../../../components/src/assets";
import { arrowicon } from "./assets";
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import InvoiceListController, { Props } from "./InvoiceListController";
import AllStudioCard from "../../../components/src/AllStudioCard";
import AllStudioDetailCard from "../../../components/src/AllStudioDetailCard";
import Loader from "../../../components/src/Loader.web";
import "./fullVisibility.css";
import InvoiceCard from "../../../components/src/InvoiceCard";
import InvoiceSortFunctionality from "../../../components/src/InvoiceSortFunctionality";
import Raiseinvoicefunctionality from "../../../components/src/Raiseinvoicefunctionality";
import SuccessfullmodelRaiseInvoice from "./SuccessfullmodelRaiseInvoice.web";
import Filtermyinvoice from "../../../components/src/Filtermyinvoice";
const theme = createTheme({
    palette: {
        primary: {
            main: "#f5c42c",
            contrastText: "#fff",
        },
    },
});
const options = [
    { id: 1, name: "All Bookings(22)", value: "All" },
    { id: 2, name: "Available Studios(14)", value: "Available" },
    { id: 3, name: "Booked Studios(8)", value: "Booked" },

];
const SortsubDivpTask = styled(Box)({
    display: 'flex',
    gap: "10px",
    alignItems: "end",
    flexDirection: 'column'
});
const SortDivTask = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    position: 'relative',
    alignItems: "center",
    "@media(max-width: 600px)": {
        gridColumnGap: '0px',
        display: 'block'
    },
});
const SearchInputTask = styled('input')({
    fontSize: "15px",
    outline: "none",
    border: "none",
    width: "85%",
    padding: "10px 3px",
    backgroundColor: "#F8FAFE",
    "@media(max-width: 500px)": {
        fontSize: '12px',
        overflow: 'hidden',
        width: "70%",
        padding: "10px 0px",
    },
});
const SortSubDivTask = styled(FormControl)({

    marginRight: "10px",
    '& .MuiSelect-select.MuiSelect-selectMenu': {
        paddingLeft: 10,
        color: "#17181d",
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 600,
        letterSpacing: "0.25454545px",
    },
    "& .MuiInput-underline::after": {
        borderBottom: 'unset'
    },
    "@media(max-width: 500px)": {
        marginRight: "0px",
    },
    "& .MuiInput-underline::before": {
        content: 'none'
    },
    "& .MuiInputLabel-animated.Mui-focused": {
        transform: 'unset',
        color: 'transparent'
    },
    "& .MuiSelect-icon": {
        marginRight: 10
    },
});
const SearchIconTask = styled(SearchOutlinedIcon)({
    padding: "10px 0 10px 13px",
    "@media(max-width: 500px)": {
        padding: "10px 0 10px 8px",
        fontSize: '18px'
    },
});

// Customizable Area End
export default class InvoiceList extends InvoiceListController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    webrenderMainContent1(sideBarStatus: boolean = this.state.isSideBarOpen) {
        return (
            <>
                <Grid style={webStyle.contentSetup}>
                <Loader loading={this.state.isLoading}></Loader>
                    < Header
                        sideBarStatus={sideBarStatus}
                        dropDownStatus={this.state.isDropdownOpen}
                        handleSideBar={this.handleSideBar}
                        handleDropdown={this.handleDropdown}
                        navigate={this.props.navigation.navigate}
                    />
                    <Grid style={webStyle.mainContent1 as React.CSSProperties}>
                        <SortDivTask>
                            <Box sx={webStyle.blockInfo1}>
                                <Typography variant="h3" style={webStyle.activeBlock1 as TypographyProps}>Invoices</Typography>
                                <Typography variant="h6" style={webStyle.blockPath1 as TypographyProps}><span data-test-id="homeLink" onClick={() => this.handleNavigation("JobProile")} style={webStyle.highlightedText} >Home</span> / Invoices</Typography>
                            </Box>
                            <SortsubDivpTask>
                                <Box style={{ display: 'flex' }}>
                                    <SortSubDivTask>
                                        <Box style={{ display: 'flex' }}>
                                            <div>
                                                <Box style={webStyle.bellIconBackground} onClick={this.handleOpen} >
                                                    <img src={Shape} style={webStyle.FilterDiv as React.CSSProperties} />
                                                </Box>
                                                <Modal
                                                    open={this.state.open}
                                                    onClose={this.handleClose} // This handles clicking anywhere outside the modal
                                                    aria-describedby="simple-modal-description"
                                                    aria-labelledby="simple-modal-title"
                                                >
                                                    <div style={{ // You can style your modal here
                                                        top: '50%',
                                                        position: 'absolute',                                              
                                                        left: '50%',
                                                        backgroundColor: '#ffffff',
                                                        borderRadius: '20px',
                                                        transform: 'translate(-50%, -50%)',


                                                    }}>
                                            
                                                        <InvoiceSortFunctionality data={""} ClearDataValues={this.ClearDataValues} allcount={this.state.allcount} handleClose={this.handleClose} handleOptionChange={this.handleOptionChange} selectstatus={this.state.selectstatus} selectstatus1={this.state.selectstatus1} handleOptionChange1={this.handleOptionChange1} approvecount={this.state.countApproved} pendingcount={this.state.countpending} completedcount={this.state.countcomplete} count={this.state.countall} getAppliedinvoiceList={this.handleChange} onhold={this.state.counthold} rejected={this.state.countreject} />
                                                    </div>
                                                </Modal>
                                            </div>

                                            
                                                <Box style={webStyle.bellIconBackground}  onClick={this.handleOpen2} >
                                                    <img src={Filtericon} style={webStyle.FilterDiv as React.CSSProperties} />
                                                </Box>
                                            
                                        </Box>

                                    </SortSubDivTask>
                                    <Box sx={webStyle.headerSearchBoxdropdown}>
                                        <SearchIconTask style={webStyle.searchicon} />
                                        <SearchInputTask placeholder={`Search Invoice`}
                                            style={{
                                                fontSize: "14px", fontWeight: 500,
                                                fontFamily: "Urbanist", letterSpacing: "0.25px"
                                            }}
                                            data-testid='search'  onChange={(event: ChangeEvent<HTMLInputElement>) => this.filterHouses(event)} />


                                    </Box>
                                </Box>
                                <div>
                                <Box
                                    sx={webStyle.submitButton}
                                    onClick={this.handleOpen1} >
                                    + Raise Invoice
                                </Box>
                                            </div>
                            </SortsubDivpTask>
                        </SortDivTask>

                        <Box sx={webStyle.headerSearchBoxInvoiceListing} >
                            {
                               this.state.allinvoiceData.length?( this.state.allinvoiceData?.map((item: any, index: any) => (
                                    <Grid item key={`${item}_allinvoice_card`} xl={3} lg={4} md={6} sm={12} xs={12} spacing={3}>
                                        <Box sx={webStyle.headerSearchParentBox1}>
                                            <Box sx={webStyle.InvoiceNoandDate}>
                                                <Box sx={webStyle.InvoiceNo}>
                                                    Invoice No:{item?.id}
                                                </Box>
                                                <Box sx={webStyle.DateandMonth}>
                                                    {item?.attributes?.invoice_date}
                                                </Box>
                                            </Box>
                                            <InvoiceCard data={item} navigateToinvoiceDetail={this.navigateToinvoiceDetail}/>
                                        </Box>
                                    </Grid>
                                ))
                               )
                               : <Typography style={{ width: '100%', textAlign: 'center', marginTop: '30px', marginBottom: '30px', color: '#6d89af', fontFamily: 'Urbanist', fontSize: 14, fontWeight: 600 }}>No record available</Typography>
                            }
                        </Box>
                        {this.state.count ?
                            (<Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Pagination style={{ justifyContent: "center", marginTop: "6px", display: "flex", marginBottom: "30px" }} data-test-id="pagination" count={Math.ceil((this.state.count) / 9)} page={this.state.currentpage} onChange={this.handlePageChange} />
                                </Grid>
                            </Grid>)
                            : null}

                    </Grid>
                    <Footer />
                </Grid >
            </>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Container style={webStyle.webparentDiv1}>
                    {
                        this.state.isSideBarOpen ?
                            <Grid container spacing={0}>
                                <Grid item xs={3} style={webStyle.websidebarParent1}>
                                    <SideBar
                                        navigate={this.props.navigation.navigate}
                                        activeMenuItem={"Invoices"}
                                        userData={{ age: 25 }}
                                        handleSideBar={this.handleSideBar} />
                                </Grid>
                                <Grid item xs={9} style={{ flexBasis: "77.75%", maxWidth: "77.75%" }}>{this.webrenderMainContent1()}</Grid>
                            </Grid> :
                            <Grid item xs={12}>{this.webrenderMainContent1()}</Grid>
                    }
                </Container>
                <Modal
                                                    open={this.state.open2}
                                                    onClose={this.handleClose} // This handles clicking anywhere outside the modal
                                                    aria-describedby="simple-modal-description"
                                                    aria-labelledby="simple-modal-title"
                                                >
                                                    <div style={{ // You can style your modal here
                                                        top: '50%',
                                                        position: 'absolute',                                              
                                                        left: '50%',
                                                        backgroundColor: '#ffffff',
                                                        borderRadius: '20px',
                                                        transform: 'translate(-50%, -50%)',


                                                    }}>
                                            
                                                      
                                                     <Filtermyinvoice data={""} handleClose={this.handleClose2} raiseinvoicetype={this.state.raiseinvoicetype} amountdatalist={this.state.amountdatalist} 
                                                       departmentlist={this.state.departmentlist} jobroledatalist={this.state.jobroledatalist}
                                                       handleOptionChange1={this.handleOptionChangejobrole} jobrolevalue={this.state.jobrolevalue} handleOptionChangeamount={this.handleOptionChangeamount} handleOptionChangedepartment={this.handleOptionChangedepartment} handleOptionChangestatus={this.handleOptionChangestatus} amountvalue={this.state.amountvalue}
                                                     statusvalue={this.state.statusvalue} departmentvalue={this.state.departmentvalue} getprojectlistfilter={this.state.getprojectlistfilter} projectvalue={this.state.projectvalue} handleOptionChangeproject={this.handleOptionChangeproject} handleChangeapply={this.handleChangeapply} ClearDataValuesfilter={this.ClearDataValuesfilter}/>
                                                    </div>
                                                </Modal>
                <Modal
                                                    open={this.state.open1}
                                                    onClose={this.handleClose} // This handles clicking anywhere outside the modal
                                                    aria-describedby="simple-modal-description"
                                                    aria-labelledby="simple-modal-title"
                                                >
                                                    <div style={{ // You can style your modal here
                                                        top: '50%',
                                                        position: 'absolute',                                              
                                                        left: '50%',
                                                        backgroundColor: '#ffffff',
                                                        borderRadius: '20px',
                                                        transform: 'translate(-50%, -50%)',


                                                    }}>
                                            
                                                       <Raiseinvoicefunctionality data={this.state.allprojectData} handleClose1={this.handleClose1} handleImage={this.handleImage}
                                                        handleChangeinvoice={this.handleChangeinvoice} 
                                                        invoiceselect={this.state.invoiceselect} handleChangeProject={this.handleChangeProject} selectproject={this.state.selectproject}
                                                        handleChangeradio={this.handleChangeradio} selectinvoicetype={this.state.selectinvoicetype} selectinvoicename={this.state.selectinvoicename} 
                                                        handleChangeName={this.handleChangeName} handleChangeDetail={this.handleChangeDetail} selectinvoicedetail={this.state.selectinvoicedetail}
                                                        handleChangeNo={this.handleChangeNo} selectinvoiceno={this.state.selectinvoiceno}
                                                        handleChangeAmount={this.handleChangeAmount} selectinvoiceamount={this.state.selectinvoiceamount} raiseinvoiceData={this.raiseinvoiceData}  image={this.state.image} errors={this.state.errors}/>
                                                    </div>
                                                </Modal>
                                              
                {this.state.isModelOpen &&this.state.modelType === "successModel" ? <SuccessfullmodelRaiseInvoice navigation={this.props.navigation} open={this.state.isModelOpen} handleClose={this.handleModelClose} handlevalue={this.handledatavalue} raiseinvoicedetail={this.state.raiseinvoicedetail} /> : null}
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
submitButton: {
    width: '161px',
    //height: '52px',
    borderRadius: '14px',
    background: '#17181d',
    color: ' #ffffff',
    fontFamily: 'urbanist',
    fontSize: '15px',
    fontWeight: 600,
    letterSpacing: ' 0.25px',
    alignItems: 'center',
    paddingTop: '15px',
    paddingBottom: '15px',
    display: 'flex',
    flexDirection: 'column-reverse',
    //marginLeft: '208px',
    cursor:'pointer',
    marginTop: "16px",
},
    headerSearchBoxdropdown: {
        border: '1px solid #e3eaf4',
        display: "flex",
        borderRadius: "35px",
        marginTop: '32px',
        minWidth: "260px",
        backgroundColor: "#ffffff",
        alignItems: "center",
        height: "44px",
        overflowX: 'hidden',
        "@media screen and (max-width: 500px)": {
            marginRight: "0px",
            width: '160px',
            minWidth: "100px",
        },
    },
    webparentDiv1: {
        padding: "0px",
        maxWidth: "none"
    },
    headerSearchBoxInvoiceListing: {
        display: "flex",
        justifyContent: "start",
        align: "end",
        flexDirection: "row",
        flexWrap: "wrap",
        padding: '12px',
        width:'100%' 
    },
    searchicon: {
        marginRight: '6px',
        fill: '#6D89AF',
        fontSize: "22px"
    },
    contentSetup: {

        borderLeft: "1px solid #E3EAF4",
    },
    websidebarParent: {
        minHeight: "100vh",
    },
    websidebarParent1: {
        minHeight: "100vh",
        maxWidth: "22.25%"
    },

    mainContent1: {
        width: "100%",
        backgroundColor: "#F8FAFE",
        boxSizing: "border-box",
        paddingRight:"24px"


    },
    mainContent: {
        width: "100%",
        backgroundColor: "#F8FAFE",
        boxSizing: "border-box",
        padding: "24px"
    },
    mainWrapper: {
        display: "flex",
        fontFamily: "Roboto-Medium",
        flexDirection: "column",
        align: "center",
        paddingBottom: "30px",
        background: "#fff",
    },
    highlightedText: {
        color: "#F5C42C",
        textDecoration: 'underline',
        textDecorationColor: "#F5C42C",
        fontWeight: 600
    },
    headerSearchBox1: {
        display: "flex",
        justifyContent: "start",
        align: "end",
        flexDirection: "row",
        flexWrap: "wrap",
        paddingTop: '24px',
        paddingLeft: "24px",
        width:"100%"
          
    },

    headerSearchParentBox1: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        padding:'12px'
    },
    InvoiceNo: {
        color: '#6d89af',
        fontFamily: 'Urbanist',
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '19px',
    },
    DateandMonth: {
        color: '#6d89af',
        fontFamily: 'Urbanist',
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '19px',
    },
    InvoiceNoandDate: {
        display: 'flex',
        justifyContent: 'space-between',
        //width: "322px",
        width:'100%',
        paddingBottom:'6px'
    },
    headerSearchParentBox: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '16px'
    },
    inputStyle: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
        width: "100%",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    buttonStyle: {
        width: "100%",
        height: "45px",
        marginTop: "40px",
        border: "none",
        backgroundColor: "rgb(98, 0, 238)",
    },
    blockInfo1: {
        paddingLeft: '24px'
    },
    blockInfo: {

    },
    bellIconBackground: {
        width: "44px",
        height: "44px",
        backgroundColor: "#F8FAFE",
        border: "1px solid #DADADA",
        borderRadius: "22px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        marginLeft: "10px",
        marginTop: "32px"
    },
    FilterDiv: {
        height: "15px",

    },
    activeBlock1: {
        fontSize: "28px",
        lineHeight: "33px",
        letterSpacing: "1px",
        fontWeight: "700",
        flexDirection: "row",
        display: "flex",
        gap: '12px',
        marginBottom: '8px',
        align: 'center',
    },
    blockPath1: {
        letterSpacing: "1px",
        fontSize: "14px",
        fontWeight: 500,
        marginBottom: "5px",
        color: "#6D89AF",
    },
};
// Customizable Area End
