import React from 'react';
import {
    // Customizable Area Start
    Box,
    Typography,
    styled,
    Grid    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import Modal from '@material-ui/core/Modal';
import SuccessfullmodelRaiseInvoiceController, { Props } from './SuccessfullmodelRaiseInvoiceController';
import CloseIcon from '@material-ui/icons/Close';
import { CustomButton } from '../../email-account-login/src/CustomButton';
const images = require("./assets");

const StyledModal = styled(Modal)({
    position: 'fixed',
    zIndex: 1300,
    inset: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const style = {
    width: 375,
    borderRadius: '20px',
    backgroundColor: 'white',
    boxShadow: 'none',
    height: 274,
};

const CustomButtonBox = styled(CustomButton)({
    '& .MuiButton-label': {
        fontWeight: 600,
        fontSize: 12,
        letterSpacing: '0.34285715px',
        lineHeight: '14px',
    }
});

// Customizable Area End
export default class SuccessfullmodelRaiseInvoice extends SuccessfullmodelRaiseInvoiceController {
    constructor(props: Props) {
        super(props);
      
    }

    

    render() {
      
        return (
            <StyledModal
                
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
            >
               
                <Box sx={style}>
                <Box style={{display:'flex',justifyContent:'end',alignItems:'end',paddingTop:'20px',paddingRight:'20px'}}>
                <Grid style={{ fontSize: "20px",
        fontWeight: 700,
        fontFamily: "urbanist",
        color: '#17181d',
        cursor:'pointer'
        }}>
                        <CloseIcon onClick={this.props.handleClose} style={{ color: "#6d89af",cursor:'pointer' }} />
                    </Grid>
                </Box>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box style={{  flexDirection: "column", alignItems: "center", padding: "0px 0px 20px", display: "flex",}}>
                            <Box style={{ width: 64, height: 64, background: "rgba(245,196,44,0.12)", borderRadius: "16px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <img style={{height: "36px" , width: "36px"}} src={images.RequestSendIcon} />
                            </Box>
                            <Typography style={{ marginTop: "14px", color: "#17181d", fontFamily: "Urbanist", fontSize: "20px", fontWeight: 700, lineHeight: "24px", letterSpacing: "0.2px", textAlign: "center" }}>Request Sent Successfully</Typography>
                            <Typography style={{color: "#6d89af", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 500, lineHeight: "18px", letterSpacing: "0.21538462px", textAlign: "center" ,width: "286px", marginTop: "11px" }}>Your request of <span style={{ color: "#f5c42c" }}></span>  invoice has
been send successfully.</Typography>
                        </Box>
                    </Grid>
                    <Grid container style={{ display:'flex',justifyContent:'center' }}>
                        <CustomButtonBox  style={{ borderRadius: "14px", height: 48, background: "#17181d", width: "343px", color: "#ffffff" } as React.CSSProperties} onClick={() => this.props.handlevalue(this.props.raiseinvoicedetail.id)}>
                            View Status
                        </CustomButtonBox>
                    </Grid>
                </Box>
            </StyledModal>
        )
        
    }
}