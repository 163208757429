import React from "react";
import { Box,Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { arrowPH, projectInvoiceIcon} from "./assets";
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined';
import moment from "moment"
interface MyProps {
  data: any;
  navigateToallinvoiceRequest:any
  
}

const useStyles = makeStyles(
 {
        parentDiv: {
            width: "100%",
            height: "175px",
            borderRadius: "12px",
            display:"flex",
            flexDirection:"column",
            justifyContent:"space-between",
            padding : '4px',
            backgroundRepeat : 'no-repeat',
            backgroundSize : 'cover',
            backgroundColor:"grey"
        },
        projectDetailsDiv: {
            width: "100%",
            height: "95px",
            borderRadius: "10px",
            background: "#e1eff2",
            backdropFilter: "blur(35.33766555786133px)",
        },
        projectTitle:{
            letterSpacing: " 0.225px",
            fontWeight: 700,
            color: "#17181D",
            fontFamily: "Urbanist",
            fontSize: "18px",
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '200px'
        },
        arrrowDiv:{
     width:'16px',
     height:'16px'
        },
        dateDiv: {
            color: " #17181D",
            textAlign: "right",
            fontFamily: "Urbanist",
            fontSize: "10px",
            fontWeight: 600,
            letterSpacing: "0.167px",
        },
        dFlex:{
            display:"flex",
            justifyContent:"space-between"
        },
        currentBalText: {
            fontWeight: 500,
            lineHeight: "16px",
            color: " #17181D",
            fontFamily: "Urbanist",
            fontSize: "12px",
            letterSpacing: "0.2px"
        },
        invoicesdiv:{
            width: "100px",
            height: "35px",
            color:"white",
            borderRadius: "17.5px",
            background: "black",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: 600,
            fontSize: "12px",
            "&:hover": {
                backgroundColor: "rgb(245, 196, 44)",
              },
        },
        marginAndGap: {
            marginTop: "3px",
            gap: "8.5px"
        },
        roleDiv: {
            fontSize: "10px",
            fontWeight: 600,
            marginRight: "10px",
            marginTop: "10px",
            color: "white",
            borderRadius: "12px",
            width: "66px",
            height: "23px",
            background: "rgba(23, 24, 29, 0.55)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },

        projectTitleDiv: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "10px",
            padding: 0
        },
        role: {
            width: "100%",
            textAlign: "right",
            display: "flex",
            justifyContent: "flex-end"
        }
 });

export default function ProductionHouseProjectCard(props: MyProps) {
  const classes = useStyles();

  return (
      <Box className={classes.parentDiv} style={{
      backgroundImage : `url(${props.data.attributes.image})`
      }} >
          <div className={classes.role}>

            <div className={classes.roleDiv} style={{color:"white"}}>
              <ArrowDownwardOutlinedIcon style={{color:"white",height:"14px",width:"14px"}}/>
              {props.data.attributes.role_count} Roles
              </div>
          </div>

          <div style={{ marginBottom: "5px" }} className={classes.projectDetailsDiv}>
              <div className={classes.projectTitleDiv}>
                 <Box style={{display:"flex",gap:"5px",alignItems:'center'}}>
                  <Typography title={props?.data?.attributes.project_name} className={classes.projectTitle}>{props?.data?.attributes.project_name}</Typography>
                  <img src={arrowPH} alt="" className={classes.arrrowDiv}/>
                  </Box>
                   <Typography className={classes.dateDiv}>{moment(props.data.attributes.created_at).format('DD, MMM YYYY')}</Typography>
              </div>
              <div className={classes.dFlex} style={{ margin: "10px" }}>
               <div>

                {props.data.attributes.current_balance?
                                (<Box>
                                <Typography className={classes.currentBalText}>Current Balance</Typography>
                <Typography className={classes.currentBalText} style={{fontWeight:800}}>{`₹${props.data.attributes.current_balance}`}</Typography>
                </Box>
                ):(null)
                }
                  
                </div>                 

                  <div className={`${classes.dFlex} ${classes.marginAndGap}`}>
                    <img width={34} height={34} style={{ borderRadius: "50%" }} 
                      src={projectInvoiceIcon} alt="InvoiceIcon" />
                      <div className={classes.invoicesdiv} onClick={(e:any)=>props.navigateToallinvoiceRequest(e,props.data.id)} >
                        Invoices ({props?.data?.attributes.invoice_count})
                      </div>
                  </div>
              </div>
          </div>
      </Box>
  );
}
