import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, InputLabel, withStyles,styled, Typography, TextField } from '@material-ui/core';
import Select from "react-select";
import CloseIcon from '@material-ui/icons/Close';
import TagsInput from './InputTag';

interface MyProps {
    handleClose:() => void;
    jobRoleProjectName:any;
    projectChangeHandle:any
    projectSelectedValue:any;
    jobRoleProjectDept:any;
    projectDeptChangeHandle:any;
    projectDeptSelectedValue:any;
    jobRoleProjectRole:any;
    projectDeptRoleHandle:any;
    projectDeptRoleSelectedValue:any;
    roleReqSelectedValue:any;
    expReqSelectedValue:any;
    budgetSelectedValue:any;
    noOfPositionSelectedValue:any;
    roleDescriptionValue:any;
    validateJobRole:any;
    addJobRoleSubmit:any
    selectedProjectVal:boolean
    selectedDeptVal:boolean
    selectedRoleVal:boolean
    roleReqChange:any
    expReqChange:any
    budgetRoleChange:any
    noOfPosChange:any
    roleDescriptionChange:any
    handleRoleError:any
    hanldeExpError:any
    handleBudgetError:any
    handlenoOfPosError:any
    handleRoleDesError:any
    handleSetEditJobRole:any

}

const useStyles = makeStyles({
    jobRoleModal: {
        minWidth: '720px',
        minHeight: '250px',
        color: '#17181D',
        padding: '20px',
    },
    jobRoleHead: {
        fontSize: '20px',
        lineHeight: '24px',
        letterSpacing: '0.2px',
        textAlign: 'center',
        fontWeight: 'bold',
        fontFamily: "urbanist",
        marginBottom: '24px'
    },
    jobRequestSaveButton: {
        margin: '20px 0 10px auto',
        maxWidth: '161px',
        borderRadius: '14px',
        background: '#17181d',
        color: '#ffffff',
        fontFamily: 'urbanist',
        fontSize: '15px',
        fontWeight: 600,
        letterSpacing: ' 0.25px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '15px 10px',   
        cursor: 'pointer', 
    },
    jobRoleWrapper: {
        '& .MuiGrid-item': {
            padding: '0 12px 12px'
        }
    },
    closeIcon: {
        color: '#6D89AF',
        position: 'absolute',
        top: '20px',
        right: '20px',
        cursor: 'pointer',
    },
    jobRoleHeadArea: {
        '& textarea': {
        height: '50px !important',
        overflowY: 'auto !important',
        }
    },
    errorMessage: {
        color: '#ff0000',
        fontFamily: 'Urbanist',
        fontSize: '13px',
        fontWeight: 600,
        lineHeight: '18px',
        letterSpacing: '0.4px',
    }
})

const CustomReactSelect = styled(Select)({
    '& .react-select__control': {
        borderRadius:"14px",
        height:"52px",
        boxShadow: 'none',
        borderColor: '#E3EAF4',
    },
    '& .react-select__control:hover': {
        borderColor: "#f5c42c"
    },
    '& .react-select__control--menu-is-open': {
        borderColor: "#f5c42c"
    },
    '& .react-select__placeholder': {
        color: "#6D89AF",

        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "18px",
        letterSpacing: "0.2px",  
      },
      "& .react-select__single-value":{
        fontWeight: 500,
        color:"#6D89AF"
      },
      '& .css-1okebmr-indicatorSeparator': {
        background: '#fff',
      }
});

const CustomInputlabel = withStyles({
    root: {
        fontSize: "13px", 
        fontFamily: "Urbanist", 
        fontWeight: 600, 
        lineHeight: '18px', 
        color: "#6D89AF", 
        padding: "16px 0 6px",
        "@media (max-width : 1440px)": {
            fontSize: '14px'
        },
        "@media (min-width : 1441px)": {
            fontSize: '14px',
            lineHeight: "14px",
            paddingBottom: "6px",
            paddingTop: "14px"

        }
    },
})(InputLabel);

const CustomCSSOfTextField = withStyles({
    root: {
        width: '100%',
        borderColor: "#E3EAF4",
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#E3EAF4",
            },
            "&:hover fieldset": {
                borderColor: "#f5c42c"
            },
            "&.Mui-focused fieldset": {
                borderColor: "#f5c42c",
                backgroundColor: 'rgba(245,196,44,0.04)',
            },
            color: '#323232',
            borderRadius: "14px",
            "& .MuiOutlinedInput-input": {
                lineHeight: '19px',
                borderColor: '#E3EAF4',
                color: '#6D89AF',
            }
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#f5c42c"
        },
        "& .MuiInputBase-input::placeholder": {
            color: '#6D89AF',
            fontWeight: 500,
            fontFamily: 'Urbanist',
        },        
    }
})(TextField);

export default function JobRoleRequestModel(props: MyProps) {
    const {handleClose , jobRoleProjectName , projectChangeHandle ,projectDeptChangeHandle,projectSelectedValue,
        projectDeptSelectedValue,jobRoleProjectDept , jobRoleProjectRole, projectDeptRoleHandle ,projectDeptRoleSelectedValue , 
        roleReqSelectedValue,expReqSelectedValue,budgetSelectedValue, noOfPositionSelectedValue,roleDescriptionValue, 
        validateJobRole , addJobRoleSubmit , selectedProjectVal,selectedDeptVal,selectedRoleVal, roleReqChange , expReqChange,
        budgetRoleChange , noOfPosChange, roleDescriptionChange, handleRoleError, hanldeExpError , handleBudgetError ,handlenoOfPosError , handleRoleDesError,handleSetEditJobRole} = props;
    const classes = useStyles();

    const renderErrorMessageJobRole = (type: boolean, message: string) => {
        return <>
            {type ? <Typography style={{ color: '#ff0000', fontFamily: 'Urbanist', fontSize: '13px', fontWeight: 600, lineHeight: '18px', letterSpacing: '0.4px' }}>{message}</Typography> : null}
        </>
    }

    return (
        <Box className={classes.jobRoleModal}>
            <Box>
                <Typography variant='h4' className={classes.jobRoleHead}>{!handleSetEditJobRole ? "Add Job role": "Edit Job Role"}</Typography>
                <CloseIcon onClick={handleClose} className={classes.closeIcon} />
            </Box>
            <Grid item container spacing={3} className={classes.jobRoleWrapper}> 
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box>
                        <CustomInputlabel id="nameOfPH">Project</CustomInputlabel>              
                        <CustomReactSelect
                            data-test-id={"stateId"}
                            classNamePrefix="react-select"
                            value={projectSelectedValue}
                            onChange = {projectChangeHandle}
                            options={jobRoleProjectName.map((option:any) => ({
                                value: option.id,
                                label: option.name
                            }))}
                            placeholder={"Select Project for Job"}
                            name="selectProject" 
                        />
                         {!selectedProjectVal && (
                            <div className={classes.errorMessage}>Select Project for Job</div>
                         )}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box>
                        <CustomInputlabel id="nameOfPH">Role Dept.</CustomInputlabel>              
                        <CustomReactSelect
                            data-test-id={"stateId"}
                            classNamePrefix="react-select"
                            value={projectDeptSelectedValue}
                            onChange ={projectDeptChangeHandle}
                            options={jobRoleProjectDept.map((option:any) => ({
                                value: option.dept_id, 
                                label: option.department
                            }))}
                            placeholder={"Select Dept. of Role"}
                            name="selectedState"
                            noOptionsMessage={() => "No option"}
                        />                      
                         {!selectedDeptVal && (
                            <div className={classes.errorMessage}>Select Dept. of Role</div>
                         )}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box>
                        <CustomInputlabel id="nameOfPH">Role Type</CustomInputlabel>              
                        <CustomReactSelect
                            data-test-id={"stateId"}
                            value={projectDeptRoleSelectedValue}
                            onChange={projectDeptRoleHandle}
                            options={jobRoleProjectRole.map((option:any) => ({
                                value: option.role_id, 
                                label: option.deptRole
                            }))}
                            classNamePrefix="react-select"
                            placeholder={"Select Role"}
                            name="selectedState"
                        />
                         {!selectedRoleVal && (
                            <div className={classes.errorMessage}>Please select a role type</div>
                         )}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box>
                        <CustomInputlabel id="nameOfPH">Role Requirements</CustomInputlabel>              
                        <TagsInput
                            selectedTags={roleReqChange}
                            fullWidth
                            variant="outlined"
                            id="roleRequirements"
                            name="tags"
                            tags={roleReqSelectedValue}
                            placeholder= {roleReqSelectedValue.length ? "" :'Type and Select Requirements'}
                        />
                        {renderErrorMessageJobRole(handleRoleError.require, "Role Requirements is required")}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box>
                        <CustomInputlabel id="nameOfPH">Exp. Required</CustomInputlabel>              
                        <CustomCSSOfTextField data-test-id={"expRequired"} type={'text'} id="outlined-basic" variant="outlined" placeholder="(eg: 4-5 Years)"
                            inputProps={{
                                'aria-label': 'exp_req',
                                type: 'text',
                                name: 'roleType',
                                onChange: expReqChange,
                                value: expReqSelectedValue,
                                required: true,
                            }}
                        />
                     {renderErrorMessageJobRole(hanldeExpError.require, "Exp is required")}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box>
                        <CustomInputlabel id="nameOfPH">Budget of role</CustomInputlabel>              
                        <CustomCSSOfTextField data-test-id={"roleBudget"} type={'text'} id="outlined-basic" variant="outlined" placeholder="(₹10,000 - ₹15,000)"
                            inputProps={{
                                'aria-label': 'budget role',
                                type: 'text',
                                name: 'budgetType',
                                onChange: budgetRoleChange,
                                value: budgetSelectedValue,
                                required: true,

                            }}
                        />
                        {renderErrorMessageJobRole(handleBudgetError.require, "Budget is required")}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box>
                        <CustomInputlabel id="nameOfPH">Number of positions</CustomInputlabel>              
                        <CustomCSSOfTextField data-test-id={"noOfPosition"} type={'text'} id="outlined-basic" variant="outlined" placeholder="No of Positions"
                            inputProps={{
                                'aria-label': 'no_of_pos',
                                type: 'text',
                                name: 'noOfPos',
                                onChange: noOfPosChange,
                                value: noOfPositionSelectedValue,
                                required: true,
                            }}
                        />
                    {renderErrorMessageJobRole(handlenoOfPosError.require, "No.Of Position is required")}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CustomInputlabel id="roleDescription">Role Description</CustomInputlabel>
                    <CustomCSSOfTextField multiline className={classes.jobRoleHeadArea} data-test-id={"noOfPosition"} type={'text'} id="outlined-basic" variant="outlined" placeholder="No of Positions"
                            inputProps={{
                                'aria-label': 'no_of_pos',
                                type: 'text',
                                name: 'noOfPos',
                                onChange: roleDescriptionChange,
                                value: roleDescriptionValue,
                                required: true,
                            }}
                        />
                    {renderErrorMessageJobRole(handleRoleDesError.require, "Role Description is required")}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box className={classes.jobRequestSaveButton} onClick={()=>{
                        addJobRoleSubmit();
                    }}>
                        Save Role
                    </Box>
                </Grid>
            </Grid>
        </Box>

    );
}
