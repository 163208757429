import React, {createRef} from "react";

import {
    // Customizable Area Start
    TypographyProps,
    Container,
    Grid,
    Box,Typography, TextField, InputAdornment, IconButton,
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
  import BalanceSheetController, {
    Props,
    configJSON,
  } from "./BalanceSheetController.web";
import Header from "../../../components/src/Header.web";
import SearchIcon from "@material-ui/icons/Search";
import SideBar from "../../../components/src/SideBar";
import Footer from "../../../components/src/Footer";
import Loader from "../../../components/src/Loader.web";
import {balanceProfile , infoIcon , successMarkIcon} from "./assets"
import { styled} from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import { IBalanceSheetProject } from "./BalanceSheetController.web";


const BalanceSearchBox = styled("div")({
  minWidth: "286px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": { 
      borderColor: "#E3EAF4" 
    },
    "&:hover fieldset": { 
      borderColor: "rgb(109, 137, 175)" 
    },
    "&.Mui-focused fieldset": {
      borderColor: "#f5c42c",
    },
  }
});
// Customizable Area End

export default class BalanceSheet extends BalanceSheetController {
    stepperRef: React.RefObject<any>;
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.stepperRef = createRef();
        // Customizable Area End
    }
   
    

    renderMainBalanceSheetProfile(balanceSheetStatus: boolean  = this.state.isBalanceSheetSideBarOpen) {
        return (
          <>
          <Box sx={webStyle.contentSetupinvoiceProdHouse}>
            <Loader loading={this.state.isLoading}></Loader>
            <Box style={webStyle.commonMainShrink}>
              < Header
                navigate={this.props.navigation.navigate}
                sideBarStatus={balanceSheetStatus}
                dropDownStatus={this.state.isBalanceSheetHeaderDropdownOpenVal}
                handleDropdown={this.handleBalanceSheetHeaderDropdownVal}
                handleSideBar={this.handleBalanceSheetSideBar}  
              />
            </Box>
            <Box style={webStyle.mainContentJobIvoice as React.CSSProperties}>
                    <Box style={webStyle.mainContent as React.CSSProperties}>
                        <Box style={webStyle.mainContentHeaderInvoice}>
                          <Box>
                            <Typography variant="h3" style={webStyle.activeBlockAllInvoice as TypographyProps}>Balance Sheets</Typography>
                            <Typography style={webStyle.breadcrumbTesxtInvoice}>Home <span style={webStyle.breadcrumbTextInvoiceSelected}>/ Balance Sheets </span>
                            </Typography>
                          </Box>
                        </Box>
                        <BalanceSearchBox>
                        <TextField
                          data-test-id="BalanceSheetSearchInput"
                          onChange={(event) => this.handleBalanceSheetSearchData(event)}
                          style={webStyle.activeBalanceInput}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <IconButton style={webStyle.balanceIconBtn}>
                                  <SearchIcon style={webStyle.balanceSearchIcon} />
                                </IconButton>
                              </InputAdornment>
                            ),
                            style: {
                              height: "44px",
                              backgroundColor: "#F8FAFE",
                              padding: "0px",
                              borderRadius: "35px",
                              fontSize: "13px",
                              '& :hover': {
                                border: 'none'
                              }
                            } as React.CSSProperties
                          }}
                          variant="outlined"
                          fullWidth
                          placeholder={`Search balance sheet`}
                        />
                      </BalanceSearchBox>
                    </Box>
                    <Grid container spacing={3}>
                      {this.state.balanceSheetData.length ? <>
                      {this.state.balanceSheetData.map((balanceSheet:IBalanceSheetProject)=>(
                      <Grid data-test-id={`${balanceSheet.id}_listContainer`}
                        item
                        xl={4}
                        md={this.state.isBalanceSheetSideBarOpen?4:3}
                        sm={12}
                        xs={12}
                      >
                        <Box style={webStyle.mainBalanceSheet as TypographyProps}>
                          <Box style={webStyle.activeBalanceSheet as TypographyProps}>
                            <Box style={webStyle.activeBalanceSheetwrap}>
                              <Box style={webStyle.balanceSheetProfileImg}>
                                <img src={balanceProfile} alt="Balance Profile" style={webStyle.balanceSheetProfImg}/>
                              </Box>
                              <Box>
                                <Typography variant="h6" title={balanceSheet.attributes.project_name} style={webStyle.headBalanceSheetInvoice}>{balanceSheet.attributes.project_name}</Typography>
                                <Typography style={webStyle.subheadBalanceSheetInvoice}>{balanceSheet.attributes.production_house}</Typography>
                              </Box>
                            </Box>
                            <Box>
                              <Typography style={webStyle.balanceSheetDate}>{balanceSheet.attributes.created_at}</Typography>
                            </Box>
                          </Box>  
                          <Box style={webStyle.currentBalanceSheetBox}>
                            <Box style={webStyle.currentBalanceSheetWrap}>
                              <Typography variant="h6" style={webStyle.balanceSheetHeading}>Current Balance</Typography>
                              <Typography variant="h5" style={webStyle.balanceSheetValue}>₹{balanceSheet.attributes.current_balance || "00.00"}K</Typography>
                            </Box>
                            <Box style={webStyle.currentBalanceSheetWrap}>
                              <Typography variant="h6" style={webStyle.balanceSheetHeading}>Overall Budget</Typography>
                              <Typography variant="h5" style={webStyle.balanceSheetValue}>₹{balanceSheet.attributes.overall_budget || "00.00"}K</Typography>
                            </Box>
                          </Box>
                          <Box style={webStyle.balanceSheetMsgWrapper}>
                            {balanceSheet.attributes.manage_balance_sheet ?
                            <>                     
                            <Box style={webStyle.balanceSheetMsg}>
                              <img src={successMarkIcon} alt="Success Mark" style={webStyle.balanceSheetMsgIcon}/>
                              <Typography style={webStyle.balanceSheetMsgText} >You’ve permission to view & manage the balance sheet</Typography>
                            </Box>
                            <Box style={webStyle.manageBtn} data-test-id={`manageSheetBtn_${balanceSheet.id}`} onClick={() => this.handleManageSheetNavigation(balanceSheet.id)}>Manage Sheet</Box>
                            </>
                           :  
                            <>                     
                            <Box style={webStyle.balanceSheetMsgWrapper}>
                                  <Box style={webStyle.balanceSheetMsg}>
                                    <img src={infoIcon} alt="Info Mark" style={webStyle.balanceSheetMsgIcon}/>
                                    <Typography style={webStyle.balanceSheetMsgText} >You’ve permission to view the balance sheet</Typography>
                                  </Box>
                                  <Box style={webStyle.manageBtn} data-test-id={`viewSheetBtn_${balanceSheet.id}`}  onClick={() => this.handleViewSheetNavigation(balanceSheet.id)}>View Sheet</Box>
                                </Box>
                              </>
                        }
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                      <Grid container style={{ margin: "8px 2px", justifyContent: "center", display: "grid" }}>
                        <Grid item xs={12}>
                          <Pagination style={webStyle.paginationWrapper}  data-test-id="pagination" count={Math.ceil(this.state.totalPages / configJSON.balanceSheetProjectListPerPage)} page={this.state.currentpage} onChange={this.handleBalanceSheetPagination} />
                      </Grid>
              </Grid>
                    </>
                     : <Typography style={{ width: '100%', textAlign: 'center', marginTop: '30px', color: '#6d89af', fontFamily: 'Urbanist', fontSize: 14, fontWeight: 600 }}>No record available</Typography>
                    }
                    </Grid>
                </Box>
                <Box style={webStyle.commonMainShrink}>
                  <Footer />
                </Box>
        </Box>
        </>
        )
    }
    
    render() {
        return ( 
            <Container maxWidth="xl" className="user_detail_container">
                {
                    this.state.isBalanceSheetSideBarOpen?
                        <Grid container spacing={0}>
                            <Grid style={webStyle.sideBarinvoiceProdHouse}  item xs={3}>
                                <SideBar
                                    userData={{ age: 25 }}
                                    activeMenuItem={"Balance Sheets"}
                                    navigate={this.props.navigation.navigate}
                                    handleSideBar={this.handleBalanceSheetSideBar}
                                   
                                    />
                            </Grid>
                            <Grid item xs={9}>{this.renderMainBalanceSheetProfile(this.state.isBalanceSheetSideBarOpen)}</Grid>
                        </Grid> :
                        <Grid item xs={12}>
                            {
                                this.renderMainBalanceSheetProfile(this.state.isBalanceSheetSideBarOpen)
                            }
                           </Grid>
                }
            </Container >
        );  }
}

// Customizable Area Start
const webStyle = {
  
    contentSetupinvoiceProdHouse: {
      borderLeft: "1px solid #E3EAF4",
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    commonMainShrink: {
      flexShrink: 0
    },
    sideBarinvoiceProdHouse: {
        minHeight: "100vh",
        maxWidth: "23%",
    },
    mainContentJobIvoice: {
        width: "100%",
        boxSizing: "border-box",
        padding: "24px",
        display:"flex",
        flexDirection:"column",
        position:"relative",
        flex: '1 0 auto'
    },
    mainContent: {
        padding: "0",
        display:"flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: '24px'
    },
      mainContentHeaderInvoice: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    activeBlockAllInvoice: {
        fontSize: "28px",
        fontWeight: "600",
        letterSpacing: "1px",
        display: "flex",
        flexDirection: "row",
        lineHeight: "33px",
        alignItems: 'center',
        gap: '12px',
        marginBottom: '8px',   
      },
      breadcrumbTesxtInvoice: {
        color: "#F5C42C",
        fontSize: "14px",
        marginTop: "8px",
        fontWeight:600
    },
    breadcrumbTextInvoiceSelected: {
      color: "rgb(109, 137, 175)"
    },
    mainBalanceSheet: {
      border: '1px solid #e3eaf4',
      padding: '12px 16px 12px 12px',
      borderRadius: '12px',
      backgroundColor: '#fff',
    },
    activeBalanceSheet: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
    activeBalanceSheetwrap: {
      display: "flex",
      alignItems: "center",
      gap: '12px',
    },
    headBalanceSheetInvoice: {
      color: '#17181d',
      fontSize: '16px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap' as 'nowrap',
      maxWidth: '180px'
    },
    subheadBalanceSheetInvoice: {
      color: '#3389FF',
      fontSize: '12px',
    },
    balanceSheetProfileImg: {
      height: 42,
      width: 42,
      borderRadius: 12,
    },
    balanceSheetProfImg: {
      height: '100%',
      width: '100%',
      borderRadius: 12,
    },
    balanceSheetDate: {
      fontSize: '11px',
      fontWeight: 600,
      color: '#6D89AF',
    },
    currentBalanceSheetBox: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
      gap: 15,
      padding: '10px 0',
      borderBottom: '1px solid #e3eaf4', 
    },
    currentBalanceSheetWrap: {
      borderRadius: '10px',
      background: 'rgba(225, 230, 236, 0.3)',
      padding: '8px 10px',
      width: '100%',
    },
    balanceSheetHeading: {
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '16px',
      color: '#6D89AF',
    },
    balanceSheetValue: {
      fontSize: '18px',
      fontWeight: 700,
      lineHeight: '19px',
      color: '#17181D',
      paddingTop: '5px',
      fontFamily: "Urbanist",
    },
    balanceSheetMsgWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: 15,
    },
    balanceSheetMsg: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
      gap: 10,
      width: '50%',
    },
    balanceSheetMsgIcon: {
      height: '18px',
    },
    balanceSheetMsgText: {
      fontSize: '10px',
      color: '#17181D',
    },
    manageBtn: {
      borderRadius: 10,
      backgroundColor: '#17181D',
      padding: '12px 20px',
      color: '#fff',
      fontSize: '12px',
      fontWeight: 700,
      cursor: 'pointer'
    },
    balanceIconBtn: {
      margin: '0px 0px 0px 15px', 
      padding: 0, 
    },
    balanceSearchIcon: {
      color: "#000", 
      width: "20px", 
      height: "20px"
    },
    activeBalanceInput: {
      border:'none',
      '& fieldset': {
        border:'none'
      }
    },
    paginationWrapper: {
      marginTop: '20px',
      display: 'flex',
      justifyContent: 'center',
    }
};
// Customizable Area End