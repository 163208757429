import React from "react";

//Customizable Area Start
import { Box, Container, Grid, InputLabel, Typography, withStyles } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import OtpScreenController, { Props, configJSON } from "./OtpScreenController";
import "./forgotPassword.css";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import OtpInput from "./OtpInput";
import CommonComponent from "./CommonComponent";
import { SignInInputLabel, CustomButton } from "../../email-account-login/src/CustomButton";
import Loader from "../../../components/src/Loader.web";
import CustomNotification from "../../../components/src/CustomNotification";
import { getStorageData } from "../../../framework/src/Utilities";
let fontSize = window.innerWidth > 1440 ? "40px" : "14px";
let signInWidth = window.innerWidth > 1440 ? "1300px" : window.innerWidth > 700 && window.innerWidth < 1440 ? "311px" : window.innerWidth <= 425 && window.innerWidth >= 375 ? "312px" : "250px";
let lineHeght = window.innerWidth > 1440 ? "40px" : "21px";

//Customizable Area End

export default class RecoveryScreen extends OtpScreenController {
    constructor(props: Props) {
        super(props);
        //Customizable Area Start
        this.state = {
            ...this.state,
            loginTab: 0,
          };
        //Customizable Area End
    }
    // Customizable Area Start
    async componentDidMount() {
        const loginTab = await getStorageData("LoginTab");
        const email = await getStorageData("recoveremail")
        const mobile  = await getStorageData("recovermobile")
        this.setState({ loginTab: parseInt(loginTab), emailid: email, mobile });
      }

    render() {
        return (
            <>
                <Grid container >
                    <CommonComponent navigation={this.props.navigation} />
                    <Loader loading={this.state.isLoading}></Loader>
                    <Box style={{ backgroundColor: '#f6f8fc', width: "100%", textAlign: "center" }}>
                        <Container maxWidth="md" data-test-id="otpScreenId" className="main_baground" style={{ backgroundColor: '#f6f8fc', width: "29rem", padding: "0px 10px" }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="otp_grid" style={{ marginTop: '18%' }}>
                                <IconButton size="medium" data-test-id="verify_text" style={{ border: '1px solid #e3eaf4' }} onClick={() => { this.props.navigation.navigate("ForgotPassword") }}>
                                    <ArrowBackIcon className="img_verify_circle" />
                                </IconButton>
                                <label className="welcome_text_otp">{configJSON.verifyText}</label>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', alignItems: 'center', marginBottom: '6%', justifyContent: 'center', textAlign: 'center', fontSize: "14px", fontFamily: "urbanist" }}>
                                <SignInInputLabel className="sing_in_text" style={{ fontWeight: 600, maxWidth: "275px", fontSize: "14px", fontFamily: "urbanist" }}>{`${configJSON.verifyMessage} ${this.state.loginTab !== 1 ? "email" : "phone number"}`} <label style={{ color: '#f5d160', fontSize: "14px", fontFamily: "urbanist" }}>{this.state.loginTab !== 1 ? this.state.emailid: this.state.mobile}</label></SignInInputLabel>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="otp_box">
                                <Grid xs={3} sm={3} md={3} lg={3} xl={3} item>
                                    <OtpInput validateOtp={this.validateOtp} otpValue={this.state.otpValue} className="otp_input" data-test-id="otp_input_text" id="text1" onKeyUp={(e: any) => this.move(e, "", "text1", "text2")} />
                                </Grid>
                                <Grid xs={3} sm={3} md={3} lg={3} xl={3} item>
                                    <OtpInput validateOtp={this.validateOtp1} otpValue={this.state.otpValue1} className="otp_input" data-test-id="otp_input_text_two" id="text2" onKeyUp={(e: any) => this.move(e, "text1", "text2", "text3")} />
                                </Grid>
                                <Grid xs={3} sm={3} md={3} lg={3} xl={3} item>
                                    <OtpInput validateOtp={this.validateOtp2} otpValue={this.state.otpValue2} className="otp_input" id="text3" data-test-id="otp_input_text_three" onKeyUp={(e: any) => this.move(e, "text2", "text3", "text4")} />
                                </Grid>
                                <Grid xs={3} sm={3} md={3} lg={3} xl={3} item>
                                    <OtpInput validateOtp={this.validateOtp3} otpValue={this.state.otpValue3} className="otp_input" id="text4" data-test-id="otp_input_text_four" onKeyUp={(e: any) => this.move(e, "text3", "text4", "")} />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="receive_code">
                                <Typography className="recieve_code_confirm">{configJSON.recieveCodeConfirmation}</Typography>
                                <a className="anchor" data-test-id="verifyLink" style={{ fontSize: "14px", fontFamily: "urbanist" }} onClick={() => {
                                    this.state.loginTab !== 1 ? this.goToOtpAfterEmailValidation() : this.goToOtpAfterMobileValidation() // You can call a method to reset data here if needed
                                    // Reload the page
                                }}>{configJSON.verifyLink}</a>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginBottom: '33%' }}>
                                <CustomButton data-test-id="otp_but" className="otp_input_button" style={{ fontSize: "16px", fontWeight: 600 }} onClick={this.verifyOtp} >{configJSON.verifyButtonText}</CustomButton>
                            </Grid>
                        </Container>
                    </Box>
                </Grid>
                <CustomNotification navigation={(route: string) => this.props.navigation.navigate(route)} notification={this.state.otpScreenNotification} handleCloseNotification={this.handleOtpScreenCloseNotification} />
            </>
        );
    }
    // Customizable Area End
}

// Customizable Area Start
export const SignInInput = withStyles({
    root: {
        fontSize: fontSize,
        fontFamily: 'Urbanist',
        letterSpacing: '0.538px',
        lineHeight: lineHeght,
        width: signInWidth,
        color: '#6d89af'
    }
})(InputLabel);
//Customizable Area End