import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import exp from 'constants';
import { Grid,Box,styled } from '@material-ui/core';
import { getStorageData } from '../../framework/src/Utilities';

import { Tringle, notificationicon, reclaiminvoice, dummyImage } from './assets';
import DateTimeDifferece from './DateTimeDifference';


interface MyProps {
    data:any
   
}


const TableGrid = styled(Box)({
    '& .MuiBox-root ::-webkit-scrollbar': {
        display: 'none',
    },
    '& .MuiBox-root': {
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
        "@media screen and (max-width: 500px)": {
        
            paddingLeft:'10px'
        }
    },
    '& .MuiFormControlLabel-label::-webkit-scrollbar': {
        display: 'none',
    },
    '& .MuiFormControlLabel-label': {
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
    },
})


const useStyles = makeStyles({
    TringlePosition:{
    
            backgroundColor: 'white',
            
            position: 'relative',
            height: '25px',
            width: '25px',
            float: 'right',
            right: '70px',
            top: '-8px',
            transform: 'rotate(45deg)',
    },

   ParentMain:{

   },
   HeadingNotification:{
    color: '#17181d',
    fontFamily: 'Urbanist',
    fontSize: '18px',
    fontWeight: 700,
    paddingTop:'18px',
    paddingLeft:'16px'
   },
   messagefastdiv:{
    paddingLeft:'16px',
    paddingTop:'16px'
   },
   messagelogobackground:{
    width: '34px',
    height: '34px',
    borderRadius: '12px',
    background: 'gainsboro',
    opacity: '49%',
    display:'flex',
    justifyContent:'center',
    

   },
   messagelogobackground1:{
    width: '34px',
    height: '34px',
    borderRadius: '12px',
   },
   messagetext:{
    width: '298px',

  color: '#7d7d7d',
  fontFamily: 'Urbanist',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '20px'
   },
   notificationviewicon1:{
    height:'34px',
    width:'34px',
    borderRadius:'12px',
    
   },
   notificationviewicon:{
    height:'15px',
    width:'15px',
    paddingTop:'9px'
    
   },
   messsagehrs:{
    color: '#6d89af',
    fontFamily: 'Urbanist',
    fontSize: '12px',
    fontWeight: 600,
    textAlign: 'right',
    paddingRight:'16px'
   },
   messagegroup:{
    overflowY:'scroll',
    maxHeight:'260px',
    scrollbarWidth:"none"

   }
});




export default function Sortfunctionality(props: MyProps) {
    const {data} = props;

    const classes = useStyles();
    const [userEmail, setUserEmail] = useState("");
    const [accountType, setAccountType] = useState("");
    const [userName, setUserName] = useState("");
    const [profile, setProfile] = useState<any>(getStorageData('image'));
  
    useEffect(() => {
      async function fetchData() {
        const currentUserEmail = await getStorageData('email');
        setUserEmail(currentUserEmail);
        const accountType = await getStorageData('account_type');
        setAccountType(accountType);
        const currentUserName = await getStorageData('full_name');
        setUserName(currentUserName);
        const profileImage = await getStorageData('image')
        setProfile(profileImage);
      }
      fetchData();
    }, []);

  
    console.log("notification..",data)
   
    
    


    return (
        <Box className={classes.ParentMain}>
             <Box className={classes.TringlePosition}>
                   
                    </Box>
          <Box className={classes.HeadingNotification}>
          Notifications
          </Box>
          <TableGrid className={classes.messagegroup}>
          {data &&
  data
    ?.sort(
        (a: any, b: any) =>
          (new Date(b.attributes.created_at) as any) -
        (  new Date(a.attributes.created_at) as any)
      )
    .map((item: any, index: number) => {
      const createdAtDate = new Date(item?.attributes?.created_at) as any;
      const hours = createdAtDate.getHours();
      const days = Math.floor((new Date() as any - createdAtDate) / (1000 * 60 * 60 * 24));

      return (
        <Box className={classes.messagefastdiv} key={index}>
          <Box style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <Box className={classes.messagelogobackground1}>
              <img src={item?.attributes?.image || dummyImage} alt="Dynamic Image" className={classes.notificationviewicon1} />
            </Box>
            <Box className={classes.messagetext}>{item?.attributes.contents}</Box>
          </Box>
          <DateTimeDifferece utcTime={item?.attributes?.created_at} />
          <Box style={{ borderBottom: '1px solid #e3eaf4', marginRight: '16px', paddingTop: '16px' }}></Box>
          <Box></Box>
        </Box>
      );
    })}



</TableGrid>
        </Box>

    );
}
