import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { confirmPasswordValidate, getStorageData, passwordValidate, setStorageData } from "../../../framework/src/Utilities";
import React from "react";
import { validateGSTNumber, validatePanNumber, validateTanNumber } from "../../../components/src/ValidationRegex";
interface VenderProfile {
  account_detail: {
    data: {
      attributes: {
        full_name: string;
        email:string;
        phone_number:number;
        country_code:number;
        gst_number: string;
        tan_number:string;
        aadhar_card_number:string;
        pan_card_number:string,
        account_number:number,
        ifsc_code:string,
        bank_name:{
          id:number,
        }
        
      }
    }
  },
  profile_bio: {
    data: {
      attributes: {
        about_business: string;
      }
    }
  },
  city: string;
  state: string;
  role: string;
  department: string;
  postal_code: string;
  documents: {
    id:number;
    path_url:string;
    filename : string;
    mime_type : string;
  }[],
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isVendorProfileSettingsSideBarOpen: boolean;
  isLoading: boolean;
  isVendorProfileSettingsDropdownOpen: boolean;
  vendorProfileData: any;
  vendorProfileDataFiles: { old: any, new: any };
  vendorProfileDataError: any;
  vendorProfileResetPasswordData: any;
  vendorProfileResetPasswordDataError: any;
  vendorProfileResetPasswordVisibility: any;
  vendorProfileTabValue: number;
  vendorProfileId: string;
  vendorProfileNotification: { type: string, open: boolean, message: string, route?: string };
  vendorProfileActiveStep: number;
  vendorProfileRolesData: any;
  bankList: any;
  vendorProfileDepartmentsData: any;
  stateData: any;
  cityData: any;
  token: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class VendorProfileSettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getVendorProfileSettingsDataMessageId: string = "";
  getRolesCallId: string = "";
  getDeptsCallId: string = "";
  getStatesMessageId: string = "";
  getCitiesMessageId: string = "";
  getBankListCallId: string = "";
  vendorUpdateProfileMessageId: string = "";
  profileUpdateBioMessageId: string = "";
  resetPasswordMessageId: string = "";
  fileUpload: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.fileUpload = React.createRef();
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isVendorProfileSettingsSideBarOpen: true,
      isLoading: false,
      isVendorProfileSettingsDropdownOpen: false,
      vendorProfileDataFiles: { old: [], new: [] },
      vendorProfileData: {
        "account[full_name]": "",
        "account[email]": "",
        "account[phone_number]": "",
        "account[country_code]": "",
        "account[role_id]": "",
        "account[department_id]": "",
        "profile[state]": "",
        "profile[city]": "",
        "profile[postal_code]": "",
        "profile[profile_bio]": "",
        "account[gst_number]": "",
        "account[tan_number]": "",
        "account[aadhar_card_number]": "",
        "account[pan_card_number]": "",
        "profile[documents][]": [],
        "account[bank_name_id]": "",
        "account[account_number]": "",
        "account[ifsc_code]": "",
      },
      vendorProfileDataError: {
        "account[full_name]": { require: false },
        "account[role_id]": { require: false },
        "account[department_id]": { require: false },
        "profile[state]": { require: false },
        "profile[city]": { require: false },
        "profile[postal_code]": { require: false, invalid: "" },
        "profile[profile_bio]": { require: false },
        "account[gst_number]": { require: false, invalid: false },
        "account[tan_number]": { require: false, invalid: false },
        "account[aadhar_card_number]": { require: false, invalid: "" },
        "account[pan_card_number]": { require: false, invalid: false },
        "profile[documents][]": { require: false },
        "account[bank_name_id]": { require: false },
        "account[account_number]": { require: false },
        "account[ifsc_code]": { require: false },
      },
      vendorProfileResetPasswordData: {
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      },
      vendorProfileResetPasswordDataError: {
        currentPassword: { require: false, invalid: false },
        newPassword: { require: false, invalid: false },
        confirmNewPassword: { require: false, match: false },
      },
      vendorProfileResetPasswordVisibility: {
        currentPassword: false,
        newPassword: false,
        confirmNewPassword: false,
      },
      vendorProfileTabValue: 0,
      vendorProfileId: "",
      vendorProfileNotification: { type: "", open: false, message: "" },
      vendorProfileActiveStep: 0,
      vendorProfileRolesData: [],
      bankList: [],
      vendorProfileDepartmentsData: [],
      stateData: [],
      cityData: [],
      token: "",
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const vendorManageProfileApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const vendorProfileResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (vendorManageProfileApiRequestCallId === this.getRolesCallId) {
        this.handleGetRolesApiResponse(vendorProfileResponseJson);
      } else if (vendorManageProfileApiRequestCallId === this.getDeptsCallId) {
        this.handleGetDeptsApiResponse(vendorProfileResponseJson);
      }  else if (vendorManageProfileApiRequestCallId === this.getCitiesMessageId) {
        this.setState({ cityData: vendorProfileResponseJson, isLoading: false });
      } else if (this.getVendorProfileSettingsDataMessageId === vendorManageProfileApiRequestCallId) {
        this.getVendorProfileSettingsDataHandleResponse(vendorProfileResponseJson);
      } else if (this.getBankListCallId === vendorManageProfileApiRequestCallId) {
        this.handleGetBankListApiResponse(vendorProfileResponseJson);
      } else if (this.vendorUpdateProfileMessageId === vendorManageProfileApiRequestCallId || this.profileUpdateBioMessageId === vendorManageProfileApiRequestCallId) {
        this.updateVendorProfileSettingsDataHandleResponse(vendorProfileResponseJson);
      } else if (this.resetPasswordMessageId === vendorManageProfileApiRequestCallId) {
        this.resetPasswordHandleResponse(vendorProfileResponseJson);
      } else if(this.getStatesMessageId === vendorManageProfileApiRequestCallId){
        this.handleStateAPIResponse(vendorProfileResponseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleStateAPIResponse = (vendorProfileResponseJson: any) => {
    if(vendorProfileResponseJson){
      this.setState({
        stateData: vendorProfileResponseJson
      })
    }
  }
  handleGetBankListApiResponse = (vendorProfileResponseJson: { data: any }) => {
    if (vendorProfileResponseJson.data) {
      this.setState({ bankList: vendorProfileResponseJson.data });
    }
    this.setState({ isLoading: false });
  }

  handleGetRolesApiResponse = (vendorProfileResponseJson: { data: any }) => {
    if (vendorProfileResponseJson.data) {
      this.setState({ vendorProfileRolesData: vendorProfileResponseJson.data });
    }
    this.setState({ isLoading: false });
  }

  handleGetDeptsApiResponse = (vendorProfileResponseJson: { data: any }) => {
    if (vendorProfileResponseJson.data) {
      this.setState({ vendorProfileDepartmentsData: vendorProfileResponseJson.data });
    }
    this.setState({ isLoading: false });
  }

  getVendorProfileSettingsDataHandleResponse = async (vendorManageProfileDataResponseJson: { errors: { token: string }[], data: any }) => {
    if (vendorManageProfileDataResponseJson.data) {
      const profileData :VenderProfile= vendorManageProfileDataResponseJson.data.attributes;
      console.log("profileData", profileData)
      const stateDetailedData = this.state.stateData.find((data: any) => data.name === profileData.state);
      const updatedProfileData = {
        "account[full_name]": profileData.account_detail.data.attributes?.full_name,
        "account[email]": profileData.account_detail.data.attributes?.email,
        "account[phone_number]": profileData.account_detail.data.attributes?.phone_number,
        "account[country_code]": profileData.account_detail.data.attributes?.country_code,
        "profile[profile_bio]": profileData.profile_bio.data?.attributes && profileData.profile_bio.data.attributes.about_business,
        "account[role_id]": this.state.vendorProfileRolesData.find((data: any) => data.name === profileData.role).id,
        "account[department_id]": this.state.vendorProfileDepartmentsData.length ? this.state.vendorProfileDepartmentsData.find((data: any) => data.department_name === profileData.department).id : "",
        "profile[state]": stateDetailedData?.iso2 || "",
        "profile[city]": profileData.city,
        "profile[postal_code]": profileData.postal_code,
        "account[gst_number]": profileData.account_detail.data.attributes?.gst_number,
        "account[tan_number]": profileData.account_detail.data.attributes?.tan_number,
        "account[aadhar_card_number]": profileData.account_detail.data.attributes?.aadhar_card_number,
        "account[pan_card_number]": profileData.account_detail.data.attributes?.pan_card_number,
        "profile[documents][]": profileData.documents,
        "account[bank_name_id]": profileData.account_detail.data?.attributes?.bank_name?.id || "",
        "account[account_number]": profileData.account_detail.data.attributes?.account_number,
        "account[ifsc_code]": profileData.account_detail.data.attributes?.ifsc_code,
      }
      this.setState({ vendorProfileData: updatedProfileData, vendorProfileDataFiles: { new: [], old: profileData.documents } }, async () => {
        this.getCitiesMessageId = await this.getStatesAndCitiesApiCall(`${configJSON.getCityAPIEndPoint}${stateDetailedData?.iso2 || ""}/cities`);
      });
    } else if (vendorManageProfileDataResponseJson.errors[0].token) {
      this.setState({ vendorProfileNotification: { open: true, type: "error", message: vendorManageProfileDataResponseJson.errors[0].token, route: "LandingPage" } });
    }
    this.setState({ isLoading: false });
  }

  updateVendorProfileSettingsDataHandleResponse = async (vendorManageProfileDataResponseJson: { errors: { token: string }[], data: any }) => {
    if (vendorManageProfileDataResponseJson.data) {
      this.handleNavigation("VendorManageProfile");
    } else if (vendorManageProfileDataResponseJson.errors[0].token) {
      this.setState({ vendorProfileNotification: { open: true, type: "error", message: vendorManageProfileDataResponseJson.errors[0].token, route: "LandingPage" } });
    }
    this.setState({ isLoading: false });
  }

  resetPasswordHandleResponse = (vendorProfileResponseJson: { data: any, meta: { message: string }, errors: [{ token: string, message: string }] }) => {
    if (vendorProfileResponseJson.data) {
      this.setState({ vendorProfileNotification: { open: true, type: "success", message: vendorProfileResponseJson.meta.message } });
    } else if (vendorProfileResponseJson.errors[0].token) {
      this.setState({ vendorProfileNotification: { open: true, type: "error", message: vendorProfileResponseJson.errors[0].token, route: "LandingPage" } });
    } else if (vendorProfileResponseJson.errors[0].message) {
      this.setState({ vendorProfileNotification: { open: true, type: "error", message: vendorProfileResponseJson.errors[0].message } });
    }
    this.setState({ isLoading: false });
  }

  getStatesAndCitiesApiCall = async (endPoint: string) => {
    const XCSCAPIKEY = "Z1dmZFJDWjlMSjl0M082ZUUyU3JESkJCQTdZeTd6UEVINXdreTdjWQ=="
    const header = {
      "Content-Type": configJSON.contentTypeApiUpdateUser,
      "X-CSCAPI-KEY": XCSCAPIKEY
    };
    this.setState({ isLoading: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handleVendorProfileSettingsSideBar = () => {
    setStorageData("sidebarvalue", (!this.state.isVendorProfileSettingsSideBarOpen).toString())
    this.setState({
      isVendorProfileSettingsSideBarOpen: !this.state.isVendorProfileSettingsSideBarOpen
    });
  };

  handleDropdown = () => {
    this.setState({
      isVendorProfileSettingsDropdownOpen: !this.state.isVendorProfileSettingsDropdownOpen
    });
  };

  handleNavigation = (page: string) => {
    this.props.navigation.navigate(page)
  };

  async componentDidMount() {
    const profileId = await getStorageData("userid");
    const isVendorProfileSettingsSideBarOpen = await getStorageData("sidebarvalue");
    this.getRolesCallId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getVendorProfileRolesApiEndPoint);
    this.getDeptsCallId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getVendorProfileDeptsApiEndPoint);
    this.getStatesMessageId = await this.getStatesAndCitiesApiCall(configJSON.getStateAPIEndPoint);
    this.getBankListCallId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getVendorProfileBankListApiEndPoint);
    this.getVendorProfileSettingsDataMessageId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getVendorManageProfileDetailsAPIEndPoint + profileId);
    this.setState({ vendorProfileId: profileId, isVendorProfileSettingsSideBarOpen: JSON.parse(isVendorProfileSettingsSideBarOpen) });
  }

  apiCall = async (vendorManageProfileMethod: string, vendorManageProfileEndPoint: string, vendorManageProfileBodyData?: any, vendorManageProfileFormData?: any) => {
    const authToken = await getStorageData("token");
    const vendorProfileSettingsHeader = {
      "Content-Type": configJSON.contentTypeApiUpdateUser,
      token: authToken
    };
    const vendorProfileSettingsRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({ isLoading: true });
    vendorProfileSettingsRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      vendorManageProfileEndPoint
    );
    vendorProfileSettingsRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(vendorManageProfileFormData ? { token: authToken } : vendorProfileSettingsHeader)
    );
    vendorManageProfileBodyData && vendorProfileSettingsRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(vendorManageProfileBodyData)
    );
    vendorManageProfileFormData && vendorProfileSettingsRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      vendorManageProfileFormData
    );
    vendorProfileSettingsRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      vendorManageProfileMethod
    );
    runEngine.sendMessage(vendorProfileSettingsRequestMessage.id, vendorProfileSettingsRequestMessage);
    return vendorProfileSettingsRequestMessage.messageId;
  }

  handleVendorProfileAppBarValue = (event: any, value: number) => {
    this.setState({
      vendorProfileTabValue: value,
    });
  }

  handleVendorProfileCloseNotification = () => {
    this.setState({ vendorProfileNotification: { type: "", open: false, message: "" } });
  }

  handleVendorProfileStepBack = () => {
    this.setState({ vendorProfileActiveStep: this.state.vendorProfileActiveStep - 1 });
  }

  handleVendorProfileMoveOnNextPage = () => {
    if (!this.state.vendorProfileActiveStep) {
      if (!this.state.vendorProfileData["account[full_name]"] || !this.state.vendorProfileData["account[role_id]"] || !this.state.vendorProfileData["account[department_id]"] ) {
        this.setState({ vendorProfileDataError: { ...this.state.vendorProfileDataError, "account[full_name]": { require: !Boolean(this.state.vendorProfileData["account[full_name]"]) }, "account[role_id]": { require: !Boolean(this.state.vendorProfileData["account[role_id]"]) }, "account[department_id]": { require: !Boolean(this.state.vendorProfileData["account[department_id]"]) }, "profile[state]": { require: !Boolean(this.state.vendorProfileData["profile[state]"]) }, "profile[city]": { require: !Boolean(this.state.vendorProfileData["profile[city]"]) }, "profile[postal_code]": { require: !Boolean(this.state.vendorProfileData["profile[postal_code]"]), "profile[profile_bio]": { require: !Boolean(this.state.vendorProfileData["profile[profile_bio]"]) } } } });
        return false;
      }
    }
    else if (this.state.vendorProfileActiveStep === 1) {
      if (!this.state.vendorProfileData["account[gst_number]"] || !this.state.vendorProfileData["account[tan_number]"] || !this.state.vendorProfileData["account[aadhar_card_number]"] || !this.state.vendorProfileData["account[pan_card_number]"]) {
        this.setState({ vendorProfileDataError: { ...this.state.vendorProfileDataError, "account[gst_number]": { require: !Boolean(this.state.vendorProfileData["account[gst_number]"]) }, "account[tan_number]": { require: !Boolean(this.state.vendorProfileData["account[tan_number]"]) }, "account[aadhar_card_number]": { require: !Boolean(this.state.vendorProfileData["account[aadhar_card_number]"]) }, "account[pan_card_number]": { require: !Boolean(this.state.vendorProfileData["account[pan_card_number]"]) } } });
        return false;
      } else if (this.state.vendorProfileDataError["account[gst_number]"].invalid) {
        this.invalidNotification("Please enter valid GST number");
      } else if (this.state.vendorProfileDataError["account[tan_number]"].invalid) {
        this.invalidNotification("Please enter valid Tan number");
      } else if (this.state.vendorProfileDataError["account[aadhar_card_number]"].invalid) {
        this.invalidNotification("Please enter valid Addhar card number");
      } else if (this.state.vendorProfileDataError["account[pan_card_number]"].invalid) {
        this.invalidNotification("Please enter valid Pan card number");
      } else if (!this.state.vendorProfileData["profile[documents][]"].length) {
        this.invalidNotification("Please upload Certificates & Documents");
      }
    }
    this.setState({ vendorProfileActiveStep: this.state.vendorProfileActiveStep + 1 });
  }

  invalidNotification = (message: string) => {
    this.setState({ vendorProfileNotification: { type: 'warning', open: true, message: message } });
    return false;
  }

  handleInputFocus = (labelName: string) => {
    const inputLabel = document.getElementById(labelName);
    if (inputLabel) {
      inputLabel.style.color = "#F5C42C";
    }
  };

  handleInputBlur = (labelName: string) => {
    const inputLabel = document.getElementById(labelName);
    if (inputLabel) {
      inputLabel.style.color = "#6D89AF";
    }
  };

  handleSaveDetails = async () => {
    if (!this.state.vendorProfileData["account[bank_name_id]"] || !this.state.vendorProfileData["account[account_number]"] || !this.state.vendorProfileData["account[ifsc_code]"]) {
      this.setState({ vendorProfileDataError: { ...this.state.vendorProfileDataError, "account[bank_name_id]": { require: !Boolean(this.state.vendorProfileData["account[bank_name_id]"]) }, "account[account_number]": { require: !Boolean(this.state.vendorProfileData["account[account_number]"]) }, "account[ifsc_code]": { require: !Boolean(this.state.vendorProfileData["account[ifsc_code]"]) } } });
      return false;
    }
    const formData = new FormData();
    Object.entries(this.state.vendorProfileData).forEach((data: any) => {
      if (data[0] !== "account[email]" && data[0] !== "account[phone_number]" && data[0] !== "account[country_code]" && data[0] !== "profile[documents][]" && data[0] !== "profile[state]") {
        formData.append(data[0], data[1]);
      }
    });
    const stateDetailedData = this.state.stateData.find((data: any) => data.iso2 === this.state.vendorProfileData["profile[state]"]);
    formData.append(`profile[state]`, stateDetailedData?.name);
    const documentsIds = this.state.vendorProfileDataFiles.old.map((data: any) => data.id.toString());
    formData.append(`profile[documents_ids]`, documentsIds.toString());
    for (let file of this.state.vendorProfileDataFiles.new) {
      formData.append(`profile[documents][]`, file);
    }
    let body = {  data: {
      attributes: {
        about_business: this.state.vendorProfileData["profile[profile_bio]"],
      },
    },}
    this.vendorUpdateProfileMessageId = await this.apiCall(configJSON.apiUpdateUserType, configJSON.vendorManageProfileSaveUpdatedProfileDetailsAPIEndPoint + this.state.vendorProfileId, null, formData);
    this.profileUpdateBioMessageId = await this.apiCall("POST",configJSON.profileUpdateBioAPIEndPoint, body, null )
    
  }

  handleValidateAddharPanTanGSTNumber = (key: string, newValue: any) => {
    const profileDataErrors = this.state.vendorProfileDataError;
    if (key === "account[aadhar_card_number]") {
      const validAadharNumber = newValue.length === 12;
      if (!validAadharNumber) {
        profileDataErrors[key].invalid = "length";
        this.setState({ vendorProfileDataError: profileDataErrors })
      } else {
        profileDataErrors[key].invalid = "";
        this.setState({ vendorProfileDataError: profileDataErrors });
      }
    } 
    else if (key === "profile[postal_code]") {
      const validPostalCode = newValue.length;
        profileDataErrors[key].invalid = "";
        this.setState({ vendorProfileDataError: profileDataErrors });
    } else if (key === "account[tan_number]") {
      const validTanNumber = validateTanNumber(newValue);
      this.validatePanTanGSTNumber(validTanNumber, key);
    } else if (key === "account[gst_number]") {
      const validGSTNumber = validateGSTNumber(newValue);
      this.validatePanTanGSTNumber(validGSTNumber, key);
    } else if (key === "account[pan_card_number]") {
      const validPanNumber = validatePanNumber(newValue);
      this.validatePanTanGSTNumber(validPanNumber, key);
    }
  }

  validatePanTanGSTNumber = (validNumber: any, key: string) => {
    const profileDataErrors = this.state.vendorProfileDataError;
    if (!validNumber) {
      profileDataErrors[key].invalid = true;
      this.setState({ vendorProfileDataError: profileDataErrors })
    } else {
      profileDataErrors[key].invalid = false;
      this.setState({ vendorProfileDataError: profileDataErrors });
    }
  }

  handleChangeProfileDetails = async (event: any, key: string) => {
    const newValue = event.target.value;
    const profileDataErrors = this.state.vendorProfileDataError;
    if (isNaN(newValue) && key === "account[aadhar_card_number]") {
      profileDataErrors["account[aadhar_card_number]"].invalid = "numeric";
      this.setState({ vendorProfileDataError: profileDataErrors });
      return false;
    }
    if (isNaN(newValue) && key === "profile[postal_code]") {
      profileDataErrors["profile[postal_code]"].invalid = "numeric";
      this.setState({ vendorProfileDataError: profileDataErrors });
      return false;
    }
    const profileData = this.state.vendorProfileData;
    profileData[key] = newValue;
    this.setState({ vendorProfileData: profileData });
    if (!newValue) {
      profileDataErrors[key].require = true;
      this.setState({ vendorProfileDataError: profileDataErrors });
      return false;
    } else {
      profileDataErrors[key].require = false;
      this.setState({ vendorProfileDataError: profileDataErrors });
    }

    if (key === "profile[state]") {
      this.getCitiesMessageId = await this.getStatesAndCitiesApiCall(configJSON.getCityAPIEndPoint + newValue + "/cities");
      profileData["profile[city]"] = "";
      profileData["profile[postal_code]"] = "";
      this.setState({ vendorProfileData: profileData });
    } else if (key === "account[bank_name_id]") {
      profileData["account[account_number]"] = "";
      profileData["account[ifsc_code]"] = "";
      this.setState({ vendorProfileData: profileData });
    }
    this.handleValidateAddharPanTanGSTNumber(key, newValue);
  }

  handleChangeResetPasswordDetails = (event: any, key: string) => {
    const resetpassword = event.target.value;
    const resetPasswordDataErrors = this.state.vendorProfileResetPasswordDataError;
    const resetPasswordData = this.state.vendorProfileResetPasswordData;
    if (!resetpassword) {
      this.setState({});
      resetPasswordData[key] = resetpassword;
      resetPasswordDataErrors[key].require = true;
      this.setState({ vendorProfileResetPasswordData: resetPasswordData, vendorProfileResetPasswordDataError: resetPasswordDataErrors });
      return false;
    } else {
      resetPasswordDataErrors[key].require = false;
      resetPasswordData[key] = resetPasswordData[key].length < resetpassword.length ? resetPasswordData[key] + resetpassword[resetpassword.length - 1] : resetPasswordData[key].slice(0, -1);
      this.setState({ vendorProfileResetPasswordData: resetPasswordData, vendorProfileResetPasswordDataError: resetPasswordDataErrors }, () => {
        this.validatePasswords(key);
      });
    }
  }

  validatePasswords = (key: string) => {
    const resetPasswordDataErrors = this.state.vendorProfileResetPasswordDataError;
    if (key === "newPassword" || key === "currentPassword") {
      const validResetPassword = passwordValidate("password", this.state.vendorProfileResetPasswordData[key]);
      if (!validResetPassword.status) {
        resetPasswordDataErrors[key].invalid = true;
        this.setState({ vendorProfileResetPasswordDataError: resetPasswordDataErrors })
      } else {
        resetPasswordDataErrors[key].invalid = false;
        this.setState({ vendorProfileResetPasswordDataError: resetPasswordDataErrors });
      }
    }
    if (key === "confirmNewPassword" || key === "newPassword") {
      const validResetPassword = confirmPasswordValidate("confirm password", this.state.vendorProfileResetPasswordData.confirmNewPassword, "password", this.state.vendorProfileResetPasswordData.newPassword);
      if (!validResetPassword.status) {
        resetPasswordDataErrors.confirmNewPassword.match = true;
        this.setState({ vendorProfileResetPasswordDataError: resetPasswordDataErrors })
      } else {
        resetPasswordDataErrors.confirmNewPassword.match = false;
        this.setState({ vendorProfileResetPasswordDataError: resetPasswordDataErrors });
      }
    }
  }

  vendorProfileHandleCancelPDF = async (fileObj: any) => {
    if (!fileObj.id) {
      const profileData = this.state.vendorProfileData;
      profileData["profile[documents][]"] = profileData["profile[documents][]"].filter((data: any) => data.name !== fileObj.name);
      this.setState({ vendorProfileData: profileData, vendorProfileDataFiles: { ...this.state.vendorProfileDataFiles, new: this.state.vendorProfileDataFiles.new.filter((data: any) => data.name !== fileObj.name) } });
    } else {
      const profileData = this.state.vendorProfileData;
      profileData["profile[documents][]"] = profileData["profile[documents][]"].filter((data: any) => data.id !== fileObj.id);
      this.setState({ vendorProfileData: profileData, vendorProfileDataFiles: { ...this.state.vendorProfileDataFiles, old: this.state.vendorProfileDataFiles.old.filter((data: any) => data.id !== fileObj.id) } });
    }
  }

  handleClickAddDocuments = () => {
    this.fileUpload.current.click();
  }

  handleSelectDocuments = async (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const profileData = this.state.vendorProfileData;
      profileData["profile[documents][]"] = [...profileData["profile[documents][]"], ...event.target.files];
      this.setState({ vendorProfileData: profileData, vendorProfileDataFiles: { ...this.state.vendorProfileDataFiles, new: [...this.state.vendorProfileDataFiles.new, ...event.target.files] } });
    }
  }

  handleVendorProfileResetPassword = async () => {
    if (!this.state.vendorProfileResetPasswordData.currentPassword || !this.state.vendorProfileResetPasswordData.newPassword || !this.state.vendorProfileResetPasswordData.confirmNewPassword) {
      this.setState({ vendorProfileResetPasswordDataError: { ...this.state.vendorProfileResetPasswordDataError, currentPassword: { require: !Boolean(this.state.vendorProfileResetPasswordData.currentPassword) }, newPassword: { require: !Boolean(this.state.vendorProfileResetPasswordData.newPassword) }, confirmNewPassword: { require: !Boolean(this.state.vendorProfileResetPasswordData.confirmNewPassword) } } });
      return false;
    } else if (this.state.vendorProfileResetPasswordDataError.confirmNewPassword.match) {
      this.setState({ vendorProfileNotification: { type: 'warning', open: true, message: "Confirm new password should be same as new password" } });
      return false;
    } else if (this.state.vendorProfileResetPasswordDataError.currentPassword.invalid) {
      this.setState({ vendorProfileNotification: { type: 'warning', open: true, message: "Current password is invalid" } });
      return false;
    } else if (this.state.vendorProfileResetPasswordDataError.newPassword.invalid) {
      this.setState({ vendorProfileNotification: { type: 'warning', open: true, message: "New password is invalid" } });
      return false;
    }

    const bodyData = {
      data: {
        attributes: {
          current_password: this.state.vendorProfileResetPasswordData.currentPassword,
          password: this.state.vendorProfileResetPasswordData.newPassword
        }
      }
    };

    this.resetPasswordMessageId = await this.apiCall(configJSON.apiUpdateUserType, configJSON.resetPasswordApiEndPoint, bodyData);
  }

  handleVendorProfileVisibilityChange = (passType: string) => {
    if (passType === "currentPassword") {
      this.setState({ vendorProfileResetPasswordVisibility: { ...this.state.vendorProfileResetPasswordVisibility, currentPassword: !this.state.vendorProfileResetPasswordVisibility.currentPassword } });
    } else if (passType === "newPassword") {
      this.setState({ vendorProfileResetPasswordVisibility: { ...this.state.vendorProfileResetPasswordVisibility, newPassword: !this.state.vendorProfileResetPasswordVisibility.newPassword } });
    } else if (passType === "confirmNewPassword") {
      this.setState({ vendorProfileResetPasswordVisibility: { ...this.state.vendorProfileResetPasswordVisibility, confirmNewPassword: !this.state.vendorProfileResetPasswordVisibility.confirmNewPassword } });
    }
  }
  // Customizable Area End
}
