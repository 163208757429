import React from "react";

import {
  Grid,
  Typography,
  Box,
  // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import ProjectMemberListController, { Props } from "./ProjectMemberListController";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import "./fullVisibility.css";
import ProjectMemberLayout from "./ProjectMemberLayout.web";
import CustomModal from "../../../components/src/CustomModal";
import { configJSON } from "./FullTeamVisibilityController";
import Pagination from "@material-ui/lab/Pagination";
import { dummyImage } from "../../../components/src/assets";
const images = require("./assets");
// Customizable Area End

export default class ProjectMemberList extends ProjectMemberListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderProjectMemberListTopRightContent() {
    return <>
      <Box style={webStyle.infoBoxLayout} onClick={this.handleModelOpen}>
        <InfoOutlinedIcon htmlColor="#3397DB" style={{ width: "24px", height: "24px" }} />
      </Box>
    </>
  }

  renderProjectMemberListMainContent() {
    return <>
      <Grid container spacing={3} style={{ marginTop: "12px" }}>
        {
          this.state.projectMemberList.length ? this.state.projectMemberList.map((project: any, index: number) => {
            return <Grid item xs={12} sm={6} md={4} key={`${project.id}_projectmemberlist`}>
              <Box style={webStyle.listBox as React.CSSProperties}>
                <Box style={{ display: "flex" }}>
                  <img src={project.attributes.image || dummyImage} style={webStyle.imageStyle} />
                  <Box>
                    <Typography style={webStyle.projectName} title={project.attributes.project_name}>{project.attributes.project_name}</Typography>
                    <Typography style={webStyle.membersCount}><img src={images.groupIcon} style={webStyle.groupIconStyle} /> {project.attributes.vendors_count} Members</Typography>
                  </Box>
                </Box>
                <Box>
                  <KeyboardArrowRightIcon onClick={() => this.handleArrowRightClick(project.id, project.attributes.project_name)} htmlColor="#6D89AF" style={webStyle.rightArrow} />
                </Box>
              </Box>
            </Grid>
          })
            : <Typography style={{ width: '100%', textAlign: 'center', marginTop: '30px', marginBottom: '30px', color: '#6d89af', fontFamily: 'Urbanist', fontSize: 14, fontWeight: 600 }}>No record available</Typography>
        }
      </Grid>
      {this.state.totalCount ?
        (<Grid container spacing={2}>
          <Grid item xs={12}>
            <Pagination style={{ marginTop: "30px", justifyContent: "center", display: "flex" }} data-test-id="pagination" count={Math.ceil(this.state.totalCount / 9)} page={this.state.currentPage} onChange={this.handlePageChange} />
          </Grid>
        </Grid>)
        : null}
    </>
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <ProjectMemberLayout
          ref={this.layoutRef}
          data-test-id="ProjectMemberLayout"
          id={this.props.navigation}
          navigation={this.props.navigation}
          isLoading={this.state.isLoading}
          projectMemberLayoutTitle={this.projectMemberListTitle}
          projectMemberLayoutBreadCrumb={this.projectMemberListBreadCrumb}
          renderProjectMemberLayoutTopRightContent={() => this.renderProjectMemberListTopRightContent()}
          renderPageContent={() => this.renderProjectMemberListMainContent()}
        />
        <CustomModal title={configJSON.projectMemberInfoModelTitle} modelContent={configJSON.projectMemberInfoModelContent} modelButtonText={configJSON.projectMemberInfoModelButtonText} icon={<InfoOutlinedIcon htmlColor="#F5C42C" style={{ width: "36px", height: "36px" }} />} open={this.state.isModelOpen} handleClose={this.handleModelClose} handleButtonClick={this.handleModelClose} />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export const webStyle = {
  infoBoxLayout: {
    width: "46px",
    borderRadius: "14px",
    height: "46px",
    border: "1px solid #3397db",
    background: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  listBox: {
    height: "66px",
    borderRadius: "12px",
    border: "1px solid #e3eaf4",
    background: "#ffffff",
    padding: "12px 14px 12px 12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxSizing: "border-box",
  },
  imageStyle: {
    width: "42px",
    height: "42px",
    borderRadius: "10px",
    marginRight: "10px",
  },
  projectName: {
    lineHeight: "19px",
    color: "#17181d",
    fontFamily: "Urbanist",
    fontSize: "16px",
    fontWeight: 600,
    letterSpacing: "0.2px",
    paddingTop: "3px",
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap' as 'nowrap',
    maxWidth: '200px'
  },
  membersCount: {
    lineHeight: "15px",
    color: "#6d89af",
    fontFamily: "Urbanist",
    fontSize: "12px",
    fontWeight: 500,
    letterSpacing: "0.15px",
    alignItems: "center",
    paddingTop: "2px",
    display: "flex",
  },
  groupIconStyle: {
    width: "16px",
    height: "16px",
    paddingRight: "4px",
  },
  rightArrow: {
    width: "22px",
    height: "22px",
    cursor: "pointer",
  }
}
// Customizable Area End
