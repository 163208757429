import React, {createRef} from "react";
import {
    // Customizable Area Start
    TypographyProps,
    Container,
    Grid,
    Box,Typography, Link, Modal,
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import JobRoleRequestController, {
    Props,
  } from "../../../blocks/fullteamvisibility/src/JobRoleRequestController.web";
import Header from "../../../components/src/Header.web";
import SideBar from "../../../components/src/SideBar";
import Footer from "../../../components/src/Footer";
import Loader from "../../../components/src/Loader.web";
import {deleteIcon, editIcon, paymentIcon, peopleIcon , arrowicon, threeDots ,bagIcon} from "./assets"
import JobRoleRequestModel from "../../../components/src/JobRoleRequestModel";
import ProductionHouseInviteMemberDialog from "../../../components/src/ProductionHouseInviteMemberDialog";
// Customizable Area End

export default class JobRolesAndRequest extends JobRoleRequestController {
    stepperRef: React.RefObject<any>;
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.stepperRef = createRef();
        // Customizable Area End
    }
   renderJobRoleGrid(data:any){
    return(
        <Box className="tabsContentJobRole" style={webStyle.tabsContentJobRole as React.CSSProperties}  >

            <Grid container spacing={4}>
                {data.length ? (
                    data.map((item:any)=>(                    
                        <Grid xs={12} sm={12} md={this.state.isJobRoleRequestSideBarOpen ? 4 : 3} item style={webStyle.jobRoleReqWrapper}>
                        
                            <Box style={webStyle.jobRoleReqWrapperBox}>
                                <Box style={webStyle.jobRoleReqMoreInfo as React.CSSProperties}>
                                    <Box>
                                        <Typography variant="h6" style={webStyle.roleReqWrapperDegs}>Dept | {item.attributes.project_department}</Typography>
                                        <Typography variant="h5" style={webStyle.roleReqWrapperHead}>{item.attributes.role}</Typography>
                                    </Box>
                                    <Box>
                                        <img src={threeDots} alt="payment" style={webStyle.jobRoleReqDropdownIcon} data-test-id="editJobRolehamburger" onClick={(event)=>this.handleJobRoleEditAndDelete(item.id, event)}/>
                                        {this.state.open2 === item.id &&<Box style={webStyle.jobRoleReqDropdown as React.CSSProperties}>
                                        <Box style={webStyle.jobRoleReqDropdownList}>
                                            <img src={editIcon} alt="Edit"/>
                                            <Typography style={webStyle.jobRoleReqDropdownListText as React.CSSProperties} data-test-id="editJobRole" onClick={()=>this.editJobRoleRequest(item.id)}>Edit Job Role</Typography>
                                        </Box>
                                            <Box style={webStyle.jobRoleReqDropdownList}>
                                                <img src={deleteIcon} alt="Delete"/>
                                                <Typography style={webStyle.jobRoleReqDropdownListText as React.CSSProperties} data-test-id="deleteButtontest" onClick={()=>this.deleteJobRoleRequest(item.id)}>Delete Job Role</Typography>
                                            </Box>
                                        </Box>}
                                    </Box>
                                </Box>
                                <Box sx={webStyle.roleReqSection}>
                                    <Box style={{ width: '50%'}}>
                                        <Box style={webStyle.roleReqContent}>
                                            <img src={bagIcon} alt="bag" style={webStyle.roleReqContentIconBg}/>
                                            <Typography style={webStyle.roleReqContentPara}>{item.attributes.exp_required} Yrs</Typography>
                                        </Box>
                                        <Box style={webStyle.roleReqContent}>
                                            <img src={paymentIcon} alt="payment"/>
                                            <Typography style={webStyle.roleReqContentPara}>₹{item.attributes.budget_of_role}</Typography>
                                        </Box>
                                    </Box>
                                    <Box style={webStyle.roleReqContent}>
                                        <img src={peopleIcon} alt="payment"/>
                                        <Typography style={webStyle.roleReqContentPara}>{item.attributes.number_of_positions} Positions</Typography>
                                    </Box>
                                </Box>
                                <Box style={webStyle.jobRequestInviteVendor}>
                                    <div style={webStyle.addEmployeeBoxJobRole}>
                                        <Typography style={webStyle.jobRequestInviteText as React.CSSProperties} onClick={()=>this.handleInviteVendorModalJobRole(item.id)}>Invite Vendors</Typography>
                                        <div style={webStyle.dialogBg}>
                                            <ProductionHouseInviteMemberDialog
                                                memberList={this.state.vendorList}
                                                inviteMemberModal={this.state.inviteVendorModalJobRole}
                                                handleInviteMemberModal={this.handleInviteVendorModalJobRole}
                                                navigateToVendorProfiles={this.navigateToManageProfilesJobRole}
                                                handleSearchstate={this.handleSearchInviteVendor}
                                                isInviteVendor
                                                count={this.state.vendorInviteCount}
                                                page={this.state.currentpage}
                                                handlePageChange={this.handlePageChangeInviteVendor}
                                            />
                                        </div>
                                    </div>
                                    <Box style={webStyle.jobRequestHeadButton as React.CSSProperties} data-test-id="requestPageNav" onClick={()=>this.navigateToRequestPage(item.id)}>
                                        {item.attributes.vendor_job_requests_count} Requests
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    ))
                ): (
                <Typography
                    style={{
                    width: '100%',
                    textAlign: 'center',
                    marginTop: '30px',
                    marginBottom: '30px',
                    color: '#6d89af',
                    fontFamily: 'Urbanist',
                    fontSize: 14,
                    fontWeight: 600,
                    }}
                >
                    No record available
                </Typography>
                )} 
            </Grid>  
            {this.state.setEditJobRole && 
                                  <Modal
                                  open={!this.state.open}
                                  onClose={this.handleCloseJobRoleModel1}
                                  aria-describedby="simple-modal-description"
                                  aria-labelledby="simple-modal-title"
                              >
                               <div style={{
                                  top: '50%',
                                  position: 'absolute',                                              
                                  left: '50%',
                                  backgroundColor: 'white',
                                  borderRadius: '20px',
                                  transform: 'translate(-50%, -50%)',
                                  height: '95%',
                                  overflow: 'auto',
                                  }}>
                                  <JobRoleRequestModel handleClose={this.handleCloseJobRoleModel1} jobRoleProjectName={this.state.projectName} jobRoleProjectDept={this.state.projectDepartment} jobRoleProjectRole={this.state.projectRole} projectChangeHandle={this.handleProjectName} projectDeptChangeHandle={this.handleProjectdepartment} projectDeptRoleHandle={this.handleProjectDeptRole} projectSelectedValue={this?.state?.jobRoleInputs?.selectedProject} projectDeptSelectedValue={this?.state?.jobRoleInputs?.selectedProjectDept} projectDeptRoleSelectedValue={this?.state?.jobRoleInputs?.selectedProjectRole} roleReqSelectedValue={this.state.jobRoleInputs.roleRequirements} expReqSelectedValue={this.state.jobRoleInputs.expRequired} budgetSelectedValue={this.state.jobRoleInputs.roleBudget} noOfPositionSelectedValue={this.state.jobRoleInputs.noOfPos} roleDescriptionValue={this.state.jobRoleInputs.roleDescription} validateJobRole={this.state.validationStatusJobRole} addJobRoleSubmit={this.handleSubmitFormAddJobRole} selectedProjectVal={this.state.isProjectSelected} selectedDeptVal={this.state.isDepartmentSelected} selectedRoleVal={this.state.isRoleTypeSeleted} roleReqChange={this.handleChangeRoleRequirements} expReqChange={this.handleChangeExp} budgetRoleChange={this.handleChangeBudget} 
                                    noOfPosChange={this.handleChangePos} roleDescriptionChange={this.handleChangeRoleDes} 
                                    handleRoleError={this.state.roleReqError} hanldeExpError={this.state.expError} handleBudgetError={this.state.budgetError} 
                                    handlenoOfPosError={this.state.noOfPosError} handleRoleDesError={this.state.roleDesError} handleSetEditJobRole={this.state.setEditJobRole}/>
                              </div>
                      </Modal>}     
                            </Box>
    )
   }
    renderJobRoleAndRequest(isJobRoleideBarStatusPH: boolean  = this.state.isJobRoleRequestSideBarOpen) {
        return (
        <Grid style={webStyle.contentSetupJobRole}>
              <Loader loading={this.state.isLoading}></Loader>
            < Header
                navigate={this.props.navigation.navigate}
                sideBarStatus={isJobRoleideBarStatusPH}
                dropDownStatus={this.state.isJobRoleRequestHeaderDropdownOpenVal}
                handleDropdown={this.handleJobRoleHeaderDropdownVal}
                handleSideBar={this.handleJobRoleSideBar}  
            />
            <Grid style={webStyle.mainContentJobRole as React.CSSProperties}>
                <Box sx={webStyle.blockInfoJobRole}>
                    <Box sx={webStyle.headInfoJobRole}>
                        <Box>
                            <Box sx={webStyle.wrapBlockAllArrowJobRoles}>
                                <Box sx={webStyle.activeBlockAllArrowJobRoles}>
                                    <div data-test-id="job-role-page" onClick={()=> this.navigateToAllJobRolePage("JobRoleRequest")}> 
                                        <img src={arrowicon} alt="arrow icon" style={webStyle.wrapBlockAllArrowBack}/> 
                                    </div>
                                </Box>
                                <Box sx={webStyle.activeBlockJobRole}>
                                    <Typography variant="h3" style={webStyle.activeBlockJobRole as TypographyProps}><div style={{ fontSize: "15px" }}></div>Job Role Requests</Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Link style={webStyle.highlightedTextJobRole} data-test-id="job-role-breadcum-home" onClick={()=>this.navigateToAllJobRolePage("JobRoleRequest")}>
                                  Home 
                                 </Link> 
                                 <Link style={webStyle.highlightedTextJobRole} data-test-id="job-role-breadcum-jobRequest"  onClick={()=>this.navigateToAllJobRolePage("JobRoleRequest")}>
                                 / Job Role Requests
                                 </Link> 
                                <span style={{ color: "#6D89AF",fontWeight:600 ,fontSize: "14px"}}> / {this.state.jobRoleprojectName}</span>
                            </Box>
                        </Box>

                    </Box>
                </Box>
                <Box className="tabsContainer">
                      <Box sx={webStyle.headerSearchBox1_JobRole}>
                            { this.renderJobRoleGrid(this.state.projectJobRoleRequest)}
                    </Box>
                    </Box>
                    </Grid>
            <Footer />
        </Grid>
        )
    }
    
    render() {
        return ( 
            <Container maxWidth="xl" className="user_detail_container">
                {
                    this.state.isJobRoleRequestSideBarOpen?
                        <Grid container spacing={0}>
                            <Grid style={webStyle.sideBarJobRole}  item xs={3}>
                                <SideBar
                                   userData={{ age: 25 }}
                                    activeMenuItem={"Job Role Requests"}
                                    navigate={this.props.navigation.navigate}
                                    handleSideBar={this.handleJobRoleSideBar}
                                   
                                    />
                            </Grid>
                            <Grid item xs={9}>{this.renderJobRoleAndRequest(this.state.isJobRoleRequestSideBarOpen)}</Grid>
                        </Grid> :
                        <Grid item xs={12}>
                            {
                                this.renderJobRoleAndRequest(this.state.isJobRoleRequestSideBarOpen)
                            }
                           </Grid>
                }
            </Container >
        );  }
}

// Customizable Area Start
const webStyle = {
   
    contentSetupJobRole: {
        borderLeft: "1px solid #E3EAF4",
    },
    
    sideBarJobRole: {
        minHeight: "100vh",
        maxWidth: "23%",
    },
    blockInfoJobRole: {

    },
    blockPathJobRole: {
        fontSize: "14px",
        letterSpacing: "1px",
        color: "#6D89AF",
        marginBottom: "24px",
        fontWeight: 500
      },
      highlightedTextJobRole: {
        color: "#F5C42C",
        textDecoration: 'underline',
        textDecorationColor: "#F5C42C",
        fontWeight: 600,
        cursor:"pointer"
      },
    activeBlockJobRole: {
        fontSize: "28px",
        fontWeight: "600",
        letterSpacing: "1px",
        display: "flex",
        flexDirection: "row",
        lineHeight: "33px",
        alignItems: 'center',  
      },
     
      mainContentJobRole: {
        width: "100%",
        backgroundColor: "#F8FAFE",
        boxSizing: "border-box",
        padding: "24px",
        height: "100%"
      },
      tabsContentJobRole: {
        paddingTop: "16px",
        display: "flex",
        gap: "1.5rem",
        flexWrap:"wrap",
        marginBottom: '15px',
        width: '100%',
    },
    headInfoJobRole: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 15,
    },
    jobRequestHeadButton: {
        minWidth: '161px',
        borderRadius: '10px',
        background: '#17181d',
        color: '#fff',
        fontFamily: 'urbanist',
        fontSize: '12px',
        fontWeight: 'bold',
        letterSpacing: ' 0.25px',
        textAlign: 'center',
        padding: '11px 10px',   
        cursor: 'pointer', 
    },
    jobRoleReqWrapper: {
    },
    jobRoleReqWrapperBox: {
        border: '1px solid  #E3EAF4',
        borderRadius: '12px',
        padding: '12px',
    },
    roleReqWrapperDegs: {
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '13px',
        fontWeight: 500,
    },
    roleReqWrapperHead: {
        fontSize: '16px',
        fontWeight: 600,
        color: '#17181d',
        fontFamily: 'Urbanist',
    },
    roleReqSection: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        gap: '8px',
    },
    roleReqContent: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        padding: '3px 0',
        color: '#6d89af',
    },
    roleReqContentPara: {
        fontFamily: 'Urbanist',
        fontSize: '13px',
        fontWeight: 600,
        lineHeight: '16px',
    },
    jobRequestInviteVendor: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '8px',
        borderTop: '1px solid #E3EAF4',
        margin: '8px 0 0',
        padding: '10px 0 0',
    },
    jobRequestInviteText: {
        width: '50%',
        textAlign: 'center',
        color: '#3389FF',
        fontWeight: 'bold',
        fontSize: '13px',
        cursor: 'pointer',
    },
    jobRoleReqMoreInfo: {
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        gap: 10,
        paddingBottom: 2,
    },
    jobRoleReqDropdown: {
        display: 'flex',
        flexDirection: 'column',
        gap: '18px',
        position: 'absolute',
        right: 0,
        minWidth: '170px',
        padding: '16px 18px',
        borderRadius: '10px',
        border: '0.5px solid #e3eaf4',
        background: '#ffffff',
        boxShadow: '0px 5px 12px rgba(23,24,29,0.07)',
    },
    jobRoleReqDropdownList: {
        display: 'flex',
        alignItems: 'flex-start',
        gap: '10px',
    },
    jobRoleReqDropdownIcon: {
        cursor: 'pointer',
    },
    jobRoleReqDropdownListText: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#17181d',
        cursor: 'pointer',
    },
    mt24JobRole: {
        marginTop: "24px"
    },
    btJobRole:{
        borderBottom: "1px solid #E3EAF4",
    },
    tabsJobRole:{
        backgroundColor: "#F5C42C",
        marginTop:"24px",
    },
    tabJobRole: {
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "24px",
        letterSpacing: "0.246px",
        textTransform:"none" as const
    },
    headerSearchBox1_JobRole: {
        display: "flex",
        justifyContent: "start",
        align: "end",
        flexDirection: "row",
        flexWrap: "wrap",
        paddingTop: '24px',
    },
    activeBlockAllArrowJobRoles: {
        backgroundColor:' #fff',
        border: '1px solid #ddd',
        borderRadius: '50%',
        padding: '10px',   
        cursor: 'pointer',
    },
    wrapBlockAllArrowJobRoles: {
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        gap: 10,
        marginBottom: '5px'
    },
    wrapBlockAllArrowBack: {
        height: '16px'
    },
    roleReqContentIconBg: {
        height: '16px',
    },
    addEmployeeBoxJobRole:{
        display: "flex",
        justifyContent: "center",
        width: "50%",
        alignItems: "center",
    },
    dialogBg: {
        backgroundColor: 'red'
    }
};
// Customizable Area End