// App.js - WEB
import React, { Component } from "react";
import firebase from 'firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import UserDetail from "../../blocks/fullteamvisibility/src/UserDetail.web";
import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import HomeScreen from '../../components/src/HomeScreen';
import Tabletsupport63 from "../../blocks/tabletsupport63/src/Tabletsupport63";
import Cftallyintegration2 from "../../blocks/cftallyintegration2/src/Cftallyintegration2";
import Matchalgorithm2 from "../../blocks/matchalgorithm2/src/Matchalgorithm2";
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import Productdescription3 from "../../blocks/productdescription3/src/Productdescription3";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import ProductionHouseSignUp from "../../blocks/mobile-account-registration/src/ProductionHouseSignUp";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Customform3 from "../../blocks/customform3/src/Customform3";
import Customizableformworkflow3 from "../../blocks/customizableformworkflow3/src/Customizableformworkflow3";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Contentmanagement3 from "../../blocks/contentmanagement3/src/Contentmanagement3";
import TermsAndCondition from "../../blocks/contentmanagement3/src/TermsAndCondition.web";
import HelpCenter from "../../blocks/contentmanagement3/src/HelpCenter.web";
import Appointments from "../../blocks/appointmentmanagement/src/Appointments";
import AddAppointment from "../../blocks/appointmentmanagement/src/AddAppointment";
import Pushnotificationintegration3 from "../../blocks/pushnotificationintegration3/src/Pushnotificationintegration3";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Notifications from "../../blocks/notifications/src/Notifications";
import Download from "../../blocks/download/src/Download";
import Location from "../../blocks/location/src/Location.web";
import WorkTravellingList from "../../blocks/location/src/WorkTravellingList.web";
import MapTravelling from "../../blocks/location/src/MapTravelling.web";
import FullTeamVisibility from "../../blocks/fullteamvisibility/src/FullTeamVisibility";
import ProjectPortfolio from "../../blocks/projectportfolio/src/ProjectPortfolio";
import AllProductionHouses from "../../blocks/fullteamvisibility/src/AllProductionHouses"
import AllProjectView from "../../blocks/fullteamvisibility/src/AllProjectView.web";
import AllStudioView from "../../blocks/fullteamvisibility/src/AllStudioView.web";
import ProductionHouseDetail from "../../blocks/fullteamvisibility/src/ProductionHouseDetail";
import ProjectMemberList from "../../blocks/fullteamvisibility/src/ProjectMemberList";
import ProjectMembersJobRole from "../../blocks/fullteamvisibility/src/ProjectMembersJobRole";
import Formapprovalworkflow from "../../blocks/formapprovalworkflow/src/Formapprovalworkflow";
import Subscriptionbilling2 from "../../blocks/subscriptionbilling2/src/Subscriptionbilling2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import UserSettings from "../../blocks/user-profile-basic/src/UserSettings.web"
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import Sorting from "../../blocks/sorting/src/Sorting";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import Automaticreminders from "../../blocks/automaticreminders/src/Automaticreminders";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword.web";
import Receiptcustomisation from "../../blocks/receiptcustomisation/src/Receiptcustomisation";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import ProjectDetailPH from "../../blocks/dashboard/src/ProjectDetailPH";
import ProjectDetailJobRoleandRequest from "../../blocks/dashboard/src/ProjectDetailJobRoleandRequest";
import Timetrackingbilling from "../../blocks/timetrackingbilling/src/Timetrackingbilling";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
// import InvoiceBilling from "../../blocks/invoicebilling/src/InvoiceBilling";
import Forecastingandbudgeting from "../../blocks/forecastingandbudgeting/src/Forecastingandbudgeting";
import ManageBalanceSheet from "../../blocks/forecastingandbudgeting/src/ManageBalanceSheet";
import ViewBalanceSheet from "../../blocks/forecastingandbudgeting/src/ViewBalanceSheet";
import Cvresumecandidatemanagement from "../../blocks/cvresumecandidatemanagement/src/Cvresumecandidatemanagement";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import Joblisting from "../../blocks/joblisting/src/Joblisting";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import Analytics from "../../blocks/analytics/src/Analytics";
import VendorAnalytics from "../../blocks/analytics/src/VendorAnalytics.web";
import VendorDetailedAnalytics from "../../blocks/analytics/src/VendorDetailedAnalytics.web";
import Expensetracking from "../../blocks/expensetracking/src/Expensetracking";
import InventoryManagement from "../../blocks/inventorymanagement/src/InventoryManagement";
import ReviewApprovalAdmin from "../../blocks/reviewandapproval/src/ReviewApprovalAdmin";
import ReviewApprovalBasicUser from "../../blocks/reviewandapproval/src/ReviewApprovalBasicUser";
import OtpScreen from "../../blocks/forgot-password/src/OtpScreen.web";
import CreateEditProjectPortfolio from "../../blocks/projectportfolio/src/CreateEditProjectPortfolio.web"
import ProjectPortfolioDetail from "../../blocks/projectportfolio/src/ProjectPortfolioDetail.web"
import UserDetailCommon from "../../blocks/fullteamvisibility/src/UserDetailCommon.web";
import UserStudioDetail from "../../blocks/fullteamvisibility/src/UserStudioDetail.web";
import UserProjectDetail from "../../blocks/fullteamvisibility/src/UserProjectDetail.web"
import JobProfile from "../../blocks/fullteamvisibility/src/JobProfile.web"
import UserJobdetail from "../../blocks/fullteamvisibility/src/UserJobdetail.web"
import StudiosBookingList from "../../blocks/user-profile-basic/src/StudiosBookingList.web"
import AllStudioBookingListPH from "../../blocks/user-profile-basic/src/AllStudioBookingListPH.web"
import AllStudioDetailPH from "../../blocks/user-profile-basic/src/AllStudioDetailPH.web"
import StudioDetailPH from "../../blocks/user-profile-basic/src/StudioDetailPH.web"
import RequestProfileDetailPH from "../../blocks/user-profile-basic/src/RequestProfileDetailPH.web"
import VendorManageProfile from "../../blocks/user-profile-basic/src/VendorManageProfile.web"
import ProductionHouseManageProfile from "../../blocks/user-profile-basic/src/ProductionHouseManageProfile.web"
import ProductionHouseProfileSettings from "../../blocks/user-profile-basic/src/ProductionHouseProfileSettings.web"
import VendorProfileSettings from "../../blocks/user-profile-basic/src/VendorProfileSettings.web"
import StudiosBookingDetail from "../../blocks/user-profile-basic/src/StudiosBookingDetail.web"
import UserMyJobDetail from "../../blocks/fullteamvisibility/src/UserMyJobDetail.web";
import VendorJobPHDetails from "../../blocks/fullteamvisibility/src/VendorJobPHDetails.web"
import UserAppliedJobDetail from "../../blocks/fullteamvisibility/src/UserAppliedJobDetail.web"
import TravellingList from "../../blocks/fullteamvisibility/src/TravellingList.web"
import InvoiceList from "../../blocks/fullteamvisibility/src/InvoiceList.web"
import UserinvoiceDetail from "../../blocks/fullteamvisibility/src/UserinvoiceDetail.web"
import InvoiceListing from "../../blocks/tasks/src/InvoiceListing.web";
import HistoryList from "../../blocks/tasks/src/HistoryList.web";
import InvoiceListingDetail from "../../blocks/tasks/src/InvoiceListingDetail.web";
import ProductionHouseProjects from "../../blocks/dashboard/src/ProductionHouseDashboard.web";
import AllInvoiceRequests from "../../blocks/invoicebilling/src/AllInvoiceRequests.web"
import InvoiceBilling from "../../blocks/invoicebilling/src/InvoiceBilling.web";
import InvoiceDetailPH from "../../blocks/invoicebilling/src/InvoiceDetailPH.web"
import BalanceSheet from "../../blocks/forecastingandbudgeting/src/BalanceSheet.web"
import JobRoleRequest from "../../blocks/fullteamvisibility/src/JobRoleRequest.web"
import JobRolesAndRequest from "../../blocks/fullteamvisibility/src/JobRolesAndRequest.web"
import AddProjects from "../../blocks/landingpage/src/AddNewProject.web"
import Request from "../..//blocks/fullteamvisibility/src/Request.web"
import RequestProfile from "../../blocks/fullteamvisibility/src/RequestProfile.web"
import ProjectDetailRequest from "../../blocks/dashboard/src/ProjectDetailRequest.web";
import RequestProfileJobRoleProjectPH from "../../blocks/dashboard/src/RequestProfileJobRoleProjectPH"
import AllEmployeeListPH from "../../blocks/dashboard/src/AllEmployeeListPH"
import AllDepartmentListPH from "../../blocks/dashboard/src/AllDepartmentListPH"
import ManageProfileInviteVendor from "../../blocks/fullteamvisibility/src/ManageProfileInviteVendor.web"
import ProjectMemberProjectVendorProfile from "../../blocks/fullteamvisibility/src/ProjectMemberProjectVendorProfile.web"
import PersonalDetailInviteVendor from "../../blocks/landingpage/src/PersonalDetailInviteVendor.web"
import InviteVendorListPD from "../../blocks/dashboard/src/InviteVendorListPD.web"
import InviteVendorProfilePD from "../../blocks/dashboard/src/InviteVendorProfilePD"

const routeMap = {

  Tabletsupport63: {
    component: Tabletsupport63,
    path: "/Tabletsupport63"
  },
  Cftallyintegration2: {
    component: Cftallyintegration2,
    path: "/Cftallyintegration2"
  },
  Matchalgorithm2: {
    component: Matchalgorithm2,
    path: "/Matchalgorithm2"
  },
  Tasks: {
    component: Tasks,
    path: "/Tasks"
  },
  InvoiceListing: {
    component: InvoiceListing,
    path: "/InvoiceListing"
  },
  HistoryList: {
    component: HistoryList,
    path: "/HistoryList"
  },
  InvoiceListingDetail: {
    component: InvoiceListingDetail,
    path: "/InvoiceListingDetail"
  },
  TaskList: {
    component: TaskList,
    path: "/TaskList"
  },
  Task: {
    component: Task,
    path: "/Task"
  },
  Productdescription3: {
    component: Productdescription3,
    path: "/Productdescription3"
  },
  OrderManagement: {
    component: OrderManagement,
    path: "/OrderManagement"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: "/EducationalUserProfile"
  },
  UserDetailCommon: {
    component: UserDetailCommon,
    path: "/UserDetailCommon"
  },
  InvoiceList: {
    component: InvoiceList,
    path: "/InvoiceList"
  },
  UserinvoiceDetail: {
    component: UserinvoiceDetail, path: "/UserinvoiceDetail"
  },
  InvoiceDetailPH: {
    component: InvoiceDetailPH,
    path: "/InvoiceDetailPH"
  },
  UserProjectDetail: {
    component: UserProjectDetail,
    path: "/UserProjectData"
  },
  UserJobdetail: {
    component: UserJobdetail,
    path: "/UserJobdetail"
  },
  StudiosBookingList: {
    component: StudiosBookingList,
    path: "/StudiosBookingList"
  },
  AllStudioBookingListPH:{
  component:AllStudioBookingListPH,
  path:"/AllStudioBookingListPH"
  },
  AllStudioDetailPH:{
    component:AllStudioDetailPH,
    path:"/AllStudioDetailPH"
    },
    StudioDetailPH:{
      component:StudioDetailPH,
      path:"/StudioDetailPH"
      },
  RequestProfileDetailPH:{
   component:RequestProfileDetailPH,
   path:"/RequestProfileDetailPH"
  },
  VendorManageProfile: {
    component: VendorManageProfile,
    path: "/VendorManageProfile"
  },
  ProductionHouseManageProfile:{
    component: ProductionHouseManageProfile,
    path: "/ProductionHouseManageProfile"
  },
  ProductionHouseProfileSettings:{
    component: ProductionHouseProfileSettings,
    path: "/ProductionHouseProfileSettings"
  },
  VendorProfileSettings: {
    component: VendorProfileSettings,
    path: "/VendorProfileSettings"
  },
  UserMyJobDetail: {
    component: UserMyJobDetail,
    path: "/UserMyJobDetail"
  },
  VendorJobPHDetails: {
    component: VendorJobPHDetails,
    path: "/VendorJobPHDetails"
  },
  UserAppliedJobDetail: {
    component: UserAppliedJobDetail,
    path: "/UserAppliedJobDetail"
  },
  TravellingList: {
    component: TravellingList,
    path: "/TravellingList"
  },
  JobProfile: {
    component: JobProfile,
    path: "/JobProfile"
  },
  JobRoleRequest: {
    component : JobRoleRequest,
    path: "/JobRoleRequest"
  },
  JobRolesAndRequest:{
    component : JobRolesAndRequest,
    path : "/JobRolesAndRequest"
  },
  ManageProfileInviteVendor:{
    component : ManageProfileInviteVendor,
    path : "/ManageProfileInviteVendor"
  },
  ProjectMemberProjectVendorProfile:{
    component : ProjectMemberProjectVendorProfile,
    path : "/ProjectMemberProjectVendorProfile"
  },
  Request:{
    component: Request,
    path : "/Request"
  },
  RequestProfile:{
    component:RequestProfile,
    path:"/RequestProfile"
  },
  BalanceSheet: {
    component: BalanceSheet,
    path: "/BalanceSheet"
  },
  UserDetail: {
    component: UserDetail,
    path: "/UserDetail"
  },
  Customform3: {
    component: Customform3,
    path: "/Customform3"
  },
  Customizableformworkflow3: {
    component: Customizableformworkflow3,
    path: "/Customizableformworkflow3"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  Appointments: {
    component: Appointments,
    path: "/Appointments"
  },
  AddAppointment: {
    component: AddAppointment,
    path: "/AddAppointment"
  },
  Pushnotificationintegration3: {
    component: Pushnotificationintegration3,
    path: "/Pushnotificationintegration3"
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  Notifications: {
    component: Notifications,
    path: "/Notifications"
  },
  Download: {
    component: Download,
    path: "/Download"
  },
  OtpScreen: {
    component: OtpScreen,
    path: "/OtpScreen"
  },
  FullTeamVisibility: {
    component: FullTeamVisibility,
    path: "/AllVendorView"
  },
  AllProductionHouses: {
    component: AllProductionHouses,
    path: "/AllProductionHouses",
  },
  AllStudioView: {
    component: AllStudioView,
    path: "/AllStudioView",
  },
  AllProjectView: {
    component: AllProjectView,
    path: "/AllProjectView"
  },
  Formapprovalworkflow: {
    component: Formapprovalworkflow,
    path: "/Formapprovalworkflow"
  },
  Subscriptionbilling2: {
    component: Subscriptionbilling2,
    path: "/Subscriptionbilling2"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: "/UserProfileBasicBlock"
  },
  UserSettings: {
    component: UserSettings,
    path: "/UserSettings"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  AccountGroups: {
    component: AccountGroups,
    path: "/AccountGroups"
  },
  Scheduling: {
    component: Scheduling,
    path: "/Scheduling"
  },
  Rolesandpermissions: {
    component: Rolesandpermissions,
    path: "/Rolesandpermissions"
  },
  Sorting: {
    component: Sorting,
    path: "/Sorting"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  RequestManagement: {
    component: RequestManagement,
    path: "/RequestManagement"
  },
  Reviews: {
    component: Reviews,
    path: "/Reviews"
  },
  AddReview: {
    component: AddReview,
    path: "/AddReview"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },
  Automaticreminders: {
    component: Automaticreminders,
    path: "/Automaticreminders"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  Receiptcustomisation: {
    component: Receiptcustomisation,
    path: "/Receiptcustomisation"
  },
  Dashboard: {
    component: Dashboard,
    path: "/Dashboard"
  },
  ProjectDetailPH: {
    component: ProjectDetailPH,
    path: "/ProjectDetailPH"
  },
  ProjectDetailJobRoleandRequest: {
    component: ProjectDetailJobRoleandRequest,
    path: "/ProjectDetailJobRoleandRequest"
  },
  Timetrackingbilling: {
    component: Timetrackingbilling,
    path: "/Timetrackingbilling"
  },
  ImportExportData: {
    component: ImportExportData,
    path: "/ImportExportData"
  },
  // InvoiceBilling: {
  //   component: InvoiceBilling,
  //   path: "/InvoiceBilling"
  // },
  Forecastingandbudgeting: {
    component: Forecastingandbudgeting,
    path: "/Forecastingandbudgeting"
  },
  InvoiceBilling: {
    component: InvoiceBilling,
    path: "/InvoiceBilling"
  },
  Cvresumecandidatemanagement: {
    component: Cvresumecandidatemanagement,
    path: "/Cvresumecandidatemanagement"
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: "/MobileAccountLoginBlock"
  },
  Uploadmedia3: {
    component: Uploadmedia3,
    path: "/Uploadmedia3"
  },
  LandingPage: {
    component: LandingPage,
    path: "/LandingPage"
  },
  Joblisting: {
    component: Joblisting,
    path: "/Joblisting"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  Expensetracking: {
    component: Expensetracking,
    path: "/Expensetracking"
  },
  InventoryManagement: {
    component: InventoryManagement,
    path: "/InventoryManagement"
  },
  ReviewApprovalAdmin: {
    component: ReviewApprovalAdmin,
    path: "/ReviewApprovalAdmin"
  },
  ReviewApprovalBasicUser: {
    component: ReviewApprovalBasicUser,
    path: "/ReviewApprovalBasicUser"
  },
  LandingPage: {
    component: LandingPage,
  },

  Tabletsupport63: {
    component: Tabletsupport63,
    path: "/Tabletsupport63"
  },
  Cftallyintegration2: {
    component: Cftallyintegration2,
    path: "/Cftallyintegration2"
  },
  Matchalgorithm2: {
    component: Matchalgorithm2,
    path: "/Matchalgorithm2"
  },
  Tasks: {
    component: Tasks,
    path: "/Tasks"
  },
  TaskList: {
    component: TaskList,
    path: "/TaskList"
  },
  Task: {
    component: Task,
    path: "/Task"
  },
  Productdescription3: {
    component: Productdescription3,
    path: "/Productdescription3"
  },
  OrderManagement: {
    component: OrderManagement,
    path: "/OrderManagement"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: "/EducationalUserProfile"
  },
  UserDetailCommon: {
    component: UserDetailCommon,
    path: "/UserDetailCommon"
  },
  InvoiceList: {
    component: InvoiceList,
    path: "/InvoiceList"
  },
  UserinvoiceDetail: {
    component: UserinvoiceDetail,
    path: "/UserinvoiceDetail"
  },
  UserProjectDetail: {
    component: UserProjectDetail,
    path: "/UserProjectData"
  },
  UserJobdetail: {
    component: UserJobdetail,
    path: "/UserJobdetail"
  },
  StudiosBookingDetail: {
    component: StudiosBookingDetail,
    path: "/StudiosBookingDetail"
  },
  UserAppliedJobDetail: {
    component: UserAppliedJobDetail,
    path: "/UserAppliedJobDetail"
  },
  TravellingList: {
    component: TravellingList,
    path: "/TravellingList"
  },
  JobProfile: {
    component: JobProfile,
    path: "/JobProfile"
  },
  UserDetail: {
    component: UserDetail,
    path: "/UserDetail"
  },
  Customform3: {
    component: Customform3,
    path: "/Customform3"
  },
  Customizableformworkflow3: {
    component: Customizableformworkflow3,
    path: "/Customizableformworkflow3"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  Contentmanagement3: {
    component: Contentmanagement3,
    path: "/Contentmanagement3"
  },
  TermsAndCondition: {
    component: TermsAndCondition,
    path: "/TermsAndCondition"
  },
  HelpCenter: {
    component: HelpCenter,
    path: "/HelpCenter"
  },
  Appointments: {
    component: Appointments,
    path: "/Appointments"
  },
  AddAppointment: {
    component: AddAppointment,
    path: "/AddAppointment"
  },
  Pushnotificationintegration3: {
    component: Pushnotificationintegration3,
    path: "/Pushnotificationintegration3"
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  Notifications: {
    component: Notifications,
    path: "/Notifications"
  },
  Download: {
    component: Download,
    path: "/Download"
  },
  OtpScreen: {
    component: OtpScreen,
    path: "/OtpScreen"
  },
  FullTeamVisibility: {
    component: FullTeamVisibility,
    path: "/AllVendorView"
  },
  AllProductionHouses: {
    component: AllProductionHouses,
    path: "/AllProductionHouses",
  },
  AllStudioView: {
    component: AllStudioView,
    path: "/AllStudioView",
  },
  AllProjectView: {
    component: AllProjectView,
    path: "/AllProjectView"
  },
  Formapprovalworkflow: {
    component: Formapprovalworkflow,
    path: "/Formapprovalworkflow"
  },
  Subscriptionbilling2: {
    component: Subscriptionbilling2,
    path: "/Subscriptionbilling2"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: "/UserProfileBasicBlock"
  },
  UserSettings: {
    component: UserSettings,
    path: "/UserSettings"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  AccountGroups: {
    component: AccountGroups,
    path: "/AccountGroups"
  },
  Scheduling: {
    component: Scheduling,
    path: "/Scheduling"
  },
  Rolesandpermissions: {
    component: Rolesandpermissions,
    path: "/Rolesandpermissions"
  },
  Sorting: {
    component: Sorting,
    path: "/Sorting"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  RequestManagement: {
    component: RequestManagement,
    path: "/RequestManagement"
  },
  Reviews: {
    component: Reviews,
    path: "/Reviews"
  },
  AddReview: {
    component: AddReview,
    path: "/AddReview"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },
  Automaticreminders: {
    component: Automaticreminders,
    path: "/Automaticreminders"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  Receiptcustomisation: {
    component: Receiptcustomisation,
    path: "/Receiptcustomisation"
  },
  Dashboard: {
    component: Dashboard,
    path: "/Dashboard"
  },
  Timetrackingbilling: {
    component: Timetrackingbilling,
    path: "/Timetrackingbilling"
  },
  ImportExportData: {
    component: ImportExportData,
    path: "/ImportExportData"
  },
  // InvoiceBilling: {
  //   component: InvoiceBilling,
  //   path: "/InvoiceBilling"
  // },
  Forecastingandbudgeting: {
    component: Forecastingandbudgeting,
    path: "/Forecastingandbudgeting"
  },
  InvoiceBilling: {
    component: InvoiceBilling,
    path: "/InvoiceBilling"
  },
  Cvresumecandidatemanagement: {
    component: Cvresumecandidatemanagement,
    path: "/Cvresumecandidatemanagement"
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: "/MobileAccountLoginBlock"
  },
  Uploadmedia3: {
    component: Uploadmedia3,
    path: "/Uploadmedia3"
  },
  LandingPage: {
    component: LandingPage,
    path: "/LandingPage"
  },
  Joblisting: {
    component: Joblisting,
    path: "/Joblisting"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration",
    protected: false
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  Expensetracking: {
    component: Expensetracking,
    path: "/Expensetracking"
  },
  InventoryManagement: {
    component: InventoryManagement,
    path: "/InventoryManagement"
  },
  ReviewApprovalAdmin: {
    component: ReviewApprovalAdmin,
    path: "/ReviewApprovalAdmin"
  },
  ReviewApprovalBasicUser: {
    component: ReviewApprovalBasicUser,
    path: "/ReviewApprovalBasicUser"
  },
  LandingPage: {
    component: LandingPage,
  },
  Tabletsupport63: {
    component: Tabletsupport63,
    path: "/Tabletsupport63"
  },
  Cftallyintegration2: {
    component: Cftallyintegration2,
    path: "/Cftallyintegration2"
  },

  Matchalgorithm2: {
    component: Matchalgorithm2,
    path: "/Matchalgorithm2"
  },
  Tasks: {
    component: Tasks,
    path: "/Tasks"
  },
  TaskList: {
    component: TaskList,
    path: "/TaskList"
  },
  Task: {
    component: Task,
    path: "/Task"
  },
  Productdescription3: {
    component: Productdescription3,
    path: "/Productdescription3"
  },
  OrderManagement: {
    component: OrderManagement,
    path: "/OrderManagement"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  ProductionHouseSignUp: {
    component: ProductionHouseSignUp,
    path: "/ProductionHouseSignUp",
    protected:false,
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: "/EducationalUserProfile"
  },
  UserDetailCommon: {
    component: UserDetailCommon,
    path: "/UserDetailCommon"
  },
  UserStudioDetail: {
    component: UserStudioDetail,
    path: "/UserStudioDetail"
  },
  UserDetail: {
    component: UserDetail,
    path: "/UserDetail"
  },
  Customform3: {
    component: Customform3,
    path: "/Customform3"
  },
  Customizableformworkflow3: {
    component: Customizableformworkflow3,
    path: "/Customizableformworkflow3"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  Appointments: {
    component: Appointments,
    path: "/Appointments"
  },
  AddAppointment: {
    component: AddAppointment,
    path: "/AddAppointment"
  },
  Pushnotificationintegration3: {
    component: Pushnotificationintegration3,
    path: "/Pushnotificationintegration3"
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  Notifications: {
    component: Notifications,
    path: "/Notifications"
  },
  Download: {
    component: Download,
    path: "/Download"
  },
  OtpScreen: {
    component: OtpScreen,
    path: "/OtpScreen"
  },
  Location: {
    component: Location,
    path: "/Location"
  },
  WorkTravellingList: {
    component: WorkTravellingList,
    path: "/WorkTravellingList"
  },
  MapTravelling: {
    component: MapTravelling,
    path: "/MapTravelling"
  },
  FullTeamVisibility: {
    component: FullTeamVisibility,
    path: "/AllVendorList"
  },
  ProjectPortfolio: {
    component: ProjectPortfolio,
    path: "/ProjectPortfolio"
  },
  AllProductionHouses: {
    component: AllProductionHouses,
    path: "/AllProductionList",
  },
  AllStudioView: {
    component: AllStudioView,
    path: "/AllStudioList",
  },
  AllProjectView: {
    component: AllProjectView,
    path: "/AllProjectList"
  },
  ProductionHouseDetail: {
    component: ProductionHouseDetail,
    path: "/ProductionHouseDetail",
  },
  ProjectMemberList: {
    component: ProjectMemberList,
    path: "/ProjectMemberList",
  },
  AllEmployeeListPH:{
    component:AllEmployeeListPH,
    path:"/AllEmployeeListPH"
  },
  AllDepartmentListPH:{
    component:AllDepartmentListPH,
    path:"/AllDepartmentListPH"
  },
  ProjectMembersJobRole: {
    component: ProjectMembersJobRole,
    path: "/ProjectMembersJobRole",
  },
  Formapprovalworkflow: {
    component: Formapprovalworkflow,
    path: "/Formapprovalworkflow"
  },
  Subscriptionbilling2: {
    component: Subscriptionbilling2,
    path: "/Subscriptionbilling2"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: "/UserProfileBasicBlock"
  },
  UserSettings: {
    component: UserSettings,
    path: "/UserSettings"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  AccountGroups: {
    component: AccountGroups,
    path: "/BlockProfiles"
  },
  Scheduling: {
    component: Scheduling,
    path: "/Scheduling"
  },
  Rolesandpermissions: {
    component: Rolesandpermissions,
    path: "/Rolesandpermissions"
  },
  Sorting: {
    component: Sorting,
    path: "/Sorting"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  RequestManagement: {
    component: RequestManagement,
    path: "/RequestManagement"
  },
  Reviews: {
    component: Reviews,
    path: "/Reviews"
  },
  AddReview: {
    component: AddReview,
    path: "/AddReview"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },
  Automaticreminders: {
    component: Automaticreminders,
    path: "/Automaticreminders"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword",
    protected:false
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP",
    protected:false
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword",
    protected:false
  },
  Receiptcustomisation: {
    component: Receiptcustomisation,
    path: "/Receiptcustomisation"
  },
  Dashboard: {
    component: Dashboard,
    path: "/Dashboard"
  },
  Timetrackingbilling: {
    component: Timetrackingbilling,
    path: "/Timetrackingbilling"
  },
  ImportExportData: {
    component: ImportExportData,
    path: "/ImportExportData"
  },
  // InvoiceBilling: {
  //   component: InvoiceBilling,
  //   path: "/InvoiceBilling"
  // },
  Forecastingandbudgeting: {
    component: Forecastingandbudgeting,
    path: "/Forecastingandbudgeting"
  },
  ManageBalanceSheet: {
    component: ManageBalanceSheet,
    path: "/ManageBalanceSheet"
  },
  ViewBalanceSheet: {
    component: ViewBalanceSheet,
    path: "/ViewBalanceSheet"
  },
  Cvresumecandidatemanagement: {
    component: Cvresumecandidatemanagement,
    path: "/Cvresumecandidatemanagement"
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: "/MobileAccountLoginBlock"
  },
  Uploadmedia3: {
    component: Uploadmedia3,
    path: "/Uploadmedia3"
  },
  LandingPage: {
    component: LandingPage,
    path: "/LandingPage"
  },
  Joblisting: {
    component: Joblisting,
    path: "/Joblisting"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  VendorAnalytics: {
    component: VendorAnalytics,
    path: "/VendorAnalytics"
  },
  VendorDetailedAnalytics: {
    component: VendorDetailedAnalytics,
    path: "/VendorDetailedAnalytics"
  },
  Expensetracking: {
    component: Expensetracking,
    path: "/Expensetracking"
  },
  InventoryManagement: {
    component: InventoryManagement,
    path: "/InventoryManagement"
  },
  ReviewApprovalAdmin: {
    component: ReviewApprovalAdmin,
    path: "/ReviewApprovalAdmin"
  },
  ReviewApprovalBasicUser: {
    component: ReviewApprovalBasicUser,
    path: "/ReviewApprovalBasicUser"
  },
  CreateEditProjectPortfolio: {
    component: CreateEditProjectPortfolio,
    path: "/ManageProjectPortfolio"
  },
  ProjectPortfolioDetail: {
    component: ProjectPortfolioDetail,
    path: "/ProjectPortfolioDetail"
  },
  Tabletsupport63: {
    component: Tabletsupport63,
    path: "/Tabletsupport63"
  },
  Cftallyintegration2: {
    component: Cftallyintegration2,
    path: "/Cftallyintegration2"
  },
  LandingPage: {
    component: LandingPage,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  Projects: {
    component: ProductionHouseProjects,
    path: "/Projects",
  },
  ProjectDetailRequest: {
    component: ProjectDetailRequest,
    path: "/ProjectDetailRequest"
  },
  RequestProfileJobRoleProjectPH:{
    component:RequestProfileJobRoleProjectPH,
    path:'/RequestProfileJobRoleProjectPH'
  },
  InviteVendorProfilePD:{
    component:InviteVendorProfilePD,
    path:"/InviteVendorProfilePD"
  },
  InviteVendorListPD:{
    component:InviteVendorListPD,
    path:"/InviteVendorListPD"
  }
,
  InviteMember: {
    component: VendorManageProfile,
    path: "/InviteMember"
  },
  InvoiceBilling: {
    component: InvoiceBilling,
    path: "/InvoiceBilling"
  },
  AllInvoiceRequests:{
    component:AllInvoiceRequests,
    path:"/AllInvoiceRequests"
  },
  AddNewProject:{
    component:AddProjects,
    path:"/AddNewProject"
  },
  PersonalDetailInviteVendor:{
    component:PersonalDetailInviteVendor,
    path:"/PersonalDetailInviteVendor"
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI?.analytics();
    defaultAnalytics?.logEvent('APP_Loaded');
    return (
      <div>

        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </div>
    );
  }
}

export default App;